import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import { GoogleMapReact,LocationPin } from 'google-maps-react';

const MeterTesting = () => {
    const location = {
        address: '1600 Amphitheatre Parkway, Mountain View, california.',
        lat: 37.42216,
        lng: -122.08427,
      }
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      MeterTesting
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <div className="tab-content tab_main">
                      <div className="tab-pane fade show active" id="login1">
                        <div className="main_wrappersd">
                          <div className="row">
                            <div className="col-md-7">
                              <div className="companysection">
                                <legend>THIRD PARTY METER TESTING</legend>
                                <p>
                                Get your Electric Meters tested through <b>NABL Accredited Meter Testing Laboratory.</b> An independent laboratory having state of the Art Meter Testing Bench with a capacity to test 20 Meters at a given point of time.
                                </p>
                                <p>
                                Meter Testing Reports are validated by Assessors of NABL (a government body) on yearly basis.
                                </p>
                                <p><b>To know about the Meter Testing charges, please write to us on nablmtl@noidapower.com or visit us at the below mentioned address</b></p>
                                <div style={{backgroundColor:"blue",color:"white",display:"flex",justifyContent:"center",alignItems:'center'}}>
                                    <p><div>NPCL Meter Testing Laboratory</div><br/>33/11 KV Substation cum Office<br/>Near Overhead Water Reservoir<br/>Sector 37, Greater Noida<br/>U.P. 201310<br/>Timing: 10:00 to 18:00<br/>Working Days: Monday- Friday</p>
                                </div>
                                <div className="cost_pdf">
                                {/* <GoogleMapReact
                                  bootstrapURLKeys={{ key: 'AIzaSyBThqSjw0lNcgdIiqKA5roCCIVPuGPzL2Y' }}
                                   defaultCenter={location}
                                  
                                 >
                                <LocationPin
                                 lat={location.lat}
                                lng={location.lng}
                               text={location.address}
                                    />
                              </GoogleMapReact> */}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="companyimg">
                                <img src="assets/img/meter_testing.jpg" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MeterTesting;
