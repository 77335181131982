import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";

const ChallagesAhead = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/about-banner.png" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <div className="innerpagebanner"></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home</a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/" className="active">
                      Challenges Ahead
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/company-profile"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Profile
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/energy-services"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Energy Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/initiativeundertaken"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Initiative Undertaken
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/our-customer"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Our Customer
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/challangeahead"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Challenges Ahead
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://iwebapps.noidapower.com:8032/awards_recognition.aspx"
                        target="_blank"
                        className="nav-link"
                        //data-toggle="tab"
                      >
                        Award & Recognition
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/credit-rating"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Credit Rating
                      </Link>
                    </li>
                  </ul>
                  <div className="row companysection com_profile">
                    <div className="col-md-7">
                      <legend>CHALLENGES AHEAD</legend>
                      <p>
                        The challenge ahead lies in nurturing and retaining
                        talent in an environment of increasing private sector
                        participation in the utility business.
                      </p>
                      <p>
                        Institutionalising HR practices will be a major task in
                        building a performance-driven organisation. It is also
                        imperative for pursuing the business goal of being a
                        quality supplier at an affordable price.
                      </p>
                      <p>
                        The aim is to build a responsive organisation dedicated
                        to people, processes, and practices. NPCL intends to
                        ensure the least turn-around time, provide the ‘best in
                        className’ services to customers, and balance growth
                        with profitability.
                      </p>
                    </div>
                    <div className="col-md-5">
                      <div className="companyimg">
                        <img src="assets/img/challages-ahead.jpg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ChallagesAhead;
