import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const SMSBasedServices = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/qms-real-time.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/SMS_based_Services" className="active">
                      SMS BASED SERVICES
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <div style={{ textAlign: "center", color: "#017abf" }}>
                      SMS BASED SERVICES (SMS FROM REGISTERED MOBILE NUMBER TO
                      AVAIL THE SERVICES )
                    </div>{" "}
                    <hr />
                    <p style={{ textAlign: "center" }}>
                      (Type Short SMS Code -space- your consumer number and send
                      to 7840002288, eg- #NOPOWER 2XXXXXXXXX)
                    </p>
                    <hr />
                    <div className="tab-content" id="Calender">
                      <table
                        id="InteractionDetail"
                        className="table table-responsive  abcd "
                        style={{ border: "1px solid #ddd" }}
                      >
                        <tr>
                          <th>S.No</th>
                          <th>Short SMS Codes</th>
                          <th>Purpose</th>
                        </tr>
                        <tr>
                          <td className="col-xs-6 lr_gutter">1</td>
                          <td className="col-xs-6 lr_gutter">
                            #SELFREADING -space- 2XXXXXXXXX -space- READING
                          </td>
                          <td className="col-xs-6 lr_gutter">
                            {" "}
                            To provide self meter reading
                          </td>
                        </tr>
                        <tr>
                          <td className="col-xs-6 lr_gutter">2</td>
                          <td className="col-xs-6 lr_gutter">
                            #BILLDISPUTE -space- 2XXXXXXXXX
                          </td>
                          <td className="col-xs-6 lr_gutter">
                            To register billing dispute complaint
                          </td>
                        </tr>
                        <tr>
                          <td className="col-xs-6 lr_gutter">3</td>
                          <td className="col-xs-6 lr_gutter">
                            #DUEAMT -space- 2XXXXXXXXX
                          </td>
                          <td className="col-xs-6 lr_gutter">
                            To know bill amount, due for payment
                          </td>
                        </tr>
                        <tr>
                          <td className="col-xs-6 lr_gutter">4</td>
                          <td className="col-xs-6 lr_gutter">
                            #DUPBILL -space- 2XXXXXXXXX
                          </td>
                          <td className="col-xs-6 lr_gutter">
                            {" "}
                            To receive the bill on registered E-Mail ID
                          </td>
                        </tr>
                        <tr>
                          <td className="col-xs-6 lr_gutter">5</td>
                          <td className="col-xs-6 lr_gutter">
                            #METERBURNT -space- 2XXXXXXXXX
                          </td>
                          <td className="col-xs-6 lr_gutter">
                            To register meter burnt complaint
                          </td>
                        </tr>
                        <tr>
                          <td className="col-xs-6 lr_gutter">6</td>
                          <td className="col-xs-6 lr_gutter">
                            #METERDEFECTIVE -space- 2XXXXXXXXX
                          </td>
                          <td className="col-xs-6 lr_gutter">
                            To register meter defective complaint
                          </td>
                        </tr>
                        <tr>
                          <td className="col-xs-6 lr_gutter">7</td>
                          <td className="col-xs-6 lr_gutter">
                            #NOPOWER -space- 2XXXXXXXXX
                          </td>
                          <td className="col-xs-6 lr_gutter">
                            {" "}
                            To register No supply complaint
                          </td>
                        </tr>
                        <tr>
                          <td className="col-xs-6 lr_gutter">8</td>
                          <td className="col-xs-6 lr_gutter">
                            #STATUS -space- 2XXXXXXXXX -space- Complaint number
                          </td>
                          <td className="col-xs-6 lr_gutter">
                            To know the status of existing complaint
                          </td>
                        </tr>
                        <tr>
                          <td className="col-xs-6 lr_gutter">9</td>
                          <td className="col-xs-6 lr_gutter">#THEFT</td>
                          <td className="col-xs-6 lr_gutter">
                            To register electricity theft complaint
                          </td>
                        </tr>
                        <tr>
                          <td className="col-xs-6 lr_gutter">10</td>
                          <td className="col-xs-6 lr_gutter">
                            #WRONGREADING -space- 2XXXXXXXXX
                          </td>
                          <td className="col-xs-6 lr_gutter">
                            To register wrong reading complaint
                          </td>
                        </tr>
                      </table>
                      <div>
                        {" "}
                        <span style={{ color: "red" }}>2XXXXXXXXX</span> is the
                        10 digit consumer no. printed on the first page of the
                        bill{" "}
                      </div>
                      <div>
                        You can register your Mobile number by clicking on{" "}
                        <span style={{ color: "#017abf" }}>
                          Sign In/ Register
                        </span>{" "}
                        link or calling our 24*7 Call Centre No. 0120-6226666
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SMSBasedServices;
