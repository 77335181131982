import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
const CompanyProfile = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/about-banner.png" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <div className="innerpagebanner"></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home</a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/" className="active">
                      Company Profile
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/company-profile"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Profile
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/energy-services"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Energy Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/initiativeundertaken"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Initiative Undertaken
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/our-customer"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Our Customer
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/challangeahead"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Challenges Ahead
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://iwebapps.noidapower.com:8032/awards_recognition.aspx"
                        target="_blank" rel="noreferrer"
                        className="nav-link"
                        //data-toggle="tab"
                      >
                        Award & Recognition
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/credit-rating"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Credit Rating
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content tab_main">
                    <div className="tab-pane fade show active" id="login1">
                      <div className="main_wrappersd">
                        <div className="row">
                          <div className="col-md-7">
                            <div className="companysection">
                              <legend>COMPANY PROFILE</legend>
                              <strong className="wrapper-strong-style">
                                Powering lives. Empowering dreams.
                              </strong>
                              <p>
                                Developed as an industrial hub and urban
                                settlement, Noida Power Company Limited is a
                                power distribution company that illuminates
                                Greater Noida by reaching out to a population of
                                approximate a million sprawling across hamlets,
                                villages, and a new township spanning an area of
                                335 sq. km.
                              </p>

                              <p>
                                As a joint venture between the RP-Sanjiv Goenka
                                Group, a leading business house in India, and
                                Greater Noida Industrial Development Authority,
                                an autonomous body of the U.P. Government
                                responsible for town planning and infrastructure
                                development, it gives a strategic dimension to
                                the privatized distribution of electricity in
                                North India. Since December 1993 after getting
                                the grant of license by the U.P. Government, it
                                has been spreading smiles all across the miles
                                with its strong distribution network.
                              </p>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="companyimg">
                              <img src="assets/img/npcl-profile1.jpg" alt="NPCL" />
                            </div>
                          </div>
                        </div>
                        <div className="row powertogrow">
                          <div className="col-md-6">
                            <div className="bakpower">
                              <h3>
                                Power to grow.
                                <br />
                                Power to do.
                              </h3>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="companysection">
                              <h3>OPERATIONS</h3>
                              <strong className="wrapper-strong-style">
                                Power to grow. Power to do.
                              </strong>
                              <p>
                                The Company executed an Agreement with the
                                erstwhile U.P. State Electricity Board (now U.P.
                                Power Corporation Limited) in November 1993 for
                                transfer of the supply arrangements and sourcing
                                of bulk power. Currently, the peak load served
                                is 446 MW as against 17 MW in 1994-95,
                                reflecting a steady increase in consumer demand.
                              </p>
                              <p>
                                From 4677 in 1993 to 1,06,707 in March 2021, it
                                has been witnessing an expansion in its customer
                                base. Its load profile is primarily dominated by
                                large and heavy industries, constituting 49% of
                                energy sales. Urban, rural, institutional, and
                                smaller industrial consumers account for the
                                balance of the business.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CompanyProfile;
