import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const Career = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/careers.jpg" + ")",
              backgroundSize: "100 % auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center"></div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/career" className="active">
                      Careers
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <ul className="nav nav-tabs nav_tbds">
                      <li className="nav-item">
                        <Link
                          to="/current-openings"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          Current Openings{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/career"
                          className="nav-link active"
                          data-toggle="tab"
                        >
                          Upload Your CV{" "}
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content tab_main">
                      <div className="tab-pane fade show active" id="uploadcv">
                        <div className="profile_cont">
                          <h2>UPLOAD YOUR CV</h2>
                          <div className="col-md-12">
                            <legend>CV Upload</legend>
                            <form
                              action="careers_submit"
                              method="get"
                              accept-charset="utf-8"
                            >
                              <div className="firstpage">
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">Job Id</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="jobid"
                                        placeholder="Job Id"
                                        name="jobid"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Name <span>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Name"
                                        placeholder="Name "
                                        name="Name"
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Father's Name <span>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="fathersname"
                                        placeholder="Father's Name"
                                        name="fathersname"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Date of Birth <span>*</span>
                                      </label>
                                      <input
                                        type="datetime"
                                        className="form-control"
                                        id="DateofBirth"
                                        placeholder="dd/mm/yyyy"
                                        name="DateofBirth"
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Gender <span>*</span>
                                      </label>
                                      <select className="form-control">
                                        <option>Mr.</option>
                                        <option>Ms.</option>
                                      </select>{" "}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Date of Birth <span>*</span>
                                      </label>
                                      <input
                                        type="datetime"
                                        className="form-control"
                                        id="DateofBirth"
                                        placeholder="Date of Birth "
                                        name="DateofBirth"
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Email <span>*</span>
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="Email "
                                        placeholder="Email "
                                        name="Email"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        City <span>*</span>
                                      </label>
                                      <input
                                        type="datetime"
                                        className="form-control"
                                        id="City "
                                        placeholder="City "
                                        name="City "
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Current Address <span>*</span>
                                      </label>
                                      <textarea
                                        type=""
                                        className="form-control"
                                        id="CurrentAddress "
                                        placeholder="Current Address "
                                        name="CurrentAddress"
                                        required
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Nationality <span>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Nationality "
                                        placeholder="Nationality"
                                        name="Nationality  "
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        State <span>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="State "
                                        placeholder="State"
                                        name="State"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        PIN <span>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="PIN "
                                        placeholder="PIN "
                                        name="PIN "
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Phone Number <span>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="PhoneNumber "
                                        placeholder="Phone Number "
                                        name="PhoneNumber"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Mobile Number <span>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="MobileNumber "
                                        placeholder="Mobile Number "
                                        name="MobileNumber "
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-4 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <input
                                        type="submit"
                                        className="btn btn-primary form-control submit_btsd"
                                        id="submit "
                                        placeholder="submit "
                                        name="PhoneNumber"
                                        value="Save and Continue"
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="secondpage">
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Total Experience <span>*</span>
                                      </label>
                                      <select className="form-control">
                                        <option>-select-</option>
                                        <option>Fresser.</option>
                                        <option>1 year</option>
                                        <option>2 years</option>
                                        <option>3 years</option>
                                        <option>4 years</option>
                                        <option>5 years</option>
                                        <option>6 years</option>
                                        <option>7 years</option>
                                        <option>8 years</option>
                                        <option>9 years</option>
                                        <option>10 years</option>
                                        <option>11 years</option>
                                        <option>12 years</option>
                                        <option>13 years</option>
                                        <option>14 years</option>
                                        <option>15 years</option>
                                        <option>16 years</option>
                                        <option>17 years</option>
                                        <option>18 years</option>
                                      </select>{" "}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Total Experience <span>*</span>
                                      </label>
                                      <select className="form-control">
                                        <option>-select-</option>
                                        <option>1 Month</option>
                                        <option>2 Months</option>
                                        <option>3 Months</option>
                                        <option>4 Months</option>
                                        <option>5 Months</option>
                                        <option>6 Months</option>
                                        <option>7 Months</option>
                                        <option>8 Months</option>
                                        <option>9 Months</option>
                                        <option>10 Months</option>
                                        <option>11 Months</option>
                                      </select>{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Total CTC <span>*</span>
                                      </label>
                                      <select className="form-control">
                                        <option>-select-</option>
                                        <option>Fresser.</option>
                                        <option>1 Lakhs</option>
                                        <option>2 Lakhs</option>
                                        <option>3 Lakhs</option>
                                        <option>4 Lakhs</option>
                                        <option>5 Lakhs</option>
                                        <option>6 Lakhs</option>
                                        <option>7 Lakhs</option>
                                        <option>8 Lakhs</option>
                                        <option>9 Lakhs</option>
                                        <option>10 Lakhs</option>
                                        <option>11 Lakhs</option>
                                        <option>12 Lakhs</option>
                                        <option>13 Lakhs</option>
                                        <option>14 Lakhs</option>
                                        <option>15 Lakhs</option>
                                        <option>16 Lakhs</option>
                                        <option>17 Lakhs</option>
                                        <option>18 Lakhs</option>
                                        <option>18 Lakhs</option>
                                        <option>19 Lakhs</option>
                                      </select>{" "}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Total CTC <span>*</span>
                                      </label>
                                      <select className="form-control">
                                        <option>-select-</option>
                                        <option>5 Thousand</option>
                                        <option>10 Thousand</option>
                                        <option>15 Thousand</option>
                                        <option>20 Thousand</option>
                                        <option>25 Thousand</option>
                                        <option>30 Thousand</option>
                                        <option>35 Thousand</option>
                                        <option>40 Thousand</option>
                                        <option>45 Thousand</option>
                                        <option>50 Thousand</option>
                                        <option>55 Thousand</option>
                                        <option>60 Thousand</option>
                                        <option>65 Thousand</option>
                                        <option>70 Thousand</option>
                                        <option>75 Thousand</option>
                                        <option>80 Thousand</option>
                                        <option>85 Thousand</option>
                                        <option>90 Thousand</option>
                                        <option>95 Thousand</option>
                                      </select>{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Expected CTC <span>*</span>
                                      </label>
                                      <select className="form-control">
                                        <option>-select-</option>
                                        <option>Fresser.</option>
                                        <option>1 Lakhs</option>
                                        <option>2 Lakhs</option>
                                        <option>3 Lakhs</option>
                                        <option>4 Lakhs</option>
                                        <option>5 Lakhs</option>
                                        <option>6 Lakhs</option>
                                        <option>7 Lakhs</option>
                                        <option>8 Lakhs</option>
                                        <option>9 Lakhs</option>
                                        <option>10 Lakhs</option>
                                        <option>11 Lakhs</option>
                                        <option>12 Lakhs</option>
                                        <option>13 Lakhs</option>
                                        <option>14 Lakhs</option>
                                        <option>15 Lakhs</option>
                                        <option>16 Lakhs</option>
                                        <option>17 Lakhs</option>
                                        <option>18 Lakhs</option>
                                        <option>18 Lakhs</option>
                                        <option>19 Lakhs</option>
                                      </select>{" "}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Expected CTC <span>*</span>
                                      </label>
                                      <select className="form-control">
                                        <option>-select-</option>
                                        <option>5 Thousand</option>
                                        <option>10 Thousand</option>
                                        <option>15 Thousand</option>
                                        <option>20 Thousand</option>
                                        <option>25 Thousand</option>
                                        <option>30 Thousand</option>
                                        <option>35 Thousand</option>
                                        <option>40 Thousand</option>
                                        <option>45 Thousand</option>
                                        <option>50 Thousand</option>
                                        <option>55 Thousand</option>
                                        <option>60 Thousand</option>
                                        <option>65 Thousand</option>
                                        <option>70 Thousand</option>
                                        <option>75 Thousand</option>
                                        <option>80 Thousand</option>
                                        <option>85 Thousand</option>
                                        <option>90 Thousand</option>
                                        <option>95 Thousand</option>
                                      </select>{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Functional Area <span>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="FunctionalArea "
                                        placeholder="Functional Area"
                                        name="FunctionalArea "
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="mobilenu">
                                        Key Skills<span>*</span>
                                      </label>
                                      <textarea
                                        type=""
                                        className="form-control"
                                        id="KeySkills "
                                        placeholder="Key Skills"
                                        name="KeySkills"
                                        required
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 secondpage">
                                <table className="table table-responsive table_desns">
                                  <tr>
                                    <th></th>
                                    <th>Marks</th>
                                    <th>Year</th>
                                    <th>Subject/Stream</th>
                                    <th>University</th>
                                  </tr>
                                  <tr>
                                    <td>10th</td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="tenthmarks"
                                        placeholder="Enter Marks"
                                        name="tenthmarks"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="year"
                                        placeholder="year"
                                        name="year"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Subject/Stream	"
                                        placeholder="Subject/Stream	"
                                        name="Subject/Stream	"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Board/University"
                                        placeholder="Board/University
																									"
                                        name="Board/University
																									"
                                        required
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>12th</td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="tenthmarks"
                                        placeholder="Enter Marks"
                                        name="tenthmarks"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="year"
                                        placeholder="year"
                                        name="year"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Subject/Stream	"
                                        placeholder="Subject/Stream	"
                                        name="Subject/Stream	"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Board/University"
                                        placeholder="Board/University
																										"
                                        name="Board/University
																										"
                                        required
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Graduate Level</td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="tenthmarks"
                                        placeholder="Enter Marks"
                                        name="tenthmarks"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="year"
                                        placeholder="year"
                                        name="year"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Subject/Stream	"
                                        placeholder="Subject/Stream	"
                                        name="Subject/Stream	"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Board/University"
                                        placeholder="Board/University
																											"
                                        name="Board/University
																											"
                                        required
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Post Graduation </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="tenthmarks"
                                        placeholder="Enter Marks"
                                        name="tenthmarks"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="year"
                                        placeholder="year"
                                        name="year"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Subject/Stream	"
                                        placeholder="Subject/Stream	"
                                        name="Subject/Stream	"
                                        required
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Board/University"
                                        placeholder="Board/University
																												"
                                        name="Board/University
																												"
                                        required
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              <div className="row">
                                <div className="form-group form_gp_des">
                                  <label for="mobilenu">
                                    Other <span>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="other "
                                    placeholder="Other"
                                    name="other "
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 col-sm-12 col-xs-12">
                                  <div className="form-group form_gp_des">
                                    <input
                                      type="text"
                                      className="btn btn-secondary form-control cancelbtns"
                                      id="FunctionalArea "
                                      name="FunctionalArea "
                                      value="Previous"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-sm-12 col-xs-12">
                                  <div className="form-group form_gp_des">
                                    <input
                                      type="text"
                                      className="btn btn-secondary form-control cancelbtns"
                                      id="FunctionalArea "
                                      name="FunctionalArea "
                                      value="Skip"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-sm-12 col-xs-12">
                                  <div className="form-group form_gp_des">
                                    <input
                                      type="text"
                                      className="btn btn-primary form-control submit_btsd"
                                      id="FunctionalArea "
                                      name="FunctionalArea "
                                      value="Save and Continue"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="fourthsec">
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label>
                                        Resume Headline <span>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="resume "
                                        name="resume "
                                        required
                                      />
                                      <div className="allowed_file">
                                        (Only JPEG, PDF or DOC file less than 5
                                        MB is allowed)
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    captcha
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-4 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <input
                                        type="text"
                                        className="btn btn-primary form-control submit_btsd"
                                        id=" "
                                        name=" "
                                        value="Submit"
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Career;
