import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Announcement from "../components/Announcement";

const BillingComplaintResolution = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/payment-collection.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center"></div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center"></div>
            <div className="col-sm-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#">Home </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link href="#" className="active">
                    Payment Options
                  </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/billing-complaint-resolution" className="">
                    Billing Complaint Resolution
                  </Link>
                </li>
              </ol>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <ul className="nav nav-tabs nav_tbds">
                        <li className="nav-item">
                          <Link
                            to="/cash-collection-centres"
                            className="nav-link  "
                            data-toggle="tab"
                          >
                            Cash/Cheque Collection Centres
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            href="https://iwebapps.noidapower.com:8032/DigitalPayment_Centre.aspx"
                            className="nav-link"
                          >
                              Digital Payment Centres
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/cheque-drop-box"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Cheque Drop Box
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/online-payment"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Online Payment
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/rtgs-and-neft-payment"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            RTGS and NEFT Payment
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/billing-complaint-resolution"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            Billing Complaint Resolution
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/nach"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            NACH
                          </Link>
                        </li>
                      </ul>

                      <div className="row cashcollectioncenter">
                        <legend>BILLING COMPLAINTS RESOLUTION</legend>
                        <h3>Procedure</h3>
                        <div className="innwrper">
                          <p>
                            You may approach our Customer Care Office in case of
                            a billing complaint along with necessary documents
                            including previous bills receipts, etc.
                            Alternatively, you may also send your relative or
                            representative with documents.We endeavour to
                            resolve most of the complaints across the table
                            through discussion.
                          </p>
                        </div>
                        <div className="innwrper">
                          <p>
                            All complaints after verification are resolved and
                            communicated to the complainant. Adjustments if any
                            are provided in the next bill.
                          </p>
                        </div>
                        <div className="innwrper">
                          <p>
                            Consumer may approach
                            <Link to="/grievence" style={{marginLeft:"7px",marginRight:"7px",textDecortion:"none"}} target="_blank">
                              "Consumer Grievance Redressal Forum"
                            </Link>
                            of the Company if he is not satisfied by the
                            decision.
                          </p>
                        </div>
                        <ul className="fullwidthpar">
                          <li>
                            <div className="cost_pdf">
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="assets/pdf/Consumer_Grivevance_Redressal_Forum_Electricity_Ombudsman.pdf"
                                target="_blank"
                              >
                                Consumer Grievance Redressal Forum (English)
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className="cost_pdf">
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="assets/pdf/Consumer_Grievance_Redressal_Forum_Electricity_ Ombudsman_Hindi.pdf"
                                target="_blank"
                              >
                                Consumer Grievance Redressal Forum (Hindi)
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BillingComplaintResolution;
