import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const FeedBackForm = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="assets/images/supply-related-banner.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Supply Related Complaint</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Portal</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/register-complain">Register a Complaint</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/supply-related-comaplaint" className="active">
                      Supply Related Complaint
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 bg_cols">
                    <div className="form_div wrapper-pd-top-login">
                      <div className="bs-example bs-example-1">
                        <div
                          className="tab-pane fade show active"
                          id="newconnection"
                        >
                          <div className="innerhdnds">
                            <h2>Supply Related Complaint</h2>
                          </div>
                          <ul className="nav nav-tabs nav_tbds" style={{overflow:"hidden"}}>
                            <li className="nav-item">
                              <Link
                                to="/supply-related-comaplaint"
                                className="nav-link "
                                data-toggle="tab"
                              >
                                Planned Outages
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/register"
                                className="nav-link "
                                data-toggle="tab"
                              >
                                Register
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/status"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Status
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/feedbackform"
                                className="nav-link active"
                                data-toggle="tab"
                              >
                                Feedback
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/complaintmanagementprocess"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Complaint Management Process (Guide)
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/faqs"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                FAQs
                              </Link>
                            </li>
                          </ul>
                        </div>{" "}
                      </div>
                      <div className="tab-content tab_main" style={{marginLeft:"20px"}}>
                        <h4 className="mt-2"> Feedback Form </h4>
                        <div className="card-body">
                          <div className="row loginwidth">
                            <div className="col-lg-6">
                              <div className="form-group form_gp_des">
                                <label>Consumer No. (उपभोक्ता संख्या)</label>
                                <input
                                  name="Consumer No"
                                  type="number"
                                  className="form-control"
                                  placeholder="Consumer No."
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group form_gp_des">
                                <label>
                                  Name of the Consumer (उपभोक्ता का नाम)
                                </label>
                                <input
                                  name="password"
                                  type="text"
                                  className="form-control"
                                  placeholder="Consumer Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group form_gp_des">
                                <label>Contact No. (संपर्क नंबर)</label>
                                <input
                                  type="tel"
                                  id="phone"
                                  name="phone"
                                  className="form-control"
                                  placeholder="Contact No"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group form_gp_des">
                                <label>E-Mail ID (ई-मेल आईडी)</label>
                                <input
                                  name="email"
                                  type="text"
                                  className="form-control"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="feedback">
                              <p>Ease of Accessing Information on Website</p>
                              <div className="feedback_ratings">
                                <span className="feedback_rating" data-rate="1">
                                  😒 Poor
                                </span>
                                <span className="feedback_rating" data-rate="2">
                                  😐 OK
                                </span>
                                <span className="feedback_rating" data-rate="3">
                                  😏 Good
                                </span>
                                <span className="feedback_rating" data-rate="4">
                                  😁 Great
                                </span>
                              </div>
                              <div className="feedback-n"></div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group form_gp_des">
                              <label>Suggestion (सुझाव )</label>
                              <textarea
                                rows="4"
                                cols="100"
                                className="form-control"
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <button
                              type="submit"
                              id="submit"
                              className="sub_btn2 submit"
                              name="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default FeedBackForm;
