import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";

import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";

const InitiativeUndertaken = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/about-banner.png" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <div className="innerpagebanner"></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/initiativeundertaken" className="active">
                      Key Initiative
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/company-profile"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Profile
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/energy-services"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Energy Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/initiativeundertaken"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Initiative Undertaken
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/our-customer"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Our Customer
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/challangeahead"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Challenges Ahead
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://iwebapps.noidapower.com:8032/awards_recognition.aspx"
                        target="_blank"
                        className="nav-link"
                        //data-toggle="tab"
                      >
                        Award & Recognition
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/credit-rating"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Credit Rating
                      </Link>
                    </li>
                  </ul>

                  <legend>INITIATIVE UNDERTAKEN</legend>
                  <div className="row_com_profileee">
                    <p>
                      It has been NPCL’s aim to build its core competence around
                      service differentiation and establish a competitive
                      organisation on the strength of cost-efficiency and high
                      productivity. Through this, it intends to ensure long-term
                      sustenance in an environment that values service quality
                      and cost accountability.
                    </p>
                    <strong className="wrapper-strong-style">
                      Technology and Social Intermediation
                    </strong>
                    <p>
                      NPCL understands that the application of innovation and
                      imagination is a must to succeed in the competitive
                      marketplace. That’s why it has gone beyond making promises
                      of reliable and affordable electricity supply and has
                      taken measures to introduce social intermediation in
                      villages to promote the concept of energy conservation and
                      commercialisation. Steps have also been taken to
                      facilitate the metering of domestic supplies. The
                      consumers have responded to these measures by
                      demonstrating commercial behaviour which has resulted in
                      the avoidance of energy thefts and an improved collection
                      of user charges. Currently, 89% of the consumer population
                      in villages is covered under metered supply.
                    </p>

                    <p>
                      NPCL has taken a unique step by organising basic literary
                      classNamees for village women to make them understand the
                      role of electricity in improving lives and to promote
                      gender equality. The success of these measures can be
                      summed up by the comments made by the U.S. Secretary of
                      the Treasury who visited one such site and stated: “Seeing
                      first-hand the tangible quality of life improvements
                      brought by reliable electricity was memorable.”
                    </p>

                    <strong className="wrapper-strong-style">
                      Time and Cost-Effective Measures
                    </strong>
                    <p>
                      To render faster and better consumer services, NPCL has
                      printed pole/feeder pillar numbers on the majority of
                      consumer bills. The same numbers have also been painted on
                      the nearby corresponding objects.
                    </p>
                    <strong className="wrapper-strong-style">
                      Conservation Efforts
                    </strong>
                    <p>
                      NPCL’s innovations in network and demand-side engineering
                      have been highly fruitful in curtailing energy losses. A
                      couple of novel steps taken by NPCL to conserve energy and
                      water and avoid environmental degradation have been the
                      introduction of LT-less distribution or agricultural
                      connections and facilitating energy-efficient pumping. It
                      has also introduced LT-insulated wires for supply in
                      village households. This step has reduced the propensity
                      of energy thefts and has optimised the network
                      performance. Process interventions in metering and billing
                      have ensured the effective plugging of the loopholes
                      related to revenue leakages.
                    </p>
                    <strong className="wrapper-strong-style">
                      Integration of IT
                    </strong>
                    <p>
                      The Company has dispensed off manual entries by following
                      a fail-safe system to transfer the electronic data of
                      meter readings to the bill-processing module. Daily
                      collections are monitored online via the WAN and LAN
                      infrastructure. Currently, the energy losses stand at 8%,
                      the lowest in the country, while the gross collection rate
                      is 99%.
                    </p>
                    <p>
                      To handle the growing size of the business, NPCL relies on
                      IT and automation while focusing on improving
                      productivity, time cycles, and customer relationships.
                      NPCL’s main initiatives in this regard can be summed up
                      as:
                    </p>

                    <ul>
                      <li style={{ listStyle: "none" }}>
                        integrating network, customer, and asset management by
                        leveraging geospatial information
                      </li>
                      <li style={{ listStyle: "none" }}>
                        undertaking remote network sensing and operation in
                        real-time
                      </li>
                      <li style={{ listStyle: "none" }}>
                        improving daily work management by automating the
                        business processes of billing, collection, and human
                        resource management on an SAP platform
                      </li>
                    </ul>
                    <p>
                      Integration of IT and Operational Technologies by NPCL has
                      provided thrust to efficient network grid and asset
                      management across the Company. GIS-based systems
                      integrated with various applications of SAP have been
                      implemented in NPCL. Through this, many mission-critical
                      business applications are being reengineered to provide
                      better consumer services in the areas of providing new
                      service connections, meter testing and replacement, etc.
                      Furthermore, the GIS system assists the Company with
                      efficient asset management for all its assets spread
                      across a wide geographical area. The Outage Management
                      System, which requires the GIS data to address
                      outage-related complaints, has also been made functional.
                      The GIS-based system assists in the planning of newly
                      emerging sectors/areas with power distribution plans along
                      with the construction of substations. It also helps the
                      Company in the handling of upcoming new connections and to
                      determine the technical losses by utilising real-time
                      data. The GIS-based Energy Audit system has been
                      implemented by NPCL to measure the losses and direct the
                      efforts to curb electricity theft; these measures help in
                      the overall loss reduction.
                    </p>

                    <p>
                      The scope of ADMS encompasses the entire distribution
                      network; from substations to LT distribution at consumer
                      premises. It also includes in its scope the backdrop of
                      geographical representation and the requisite network
                      information sourced from the GIS repository; both these
                      factors are subjected to timely updates through a
                      systematic mechanism. All the existing 33/11 KV
                      substations are fully automated and SCADA-enabled. This
                      factor has resulted in completely unmanned remote
                      operations. The ADMS environment also offers an array of
                      Distribution Management System (DMS) applications for
                      executing complex analytics engineering on the basis of
                      authentic SCADA information.
                    </p>

                    <p>
                      The Outage Management System (OMS) system is integrated
                      with the GIS system. They collectively serve as a
                      centralized repository and management for all
                      outage-related complaints of the Company’s power
                      distribution network and interface with customer-facing
                      systems. These systems include the CRM system and the
                      SCADA system. An effective Building Management System has
                      been deployed in the Company’s major buildings, such as
                      the Customer Care Buildings and the Corporate Office.
                    </p>
                    <strong className="wrapper-strong-style">Innovation</strong>

                    <p>
                      The Company has established a state-of-the-art new
                      Technology Centre, which houses both IT and OT
                      infrastructures. The Centre is managed through the
                      Building Management System (BMS). The Centre hosts SCADA
                      and BMS control rooms with round-the-clock operations
                      monitoring facilities for power systems, network
                      operations, and building facility management respectively.
                      Furthermore, the OMS dispatch centre along with the
                      integrated customer call centre also operates
                      round-the-clock through a different building. All these
                      facilities have dedicated operators working in shifts to
                      ensure seamless service delivery. The Division offices are
                      also equipped with the web view of SCADA.
                    </p>

                    <p>
                      The Company has devised a concept of smart substations
                      equipped with all necessary safety and security-centric
                      BMS applications viz. Fire Alarm system, CCTV
                      surveillance, Access Control, Electric Fencing, Public
                      Address System, Energy Management, Facility Management,
                      Rodent Repellent, Water Leak Detection, Wicket gate
                      monitoring, etc. Presently, two completely unmanned smart
                      substations are deployed on a pilot basis. These
                      substations are equipped with all the above-mentioned
                      safety and security features. Many such deployments are
                      expected in the coming years.
                    </p>

                    <p>
                      The critical nodes on the optical fibre communication
                      network of the Company are continuously being monitored
                      through the centralized Fibre Management and Monitoring
                      System (FMMS). The FMMS facilitates immediate and accurate
                      fault detection and location of the network. The Company
                      has also deployed eight weather-monitoring stations at
                      strategic locations for better weather monitoring and
                      subsequent load planning. The Smart grid technologies of
                      FLISR (Fault Location, Isolation, and Service Restoration)
                      and Self-Healing grid application have been deployed on
                      selected 11KV RMU networks. These technologies have
                      enabled faster fault identification, isolation, and
                      restoration of healthy networks with supervised and
                      automated decisions as per technology selection. The
                      Company is looking forward to expanding its coverage
                      further to viable power distribution networks.
                    </p>

                    <p>
                      The Company is also actively engaged in Smart Grid
                      initiatives. Various projects have been deployed or are
                      under deployment at 33KV and 11KV distribution networks
                      viz., real-time control and monitoring of RMUs,
                      Distribution Transformer Health and Load Monitoring, fault
                      passage indicators, load break switches, deployment of
                      smart street light controllers, monitoring of captive and
                      consumer renewable energy plants and identified HV
                      consumers with high demand over SCADA system, etc.
                    </p>

                    <strong className="wrapper-strong-style">
                      Walking hand in hand with latest technology
                    </strong>
                    <p>
                      To keep pace with the changing times, the Company keeps a
                      tap on the latest developments and technologies that are
                      being introduced in the power sector. One such application
                      of the latest technology is Intelligent Load Shedding that
                      provides a boost to the network management for maintaining
                      grid discipline. It is done by optimizing the load demand
                      as per the available power. The Company has also entered
                      into the realm of Machine Learning and Network
                      Intelligence through the deployment of the Integrated Load
                      Forecasting Module, ensuring a better forecast of load
                      demand.
                    </p>
                    <p>
                      The Company’s chatbot “Saathi”, based on the technology of
                      Artificial Intelligence, has been launched on the company
                      website to take care of the inquiries made by the
                      customers. Facilitated with a simple conversation format,
                      the chatbot provides an enriching experience to the
                      customers through a virtual helpdesk.
                    </p>
                    <p>
                      The Company has also introduced a real-time Battery Health
                      Monitoring System (BHMS) for substation batteries. It is
                      among one of the first such utilities in the power
                      distribution sector. The next-generation technologies like
                      the IoT are also under consideration for applications such
                      as DT Monitoring.
                    </p>

                    <p>
                      NPCL has successfully tested the ‘Drone’ technology by
                      conducting a proof of concept to inspect the overhead
                      distribution line for preventive maintenance and
                      vegetation management. The objective behind this step is
                      to arrest the pilferage at low-tension mains and to update
                      the land base as per the requirement. The drone data
                      processing software has already been successfully deployed
                      at the Company's GIS lab. Now the Company is reaping
                      benefits from the Drone technology as per its defined use
                      cases on a regular basis.
                    </p>

                    <p>
                      A sophisticated smart grid lab has been deployed at one of
                      the Company’s offices locations. It is used for the
                      purpose of learning, visualization, and testing new and
                      smart grid technologies viz. GIS applications, SCADA
                      operations, field area network automation systems, peak
                      load management system, demand response system, microgrid
                      system, power quality monitoring, intelligent load
                      shedding, self-healing system, etc. The lab also has a
                      test bench to perform troubleshooting of installed systems
                      and testing of new technologies in the lab environment.
                      The lab remains under continual up-gradation.
                    </p>

                    <p>
                      NPCL envisages new technologies and innovations in order
                      to meet utility requirements in an automotive manner. The
                      Company has successfully deployed the technology of
                      Virtual Reality (VR) with the aim to create interactive
                      and immersive applications. This technology ensures a 360⁰
                      VR experience of any assets with a realistic and
                      new-fangled visualization that is used for training
                      purposes and showcasing the assets remotely. This measure
                      is one of the foremost implementations in power
                      distribution utility undertaken with the help of in-house
                      expertise and skills in the niche technology.
                    </p>

                    <p>
                      NPCL understands that due consideration is necessary to
                      develop the power infrastructure and meet the demand
                      escalation while ensuring business growth. That’s why new
                      avenues are being explored to procure power from
                      alternative sources and to supplement the current intake
                      arrangement to meet the increase in load demand in short
                      and medium terms.
                    </p>

                    <strong className="wrapper-strong-style">
                      Compliance with Government Defined Standards
                    </strong>
                    <p>
                      The Company has been taking steps in compliance with the
                      Honorable UPERC order and the directives of the 13th
                      amendment of the Electricity Supply Code. One of such
                      steps is converting the existing housing societies from a
                      single point to multipoint connections. This is done with
                      the help of smart prepaid meters with dual register and
                      dual recharge feature for Grid and DG supply. This is
                      accomplished by using the RF communication infrastructure
                      for two-way data communication. The Company is also
                      releasing new connections of multi-story residential
                      buildings in compliance with the Honorable UPERC order.
                      The connections being released in multiple point mode are
                      only for the supply of power to individual owners or
                      occupants.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default InitiativeUndertaken;
