import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const DMSwebsite = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="assets/images/Sustaianable.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Npcl Project</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-9 bg_cols">
                  
                    <div className="form_div cashcollectioncenter">
                      <legend>Noida Power Company Limited (NPCL)</legend>
                      <section className="gallery-thumbs">
                        <div className="container">
                          <div className="row g-2 justify-content-center">
                            <div className="col-md-6 col-6"> 
                            <div className="cothumb-inner" data-toggle="modal" data-target=".bd-example-modal-lg">
                                    <img src="assets/images/photo1.jpg"className="img-zoom"/>
                            </div>
                            </div>
                            <div className="col-md-6 col-6"> 
                            <div className="cothumb-inner" data-toggle="modal" data-target=".bd-example-modal-lg1">
                              <img src="assets/images/photo2.jpg"className="img-zoom"/>
                              </div>
                            </div>
                            <div className="col-md-6 col-6"> 
                            <div className="cothumb-inner" data-toggle="modal" data-target=".bd-example-modal-lg2">
                              <img src="assets/images/photo3.jpg"className="img-zoom"/>
                              </div>
                            </div>
                            <div className="col-md-6 col-6"> 
                            <div className="cothumb-inner" data-toggle="modal" data-target=".bd-example-modal-lg3">
                              <img src="assets/images/photo5.jpg"className="img-zoom"/>  
                              </div>
                            </div>
                            <div className="col-md-3 col-6">  
                            <div className="cothumb-inner" data-toggle="modal" data-target=".bd-example-modal-lg4">
                              <img src="assets/images/photo4.png"className="img-zoom"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>


                        <div className="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                    <button type="button" className="close-popup" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div className="modal-body">
                                        <div className="cothumb-outer">
                                            <a href=" https://eeslmart.in/?affiliate=npcl"><img src="assets/images/photo1.jpg" className="img-zoom"/></a>
                                        </div>
                                        <div className="modal-btn"><a>Click on Link</a></div>
                                    </div>
                </div>
              </div>
            </div>
<div className="modal fade bd-example-modal-lg1" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-centered">
    <div className="modal-content">
      <button type="button" className="close-popup" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-body">
                                        <div className="cothumb-outer">
                                            <a href=" https://eeslmart.in/?affiliate=npcl"><img src="assets/images/photo2.jpg" className="img-zoom"/></a>
                                        </div>
                                        <div className="modal-btn"><a>Click on Link</a></div>
                                    </div>
    </div>
  </div>
</div>
<div className="modal fade bd-example-modal-lg2" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-centered">
    <div className="modal-content">
      <button type="button" className="close-popup" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-body">
                                        <div className="cothumb-outer">
                                            <a href=" https://eeslmart.in/?affiliate=npcl"><img src="assets/images/photo3.jpg" className="img-zoom"/></a>
                                        </div>
                                        <div className="modal-btn"><a>Click on Link</a></div>
                                    </div>
    </div>
  </div>
</div>
<div className="modal fade bd-example-modal-lg3" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-centered">
    <div className="modal-content">
      <button type="button" className="close-popup" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-body">
                                        <div className="cothumb-outer">
                                            <a href=" https://eeslmart.in/?affiliate=npcl"><img src="assets/images/photo5.jpg" className="img-zoom"/></a>
                                        </div>
                                        <div className="modal-btn"><a>Click on Link</a></div>
                                    </div>
    </div>
  </div>
</div>
<div className="modal fade bd-example-modal-lg4" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-centered">
    <div className="modal-content">
      <button type="button" className="close-popup" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-body">
                                        <div className="cothumb-outer text-center">
                                            <a href=" https://eeslmart.in/?affiliate=npcl"><img src="assets/images/photo4.png" className="img-zoom qr-img"/></a>
                                        </div>
                                        <div className="modal-btn"><a>Click on Link</a></div>
                                    </div>
    </div>
  </div>
</div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default DMSwebsite;
