import React from "react";
import Header2 from "../components/Header2";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import {useState} from 'react'
import Table from "../components/Table";

const NewServiceConnectionCalculate = () => {
  const [openDomestic,setOpenDomestic] = useState(true)
  const [openNonDomestic,setOpenNonDomestic] = useState(false)

  const open1 = ()=>{
  setOpenDomestic(true);
  setOpenNonDomestic(false);
  }
  const open2 = ()=>{
    setOpenNonDomestic(true);
    setOpenDomestic(false);
  }
 
    const [table,setTable]=useState([])
    const clickHandler = ()=>{
    setTable([...table,"d"])
  }
  const removeHandler = ()=>{
  const q= table.length-1;
  let [first,...rest] = table;
  setTable(rest)
  }
  return (
    <>
         <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods consumersec">
        <div className="upperdesignds">
      <div className="row">
        <div className="col-md-1">
        <img className="bgstlsd" src="assets/img/small/payment-options.jpg" alt=""/>
      </div>
      <div className="col-md-11 textdesing">
              <h2 className="headtit">New Connection</h2>
      </div>
      </div>
    </div>
        <div className="container">
        <div className="row justify-content-center padtops">
        <div className="col-sm-12 form_div" style={{marginTop:"20px"}}>
        <ul className="parentClass">
            <li><label><input type="radio" value name="anything" className="radioCls" id="yes" onClick={open1} defaultChecked /> Domestic</label></li>
            <li><label><input type="radio" value name="anything" className="radioCls" id="no" onClick={open2}/> Non-Domestic</label></li>
          </ul>
        </div>
        </div>

        {openDomestic ? ( <div className="" id="first">
        <ul className="nav nav-pills" role="tablist">
        <li className="nav-item">
         <Link className="nav-link" data-toggle="pill" to="/new-service-connection">Apply</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link active" data-toggle="pill" to="/new-service-connection-calculate">Calculate Your Load</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-document">Document Checklist</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-sample">Sample Documents</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-online">Online Application</Link>
       </li>
        </ul>
        <div id="loadcalcu" className="container tab-pane"><br/>
        <h4 className="mt-2">Calculate Your Energy Consumption and Load(Domestic)</h4>
      
      <div className="row">
            <div className="col-sm-12">
            <table className="table" style={{width: "100%", border:"1px #ccc solid"}}>
  <thead>
    <tr className="top_heading">
      <th>Appliances</th>
      <th>(Watts)</th>
      <th>No. of Equipment</th>
      <th>Load (Watts)</th>
      <th>Average Hours</th>
    <th>Approximate Units/Month</th>
    <th></th>
    </tr>
    <Table/>
  {table.map(()=><Table/>)}
  
  </thead>
  <tbody id="tableBody"  className="calculate_cont"></tbody>
  
</table> 
<button className="btn add_btn" id="addRow" onClick={()=>clickHandler()}>Add Application</button>
<button className="btn add_btn" id="addRow" onClick={()=>removeHandler()}>Remove Application</button>
<div className="row total_box">
    <div className="col-sm-3">&nbsp;</div>
      <div className="col-sm-2">
            <p>Total Load</p>
            </div>
            <div className="col-sm-2">
      <input type="text" name="name" className="appl_box" required/>
      </div>
      <div className="col-sm-3">
      <p>Total Consumption</p>
      </div>
      <div className="col-sm-2">
      <input type="text" name="name" className="appl_box" required/>
      </div>
    </div>
            </div>
            </div>
        </div>
        </div>):null}
       {openNonDomestic ? ( <div className="" id="first">
        <ul className="nav nav-pills" role="tablist">
        <li className="nav-item">
         <Link className="nav-link" data-toggle="pill" to="/new-service-connection">Apply</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link active" data-toggle="pill" to="/new-service-connection-calculate">Calculate Your Load</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-document">Document Checklist</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-sample">Sample Documents</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-online">Online Application</Link>
       </li>
        </ul>
        <div id="loadcalcu" className="container tab-pane"><br/>
        <h4 className="mt-2">Calculate Your Energy Consumption and Load(Non-Domestic)</h4>
      
      <div className="row">
            <div className="col-sm-12">
            <table className="table" style={{width: "100%", border:"1px #ccc solid"}}>
  <thead>
    <tr className="top_heading">
      <th>Appliances</th>
      <th>(Watts)</th>
      <th>No. of Equipment</th>
      <th>Load (Watts)</th>
      <th>Average Hours</th>
    <th>Approximate Units/Month</th>
    <th></th>
    </tr>
    <Table/>
  {table.map(()=><Table/>)}
  
  </thead>
  <tbody id="tableBody"  className="calculate_cont"></tbody>
  
</table>
<button className="btn add_btn" id="addRow" onClick={()=>clickHandler()}>Add Application</button>
<button className="btn add_btn" id="addRow" onClick={()=>removeHandler()}>Remove Application</button>
<div className="row total_box">
    <div className="col-sm-3">&nbsp;</div>
      <div className="col-sm-2">
            <p>Total Load</p>
            </div>
            <div className="col-sm-2">
      <input type="text" name="name" className="appl_box" required/>
      </div>
      <div className="col-sm-3">
      <p>Total Consumption</p>
      </div>
      <div className="col-sm-2">
      <input type="text" name="name" className="appl_box" required/>
      </div>
    </div>
            </div>
            </div>
        </div>
        </div>):null}


        </div>
        </section>
        </div>
    </>
  )
}

export default NewServiceConnectionCalculate




