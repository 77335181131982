import React from "react";
import Announcement from "../components/Announcement";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { FaWhatsapp } from "react-icons/fa";

const WhatsappBasedServices = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/qms-real-time.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/login" className="active">
                      Customer Service
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="wrapper-panku-pd-left">
                          <a
                            href="https://api.whatsapp.com/send?phone=911206226666"
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              className="social-btn whatsapp-color"
                              style={{ display: "flex" }}
                            >
                              <div className="icons8-whatsapp">
                                <FaWhatsapp
                                  style={{
                                    fontSize: "35px",
                                    color: "white",
                                    background: "green",
                                    borderRadius: "50%",
                                    marginRight: "10px",
                                  }}
                                />{" "}
                              </div>
                              <strong>Click to connect</strong>
                            </div>
                          </a>
                          <img src="assets/img/NPCL_Mascot.png" alt="" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="only-my-class-style-additional-step-1">
                          <img src="assets/img/fav-whatsappmobile.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div only-my-class-style-additional">
                      <h3>WHATSAPP SERVICES</h3>

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <img
                            src="https://iwebapps.noidapower.com:8032/images/WhatsApp_Creative.png"
                            id="wimg"
                            className="img-fluid"
                            style={{ maxWidth: "100%", paddingLeft: "0px " }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default WhatsappBasedServices;
