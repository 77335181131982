import React, { useState } from "react";
import { Link } from "react-router-dom";
import SvgDesign from "./SvgDesign";
import { BsPlusLg } from "react-icons/bs";
// import { $, jQuery } from "jquery";
// import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "/node_modules/bootstrap/dist/js/bootstrap.bundle";
const FixMenu = () => {
  const [about, setAbout] = useState(false);
  const [regulation, setRegulation] = useState(false);
  const [procurement, setProcurement] = useState(false);
  const [carrer, setCarrer] = useState(false);

  const openAbout = () => {
    setAbout(!about);
  };
  const openRegulation = () => {
    setRegulation(!regulation);
  };

  const openProcurement = () => {
    setProcurement(!procurement);
  };

  const openCarrer = () => {
    setCarrer(!carrer);
  };
  return (
    <>
      <div className="fixmnu" id="fixmnu">
        <div className="d-flex w-100">
          <div className="mnuinr">
            <Link to="/Home" className="navlgo">
              <img
                className="lazyload"
                src="assets/img/logo.png"
                alt="logo"
                width="180"
              />
            </Link>
            <div
              id="jquery-accordion-menu"
              className="jquery-accordion-menu red"
            >
              <div className="jquery-accordion-menu-header" id="form"></div>
              <ul id="demo-list">
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={openAbout}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    About NPCL <BsPlusLg />
                  </Link>
                  {about ? (
                    <ul className="submenuh">
                      <li>
                        <Link to="/company-profile">Company Profile</Link>
                      </li>
                                       <li>
                        <Link to="/corporate-policies">Corporate Policies</Link>
                      </li>
                      <li>
                      <a
                          href="https://iwebapps.noidapower.com:8032/download/Notice of 31st AGM-Website.pdf"
                          target="_blank"  rel="noreferrer"
                        >
                          Notice of 31<sup>st</sup> AGM
                        </a>
                      </li>
                      <li>
                      <a
                          href="https://iwebapps.noidapower.com:8032/download/Notice of 32nd AGM - Website.pdf"
                          target="_blank"  rel="noreferrer"
                        >
                          Notice of 32<sup>nd</sup> AGM
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/awards_recognition.aspx"
                          target="_blank"  rel="noreferrer"
                        >
                          Award & Recognition
                        </a>
                      </li>
                      <li>
                        <Link to="/vission-mission">Vision &amp; Mission</Link>
                      </li>
                      <li>
                        <Link to="/rpsg-group-vision-and-core-values">
                          RPSG Group Vision and Core Values
                        </Link>
                      </li>
                      <li>
                        <Link to="/corporate-social-responsibility">
                          Corporate Social Responsibility
                        </Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
                <li>
                  <Link
                    to="#"
                   
                    onClick={openRegulation}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    Regulations <BsPlusLg />
                  </Link>
                  {regulation ? (
                    <ul className="submenuh">
                      {/* <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/Tariff%20applicable%20from%204th%20August%202022.pdf"
                          target="_blank" rel="noreferrer"
                        >
                          Tariff applicable from 4th August 2022
                        </a>
                      </li> */}
                      <li>
                              <Link
                                to="/NPCL-Tariff-Petition-ARR-2024-25"
                                className="dropdown-item"
                              >
                               NPCL Tariff Petition for ARR 2024-25
                              </Link>
                            </li>
                      <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/Tariff%20applicable%20from%206th%20June%202023.pdf"
                          target="_blank" rel="noreferrer"
                        >
                          Tariff applicable from 6th June 2023
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/Tariff Order NPCL FY 2023-24.pdf"
                          target="_blank" rel="noreferrer"
                        >
                         NPCL Tariff Order for FY 2023-24 dated 24th May 2023

                        </a>
                      </li>
                      <li>
                        <Link to="/cost-data-book">Cost Data Book</Link>
                      </li>

                      <li>
                              <Link
                                to="/Fuel-and-Power-Purchase-Cost-Adjustment"
                                className="dropdown-item"
                              >
                               Fuel Surcharge (FPPCA)
                              </Link>
                            </li>
                      {/* <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/RSPV%20Regulation%202019%20dt.%2004-01-2019.pdf"
                          target="_blank" rel="noreferrer"
                        >
                          UPERC Roof top Solar PV regulations 2019
                        </a>
                      </li>
                      <li>
                        <Link to="/tarrif-schedule">Tariff Schedule</Link>
                      </li> */}
                      <li>
                        <Link to="/up-electricity-supply-code">
                          UP Electricity Supply Code
                        </Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
                <li>
                  <Link
                    to="#"
                   
                    onClick={openProcurement}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    Procurement <BsPlusLg />
                  </Link>
                  {procurement ? (
                    <ul className="submenuh">
                      <li>
                        <Link to="/online-vendor-registration">
                          Online Vendor Registration
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-for-eprocurement">
                          Contact for e-Procurement
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-for-power-procurement">
                          Contact for Power-Procurement
                        </Link>
                      </li>
                      <li>
                        <Link to="/tenders">Tenders</Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={openCarrer}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    Careers <BsPlusLg />
                  </Link>
                  {carrer ? (
                    <ul
                      className="submenuh"
                      // style={{
                      //   display: "flex",
                      //   justifyContent: "space-between",
                      // }}
                    >
                      <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/current_opening.aspx"
                          target="_blank" rel="noreferrer"
                        >
                          Current Openings
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/cv_upload.aspx"
                          target="_blank" rel="noreferrer"
                        >
                          Upload Your CV
                        </a>
                      </li>
                    </ul>
                  ) : null}
                </li>
                <li>
                  <Link to="/consumer-portal">Consumer Portal</Link>
                </li>
              </ul>
            </div>
            <ul className="follow_us_icon">
              <li className="followuson">Follow us on -</li>
              {/* <li>
                <Link to="#" target="_blank">
                  <img src="assets/img/facebook.png" alt="" />
                </Link>
              </li>
              <li>
                <Link to="#" target="_blank">
                  <img src="assets/img/twitter.png" alt="" />
                </Link>
              </li> */}
              <li>
                <Link
                  to="https://www.youtube.com/channel/UCtR4-FW_xyalLl8nPRBfxMw?view_as=subscriber"
                  target="_blank"
                >
                  <img src="assets/img/youtube.png" alt="" />
                </Link>
              </li>

              {/* <li>
                <Link to="#" target="_blank">
                  <img src="assets/img/pinterest.png" alt="" />
                </Link>
              </li>
              <li>
                <Link to="#" target="_blank">
                  <img src="assets/img/telegram.png" alt="" />
                </Link>
              </li> */}
            </ul>
          </div>
          <SvgDesign />
        </div>
      </div>
    </>
  );
};

export default FixMenu;
