import React from "react";
import Slider from "react-slick";

function ReactCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: true,
  };
  return (
    <>
      <div className="col-sm-12">
        <div className="row justify-content-center">
          <div className="col-sm-10">
            <div className="filterdesign slider">
              <Slider ref={(slider) => (slider = slider)} {...settings}>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=nOVt8nKkBLQ"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/nOVt8nKkBLQ/0.jpg"
                      className="img-responsive imagesizes" alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        Load Augmentation <br />
                          22-09-2020
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=nOVt8nKkBLQ"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png"  alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=Mb0vtONOD30"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/Mb0vtONOD30/0.jpg"
                      className="img-responsive imagesizes"  alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        Prevent leakage current <br />
                         17-06-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=Mb0vtONOD30"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png"  alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=XaGx2XuP3sE"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/XaGx2XuP3sE/0.jpg"
                      className="img-responsive imagesizes" alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                      Do not use electrical cable  <br />
                         17-06-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=XaGx2XuP3sE"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png"  alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=9gBKe4-9jyY"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/9gBKe4-9jyY/0.jpg"
                      className="img-responsive imagesizes"  alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                      Self Submission of Meter Reading <br />
                      05-03-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=9gBKe4-9jyY"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png"  alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=m9EmKtp0UpY"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/m9EmKtp0UpY/0.jpg"
                      className="img-responsive imagesizes"  alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                      Energy Efficiency Tips <br />
                        05-03-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=m9EmKtp0UpY"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png"  alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
               {/* <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=epDCDbinzGQ"
                    target="_blank"
                  >
                    <img
                      src="https://img.youtube.com/vi/epDCDbinzGQ/0.jpg"
                      className="img-responsive imagesizes"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                      Safety Measures at NPCL Customer Care Office <br />
                        27-11-2020
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=epDCDbinzGQ"
                        target="_blank"
                      >
                        <img src="assets/img/youtube.png" />
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=X_7VaQQbZM0"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/X_7VaQQbZM0/0.jpg"
                      className="img-responsive imagesizes" alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                     Benefits of Dual Source Meter <br />
                      27-11-2020
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=X_7VaQQbZM0"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png"  alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=mdyEreMOZTw"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/mdyEreMOZTw/0.jpg"
                      className="img-responsive imagesizes"  alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                      Single point to multi-point conversion <br />
                      12-10-2020
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=mdyEreMOZTw"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png"  alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=BLN9oNpdQOE"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/BLN9oNpdQOE/0.jpg"
                      className="img-responsive imagesizes"  alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                      How electricity tariff is fixed <br />
                      01-10-2020
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=BLN9oNpdQOE"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png"  alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=uyPfU8YdPPs"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/uyPfU8YdPPs/0.jpg"
                      className="img-responsive imagesizes"  alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                      Power Theft -How it affects consumers <br />
                      25-09-2020
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=uyPfU8YdPPs"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png"  alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=Haw57k8jnXI"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/Haw57k8jnXI/0.jpg"
                      className="img-responsive imagesizes"  alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                      Know Your Bill <br />
                       24-09-2020
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=Haw57k8jnXI"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png"  alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=JQtAg3VTJug"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/JQtAg3VTJug/0.jpg"
                      className="img-responsive imagesizes"  alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                      Go Digital <br />
                      23-09-2020
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=JQtAg3VTJug"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png"  alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=nOVt8nKkBLQ"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/nOVt8nKkBLQ/0.jpg"
                      className="img-responsive imagesizes"  alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                      Load Augmentation <br />
                      22-09-2020
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=nOVt8nKkBLQ"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png"  alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=n1rKP-632xM"
                    target="_blank" rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/n1rKP-632xM/0.jpg"
                      className="img-responsive imagesizes"  alt="NPCL"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        Prepaid Meters <br />
                         21-09-2020
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=n1rKP-632xM"
                        target="_blank" rel="noreferrer"
                      >
                        <img src="assets/img/youtube.png" alt="NPCL" />
                      </a>
                    </div>
                  </div>
                </div>
                {/*<div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=_TwJBXtX7Kc"
                    target="_blank"
                  >
                    <img
                      src="https://img.youtube.com/vi/_TwJBXtX7Kc/0.jpg"
                      className="img-responsive imagesizes"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        @NPCL Noida <br />
                        05-08-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=_TwJBXtX7Kc"
                        target="_blank"
                      >
                        <img src="assets/img/youtube.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/embed/MpgcpQ3wL7A"
                    target="_blank"
                  >
                    <img
                      src="https://img.youtube.com/vi/MpgcpQ3wL7A/0.jpg"
                      className="img-responsive imagesizes"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        @NPCL Noida <br />
                        05-08-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/embed/MpgcpQ3wL7A"
                        target="_blank"
                      >
                        <img src="assets/img/youtube.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=qhUCvlgcEgI"
                    target="_blank"
                  >
                    <img
                      src="https://img.youtube.com/vi/qhUCvlgcEgI/0.jpg"
                      className="img-responsive imagesizes"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        @NPCL Noida <br />
                        05-08-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=qhUCvlgcEgI"
                        target="_blank"
                      >
                        <img src="assets/img/youtube.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=vg4C_cyTiXM"
                    target="_blank"
                  >
                    <img
                      src="https://img.youtube.com/vi/vg4C_cyTiXM/0.jpg"
                      className="img-responsive imagesizes"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        @NPCL Noida <br />
                        05-08-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=vg4C_cyTiXM"
                        target="_blank"
                      >
                        <img src="assets/img/youtube.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=vg4C_cyTiXM"
                    target="_blank"
                  >
                    <img
                      src="https://img.youtube.com/vi/vg4C_cyTiXM/0.jpg"
                      className="img-responsive imagesizes"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        @NPCL Noida <br />
                        05-08-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=vg4C_cyTiXM"
                        target="_blank"
                      >
                        <img src="assets/img/youtube.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=Zr4IumANlds"
                    target="_blank"
                  >
                    <img
                      src="https://img.youtube.com/vi/Zr4IumANlds/0.jpg"
                      className="img-responsive imagesizes"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        @NPCL Noida <br />
                        05-08-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=Zr4IumANlds"
                        target="_blank"
                      >
                        <img src="assets/img/youtube.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=6SbUDrf8VYM"
                    target="_blank"
                  >
                    <img
                      src="https://img.youtube.com/vi/6SbUDrf8VYM/0.jpg"
                      className="img-responsive imagesizes"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        @NPCL Noida <br />
                        05-08-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=6SbUDrf8VYM"
                        target="_blank"
                      >
                        <img src="assets/img/youtube.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=cwrG-S5pkmU"
                    target="_blank"
                  >
                    <img
                      src="https://img.youtube.com/vi/cwrG-S5pkmU/0.jpg"
                      className="img-responsive imagesizes"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        @NPCL Noida <br />
                        05-08-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=cwrG-S5pkmU"
                        target="_blank"
                      >
                        <img src="assets/img/youtube.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=82VOXaEP5IE"
                    target="_blank"
                  >
                    <img
                      src="https://img.youtube.com/vi/82VOXaEP5IE/0.jpg"
                      className="img-responsive imagesizes"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        @NPCL Noida <br />
                        05-08-2021
                      </h5>
                    </div>
                    <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=82VOXaEP5IE"
                        target="_blank"
                      >
                        <img src="assets/img/youtube.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery_product filter irrigation">
                  <a
                    href="https://www.youtube.com/watch?v=j2ijHU0PdsQ"
                    target="_blank"
                  >
                    <img
                      src="https://img.youtube.com/vi/j2ijHU0PdsQ/0.jpg"
                      className="img-responsive imagesizes"
                    />
                  </a>
                  <div className="warptext youtubediv">
                    <div className="postname">
                      <h5>
                        @NPCL Noida <br />
                        05-08-2021
                      </h5>
                    </div>
                     <div className="posticon">
                      <a
                        href="https://www.youtube.com/watch?v=j2ijHU0PdsQs"
                        target="_blank"
                      >
                        <img src="assets/img/youtube.png" />
                      </a>
                    </div> 
                  </div>
                </div>*/}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ReactCarousel;
