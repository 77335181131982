import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const ContactForPowerProcurement = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" +
                "assets/img/inner/contact-for-power-procurement.jpg" +
                ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>

        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home</a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Contact for Power-Procurement
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/online-vendor-registration"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Online Vendor Registration
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/contact-for-eprocurement"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Contact for e-Procurement
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/contact-for-power-procurement"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Contact for Power Procurement
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/tenders"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Tenders
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content tab_main">
                    <div className="tab-pane fade show active" id="login3">
                      <legend>CONTACT FOR POWER PROCUREMENT</legend>
                      <div className="row">
                        <div className="col-md-6 form-group form_gp_des">
                          <label>
                            Email <span>*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Enter your email id"
                            required
                          />
                        </div>
                        <div className="col-md-6 form-group form_gp_des">
                          <label>
                            Contact Number <span>*</span>
                          </label>
                          <input
                            type="text"
                            name="phone"
                            className="form-control"
                            placeholder="Enter your Contact Number"
                            required
                          />
                        </div>
                        <div className="col-md-6 form-group form_gp_des">
                          <label>
                            Subject <span>*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Enter your Subject"
                            required
                          />
                        </div>
                        <div className="col-md-6 form-group form_gp_des">
                          <label>
                            Description <span>*</span>
                          </label>
                          <textarea
                            name="name"
                            className="form-control"
                            required
                          ></textarea>
                        </div>
                        <div className="col-md-6 form-group form_gp_des">
                          <label>Attachments</label>
                          <input
                            type="file"
                            name="ctl00$ContentPlaceHolder1$FileUpload1"
                            className="form-control"
                          />
                          <span className="fle">
                            (Only PDF file less than 1 MB is allowed)
                          </span>
                        </div>
                        <div className="col-md-6 form-group form_gp_des">
                          <label>
                            Captcha <span>*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder=""
                            required
                          />
                        </div>
                        <div className="col-md-6 form-group form_gp_des">
                          <button
                            type="submit"
                            id="submit"
                            className="sub_btn2 submit"
                            name="submit"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactForPowerProcurement;
