import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";

const EnergyServices = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/about-banner.png" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <div className="innerpagebanner"></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home</a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/" className="active">
                      Energy Services
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/company-profile"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Profile
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/energy-services"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Energy Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/initiativeundertaken"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Initiative Undertaken
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/our-customer"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Our Customer
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/challangeahead"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Challenges Ahead
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://iwebapps.noidapower.com:8032/awards_recognition.aspx"
                        target="_blank"
                        className="nav-link"
                        //data-toggle="tab"
                      >
                        Award & Recognition
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/credit-rating"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Credit Rating
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content tab_main">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="companysection">
                          <legend>SERVICES</legend>
                          <p>
                            The principal role of Noida Power is that of a
                            service provider to support economic and lifestyle
                            activity. Building an efficient and reliable
                            delivery system thus assumes top priority to provide
                            supply dependability. That Greater Noida is witness
                            to the setting up of world-className manufacturing
                            by multinationals like Honda Cars, Yamaha Motors,
                            New Holland Tractors, LG Electronics, ST
                            Microelectronics, India Exposition Mart, GSC
                            Toughened Glass etc., and is also home to an
                            upwardly mobile residential population, underscores
                            this point further.
                          </p>
                          <p>
                            The Company maintains and operates round-the-clock
                            emergency services to deal with supply-related
                            complaints and undertake rectification works. The
                            facilities have been reinforced by establishing a
                            Call Centre that provides 24-hour messaging services
                            to consumers and improves trouble call monitoring.
                            Complaint management is computerised, enabling call
                            tracking from start to finish and generation of
                            ‘exception’ reports.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="companyimg">
                          <img src="assets/img/npcl-service.jpg" />
                        </div>
                      </div>
                    </div>
                    <div className="row com_profile">
                      <div className="col-md-5">
                        <div className="companyimg">
                          <img src="assets/img/energy-pic.jpg" />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="companysection">
                          <h2>Energy Services</h2>
                          <p>
                            The main role of NPCL as a service provider is to
                            support activities related to the economy and
                            lifestyle. That’s why building a dependable and
                            efficient delivery system is its top priority. This
                            factor becomes all the more relevant in the light of
                            the fact that several manufacturing plants of
                            multinational companies such as Honda Cars, Yamaha
                            Motors, New Holland Tractors, LG Electronics,
                            STMicroelectronics, India Exposition Mart, and GSC
                            Toughened Glass have been set up in Greater Noida.
                            Moreover, Greater Noida is home to a rising mobile
                            residential population.
                          </p>

                          <p>
                            The Company maintains and manages round-the-clock
                            emergency services to handle supply-related
                            complaints efficiently. Once the flaws have been
                            identified, it takes measures to rectify them. A
                            call centre has also been established to reinforce
                            the facilities by ensuring 24-hour messaging
                            services to the consumers. This call centre also
                            improves trouble call monitoring. NPCL has a
                            computerized complaint management system that
                            enables call tracking from start to end and
                            generates ‘exception reports’.
                          </p>

                          <p>
                            The Company’s constructive relationship with its
                            customers can be defined by its streamlined
                            administrative procedures, on-time delivery of new
                            supplies, and billing consistency.
                          </p>

                          <p>
                            The hierarchical responsibilities and organisational
                            processes are designed in a way to meet the demands
                            for services in an optimum manner, including the
                            settlement of customer claims.
                          </p>

                          <p>
                            To maintain its service standards, NPCL employs
                            internal measures to assess the quality of its
                            services. Besides that, it conducts annual
                            independent surveys to evaluate the feedback of the
                            customers. Based on the findings of these surveys,
                            it then addresses the deficiencies in its process
                            and system to bridge the gap between its actual and
                            the expected performance levels.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EnergyServices;
