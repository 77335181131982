import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";

const CommunityDevelopment = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" +
                "assets/img/inner/corporatesocialresponsibility-banner.jpg" +
                ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Community Development
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/corporate-social-responsibility"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Corporate Social Responsibility
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/lifestyle-improvement"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Lifestyle Improvement
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/community-development"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Community Development
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/commitment-to-environment"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Commitment to Environment
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/csractivities"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        CSR Activities
                      </Link>
                    </li>
                  </ul>

                  <legend>COMMUNITY DEVELOPMENT</legend>
                  <div className="row com_profile">
                    <p>
                      Consistent with the public policy of providing
                      ‘electricity to all’, the Company has drawn the broad
                      agenda of improving the power supply in rural areas under
                      a unique scheme that combines network engineering with
                      social engineering.
                    </p>
                    <p>
                      As a first-time measure, the services of an NGO have been
                      introduced to undertake social intermediation in villages,
                      cultivate commercial behaviour among consumers and promote
                      both energy and water conservation as an act of public
                      good. Immediate impact is seen in the spread of computer
                      literacy, improvement in agricultural produce and
                      enhancement of small trade as an outcome of reliable power
                      supply. Community participation is encouraged to realise
                      the benefits of electricity as a means of social
                      upliftment and lifestyle improvement. Local self-help
                      groups are encouraged to build partnerships with the
                      Company and undertake such activities as bill distribution
                      on contractual terms. A 'Village Contact Person' now helps
                      NPCL in network surveillance, credit control and complaint
                      handling.
                    </p>
                    <p>
                      Quality of life is an enabler in ushering in new mindsets
                      that help improve the environment in which the utility has
                      to operate. To drive home the advantage of improved
                      electric lighting (now available day-long), active
                      interventions have been made in promoting basic literacy
                      among women by holding night classNamees, which are being
                      funded by NPCL. The result is that NPCL is today not
                      viewed merely as an organisation with commercial
                      interests, but one with a social agenda.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CommunityDevelopment;
