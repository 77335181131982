import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
const ContactForEprocurement = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/contact-for-e-procurement.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>

        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home</a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Contact for e-Procurement
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/online-vendor-registration"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Online Vendor Registration
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/contact-for-eprocurement"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Contact for e-Procurement
                      </Link>
                    </li>
                    <li className="nav-item">
                    <li className="nav-item">
                      <a href="https://iwebapps.noidapower.com:8032/powerProcAddr.aspx"
                        className="nav-link" target="_blank" rel="noreferrer"
                      >
                        Contact for Power Procurement
                      </a>
                    </li>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/tenders"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Tenders
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content tab_main">
                    <div className="tab-pane fade show active" id="login2">
                      <div className="row">
                        <div className="col-md-7">
                          <div className="companysection">
                            <legend>CONTACT FOR E-PROCUREMENT</legend>
                            <p>Contact for e-Procurement related Queries</p>
                            <p>
                              <b>+91-120-2333602</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactForEprocurement;
