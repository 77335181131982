import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const SafetyFirst = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="assets/images/shifting-service-banner.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Safety First</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Portal</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/safety-first" className="active">
                      Safety First
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <h2 className="online_heading">Safety First</h2>

                    <div className="rediobuttonstyle">
                      <form className="form">
                        <div className="plan">
                          <ul className="box_online">
                            <li>
                              <Link to="/safety">
                                <img
                                  src="assets/images/registerComplaint.jpg"
                                  alt=""
                                />{" "}
                                Safety Tips
                              </Link>
                            </li>
                            <li>
                              <Link to="/dosndnts">
                                <img
                                  src="assets/images/load_reduction.jpg"
                                  alt=""
                                />{" "}
                                Do's & Don’ts
                              </Link>
                            </li>
                            <li>
                              <Link to="/easy-ways-to-lower-your-bill">
                                <img
                                  src="assets/images/TipsLowerBill.jpg"
                                  alt=""
                                />{" "}
                                Easy Way to Lower Your Bills
                              </Link>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/reporting_theft.aspx"
                                target="_blank"
                              >
                                <img
                                  //src="https://image.noidapower.com/image-photo/businesswoman-working-laptop-writing-something-600w-667206523.jpg"
                                  src="assets/images/ConsumerContactProgram.jpg"
                                  alt=""
                                />{" "}
                                Report a concern
                              </a>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SafetyFirst;
