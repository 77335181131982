import React from "react";
import Announcement from "../components/Announcement";
import Slider from "react-slick";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";

const SafetyIndoors = () => {
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/login" className="active">
                      SAFETY TIPS
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...settings2}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="our_other_links">
                        <h4>Other Links</h4>
                        <ul>
                          <li>
                            <Link to="#">
                              <span></span> Calculate Energy Consumption{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres" target="_blank">
                              <span></span>Payment Options{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online-vendor-registration"
                              target="_blank"
                            >
                              <span></span>Vendor Registration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Regulation &amp; Statutory Bodies{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                  <div className="form_div">
                  <div className="main_wrappersd">
                  <div className="profile_cont">
                  <h2>SAFETY TIPS
                   </h2>
                   <p><b>Why we are at risk
                  </b></p>
                 <p>Electricity travels by the path of least resistance to ground. Hence, elements, which are good conductors of electricity, are more at risk when in contact with a source of electricity. For instance Metals, water and human bodies are good conductors of electricity.
                   </p>
                  <p>The sheer water content of human bodies (approximately 70%) makes human beings particularly susceptible to passage of electricity. When electricity passes through our body, it puts enormous stress on the nervous and cardiovascular systems.
                     </p>
                   <p>Burns caused by electric 'shocks' may result in serious injury or death. Hence, electrical safety is something that should never be taken lightly. Therefore, to ensure your safety, this section brings you some safety tips.
                     </p>
                     <ul className="nav nav-tabs nav_tbds">
                     <li className="nav-item"><Link to="/safety" className="nav-link " data-toggle="tab">Outdoors </Link></li>
                  <li className="nav-item"><Link to="/safetyIndoors" className="nav-link active" data-toggle="tab">Indoors </Link></li>
                   <li className="nav-item"><Link to="/safetyPrecautions" className="nav-link" data-toggle="tab">Precautions for Kids  </Link></li>
                   </ul>
                   <div className="tab-content tab_main">
                   <div className="tab-pane show active" id="Indoors">
                   <ol>
        		<li>Licensed Electrical Contractors should do installation of wiring and electrical fittings/equipment.
                 </li>
        		<li>Fans, Switchboards and other electrical equipment should be properly earthed. · Loose or unsupported wiring installation should be avoided. Wiring for electric motors should be done with all three-phase wire bunched in a metallic conduit, this helps in effective earthling. The minimum permissible size of earth wire should be no.14 SWG.
                </li>
        		<li>Metallic supports should not be used for wiring installations.
                 </li>
        		<li>When in doubt you should always have your installation checked by Licensed Electrical Contractor. Even otherwise, a complete check-up is recommended periodically.
                 </li>
        		<li>Fuses of proper rating should be installed. This is extremely important for safety of the installation and the user.
                </li>
        		<li>A triple-pole-linked switch protected with no-volt release and triple pole fuses (for overload purpose) shall control the motor circuit.
                </li>
        		<li>The connected load of your installation should be in conformity with the details furnished in the application form and B&L form.
                </li>
        		<li>Plug points should be of the 3 pin type and installations should be connected to the plug point should be with 3 core cables and 3 pin plug tops.
               </li>
        		<li>AC motor should not be connected unless the motor and the installation have suitable devices to limit the starting current.
                </li>
        		<li>You should Install MCB's in your household circuits under the guidance of a Licensed Contractors to protect your appliances as well as your family members from electrical overloading or short-circuit.
                 </li>
        		<li>Don't overload electrical outlets. If you must use an extension cord temporarily, match the amperage and wattage limits of the cord and appliance, and do not use damaged cords.
               </li>
        		<li>Stay dry. Keep electric appliances, such as hair dryers, away from water. Never operate an appliance when wet.
                </li>
        		<li>Keep household appliances in good working order. If something seems wrong with an appliance or tool, or if it gives even the slightest shock, disconnect it. Have it repaired or discard it.
                </li>
        		<li>Never carry appliances by the cords, and remove cords from outlets by pulling on the plug head. Don't run cords under rugs or furniture; they could become damaged or overheated.
                </li>
        		<li>Any plug-in appliance should always be unplugged when it is not being used. Many people are injured and home fires started by leaving appliances plugged-in when not in use.
                 </li>
        		<li>Recognize workplace hazards. Keep your tools, work area and storage space clean and dry. Check cords for wear and keep tools switched "off" before plugging or unplugging.
                 </li>
        		<li>Be prepared for power outages during storms. Keep flashlights and batteries where they can be found easily. Unplug your appliances during storms to prevent lightning damage, and don't forget lightning can come into your home through cable television and telephone lines as well. When power resumes, turn on only necessary items and do so one appliance at a time to avoid overloading the electric system.
               </li>
        	</ol>
                   </div>
                   </div>
                  </div>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SafetyIndoors;
