import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <footer>
        <img src="assets/img/footerlogo.png" className="footerlogo" alt="NPCL Footer" />
        <div className="container">
          <div className="row">
            <div className="col-sm-10">
              <div className="row">
                <div className="col-sm-3">
                  <div className="footermenu">
                    <h4>Corporate Social Responsibility</h4>
                    <ul>
                      <li>
                        <Link
                          to="/corporate-social-responsibility"
                          target="_blank"
                        >
                          Corporate Social Responsibility
                        </Link>
                      </li>
                      <li>
                        <Link to="/lifestyle-improvement" target="_blank">
                          Lifestyle Improvement
                        </Link>
                      </li>
                      <li>
                        <Link to="/community-development" target="_blank">
                          Community Development
                        </Link>
                      </li>
                      <li>
                        <Link to="/commitment-to-environment" target="_blank">
                          Commitment to Environment
                        </Link>
                      </li>
                      <li>
                        <Link to="/csractivities" target="_blank">
                          CSR Activities
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/ISMS Policy Statement for Website.pdf"
                          target="_blank" rel="noreferrer"
                        >
                          ISMS Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footermenu">
                    <h4>Important Links</h4>
                    <ul>
                      <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/TermsOfUse.aspx"
                          target="_blank" rel="noreferrer"
                        >
                          Terms Of Use
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footermenu">
                    <h4>Safety & Conservation</h4>
                    <ul>
                      <li>
                        <Link to="/safety" target="_blank">
                          Safety Tips
                        </Link>
                      </li>
                      <li>
                        <Link to="/dosndnts" target="_blank">
                          Dos & Don'ts
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/easy-ways-to-lower-your-bill"
                          target="_blank"
                        >
                          Easy Ways to Lower Your Bill
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footermenu">
                    <h4>Contact Us</h4>
                    <ul>
                      <li>
                        <Link to="/contactOverPhone" target="_blank">
                          Contact Over Phone
                        </Link>
                      </li>
                      <li>
                      <a href="https://iwebapps.noidapower.com:8032/powerProcAddr.aspx"
                                target="_blank" rel="noreferrer"
                              >
                          Contact for Power Procurement
                        </a>
                      </li>
                      <li>
                        <Link to="/contact-for-eprocurement" target="_blank">
                          Contact for e-Procurement
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/rebate" target="_blank">
                          Office Address
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2">
                     

              <div className="footermenu">
              <h4>Follow Us on</h4>
          
   
              <ul style={{ display: 'flex', listStyle: 'none', width:'220px'}}>
              <li>
              <a
                  href="https://www.linkedin.com/company/npclgreaternoida/"
                  target="_blank" rel="noreferrer"
                >
                  <img src="assets/img/linkedin2.png" alt="NPCL APP" style={{ border: 'none' }} className="img-responsive" />
                </a>
  
              </li>
              <li >
              <a
                  href="https://www.facebook.com/profile.php?id=61556912036659&mibextid=dGKdO"
                  target="_blank" rel="noreferrer"
                >
                  <img src="assets/img/Fb.png" alt="NPCL APP" style={{ border: 'none' }} className="img-responsive" />
                </a>

              </li>
              <li>
              <a
                  href="https://www.youtube.com/@noidapowercompanylimited3489"
                  target="_blank" rel="noreferrer"
                >
                  <img src="assets/img/utube.png" alt="NPCL APP" style={{ border: 'none' }} className="img-responsive" />
                </a>
       
              </li>
              <li>

              <a
                  href="https://www.instagram.com/npclgrnoida?igsh=YzljYTk1ODg3Zg=="
                  target="_blank" rel="noreferrer"
                >
                  <img src="assets/img/insta.png" alt="NPCL APP" style={{ border: 'none' }} className="img-responsive" />
                </a>
                </li>

                <li>

              <a
                  href="https://twitter.com/npclgrnoida"
                  target="_blank" rel="noreferrer"
                >
                  <img src="assets/img/twitter.png" alt="NPCL APP" style={{ border: 'none' }} className="img-responsive" />
                </a>
                </li>
                  
                </ul>
                <h4>Download Our App </h4>
                <a
                  href="https://play.google.com/store/apps/details?id=com.npclapp.com&hl=en_IN&gl=US"
                  target="_blank" rel="noreferrer"
                >
                  <img src="assets/img/download-app.png" alt="NPCL APP"  />
                </a>
                <img src="assets/img/npcl.png" alt="NPCL APP"  />
              </div>
            </div>
          </div>
          </div>

      </footer>
    </React.Fragment>
  );
};

export default Footer;
