import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const ContactOverPhone = () => {

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);

  const toggleShow1 = () => {
    setShow1(!show1);
    if (show2) {
      setShow2(false);
    }
    else if (show3) {
      setShow3(false);
    }
    else if (show4) {
      setShow4(false);
    }
    else if (show5) {
      setShow5(false);
    }
  };

  const toggleShow2 = () => {
    setShow2(!show2);
    if (show1) {
      setShow1(false);
    }
    else if (show3) {
      setShow3(false);
    }
    else if (show4) {
      setShow4(false);
    }
    else if (show5) {
      setShow5(false);
    }
  };

  const toggleShow3 = () => {
    setShow3(!show3);
    if (show2) {
      setShow2(false);
    }
    else if (show1) {
      setShow1(false);
    }
    else if (show4) {
      setShow4(false);
    }
    else if (show5) {
      setShow5(false);
    }
  };

  const toggleShow4 = () => {
    setShow4(!show4);
    if (show2) {
      setShow2(false);
    }
    else if (show3) {
      setShow3(false);
    }
    else if (show1) {
      setShow1(false);
    }
    else if (show5) {
      setShow5(false);
    }
  };

  const toggleShow5 = () => {
    setShow5(!show5);
    if (show2) {
      setShow2(false);
    }
    else if (show3) {
      setShow3(false);
    }
    else if (show4) {
      setShow4(false);
    }
    else if (show1) {
      setShow1(false);
    }
  };

  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/payment-collection.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center"></div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <div className="tab-content tab_main">
                        <div
                          className="tab-pane fade show active"
                          id="checkcollection"
                        >
                          <div className="row cashcollectioncenter">
                            <legend>Contact Over Phone</legend>
                            <hr />
                            <div className="innwrper">
                               <div className="contactOver">  
                                <div className="col-sm-6">24*7 Call Center / Customer Care number </div>
                                <div className="col-sm-6">0120 - 622 6666</div>
                                </div>  
                                <div className="contactOver">  
                                <div className="col-sm-6">No Power complaint E-mail</div>
                                <div className="col-sm-6">npc@noidapower.com</div>
                                </div>  
                                <div className="contactOver">  
                                <div className="col-sm-6">General query complaint E-mail</div>
                                <div className="col-sm-6">crm@noidapower.com</div>
                                </div>  
                            </div>

                            {/* <div className="innwrper">
                              <table table table-responsive col-sm-12>
                                <tr>
                                  <td>
                                <Link 
                                className="nav-link active"
                                data-toggle="tab"
                                 to="#" onClick={toggleShow1}>Customer Relation</Link>
                                </td>
                                <td>
                                <Link to="#" onClick={toggleShow2}>New Connection - Operations</Link>
                                </td>
                                <td><Link to="#" onClick={toggleShow3}>Operations & Maintenance - Zone-1</Link></td>
                                <td><Link to="#" onClick={toggleShow4}>Operations & Maintenance - Zone-2</Link></td>                             
                                </tr>
                                <tr><td><Link to="#" onClick={toggleShow5}>Commercial Services</Link></td></tr>
                              </table>                         
                            </div> */}

<div>
<ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow1}
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Customer Relation
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow2}
                        className="nav-link"
                        data-toggle="tab"
                      >
                        New Connection - Operations
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow3}
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Operations & Maintenance - Zone-1
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow4}
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Operations & Maintenance - Zone-2
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#" onClick={toggleShow5}
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Commercial Services
                      </Link>
                    </li>
                  </ul>
</div>
                            
                            {show1 ? (
<div className="innwrper">                      
<table className="table table-responsive col-sm-12">
<tr>

<td className="col-sm-2"> <strong>Heading</strong> </td>
<td className="col-sm-3">  <strong>Sub-Heading</strong> </td>
<td className="col-sm-7">
    <table className="table table-responsive">
      <tr>
        <td className="col-sm-5"> <strong>Name</strong> </td>
        <td className="col-sm-4"> <strong>Designation</strong> </td>
        <td className="col-sm-3"> <strong>Mobile</strong>   </td>                                   
      </tr>     
  </table>
</td>
</tr>
<tr>
<td className="col-sm-2"> Customer Relation </td>
<td className="col-sm-3"> General queries / requests / complaints /information </td>
<td className="col-sm-7">
    <table className="table table-responsive">
      <tr>
        <td className="col-sm-5"> Mr. Tarun Raj</td>
        <td className="col-sm-4"> Dy. Manager - BE&CR</td>
        <td className="col-sm-3"> 9911998254</td>                                   
      </tr>
      <tr>
        <td> Mr. Prabhat Ranjan</td>
        <td> Dy. Manager - BE&CR</td>
        <td> 7065049851</td>                                   
      </tr>
      <tr>
        <td> Mr. Gautam Kumar</td>
        <td> Manager - BE&CR </td>
        <td> 8287938179 </td>                                   
      </tr>
      <tr>
        <td> Ms. Neha Rai </td>
        <td> Manager - BE&CR</td>
        <td> 7065049852</td>                                   
      </tr>
    </table>        
</td>
</tr>
<tr>
<td></td>
<td>Overall</td>
<td>
<table className="table table-responsive">
      <tr>
        <td className="col-sm-5">  Ms. Meghna Dosi  </td>
        <td className="col-sm-4">  Head - BE&CR </td>
        <td className="col-sm-3"> 7840008873</td>                                   
      </tr>
    </table>   
</td>
</tr>
</table>
</div>
) : null}

{show2 ? (
  <div className="innwrper">
                            <table className="table table-responsive col-sm-12">
                            <tr>

<td className="col-sm-2"> <strong>Heading</strong> </td>
<td className="col-sm-10">
    <table className="table table-responsive">
      <tr>
        <td className="col-sm-5">  <strong>Sub-Heading</strong> </td>
        <td className="col-sm-4"> <strong>Name</strong> </td>
        <td className="col-sm-3"> <strong>Designation</strong> </td>
        <td className="col-sm-3"> <strong>Mobile </strong>  </td>                                   
      </tr>
      
  </table>
</td>
</tr>
                              <tr>
                              <td className="col-sm-2"> New Connection - Operations</td>
                              <td className="col-sm-10">
                                  <table className="table table-responsive">
                                    <tr>
                                      <td className="col-sm-5"> New Connection(LT - Upto 50 KW)</td>
                                      <td className="col-sm-4"> Mr. Gurchant Singh </td>
                                      <td className="col-sm-3"> Sr. Executive - Operations </td>     
                                      <td className="col-sm-3"> 9911998242 </td>                                 
                                    </tr>
                                    <tr>
                                      <td>  New Connection(HT - > 50  KW)</td>
                                      <td>   Mr. Udit Kulshrestha </td>
                                      <td>  Assistant Manager - Operations </td>     
                                      <td>  8505952424 </td>                                   
                                    </tr>
                                    <tr>
                                      <td> Coversion of single point connection to multi point connection for multi-storied building</td>
                                      <td> Mr. Tarun Chauhan  </td>                                      
                                      <td> Sr. Executive - Operations</td>     
                                      <td> 9911998208</td>                                   
                                    </tr>
                                    <tr>
                                      <td> New Connection Head </td>
                                      <td> Mr. Jitendra Dhamat</td>
                                      <td> Sr. Manager - Operations</td>     
                                      <td> 8743009894</td>                                   
                                    </tr>
                                  </table>        
                              </td>
                              </tr>
                      </table>
                            </div>
) : null}

{show3 ? (
  <div className="innwrper">
  <table className="table table-responsive col-sm-12">
  <tr>

<td className="col-sm-2"> <strong>Heading</strong> </td>
<td className="col-sm-10">
    <table className="table table-responsive">
      <tr>
        <td className="col-sm-5">  <strong>Sub-Heading</strong> </td>
        <td className="col-sm-4"> <strong>Name</strong> </td>
        <td className="col-sm-3"> <strong>Designation</strong> </td>
        <td className="col-sm-3"> <strong>Mobile</strong>   </td>                                   
      </tr>
      
  </table>
</td>
</tr>
    <tr>
    <td className="col-sm-2"> Operations & Maintenance - Zone-1</td>
    <td className="col-sm-10">
        <table className="table table-responsive">
          <tr>
            <td className="col-sm-5"> Surajpur-1 (Site C/Site B/ Udyog Vihar/Udyog  kendra/B2P area/Ecotech-3 etc.)</td>
            <td className="col-sm-4"> Mr. Biswaranjan Gouda</td>
            <td className="col-sm-3"> Asst. Manager - Operations  </td>     
            <td className="col-sm-3"> 7834807022 </td>                                 
          </tr>
          <tr>
            <td>  Surajpur-2 (Surajpur/Kuleshra/Tilapta/Devla/ Jalpura/ Haldauni/Habibpur/ Thapkhera/Boraki/Pali/Ghori etc.)</td>
            <td>  Mr. Amit Kumar </td>
            <td>  Dy. Manager - Operations </td>     
            <td>  9911998247 </td>                                   
          </tr>
          <tr>
            <td> Greater Noida West (Societies of GNW/Ecotech-12/ Techzon-4/Sec-10/Ithehra etc.)</td>
            <td> Mr. Inder Pal Singh Lodhi  </td>                                      
            <td> Dy. Manager - Operations</td>     
            <td> 8743009896</td>                                   
          </tr>
          <tr>
            <td> Zone-1 Head </td>
            <td> Mr. Gaurav Sharma</td>
            <td> Sr. Manager - Operations</td>     
            <td> 9990046407</td>                                   
          </tr>
        </table>        
    </td>
    </tr>
</table>
  </div>
) : null}

{show4 ? (
  <div className="innwrper">
  <table className="table table-responsive col-sm-12">
  <tr>

<td className="col-sm-2"><strong> Heading </strong></td>
<td className="col-sm-10">
    <table className="table table-responsive">
      <tr>
        <td className="col-sm-5"> <strong>Sub-Heading</strong>  </td>
        <td className="col-sm-4"> <strong>Name</strong> </td>
        <td className="col-sm-3"> <strong>Designation</strong> </td>
        <td className="col-sm-3"> <strong>Mobile</strong>   </td>                                   
      </tr>
      
  </table>
</td>
</tr>
    <tr>
    <td className="col-sm-2"> Operations & Maintenance - Zone-2 </td>
    <td className="col-sm-10">
        <table className="table table-responsive">
          <tr>
            <td className="col-sm-5"> Urban-1(Alpha/Beta/Gamma/Delta/ Eta/Zeta/Tughalpur/KP1/ KP2/KP3/KP4 etc.)</td>
            <td className="col-sm-4"> Mr. Amandeep Singh</td>
            <td className="col-sm-3"> Executive - Operations  </td>     
            <td className="col-sm-3"> 8750068236 </td>                                 
          </tr>
          <tr>
            <td> Urban-2 & Kasna (Sigma/ Omicron/ ZU/MU/Chi/Builders area/swarn Nagri /Site-4/Site-5/Ecotech Ext-1/Ecotech-6 etc.)</td>
            <td> Mr. Pawan Gautam </td>
            <td>  Dy. Manager - Operations </td>     
            <td> 9540060874 </td>                                   
          </tr>
          <tr>
            <td> Zone-2-Head</td>
            <td> Mr. Pankaj Palawat  </td>                                      
            <td> Sr. Manager - Operations </td>     
            <td> 8287238929 </td>                                   
          </tr>
         
        </table>        
    </td>
    </tr>
</table>
  </div>
) : null}

{show5 ? (
  <div className="innwrper">
  <table className="table table-responsive col-sm-12">
  <tr>

<td className="col-sm-2"><strong> Heading</strong> </td>
<td className="col-sm-10">
    <table className="table table-responsive">
      <tr>
      <td className="col-sm-5"><strong>Sub-Heading</strong></td>
<td className="col-sm-4"><strong>Name</strong></td>
<td className="col-sm-3"><strong>Designation</strong></td>
<td className="col-sm-3"><strong>Mobile</strong></td>                        
      </tr>
      
  </table>
</td>
</tr>
    <tr>
    <td className="col-sm-2"> Commercial Services </td>
    <td className="col-sm-10">
        <table className="table table-responsive">
          <tr>
            <td className="col-sm-5"> Reconnection / Disconnection</td>
            <td className="col-sm-4"> Mr. Sunil Kumar Sharma</td>
            <td className="col-sm-3"> Asst. Manager - Commercial </td>     
            <td className="col-sm-3"> 9990046403 </td>                                 
          </tr>
          <tr>
            <td> Reconnection / Disconnection (Rural)</td>
            <td>  Mr. Tarun Goel </td>     
            <td> Manager - Loss Control Cell</td>
            <td>7065049847</td>
          </tr>
          <tr>
            <td> Meter reading & Billing</td>
            <td>  Mr. Abhishake Jain </td>     
            <td> DGM - Commercial</td>
            <td>9718804962</td>
          </tr>
          <tr>
            <td> Commercial services (Name mutation/load reduction/category transfer/Permanent disconnection/Security deposit refund) </td>
            <td> Mr. Nikhil Garg </td>                                      
            <td> DGM-Commercial </td>     
            <td> 9990046406</td>                                   
          </tr>

          <tr>
            <td> Overall</td>
            <td> Mr. Harinder Singh </td>                                      
            <td> Head - Commercial </td>     
            <td> 9625313294 </td>                                   
          </tr>
         
        </table>        
    </td>
    </tr>
</table>
</div>
) : null}

                            <div style={{ marginTop: "10px" }}>
                              <a style={{ textDecoration: "none" }}>
                                Calls to NPCL officials shall be accepted during
                                office hours.
                              </a>
                            </div> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactOverPhone;
