import React from "react";
import { Link } from "react-router-dom";

const SiteMap = () => {
  return (
    <React.Fragment>
      <div className="sideleft">
        <ul>
          <li>
            <Link to="/sitemap2" target="_blank">
              <i className="fa fa-sitemap"></i> Sitemap
            </Link>
          </li>
          <li>
            <Link to="/faq" target="_blank">
              <i className="fa fa-question-circle"></i> FAQ’S
            </Link>
          </li>
          <li>
            <Link to="/quick-links" target="_blank">
              <i className="fa fa-download"></i> Quick Links
            </Link>
          </li>

          {/* <li>
            <Link to="/Sustainability" target="_blank" className="susTab">
            <i className="fa fa-leaf"></i>  Sustainability
            </Link>
          </li> */}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default SiteMap;
