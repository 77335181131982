import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const BillEstimatePayment = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="assets/images/bill-estimate-payment-banner.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Bill Estimate Payment</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-lg-12 brdcum_designs">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="https://www.noidapower.com/">Home</a>
                      <span className="icondsds">
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/consumer-portal">Consumer Portal</Link>
                      <span className="icondsds">
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/bill-estimate-payment" className="active">
                        Bill Estimate Payment
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <h2 className="online-heading">Bill Estimate Payment</h2>

                    <div className="rediobuttonstyle">
                      <form className="form">
                        <div className="plan">
                          <ul className="box_online">
                            <li>
                              <Link to="/ebill-download">
                                <img
                                  src="https://image.noidapower.com/image-photo/close-smart-young-woman-calculating-600w-2036382635.jpg"
                                  alt=""
                                />{" "}
                                View/Download Bill and Estimate
                              </Link>
                            </li>
                            <li>
                              <Link to="/cash-collection-centres">
                                <img
                                  src="https://image.noidapower.com/image-photo/hands-holding-medical-billing-statement-600w-1964273353.jpg"
                                  alt=""
                                />{" "}
                                Payment Modes
                              </Link>
                            </li>
                            <li>
                              <Link to="/quick-pay-pre">
                                <img
                                  src="https://image.noidapower.com/image-photo/close-female-client-hold-smartphone-600w-1463235668.jpg"
                                  alt=""
                                />{" "}
                                Make Payment
                              </Link>
                            </li>
                            <li>
                              <Link to="/last-payment">
                                <img
                                  src="assets/images/last-payment.jpg"
                                  alt=""
                                />{" "}
                                Last Payment
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BillEstimatePayment;
