import React from 'react'
import ReactDom from 'react-dom'
import { useHistory } from 'react-router-dom';
import Slider from "react-slick";

const Modal  = ({show,setShow}) => {
    const histor = useHistory(); 

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed:1500,
      dots:true,
    
    };

    const closeFunction = ()=>{
        histor.push("/NPCL-creatives");
        setShow(false)
    }
    return ReactDom.createPortal(
        <div id="main">
        <div id="pop-up">
          <button id='close-btn' onClick={()=>closeFunction()} style={{right:"-19px"}}>X</button>
          <Slider
                            ref={(slider) => (slider = slider)}
                            {...settings} 
                          >
                            <div className="csrslider" >
                              <img src="assets/images/benefit.jpeg" style={{maxHeight:"600px"}}/>
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/bill.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/billable.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Estimated.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Electricity.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Avoid.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Pay.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Be.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Energy.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Stay.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Howw.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Current.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Never.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Get.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/NPCL.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Protect.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Don'ts.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/NPCLl.png" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Do.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/NPCLll.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/NPCLDigital.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Self.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Register.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Download.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Self.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Stay.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Monsoon.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Online.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/View.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/How.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Submit.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/SMS.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Online.jpeg" style={{maxHeight:"600px"}}/>  
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/Signs.jpg" style={{maxHeight:"600px"}}/>  
                            </div>
           </Slider>
        </div>
      </div>,document.querySelector('#modal')
    )
}

export default Modal