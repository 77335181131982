import React from "react";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import { Link } from "react-router-dom";

const CurrentOpenings = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/careers.jpg" + ")",
              backgroundSize: "100 % auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center"></div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/career" className="active">
                      Careers
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <ul className="nav nav-tabs nav_tbds">
                      <li className="nav-item">
                        <Link
                          to="/current-openings"
                          className="nav-link active"
                          data-toggle="tab"
                        >
                          Current Openings{" "}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/career"
                          className="nav-link "
                          data-toggle="tab"
                        >
                          Upload Your CV{" "}
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content tab_main">
                      <div className="profile_cont">
                        <h2>No Openning Available</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CurrentOpenings;
