import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const PrePaidMeterRecharge = () => {
  return (
    <React.Fragment>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
          
              style={{
              backgroundImage:
                "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          
          >
            <div className="container">
              <div className="row justify-content-center"></div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center"></div>
            <div className="col-sm-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                   <Link to="#">Home </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/pre-paid-meter-recharge" className="active">
                    Pre paid meter recharge
                  </Link>
                </li>
              </ol>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row bg_cols">
                <div className="col-sm-12">
                  <div className="form_div">
                    <legend>PREPAID RECHARGE</legend>
                    <p>

                    To make payment and generate <strong>Secure Prepaid Meter Recharge Coupon</strong> via
                    </p>
                    (A) NPCL Website (BillDesk) <a className="lingcoldfsds" href="https://iwebapps.noidapower.com:8032/SMeterPPMT/Quick_pay.aspx" target="_blank" rel="noreferrer"> click here </a> to Redirected
                    <br/> 
                    <br/>
                    {/* <p><strong> (B)</strong> Paytm    <a className="lingcoldfsds"
                      href="https://paytm.com/electricity-bill-payment"
                      target="_blank" rel="noreferrer"
                    >click here Redirected</a> to Paytm <span>(You are being redirected to the Paytm website for online
                      recharge. In case of any issue with the transaction,
                      please get in touch with Paytm Customer Care directly.)</span></p> */}
                      <br/> 
                    <br/>
                    <p><a className="lingcoldfsds"
                      href="http://iwebapps.noidapower.com:8032/download/Prepaid_recharge_manual.pdf"
                      target="_blank" rel="noreferrer"
                    ><img src="assets/img/pdf.png" alt="" />  Please click here to download Prepaid Meter Recharge Manual </a></p>
                    {/* <a
                      className="lingcoldfsds"
                      href="https://paytm.com/electricity-bill-payment"
                      target="_blank" rel="noreferrer"
                    >
                      Please click here to continue..
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default PrePaidMeterRecharge;
