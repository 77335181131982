import React, { useState } from "react";
import Header2 from "../components/Header2";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";

const LoadReductionAugmentationFaq = () => {
  const [showMeter, setShowMeter] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showBill, setShowBill] = useState(false);
  const [showTheft, setShowTheft] = useState(false);
  const [showDisconnection, setShowDisconnection] = useState(false);
  const [showReconnection, setShowReconnection] = useState(false);
  const [showWebSite, setShowWebSite] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const [showSupply, setShowSupply] = useState(false);

  const [loadReduction, setLoadReduction] = useState(true);
  const [augmentation, setAugmentation] = useState(false);

  const openLoad = () => {
    setLoadReduction(true);
    setAugmentation(false);
  };
  const openAug = () => {
    setLoadReduction(false);
    setAugmentation(true);
  };
  return (
    <>
      <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods consumersec">
          <div className="upperdesignds">
            <div className="row">
              <div className="col-md-1">
                <img
                  className="bgstlsd"
                  src="assets/img/small/payment-options.jpg"
                  alt=""
                />
              </div>
              <div className="col-md-11 textdesing">
                <h2 className="headtit">
                  {loadReduction ? "Load Reduction" : "load Augmentation"}
                </h2>
              </div>
            </div>
          </div>
          <div className="container">
            <section className="main_sectiods">
              <div className="container">
                <div className="row justify-content-center padtops">
                  <div className="col-sm-12">
                    <div className="col-lg-12 brdcum_designs">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="#">Home</Link>
                          <span className="icondsds">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/consumer-portal" target="_blank">
                            Consumer Portal
                          </Link>
                          <span className="icondsds">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#" className="active">
                            Apply Online
                          </a>
                        </li>
                      </ol>
                    </div>
                    <div className="bs-example bs-example-1">
                      <div className="tab-pane show active" id="newconnection">
                        <ul className="nav">
                          <li className="nav-item" onClick={openLoad}>
                            <Link
                              className={`nav-link ${
                                loadReduction ? "active" : ""
                              }`}
                              data-toggle="tab"
                              style={{ color: loadReduction ? "blue" : "red" }}
                            >
                              Load Reduction
                            </Link>
                          </li>
                          <li className="nav-item" onClick={openAug}>
                            <Link
                              className={`nav-link ${
                                augmentation ? "active" : ""
                              }`}
                              data-toggle="tab"
                              style={{ color: augmentation ? "blue" : "red" }}
                            >
                              Load Augmentation
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {loadReduction ? (
                      <div className="col-sm-12 bg_cols">
                        <div className="form_div">
                          <div className="tab-content tab_main">
                            <div
                              className="tab-pane  show active"
                              id="newconnection"
                            >
                              <div className="tab-pane " id="faq">
                                <div className="bs-example bs-example-1">
                                  <div
                                    className="tab-pane fade show active"
                                    id="newconnection"
                                  >
                                    <ul
                                      className="nav nav-tabs nav_tbds"
                                      style={{ overflow: "hidden" }}
                                    >
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation"
                                          className="nav-link"
                                          data-toggle="tab"
                                        >
                                          Calculate Your Load
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation-doc"
                                          className="nav-link"
                                          data-toggle="tab"
                                        >
                                          Document Checklist
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation-sample"
                                          className="nav-link"
                                          data-toggle="tab"
                                        >
                                          Sample Documents
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation-faq"
                                          className="nav-link active"
                                          data-toggle="tab"
                                        >
                                          FAQs
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation-apply"
                                          className="nav-link"
                                          data-toggle="tab"
                                        >
                                          Apply
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation-status"
                                          className="nav-link"
                                          data-toggle="tab"
                                        >
                                          Application Status
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation-feedback"
                                          className="nav-link"
                                          data-toggle="tab"
                                        >
                                          Feedback
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>{" "}
                                </div>

                                <h4
                                  className="mt-2"
                                  style={{ marginBottom: "20px" }}
                                >
                                  {" "}
                                  FAQs{" "}
                                </h4>
                                <div id="accordion">
                                  <div className="card">
                                    <div className="card-header">
                                      <a
                                        className="collapsed card-link"
                                        data-toggle="collapse"
                                        onClick={() => setShowMeter(!showMeter)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Meter Related
                                      </a>
                                    </div>

                                    {showMeter ? (
                                      <div id="collapseOne">
                                        <div className="card-body">
                                          <p className="card_cont">
                                            <span>
                                              Q. What should be done in case
                                              there is no supply in the meter ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            In case the nearby are is under
                                            Supply Outage, you may wait until
                                            the supply is restored. If there is
                                            no power even after the supply is
                                            restored Consumer may contact at our
                                            24X7 CALL CENTER - 0120 6226666 You
                                            may reach out us through WhatsApp
                                            no. 0120-6226666 (NoPower Consumer
                                            No.) or Send SMS at 7840002288 (
                                            #NoPower Consumer No.)
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What to do in case of meter is
                                              running fast/ excess billing?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Domestic Consumer: May please
                                            check the internal wiring by any
                                            private electrician. If no such
                                            issue persist may kindly contact
                                            24X7 Call Centre No. 0120-6226666 or
                                            visit Customer Care Office at KP-I
                                            to register request for Meter
                                            Testing. Demand note for meter
                                            testing fees will be generated as
                                            per UPERC guidelines.
                                          </p>
                                          <p className="card_cont">
                                            Industrial & Institutional Consumers
                                            : May please check the Power Factor
                                            & Neutral disturbance issue to avoid
                                            any demand shoot-up in billing. if
                                            persist may kindly contact 24X7 Call
                                            Centre No. 0120-6226666
                                          </p>
                                          <p className="card_cont">
                                            You may reach out us through
                                            WhatsApp no. 0120-6226666
                                            (BillDispute Consumer No.) or Send
                                            SMS at 7840002288 ( #BILLDISPUTE
                                            Consumer No.) or else you may also
                                            visit our Customer Care office at
                                            Knowledge Park-I or Techzone-IV .
                                            Timings 10:00 am to 5:00 pm , Monday
                                            to Friday.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. How to analysis correct meter
                                              consumption/ abnormal consumption?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. In a normal house hold having 1
                                            or 2 A/C’s along with Geyser, Fridge
                                            etc., the average units consumed may
                                            be between 500 to 1000 Units per
                                            month depending upon actual use. If
                                            consumer feel that the consumption
                                            is more as compared to the usage and
                                            load, we request consumers to please
                                            get their wiring checked by
                                            competent electrician with respect
                                            to below mentioned points:
                                          </p>
                                          <ul>
                                            <li>
                                              To check any Earth Leakage in the
                                              internal wiring or not.
                                            </li>
                                            <li>
                                              In case of multiple connections in
                                              the building, whether your
                                              internal wiring has a separate
                                              neutral wire and should not be
                                              mixed with other connections.
                                            </li>
                                            <li>
                                              Check if someone else is also
                                              using supply from your meter or
                                              not.
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            However, it is to inform that, NPCL
                                            will not be responsible for higher
                                            consumptions due to any of the above
                                            reasons but taking action by
                                            consumer will reduce the
                                            consumption. After having checked
                                            all the above, If consumption is
                                            still not reduced as per consumer’s
                                            expectation, then he is having an
                                            option to get the meter tested by
                                            contacting our helpline
                                            number+91-120-2333555 Apply online
                                            for meter testing or visit Customer
                                            Care Office at Knowledge Park-I or
                                            Techzone-IV . Timings 10:00 am to
                                            5:00 pm , Monday to Friday. and get
                                            the offline requests generated for
                                            meter checking . Meter Testing fees
                                            will be generated & added in
                                            subsequent bill as per UPERC
                                            guidelines.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the various reasons of
                                              meter change?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. A Distribution Licensee may
                                            change the meter from time to time
                                            for any of the following reasons:
                                          </p>
                                          <ul>
                                            <li>Version Upgrade</li>
                                            <li>Meter is burnt</li>
                                            <li>Meter is faulty</li>
                                            <li>Meter is damaged</li>
                                            <li>No display in the meter</li>
                                            <li>Suspected to be tampered</li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the
                                              timelines/Resolutions for Meter
                                              Testing, Fast, Slow, Burnt,
                                              Damaged, Stop, Faulty, Shifting?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Time period for compliances
                                          </p>
                                          <ul>
                                            <li>
                                              <b>Meter Stop/Meter Damaged</b>:
                                              14 working days for meter
                                              replacement.
                                            </li>
                                            <li>
                                              <b>Meter Slow/Fast</b>: 14 working
                                              days after estimate payment for
                                              testing & another 14 working days
                                              for replacement if required
                                            </li>
                                            <li>
                                              <b>Meter Burnt</b>: 3 Working Days
                                              for replacement of the burnt meter
                                            </li>
                                            <li>
                                              <b>Meter Shifting</b>: 7 Working
                                              Days as per site conditions
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the charges for Meter
                                              testing ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Meter Testing Charges are as
                                            follows:
                                          </p>
                                          <ul>
                                            <li>Single Phase Rs.207</li>
                                            <li>Three Phase Rs.413</li>
                                            <li>LT/CT, CT/HT Meter Rs.1180</li>
                                          </ul>
                                          <p className="card_cont">
                                            *All Applicable taxes on the meter
                                            testing fee shall also be charged as
                                            per the norms.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. How to convert post-paid
                                              connection to prepaid connection?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. To get the post-paid connection
                                            to prepaid connection, consumer can
                                            visit our Customer Care Office at
                                            Knowledge Park -1 or Techzone-4,
                                            Greater Noida . Consumer has to
                                            apply under category conversion
                                            services by fill an application form
                                            and submit their ID copy Knowledge
                                            Park-I or Techzone-IV . Timings
                                            10:00 am to 5:00 pm , Monday to
                                            Friday.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the security deposit
                                              charges for conversion from
                                              post-paid to pre-paid or for new
                                              connection installation in
                                              Pre-paid?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Meter charges to paid as per
                                            UPERC cost data book enforce
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What is the meaning of
                                              downloading of parameter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Various parameters measured and
                                            recorded by the meter are finally
                                            downloaded for billing/ monitoring
                                            purpose. The downloading of
                                            parameter means transferring the
                                            recorded data from meter to the
                                            records of the company. Downloading
                                            can be automatic (AMR) or using a
                                            gadget. However we can capture data
                                            / parameter by reading the display
                                            recording on a notebook.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Who can apply for Prepaid
                                              Meter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Consumers pertatining to below
                                            mentioned categories can apply for
                                            prepaid meter:
                                          </p>
                                          <ul>
                                            <li>LMV-1</li>
                                            <li>LMV-2</li>
                                            <li>LMV-5</li>
                                            <li>LMV-9</li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. How to recharge prepaid meter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Prepaid meter recharge coupon
                                            are available at cash collection
                                            centre and can be paid via
                                            cash/DD/Credit or debit card from
                                            our customer care office at
                                            Knowledge Park-I or Techzone-IV .
                                            Timings 10:00 am to 5:00 pm , Monday
                                            to Friday.. Recharge can be done
                                            online via NPCL’s website or PAYTM.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. How does the alarm work in
                                              Pre-paid meter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. If your credit gets below
                                            Rs.100/-, you will have an alarm for
                                            30 seconds. Press any button to turn
                                            it off. The alarm will be repeated
                                            every half an hour until recharged.
                                            It is advised that once meter
                                            reached its minimum limit recharge
                                            immediately to avoid disconnection.
                                            You can also set minimum limit as
                                            per your convenience.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Whom to contact in case of
                                              misplacing of Recharge Coupon?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Simply visit the NPCL Cash
                                            Collection Center for re-issuing of
                                            recharge coupon which will be done
                                            free of cost. Consumer have to
                                            mention BP no./ Meter no. to get the
                                            recharge coupon. May please note-
                                            the coupon is specific to your meter
                                            hence cannot be used elsewhere.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. How to convert post-paid
                                              connection into prepaid
                                              connection?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. To get the post-paid connection
                                            to prepaid connection, consumer can
                                            visit our Customer Care Office at
                                            Knowledge Park -1 or Techzone-4,
                                            Greater Noida . Consumer has to
                                            apply under category conversion
                                            services by fill an application form
                                            and submit their ID copy Knowledge
                                            Park-I or Techzone-IV . Timings
                                            10:00 am to 5:00 pm , Monday to
                                            Friday.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the security deposit
                                              charges for conversion from
                                              post-paid to pre-paid or for new
                                              connection installation in
                                              Pre-paid?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the documents required
                                              if meter get stolen?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. You have to submit an
                                            application, original FIR, ID proof
                                            of registered consumer at our
                                            Customer Care office at Knowledge
                                            Park-I or Techzone-IV . Timings
                                            10:00 am to 5:00 pm , Monday to
                                            Friday.
                                          </p>
                                          <p className="card_cont">
                                            Note: Meter charges are applicable
                                            as per UPERC cost data book.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the New Meter cost in
                                              Meter stolen cases?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Once the consumer process the
                                            application for re-installation of
                                            meter in case of Meter Stolen,
                                            applicable meter charges to paid as
                                            per UPERC cost data book enforce.
                                          </p>
                                          <p className="card_cont">
                                            *All Applicable taxes on the new
                                            meter cost shall also be charged as
                                            per the norms.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Whom to contact if meter is not
                                              at the premises?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Please contact our Call at our
                                            24X7 CALL CENTER - 0120 6226666 or
                                            you may visit Customer Care Office
                                            at Knowledge Park-I or Techzone-IV .
                                            Timings 10:00 am to 5:00 pm , Monday
                                            to Friday.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Why my meter screen is blank.?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Please check if the supply is
                                            On in the area and your supply is
                                            not coming, if Yes, you may kindly
                                            contact 24X7 Call Centre No.
                                            0120-6226666 to register complaint
                                            for No Supply or WhatsApp on
                                            0120-6226666 (NoPower Consumer No.)
                                            or send SMS at 7840002288 ( #NOPOWER
                                            Consumer No.) or visit atCustomer
                                            Care office at Knowledge Park-I or
                                            Techzone-IV . Timings 10:00 am to
                                            5:00 pm , Monday to Friday.. In case
                                            supply is ON but no display on
                                            meter, register complaint for meter
                                            display problem on above given
                                            numbers. Our Team will visit to do
                                            the needful within 7 working days.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. If my meter box has a husky
                                              display, what should I do ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Is your meter box is having
                                            husky display.Kindly contact 24X7
                                            Call Centre No. 0120-6226666 or
                                            visit at our Customer Care office at
                                            Knowledge Park-I or Techzone-IV .
                                            Timings 10:00 am to 5:00 pm , Monday
                                            to Friday. and register complaint
                                            for meter display problem. Our Team
                                            will visit to do the needful within
                                            7 working days.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Where to report incase of No
                                              Display in the meter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Consumer may contact at our
                                            24X7 CALL CENTER - 0120 6226666 or
                                            visit at our Customer Care office at
                                            Knowledge Park-I or Techzone-IV .
                                            Timings 10:00 am to 5:00 pm , Monday
                                            to Friday and register complaint for
                                            meter display problem. Our Team will
                                            visit to do the needful within 7
                                            working days.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the charges of meter
                                              testing and why is it mandatory to
                                              pay ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Meter Testing Charges are as
                                            follows:
                                          </p>
                                          <ul>
                                            <li>Single Phase Rs.207</li>
                                            <li>Three Phase Rs.413</li>
                                            <li>LT/CT, CT/HT Meter Rs.1180</li>
                                          </ul>
                                          <p className="card_cont">
                                            *All Applicable taxes on the meter
                                            testing fee shall also be charged as
                                            per the norms.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the different options
                                              available for Meter Testing?
                                            </span>
                                          </p>
                                          <ul>
                                            <li>
                                              Online from our website
                                              www.noidapower.com and refer
                                              consumer services tab wherein
                                              "Online Meter Testing" option is
                                              available.
                                            </li>
                                            <li>
                                              You may also visit Customer Care
                                              Office at Knowledge Park-I or
                                              Techzone-IV . Timings 10:00 am to
                                              5:00 pm , Monday to Friday.
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. How can I know the status of
                                              Meter Testing Request ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Consumer may visit our website
                                            www.noidapower.com and refer
                                            consumer services tab wherein
                                            "Online Meter Testing" option is
                                            available : You may also download
                                            our Mobile application and apply for
                                            meter testing or else it can be
                                            placed by calling to our 24X7
                                            helpline numbers on or 0120-6226666
                                            You may also visit Customer Care
                                            Office at Knowledge Park-I or
                                            Techzone-IV . Timings 10:00 am to
                                            5:00 pm , Monday to Friday.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. On what basis the meter testing
                                              fee is imposed?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Meter testing fees is charged
                                            on "type of meter" and it is
                                            applicable as per UPERC Tariff
                                            Order.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Would you refund the testing
                                              fee if meter found ok?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. There is no provision for
                                            refund of testing fees.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Will I receive testing report
                                              on meter testing? What is the
                                              procedure of complaining if I
                                              don’t receive one?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Everytime when we will test
                                            meter a test reprt is issued to our
                                            consumer. In case you have not
                                            received it, you may write us at
                                            CRM@noidapower.com. You may also
                                            visit Customer Care Office at
                                            Knowledge Park-I or Techzone-IV .
                                            Timings 10:00 am to 5:00 pm , Monday
                                            to Friday
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Which parameter define that
                                              meter is faulty or meter testing
                                              is Ok?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Kindly note that meter testing
                                            is done on many factors such as
                                            meter accuracy results , display
                                            parameters & downloaded data
                                            analysis on which testing engineer
                                            will confirm whether meter is faulty
                                            or meter is working normal.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Why meters are changed without
                                              any complaint?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. In case of technology
                                            upgradation or periodic replacement
                                            from Old to New Generation meter as
                                            per the Guideline of Hon’ble
                                            Regulatory Commission.
                                          </p>
                                          <p className="card_cont">
                                            A Distribution Licensee may change
                                            the meter from time to time for any
                                            of the following reasons:
                                          </p>
                                          <ul>
                                            <li>Version Upgrade</li>
                                            <li>Meter is burnt</li>
                                            <li>Meter is faulty</li>
                                            <li>Meter is damaged</li>
                                            <li>No display in the meter</li>
                                            <li>Suspected to be tampered</li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. How much time will you take to
                                              replace the faulty meter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Time period for compliances :
                                          </p>
                                          <ul>
                                            <li>
                                              <b>Meter Stop/Meter Damaged</b>:
                                              14 working days for meter
                                              replacement
                                            </li>
                                            <li>
                                              <b>Meter Slow/Fast</b>: 14 working
                                              days after estimate payment for
                                              testing & another 14 working days
                                              for replacement if required
                                            </li>
                                            <li>
                                              <b>Meter Burnt</b>: 3 Working Days
                                              for replacement of the burnt meter
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. Whom to contact if there is No
                                              power after successful recharge?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Time period for compliances :
                                          </p>
                                          <ul>
                                            <li>
                                              For Secure Prepaid meters
                                              <ul>
                                                <li>
                                                  Contact your maintenance
                                                  office it may be your main MCB
                                                  be off which is located in
                                                  Electric shaft in societies/
                                                  check your MCB status
                                                </li>
                                                <li>
                                                  Contact NPCL Customer Care
                                                  office at KP-1 or Techzone-4
                                                  :During 10:00 A.M to 5:00 P.M
                                                  (Mon-Fri) to know more about
                                                  the same. Knowledge Park-I or
                                                  Techzone-IV.
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              Radius Smart Meters(AEW Meters)
                                              <ul>
                                                <li>
                                                  Check balance / supply status
                                                  in mobile aap.
                                                </li>
                                                <li>
                                                  Contact your maintenance
                                                  office.
                                                </li>
                                                <li>
                                                  Contact NPCL Customer Care
                                                  office at KP-1 or Techzone-4 :
                                                </li>
                                                <li>
                                                  Contact NPCL Customer Care
                                                  office at KP-1 or Techzone-4
                                                  :During 10:00 A.M to 5:00 P.M
                                                  (Mon-Fri) to know more about
                                                  the same. Knowledge Park-I or
                                                  Techzone-IV.
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. Will I receive a bill for
                                              Pre-paid meter
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Monthly e-bill will be sent to
                                            registered email id & phone number
                                            post bill generation. It can also be
                                            downloaded from our website via
                                            Quick E-bill Download : or follow
                                            direct link: No payment is required
                                            to be made against the bill. Ensure
                                            sufficient balance in prepaid meter
                                            through timely recharge in order to
                                            avail uninterrupted power supply.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Whom to contact if the meter
                                              doesn’t show the credit amount
                                              after recharge?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Please refer our prepaid meter
                                            manual available at our website and
                                            refer page no. 6 & 7 Or In case,
                                            payment is been done but credit
                                            still pending, you may contact at
                                            our helpline no. +91-0120 6226666 or
                                            else if issue still persist, please
                                            visit our customer care oﬃce, KP-1
                                            or Techzone-4 in person :During
                                            10:00 A.M to 5:00 P.M (Mon-Fri) to
                                            know more about the same. Knowledge
                                            Park-I or Techzone-IV .
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Why meter is beeping when it is
                                              already recharge?
                                            </span>
                                          </p>
                                          <ul>
                                            <li>
                                              There is a provision in meter to
                                              set recharge limit if balance will
                                              go down from this limit then only
                                              meter will start beeping at low
                                              balance . This feature is
                                              configurable ,consumer itself can
                                              set this limit as per their
                                              consumption pattern.
                                            </li>
                                            <li>
                                              Freedom Bluetooth unit may be
                                              faulty ( if Showing Con. Fault in
                                              display ).
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. What options are available for
                                              pre-paid meter recharge?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. (A) Secure Prepaid Meters
                                          </p>
                                          <ul>
                                            <li>
                                              Paytm, (there are many options to
                                              pay amount in paytm ex- Through
                                              UPl, Debit Card,credit Card and
                                              Net Banking)
                                            </li>
                                            <li>
                                              Consumer can get token from NPCL
                                              Customer Care Office, KP-1,
                                              Greater Noida
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            (B) Radius Smart Meters( AEW Meters)
                                          </p>
                                          <ul>
                                            <li>
                                              Paytm, Bill Desk (there are many
                                              options to pay amount in paytm ex-
                                              Through UPl, Debit Card,credit
                                              Card and Net Banking)
                                            </li>
                                            <li>
                                              NPCL Multipoint App., NPCL web
                                              site
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. What is “CON FAULT”, what is to
                                              be done to get it corrected?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. CON FAULT” means connection
                                            faulty, meter will be displaying
                                            digit 17 when your connection from
                                            meter to display unit is not proper.
                                            Kindly register you’re compliant on
                                            our call center contact no. -
                                            0120-6226666/.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Can supply gets disconnected if
                                              meter is not recharged or do I get
                                              any reminder?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. (A) Secure Prepaid Meters
                                          </p>
                                          <ul>
                                            <li>
                                              Reminder beep will come in minimun
                                              available balance. Supply will be
                                              disconnected in negative balance.
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            (B) Radius Smart Meters(AEW Meters)
                                          </p>
                                          <ul>
                                            <li>
                                              Go to NPCL Multipoint App by
                                              turning on low money
                                              notifications, consumer can get a
                                              reminder.
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. How to check the balance?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. (A) Secure Prepaid Meters
                                          </p>
                                          <ul>
                                            <li>
                                              Meter Automatically showing
                                              Balance & other details in Meter
                                              display.
                                            </li>
                                            <li>
                                              Consumers may check their account
                                              balance by pressing # button in
                                              key pad of meter display unit &
                                              for single phase meters - Press
                                              key "1" single time to check
                                              balance.
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            (B) Radius Smart Meters(AEW Meters)
                                          </p>
                                          <ul>
                                            <li>
                                              Login in NPCL Multipoint App/ web
                                              and check balance.
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. What should be the minimum
                                              balance to maintain pre-paid
                                              meter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Consumer can change minimum
                                            balance in meter acocording to
                                            consumption and requirement.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. If meter is showing different
                                              date from actual does it mean that
                                              it is faulty ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. It is a matter of meter testing
                                            & inspection then only we will
                                            confirm the same. Based on the
                                            testing report our team shall
                                            analyse the data.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Whom to contact when there is
                                              no display in the meter but supply
                                              is connected?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Please check if the supply is
                                            On in the area and your supply is
                                            not coming, if Yes, you may kindly
                                            contact 24X7 Call Centre No.
                                            0120-6226666 to register complaint
                                            for No Supply or WhatsApp on
                                            0120-6226666 (NoPower Consumer No.)
                                            or send SMS at 7840002288 ( #NOPOWER
                                            Consumer No.)
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Red light in meter indicates
                                              what?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. it’s a calibration LED in meter
                                            which indicates pulse / revolution
                                            of meter , if Red light is blinking
                                            in meters it means meter is running
                                            on load.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. How can I change my meter from
                                              post-paid to pre-paid and what
                                              documentation is required?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. To get the post-paid connection
                                            to prepaid connection, consumer can
                                            visit our Customer Care Office at
                                            Knowledge Park -1 or Techzone-4,
                                            Greater Noida . Consumer has to
                                            apply under category conversion
                                            services by fill an application form
                                            and submit their ID copy Knowledge
                                            Park-I or Techzone-IV . Timings
                                            10:00 am to 5:00 pm , Monday to
                                            Friday.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. How can I see my consumption
                                              pattern in pre-paid meter ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. (A) Secure Prepaid Meters
                                          </p>
                                          <ul>
                                            <li>
                                              By pressing Key"2" consumer can
                                              check consumptions of today,
                                              previous day, previous Week and
                                              current month)
                                            </li>
                                            <li>
                                              By Pressing key"7" consumer can
                                              check monthly consumptions of
                                              present calendar Year)
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            (B) Radius Smart Meters ( Aew
                                            Meters)
                                          </p>
                                          <ul>
                                            <li>
                                              Visit NPCL Multipoint App and
                                              consumer can see daily
                                              consumption( Current month) and
                                              Monthly consumptions( Present
                                              Calendar Year) by selecting
                                              "Report" option.
                                            </li>
                                            <li>
                                              Visit NPCL Multipoint Web, conumer
                                              can download and see consumptions
                                              by selecting "Report" option.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <a
                                    className="collapsed card-link"
                                    data-toggle="collapse"
                                    href="#"
                                    onClick={() => setShowPayment(!showPayment)}
                                  >
                                    Payment Related
                                  </a>
                                </div>

                                {showPayment ? (
                                  <div id="collapseTwo">
                                    <div className="card-body">
                                      <p className="card_cont">
                                        <span>
                                          Q.What are the different modes of
                                          payments available?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. Modes of payments are as follows:
                                      </p>
                                      <p className="card_cont">
                                        <b>A. Digital Payment through:-</b>
                                      </p>
                                      <ul>
                                        <li>NPCL Website</li>
                                        <li>NPCL’s Mobile App and BHIM App</li>
                                        <li>
                                          PayTM, Mobiquick, and Other wallets
                                        </li>
                                        <li>Bharat QR (printed on bills)</li>
                                        <li>
                                          Bharat Bill Payment System (BBPS)
                                        </li>
                                        <li>
                                          Utility bill payment option in
                                          Consumer’s internet banking accounts
                                        </li>
                                        <li>
                                          Auto- Debit facility through NACH
                                          (National Automated Clearing House)
                                        </li>
                                        <li>RTGS/ NEFT to NPCL’s accounts</li>
                                        <li>
                                          Credit & Debit cards to Village
                                          Contact Persons and at Customer Care
                                          Office, KP-1 or Techzone-4*.
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        <b>
                                          Non-Digital Payment Modes are as
                                          follows:-
                                        </b>
                                      </p>
                                      <ul>
                                        <li>
                                          Cash / Cheque / DD at Customer Care
                                          Office, KP-1 or Techzone-4* or to
                                          Village Contact Persons.
                                        </li>
                                        <li>
                                          Cheque/DD at NPCL’s Cheque Drop Boxes.
                                        </li>
                                        <li>
                                          Cash at nearby BBPS authorised retail
                                          outlets For further details please
                                          visit NPCL’s website.
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        *You may visit Customer Care Office at
                                        Knowledge Park-I or Techzone-IV .
                                        Timings 10:00 am to 5:00 pm , Monday to
                                        Friday.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.Where can I make cash payment?
                                        </span>
                                      </p>
                                      <ul>
                                        <li>
                                          In case of Rural Consumers -
                                          Authorised Village Contact Persons
                                          (VCPs) or cash collection centre,
                                          Tugalpur, Knowledge Park-1 or
                                          Techzone-4, Greater Noida between
                                          10:00 AM to 5:00 PM, Monday to
                                          Saturday (Except 2nd & 4th Saturdays
                                          and Gazetted Holidays) (On 1st, 3rd &
                                          5th Saturdays counter will be open
                                          from 10:00 AM to 04:30 PM).
                                        </li>
                                        <li>
                                          In case of Urban / Industrial /
                                          Institutional Consumers - Cash
                                          Collection Centre.
                                        </li>
                                        <li>
                                          Any consumer - Nearby BBPS Authorised
                                          Retail Outlets. There are more than
                                          1500 authorised BBPS retails outlets
                                          in and around Greater Noida area.
                                          Consumers may locate nearest BBPS
                                          Authorised Retails Outlets on NPCL
                                          website through web-link “Find Nearest
                                          Bharat Bill Pay Outlet”.
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        For more details you may also visit NPCL
                                        website.
                                      </p>
                                      <p className="card_cont">
                                        You may visit Customer Care Office at
                                        Knowledge Park-I or Techzone-IV .
                                        Timings 10:00 am to 5:00 pm , Monday to
                                        Friday.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.What is the upper limit for Cash
                                          Payment.?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. As per section 6.10 of Electricity
                                        Supply code 2005, you can make cash
                                        payment up to Rs.20,000/- only. In case
                                        your bill is more than the above
                                        mentioned value, remaining payment shall
                                        be made via any mode other than cash.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.How to make online payment of Bills?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. Online Payment of Bills can be made
                                        through below mentioned modes :
                                      </p>
                                      <ul>
                                        <li>NPCL Website</li>
                                        <li>NPCL’s Mobile App and BHIM App</li>
                                        <li>
                                          PayTM, Mobiquick, and Other wallets
                                        </li>
                                        <li>Bharat QR (printed on bills)</li>
                                        <li>
                                          Bharat Bill Payment System (BBPS)
                                        </li>
                                        <li>
                                          Utility bill payment option in
                                          Consumer’s internet banking accounts
                                        </li>
                                        <li>
                                          Auto- Debit facility through NACH
                                          (National Automated Clearing House)
                                        </li>
                                        <li>RTGS/ NEFT to NPCL’s accounts</li>
                                      </ul>
                                      <p className="card_cont">
                                        For more details you may also visit NPCL
                                        website.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.Do I need to pay extra charges if I
                                          wish to pay Online through NPCL
                                          website/Mobile App ?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. NPCL do not levy any extra charge
                                        for online payment. However, the Payment
                                        Gateway , Wallets and Other service
                                        providers may levy service charges
                                        along-with applicable taxes for
                                        transactions above Rs. 4,000/-. In case
                                        the transaction is made through Paytm
                                        Gateway service charges would be levied
                                        at following rate:
                                      </p>
                                      <p className="card_cont">
                                        ** For credit/debit card transactions,
                                        1.10% of the transaction amount being
                                        paid
                                      </p>
                                      <p className="card_cont">
                                        * For Net banking transactions,
                                        Rs.4/-per transaction
                                      </p>
                                      <p className="card_cont">
                                        In case the transaction is made through
                                        Bill desk Gateway service charges would
                                        be levied at following rate:
                                      </p>
                                      <ul>
                                        <li>
                                          * For credit/debit card transactions,
                                          1% of the transaction amount being
                                          paid
                                        </li>
                                        <li>
                                          * For Net banking transactions,
                                          Rs.3/-per transaction
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        <span>
                                          Q.Is it safe to give my bank details
                                          on NPCL website ?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. Yes, It is completely safe. NPCL
                                        follows highest level of security
                                        standards to ensure that the data
                                        entered by consumers remains
                                        confidential and not stored anywhere on
                                        NPCL Server.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.How can I make Cheque Payments
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. Consumer can drop cheque at
                                        following Cheque Collection Centres of
                                        NPCL along with latest bill stub (Lower
                                        portion of bill). The cheques should be
                                        in favour of ""Noida Power Company
                                        Limited"". Write down the cheque with
                                        credentials like your Name, Consumer
                                        Number and mobile number at the back of
                                        cheque.
                                      </p>
                                      <ul>
                                        <li>
                                          NPCL Customer Care Centre (Sector
                                          KP-1)
                                        </li>
                                        <li>
                                          NPCL Customer Care Centre
                                          (Techzone-IV)
                                        </li>
                                        <li>
                                          NPCL Sub-station atAlpha-1, Opp Ryan
                                          International School
                                        </li>
                                        <li>
                                          NPCL Sub-station, Sector Omega-I,
                                          Builders Area, Near AWHO Apartments
                                        </li>
                                        <li>
                                          NPCL Sub-station at Surajpur, Near
                                          Surajpur Police Station
                                        </li>
                                        <li>
                                          Bar Association Office, Surajpur
                                          District Court
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        <span>
                                          Q.Can I make payment through Post
                                          dated cheques ?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. Post-dated and outstation cheques
                                        are not accepted at NPCL.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.What are the charges applicable on
                                          cheque bounce?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. In case the Cheque gets bounced due
                                        to insufficient funds or exceed
                                        arrangement, you will not be able to
                                        make payment through cheque in that
                                        Financial Year. Charges applicable are
                                        mentioned below:
                                      </p>
                                      <ul>
                                        <li>
                                          Cheque amount of less than Rs. 5000 :
                                          Rs.250
                                        </li>
                                        <li>
                                          Cheque amount from Rs. 5000 & up to
                                          Rs. 10000 : Rs. 500
                                        </li>
                                        <li>
                                          Cheque amount from Rs. 10000 & up to
                                          Rs. 1 Lac : Rs. 1000
                                        </li>
                                        <li>
                                          Cheque amount of Rs. 1 Lac and above :
                                          Rs. 5000
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        <span>
                                          Q.Can I issue another cheque, if my
                                          cheque bounces?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. In case the Cheque got bounced due
                                        to insufficient funds or exceed
                                        arrangements, the consumer will not be
                                        able to make payment through cheque in
                                        that Financial Year.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.Can I issue cheque, if my supply is
                                          Disconnected ?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. Yes, consumer can pay their bill
                                        amount through cheque (Considering no
                                        cheque bounce history).
                                      </p>
                                      <p className="card_cont">
                                        Payment can be done for outstanding bill
                                        and reconnection charges as below:-
                                      </p>
                                      <ul>
                                        <li>
                                          Bill payment via cheque and
                                          reconnection charge via cash and vice
                                          versa Bill payment and reconnection
                                          charge together via cash.
                                        </li>
                                        <li>
                                          Bill payment and reconnection charge
                                          together via cheque . Please note, if
                                          in case the same get dishonoured, the
                                          supply will be disconnected and the
                                          Reconnection amount has to be paid
                                          again.
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        <span>
                                          Q.When will my online payment be
                                          updated in my account?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. All payments made through other
                                        that RTGS / NEFT mode are updated on
                                        Real-time basis in consumers account
                                        except during system
                                        shutdown/maintenance when it may take up
                                        to 2 to 3 working days.
                                      </p>
                                      <p className="card_cont">
                                        Q.Where can I approach to register my
                                        payment related complaint?
                                      </p>
                                      <p className="card_cont">
                                        Ans. Complaints can be registered at:
                                      </p>
                                      <ul>
                                        <li>NPCL Website.</li>
                                        <li>NPCL Mobile App.</li>
                                        <li>
                                          Our 24X7 Call centre at 0120-6226666 /
                                          2333555.
                                        </li>
                                        <li>
                                          You may visit Customer Care Office at
                                          Knowledge Park-I or Techzone-IV .
                                          Timings 10:00 am to 5:00 pm , Monday
                                          to Friday.
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        <span>
                                          Q.What are the GST % will be charged
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. GST will be levied as per GST
                                        regulations and rates applicable to
                                        various services provided by NPCL.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.For which services I have to pay GST
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. As per GST Circular No.
                                        34/08/2018-GST dated 01st March 2018,
                                        GST is applicable on all the services
                                        provided by the NPCL except energy
                                        charges.
                                      </p>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="collapsed card-link"
                                data-toggle="collapse"
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowBill(!showBill)}
                              >
                                Billing Related
                              </a>
                            </div>

                            {showBill ? (
                              <div
                                id="collapseThree"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q.How are bills prepared if the meter is
                                      not read?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If the actual meter reading is not
                                    acquired due to any reason; provisional bill
                                    will be raised which is subjected to
                                    adjustment upon receipt of actual meter
                                    reading in the subsequent months.
                                    Provisional bills are raised on average
                                    consumption of last 3 months.
                                    Inaccessibility to premises for 3 months or
                                    more could lead to disconnection of supply
                                    as per Electricity ̀ Supply Code 2005.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is the normal reading span for
                                      monthly Meter Reading?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Meter reading is normally taken between
                                    a period of 28 to 32 days from the last
                                    meter reading date. The same is intimated to
                                    you through SMS if your mobile number is
                                    registered with us. Fixed Charges and slabs
                                    for Energy charges are calculated
                                    proportionally for the billing period.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How to calculate / understand
                                      electricity bill?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Ans. Please refer back side of
                                    electricity bill and check for applicable
                                    Tariff and Bill Calculation. Additionally,
                                    you can reach out to us at
                                    crm@noidapower.com
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is the meter reading system?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Reading of three phase meters is taken
                                    remotely through AMR or LPR facility. For
                                    Single Phase meters, meter readers go
                                    door-to-door and record readings in Hand
                                    Held Devices (HHD) / Meter Reading Terminals
                                    (MRT). Reading information can also be
                                    conveyed through SMS/Email registered with
                                    us.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why is my meter not read regularly?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Any one of the following can be the
                                    reasons:
                                  </p>
                                  <ul>
                                    <li>
                                      Your premises are vacant / locked for long
                                      period of time or constantly during
                                      daytime resulting in inaccessibility of
                                      meter to the reader. Code 2005.
                                    </li>
                                    <li>
                                      Discrepancy in address in our database
                                      with the actual address may result in
                                      meter reader missing out your premises.
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    If you observe such shortcomings in the
                                    address (as printed in bill), please bring
                                    the same to the notice of CR(Front Desk) at
                                    NPCL Customer Care Office immediately for
                                    corrections.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Where to approach when meter is not read
                                      ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If consumer premises is found locked,
                                    meter reader will stick a ""miss you""
                                    sticker intimating about the failed reading
                                    attempt alongwith a contact number to
                                    coordinate the day & time of reading. Or you
                                    may:
                                  </p>
                                  <ul>
                                    <li>
                                      Call at our 24X7 CALL CENTER - 0120
                                      6226666
                                    </li>
                                    <li>
                                      Mail your concern at crm@noidapower.com
                                    </li>
                                    <li>
                                      Whatsapp on 0120-6226666 WrongReading
                                      Consumer Number
                                    </li>
                                    <li>
                                      Send SMS at 7840002288 #WRONGREADING
                                      Consumer Number (Only which is showing in
                                      KWH)
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why fixed charges are not constant?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Fixed Charges defined by Monthly Tariff
                                    applies to a period of 30 days. When billing
                                    period is not 30 days, applicable fixed
                                    charges are calculated on prorata basis.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.What are Penal Demand Charges</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Use of load beyond the sanctioned load
                                    is a cognisable oﬀence. Penal demand charges
                                    are levied in excess to ﬁxed charges based
                                    on demand usage over and above the
                                    sanctioned load. It is thus advised to
                                    enhance your contracted load to avoid penal
                                    demand charges.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.How to calculate slab ?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Slab based energy rates defined by
                                    Tariff is applicable for 30 days per month
                                    and is prorated on simple unitary method
                                    based on number of billing days i.e.
                                    difference between current reading date and
                                    previous reading date (both dates inclusive)
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.What are Makeup Charges?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Ans. : As per approved Tariﬀ, consumers
                                    who are part of LMV-2 (Commercial Supply)
                                    and LMV-9 (Temporary Supply) are subjected
                                    to load based minimum charges. If the sum of
                                    Fixed Charges (for LMV-2 only) and Energy
                                    Charges is less than applicable monthly
                                    minimum charge then diﬀerential amount is
                                    levied as Make Up Charges. Makeup charge =
                                    minimum charge - (ﬁxed charges + energy
                                    charge).
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Wrong reading mentioned on my bill. How
                                      to get it rectified?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Ans. Request for wrong reading can be
                                    registered through following touchpoints
                                  </p>
                                  <ul>
                                    <li>
                                      Call at our 24X7 CALL CENTER - 0120
                                      6226666
                                    </li>
                                    <li>
                                      Email your concern at crm@noidapower.com.
                                      Do attach meter reading photograph for
                                      convenience.
                                    </li>
                                    <li>
                                      Whatsapp on 0120-6226666 WrongReading
                                      Consumer Number.
                                    </li>
                                    <li>
                                      Send SMS at 7840002288 #WRONGREADING
                                      Consumer Number Reading (Only which is
                                      showing in KWH)
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q.What to do if I am not satisfied with
                                      the meter reading?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If you are not satisfied with your
                                    bill, you may
                                  </p>
                                  <ul>
                                    <li>
                                      Call at our 24X7 CALL CENTER - 0120
                                      6226666
                                    </li>
                                    <li>
                                      Mail your concern at crm@noidapower.com
                                    </li>
                                    <li>
                                      Whatsapp on 0120-6226666 WrongReading
                                      Consumer Number.
                                    </li>
                                    <li>
                                      Send SMS at 7840002288 #SELFREADING
                                      Consumer Number Reading (Only which is
                                      showing in KWH)
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is the Frequency of billing ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Billing for every consumer is done on
                                    Monthly basis
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How electricity bills are delivered?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Bills are delivered through third party
                                    vendor every month. You are requested to
                                    subscribe for e-bill for on-time and assured
                                    delivery of bill.
                                  </p>
                                  <p className="card_cont">
                                    In case you need duplicate bill copy, may
                                    please click on below link given on our
                                    website : You may reach out us through
                                    WhatsApp no. 0120-6226666 (DupBill Consumer
                                    No.) or Send SMS at 7840002288 ( #DUPBILL
                                    Consumer No.)
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.How to get duplicate bill ?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. In case you need duplicate bill copy,
                                    may please click on below link given on our
                                    website : You may also login to our website
                                    or Mobile www.noidapower.com App using your
                                    credentials (Username & Password) for
                                    downloading / viewing last 6 bills.
                                  </p>
                                  <p className="card_cont">
                                    In case you have not registered on our
                                    website, kindly go through the “Need help in
                                    Sign In” tab under My Account tab.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.What is DPS and Rebate?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Payment before Due Date allows
                                    consumers to avail rebate of 1% on Fixed and
                                    Energy Charges. DPS means Delayed Payment
                                    Surcharge which is applicable due to
                                    non-payment of bills upto due date.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How DPS is charged in the bill?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If a consumer fails to pay his
                                    electricity bill by due date specified
                                    therein, a late payment surcharge shall be
                                    levied at 1.25% on the dues per month up-to
                                    first three months of delay. Post three
                                    months, DPS will be charged at 2.00% per
                                    month on the unpaid dues. In case of
                                    constant non-payment, your elctricity
                                    connection can be disconnected.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q. DPS waiver.?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. DPS is levied as per Rate Schedule
                                    approved by Hon'ble UPERC and no-one can
                                    waive the same
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How to lodge complaint through SMS?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You can raise your complaint & avail
                                    other important information by sending SMS
                                    on 7840002288 from your registered mobile
                                    number. To see list of short codes with
                                    mandatory details, click on
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Is there any rebate if I pay bills
                                      online?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. No, there is no additional rebate
                                    provided for online payments. However, a
                                    rebate of 1% against sum of Demand Charges &
                                    Energy Charges is provided upon payment
                                    within due date.
                                  </p>
                                  <p className="card_cont">
                                    {" "}
                                    Q.Why there are different colour bills? It
                                    denotes what ?
                                  </p>
                                  <p className="card_cont">
                                    Ans. Multi colour bills are designed to
                                    reflect upon varied consumer behaviour
                                    towards payment of dues and usage of
                                    electricity.
                                  </p>
                                  <ul>
                                    <li>
                                      Blue Bill denotes timely bill payments
                                    </li>
                                    <li>
                                      Green Bill denotes payment through digital
                                      mode and/or Solar Net Metering Consumer
                                    </li>
                                    <li>
                                      Orange Bill denotes delayed payment and/or
                                      arrears in current bill
                                    </li>
                                    <li>
                                      Red bill cautions consumer to abstain from
                                      unauthorized use of electricity based on
                                      site reporting.
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q.How much interest paid on Security
                                      Deposit?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Interest is credited to consumers
                                    against existing security deposit on annual
                                    basis as per the bank rate prescribed by the
                                    RBI.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What would be the disconnection date
                                      once arrear is paid from the current month
                                      bill?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. It is advised to clear entire dues in
                                    event of arrears amount in current bill.
                                    However, if payment is made only against
                                    arrears, then disconnection date against
                                    current dues is 15 days from the due date as
                                    mentioned on the electricity bill.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Who can submit self meter reading ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. The facility of self submission of
                                    meter reading is available for single phase
                                    consumers. Reading can be submitted through
                                    SMS, Website or App in the dedicated slot
                                    provided every month.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Will I receive a bill for Pre-paid
                                      meter? Do I need to make payment against
                                      bill?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Monthly e-bill will be sent to
                                    registered email id & phone number post bill
                                    generation. It can also be downloaded from
                                    our website via Quick E-bill Download or
                                    follow direct link:
                                  </p>
                                  <p className="card_cont">
                                    No payment is required to be made against
                                    the bill. Ensure sufficient balance in
                                    prepaid meter through timely recharge in
                                    order to avail uninterrupted power supply.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How & when self reading can be provided
                                      by consumer?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Reading submission slot is 1st to 10th
                                    day of every month. You can easily reach out
                                    to us by sending SMS at 7840002288
                                    (#SELFREADING Consumer No. Reading) or
                                    WHATSAPP us at 0120-6226666 (SelfReading
                                    Consumer No. Reading). The service is also
                                    available on our website & app.
                                  </p>
                                  <p className="card_cont">
                                    * Please note that reading should be in KWH
                                    parameter
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.What is power factor?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Power factor for the month is
                                    determined as the ratio of KWH (Active Power
                                    Consumption) to KVAH(Apparent power
                                    consumption).
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is the minimum Power Factor to be
                                      maintained?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. As per the regulations of U.P.
                                    Electricity Supply Code, 2005 minimum power
                                    factor to be maintained is 0.75. If recorded
                                    power factor is less than 0.75,suitable
                                    action as per the provisions shall be
                                    initiated by NPCL.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What can be the reason which may cause
                                      dip in Power Factor? How it is to be
                                      maintained?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Majority of three phase connections
                                    constitutes of inductive load which draws
                                    non working / reactive power thereby
                                    increasing the KVAH(Apparent power
                                    consumption). Use of capacitor banks is
                                    helpful in offsetting this non working power
                                    requirement, but it should be regulated
                                    according to variance in load requirement by
                                    installing Automated Power Factor
                                    Controller(APFC). Regular check up and
                                    maintenance is also advised.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why it is important to maintain Power
                                      Factor?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Consumers above 10 KW are billed on
                                    KVAH basis as per the applicable tariff
                                    provisions. Dip in power factor(P.F.) cause
                                    increase in KVAH consumption which in turn
                                    results in higher energy charges for the
                                    consumer. Also, it reduces the effective
                                    distribution network capacity of NPCL which
                                    can lead to power outage in the area.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What are the consequences if power
                                      factor controller is not working properly?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Failure of power factor controller i.e.
                                    APFC/Capacitor banks will lead to
                                    inadequate/over compensation of reactive
                                    power requirement. In both scenarios, KVAH
                                    consumption will suddenly increase leading
                                    to higher energy charges being billed.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Can NPCL help in improving Power Factor?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. No, it is consumer's responsibility to
                                    maintain their power factor from time to
                                    time
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What is the Frequency of billing ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Billing for every consumer is done on
                                    Monthly basis
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How electricity bills are delivered?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Bills are delivered through third party
                                    vendor every month. You are requested to
                                    subscribe for e-bill for on-time and assured
                                    delivery of bill.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q. How to get duplicate bill ?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. "You are required to login on our
                                    website or Mobile www.noidapower.com App
                                    using your credentials (Username & Password)
                                    for downloading / viewing last 6 bills.
                                  </p>
                                  <p className="card_cont">
                                    In case you have not registered on our
                                    website, kindly go through the “Need help in
                                    Sign In” tab under My Account tab".
                                  </p>
                                  <p className="card_cont">
                                    <span>Q. What is DPS and Rebate?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Payment before Due Date allows
                                    consumers to avail rebate of 1% on Fixed and
                                    Energy Charges. DPS means Delayed Payment
                                    Surcharge which is applicable due to
                                    non-payment of bills upto due date.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How DPS is charged in the bill?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If a consumer fails to pay his
                                    electricity bill by due date specified
                                    therein, a late payment surcharge shall be
                                    levied at 1.25% on the dues per month up-to
                                    first three months of delay. Post three
                                    months, DPS will be charged at 2.00% per
                                    month on the unpaid dues. In case of
                                    constant non-payment, your elctricity
                                    connection can be disconnected.
                                  </p>
                                  <p className="card_cont">Q. DPS waiver.?</p>
                                  <p className="card_cont">
                                    Ans. DPS is levied as per Rate Schedule
                                    approved by Hon'ble UPERC and no-one can
                                    waive the same.
                                  </p>
                                  <p className="card_cont"></p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="card-link"
                                data-toggle="collapse"
                                onClick={() => setShowTheft(!showTheft)}
                                style={{ cursor: "pointer" }}
                              >
                                Theft Related
                              </a>
                            </div>
                            {showTheft ? (
                              <div
                                id="collapseFour"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q. Where I can contact in case of Direct
                                      theft booked on my consumer number.?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You may visit to Our Office at KP-II
                                    from 10:00 A.M to 3:00 P.M (Mon-Fri) for any
                                    detailed information about theft booked or
                                    may contact our 24X7 CALL CENTER - 0120
                                    6226666 or WhatsApp on 0120-6226666, write
                                    Theft (to report electricity theft) may
                                    please click on given link to register
                                    online complaint.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q. How to report Direct Theft</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You can call and complain against theft
                                    of electricity / tampering of meter through
                                    following avenues:
                                  </p>
                                  <ul>
                                    <li>Helpline Number : 9891222240</li>
                                    <li>
                                      <Link to="https://www.noidapower.com/">Noidapower.com</Link>
                                    </li>
                                    <li>Mobile App</li>
                                    <li>CALL CENTER - 0120 6226666</li>
                                  </ul>
                                  <p className="card_cont">
                                    Your identity shall remain confidential and
                                    action will be taken at the earliest.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What is meant by “Direct Theft”?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Section 135(1) of Electricity Act, 2003
                                    as amended by Electricity (amendment) Act ,
                                    2007, Theft of electricity is defined as,
                                    whoever dishonestly,
                                  </p>
                                  <ul>
                                    <li>
                                      a) Taps Electricity line:
                                      <ul>
                                        <li>- overhead</li>
                                        <li>- underground</li>
                                      </ul>
                                    </li>
                                    <li>
                                      b) Tampers meter:
                                      <ul>
                                        <li>- usage of tampered meter</li>
                                        <li>- current reversing transformer</li>
                                        <li>- loop connection</li>
                                        <li>
                                          - other device interferes with
                                          accurate/proper registration. c) Other
                                          device/method result in a manner
                                          whereby:
                                        </li>
                                        <li>- electricity is stolen</li>
                                        <li>
                                          - damages/destroys an electric meter/
                                          apparatus/equipment/wire/ cause
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      c) Uses electricity through a tampered
                                      meter
                                    </li>
                                    <li>
                                      d) Uses electricity for purposes other
                                      than that which was authorized.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="collapsed card-link"
                                data-toggle="collapse"
                                href="#"
                                onClick={() =>
                                  setShowDisconnection(!showDisconnection)
                                }
                              >
                                Disconnection Related
                              </a>
                            </div>
                            {showDisconnection ? (
                              <div
                                id="collapseFive"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q. How to apply for permanent
                                      disconnection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If you want your connection to be
                                    permanently disconnected, you have to submit
                                    the ID proof, last paid bill with our
                                    written application form at Customer Care
                                    Oﬃce at KP-I or Techzone-4*. 2 Years Lock-in
                                    Period is applicable for all consumers
                                    except LMV-9 (Temporary Connection) from
                                    last date of any change in contract. Please
                                    follow the link to know documentation
                                    requirement *Knowledge Park-I or Techzone-IV
                                    . Timings 10:00 am to 5:00 pm , Monday to
                                    Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What are the Timelines of Permanent
                                      Disconnection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Disconnection of supply will completed
                                    within 30 working days.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. Can I apply for the same meter after
                                      surrendering of current meter?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once the connection is permanently
                                    disconnected, consumer has to follow new
                                    connection application process for getting
                                    an electricity connection.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How to apply for refunding of security
                                      funds?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You may visit Consumer Care Centre at
                                    KP-I or Techzone-4, and submit all the
                                    requisite documents which are mentioned at
                                    the back side of application form. Please
                                    follow the link to know documentation
                                    requirement Knowledge Park-I or Techzone-IV
                                    . Timings 10:00 am to 5:00 pm , Monday to
                                    Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. Why and when MCG charges are applicable
                                      for Permanent Disconnection application?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. MCG calculated as per provisions of
                                    Electricity Supply Code, 2005. The Tariff
                                    Orders issued by Hon’ble Uttar Pradesh
                                    Electricity Regulatory Commission (“State
                                    Commission”) from time to time.Clause 4.14
                                    (Agreement) - Point (h) However, if the
                                    agreement is to be terminated before
                                    completion of 2 years: 30 (i) Consumer shall
                                    be liable to pay the minimum charges (or the
                                    demand / fixed charges, if no minimum
                                    charges are prescribed for that category)
                                    for a period of 6 months or the period by
                                    which the total duration of the agreement
                                    falls short of 2 years, whichever is lower.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="collapsed card-link"
                                data-toggle="collapse"
                                href="#"
                                onClick={() =>
                                  setShowReconnection(!showReconnection)
                                }
                              >
                                Reconnection Related
                              </a>
                            </div>
                            {showReconnection ? (
                              <div
                                id="collapseSix"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q. How to apply for reconnection after
                                      making the part payment of outstanding
                                      amount?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Reconnection is not allowed on part
                                    payment.
                                  </p>
                                  <p className="card_cont"></p>
                                  <p className="card_cont">
                                    <table className="table table-responsive">
                                      <thead>
                                        <tr>
                                          <td>
                                            Criteria (load &amp; Category){" "}
                                          </td>
                                          <td>DR charges (Rs.)* </td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            Pre-paid Meters - Single phase{" "}
                                          </td>
                                          <td>- Zero</td>
                                        </tr>
                                        <tr>
                                          <td>Pre-paid Meters -3 phase </td>
                                          <td>- Zero</td>
                                        </tr>
                                        <tr>
                                          <td>Smart Meters - Single phase </td>
                                          <td>- 100</td>
                                        </tr>
                                        <tr>
                                          <td>Smart Meters - 3 phase </td>
                                          <td>- 200</td>
                                        </tr>
                                        <tr>
                                          <td>&gt;75 KW/100BHP </td>
                                          <td>- 2000</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            LMV-6 (having load &lt;=75KW/100BHP){" "}
                                          </td>
                                          <td>- 1000</td>
                                        </tr>
                                        <tr>
                                          <td>All others categories </td>
                                          <td>- 600</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </p>
                                  <p className="card_cont">
                                    Note: * 18% GST is applicable on DR charges
                                    and supply will be reconnected within 48
                                    hrs. for Rural and 24 hrs. for urban after
                                    payment of Outstanding dues along with
                                    reconnection charges.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What are the Timelines for Reconnection
                                      after temporary disconnection of supply?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Timeline for reconnection of supply
                                    will be Maximum of 48 Hrs for Rural and 24
                                    hrs in Urban after payment of bill &
                                    Reconnection charges.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How to apply for reconnection when
                                      meter already removed at site?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. There is no such provision
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How to apply for reconnection after
                                      final bill generation?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. There is no such provision of
                                    reconnection. Once the payment of final bill
                                    is done, consumer has to apply for new
                                    connection with all required documents.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="collapsed card-link"
                                data-toggle="collapse"
                                href="#"
                                onClick={() => setShowWebSite(!showWebSite)}
                              >
                                Website / Mobile App Related
                              </a>
                            </div>
                            {showWebSite ? (
                              <div
                                id="collapseSeven"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q. What is the procedure to Login on{" "}
                                      <Link to="https://www.noidapower.com/">www.noidapower.com ?</Link>
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Go to www.noidapower.com. Click on
                                    “Consumer Login” and register your Consumer
                                    Number or click on the link: www.npcl.com
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What are the various
                                      services/information available on NPCL's
                                      website?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans.The main features of website are as
                                    follows:
                                  </p>
                                  <ul>
                                    <li>
                                      About NPCL and Contact Details of
                                      registered offices
                                    </li>
                                    <li>Bill Payment Option</li>
                                    <li>Outage Alerts</li>
                                    <li>Service Application and Complaint</li>
                                    <li>Tariff Details</li>
                                    <li>Report a Concern</li>
                                    <li>Account Registration</li>
                                    <li>CSR activities</li>
                                    <li>Self Meter Reading</li>
                                    <li>Quick Ebill Download</li>
                                    <li>Quick Bill Payment</li>
                                    <li>Contact Us</li>
                                    <li>Online Application</li>
                                    <li>Payment Options</li>
                                    <li>Pre-paid Meter Recharge</li>
                                    <li>
                                      Consumer Services for Application form,
                                      Document Checklist, Online Meter Testing,
                                      SMS Based Services, WhatsApp based
                                      services, Customer Care office Queue
                                      Status, Single to Multipoint Conversion,
                                      Consumer Awareness and Many more.
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>Q. How to reset password ?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Password can be reset after clicking
                                    forgot password option. You have to punch
                                    your registered email id and consumer number
                                    to receive an updated password , if problem
                                    still persist please write us at crm@noida
                                    power.com)
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How and from where a consumer can
                                      update his contact details ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. For updating contact details (mobile
                                    number/ email-id) consumer can login to our
                                    website or mobile app. www.noidapower.com
                                    You may also visit our customer care oﬃce,
                                    Knowledge Park-I or Techzone-IV . Timings
                                    10:00 am to 5:00 pm , Monday to Friday,
                                    along with the BP No. and ID proof of
                                    registered consumer or company. You may also
                                    write us at crm@noidapower.com
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. Is it safe to give my bank details on
                                      NPCL website?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Yes, It is completely safe. NPCL
                                    follows highest level of security standards
                                    to ensure that the data entered by consumers
                                    remains conﬁdential.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. Do I need to pay extra charges for
                                      online payment?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. NPCL does not levy any extra charge for
                                    online payment. However, the Payment
                                    Gateway, Wallets and other service providers
                                    may levy service charges along with
                                    applicable taxes for transaction above Rs.
                                    4,000/-.
                                  </p>
                                  <p className="card_cont">
                                    In case the transaction is made through
                                    Paytm Gateway service charges would be
                                    levied at following rate:
                                  </p>
                                  <ul>
                                    <li>
                                      For credit/debit card transaction, 1.10%
                                      of the transaction amount being paid
                                    </li>
                                    <li>
                                      For Net banking transaction, Rs.4/-per
                                      transaction
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    In case the transaction is made through Bill
                                    desk Gateway service charges would be levied
                                    at following rate:
                                  </p>
                                  <ul>
                                    <li>
                                      For credit/debit card transaction, 1% of
                                      the transaction amount being paid
                                    </li>
                                    <li>
                                      For Net banking transaction, 3% per
                                      transaction
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q. Why this month bill is not updated on
                                      Website Mobile app ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Bills are generated from time to time
                                    from commercial department. It might be
                                    possible due to some certain reason bill of
                                    this month may be delayed and it’s not
                                    generated yet. Once it will have generated
                                    in SAP system then PDF and bill details will
                                    appear on Website.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What happen if my ID is locked in NPCL
                                      Website?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. USERID will be automatic unlock in 24
                                    hours for the website users
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What all the features available on
                                      Mobile App ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. After Login fetures are as follows :
                                  </p>
                                  <ul>
                                    <li>Bill and Payment History</li>
                                    <li>Register Request & Complaints</li>
                                    <li>Consumer Consumption Analysis</li>
                                    <li>Energy Calculator</li>
                                    <li>Request & Complaint Status</li>
                                    <li>Subscribe for E-bill</li>
                                    <li>Self-Meter Reading</li>
                                    <li>Quick Payment</li>
                                    <li>Energy Tips</li>
                                    <li>KYC Updation</li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q. What is Un-Authenticated Sender issue.
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. User details like USERNAME, Email ID
                                    and Password are not matched when user face
                                    this kind of problem.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="collapsed card-link"
                                data-toggle="collapse"
                                href="#"
                                onClick={() => setShowComplaint(!showComplaint)}
                              >
                                Complaint Against Services Related
                              </a>
                            </div>
                            {showComplaint ? (
                              <div
                                id="collapseEight"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q. What is the process of applying for New
                                      Connection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Applicant can download or ﬁll the
                                    online New Connection Service form from our
                                    website and upload necessary documents
                                    www.noidapower.com as mentioned in the
                                    documents checklist. You can also visit our
                                    Customer Care Oﬃce at Knowledge Park-I or
                                    Techzone-IV . Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday, to apply in person.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. Within how much time should the
                                      connection be provided to me?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once the application and necessary
                                    documents are reviewed at NPCL, and estimate
                                    gets generated which needs to be paid by the
                                    consumer. Post which it will take
                                    approximately 7 working days* for the
                                    apparatus to be installed at the consumers
                                    premises and for the energization of supply.
                                  </p>
                                  <p className="card_cont">
                                    *This SLA is applicable for the urban
                                    domestic connection where no augmentation of
                                    main is required.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why I have not received my estimate
                                      intimation?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once you have successfully submitted
                                    your new service application form at our
                                    Customer Care Office or Online , we process
                                    the application in our system and it gets
                                    forwarded to concerned department for the
                                    Commercial & LCC Dues, Technical feasibility
                                    check. Once clearance is received from all
                                    the departments it is followed by estimate
                                    preparation. During this process system
                                    generated messages are triggered, after
                                    completion of necessary stages. Thus, you
                                    are requested to please wait for the same as
                                    we will be working on 1st cum 1st serve
                                    basis.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Can I pay the Estimate amount Online
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Yes , you can make payment online thru
                                    NPCL website or you may visit our Customer
                                    Care Office at Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday to apply in person for
                                    payment of estimate.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Estimate paid but meter not installed
                                      yet?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Post payment of estimated amount, New
                                    connection will be provided within 7 days
                                    where no Augmentation of existing mains are
                                    required.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Whom to contact for the status of
                                      application?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. To know the status of application you
                                    may WhatsApp us on 0120-6226666 , to know
                                    the short codes follow the link or can write
                                    us at crm@noidapower.com or visit us at our
                                    Customer Care Oﬃce, Knowledge Park-I or
                                    Techzone-IV . Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How to apply for refunding of security
                                      fund
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You may visit our Customer Care Office
                                    at Knowledge Park-I or Techzone-IV Timings
                                    10:00 am to 5:00 pm , Monday to Friday to
                                    apply for security refund, please follow the
                                    link to know documentation requirement
                                    (https://www.noidapower.com/Services/Document_Checklist)
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Paper Formalities Required for Load
                                      Reduction?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Consumer has to fill application and
                                    provide all the requisite documentation
                                    which are mentioned on the back side of
                                    application form. Please follow the link to
                                    know documentation requirement. Please note
                                    for load reduction consumer can only apply
                                    after two years from date of energization.
                                    For Temp. Supply, consumer can apply for
                                    load reduction at any point of time.
                                  </p>
                                  <p className="card_cont">
                                    Q.How can consumer find the status of
                                    request against New Connection?
                                  </p>
                                  <p className="card_cont">
                                    Ans. To know the status of a New Connection
                                    request, consumer may use following options.
                                  </p>
                                  <ul>
                                    <li>
                                      You may WhatsApp us on 0120-6226666,NSC
                                      Notification No. (to know New Service
                                      connection application status)
                                    </li>
                                    <li>Can write us at crm@noidapower.com</li>
                                    <li>
                                      Visit us at our Customer Care Office,
                                      Knowledge Park-I or Techzone-IV Timings
                                      10:00 am to 5:00 pm , Monday to Friday.
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>Q.Estimate not Received Yet</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. In case you have not received any
                                    update after 7-10 days of applying for
                                    request.To know the status of application
                                    you may WhatsApp us on 0120-6226666 , to
                                    know the short codes follow the link or can
                                    write us at crm@noidapower.com or visit us
                                    at our Customer Care Oﬃce, Knowledge Park-I
                                    or Techzone-IV . Timings 10:00 am to 5:00 pm
                                    , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is minimum charges & why it is to
                                      be paid.?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Consumer has to bear the minimum
                                    charges based on the total sanctioned load &
                                    as per the applicable tariff rate it is been
                                    calculated for remaining Tenure in case you
                                    have not completed 2 yrs. to the connection
                                    or recent load enhancement/reduction
                                    process. In Case the maximum demand recorded
                                    in any of the last 2 billing cycles is
                                    higher than the reduced contracted load
                                    consumer can not opt for the proposed
                                    contracted load
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is Augmentation? How much time it
                                      take to complete?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Augmentation means
                                    improvement/enhancement of the system
                                    capacity to be able to cater to the
                                    increased electricity load requirement. Such
                                    augmentation needs capital investment.
                                    Sometimes, only after requests received for
                                    new connections or load enhancement;
                                    particularly if the requested load is high.
                                    Different types of Augmentation may be
                                    required such as:
                                  </p>
                                  <ul>
                                    <li>Low Tension (LT) Line Extension</li>
                                    <li>Transformer Capacity Augmentation</li>
                                    <li>
                                      {" "}
                                      High Tension ( 11 KV) Line Extension
                                    </li>
                                    <li>
                                      Complete electrification of an
                                      un-electrified area
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    Timeline for different type of augmentations
                                    are as follows:
                                  </p>
                                  <p className="card_cont">
                                    <table className="table table-responsive">
                                      <thead>
                                        <tr>
                                          <td>
                                            Type of augmentation Timeline
                                            required for augmentation{" "}
                                          </td>
                                          <td>(Working Days) </td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>LT Network augmentation </td>
                                          <td>- 60</td>
                                        </tr>
                                        <tr>
                                          <td>Transformer Augmentation </td>
                                          <td>- 120</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            HT - 11 KV Network Strengthening{" "}
                                          </td>
                                          <td>- 180 </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Un-electrified Area or New Grid
                                            Required{" "}
                                          </td>
                                          <td>- 180 </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How and where to approach for Shifting
                                      of Mains ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. We accept application for following
                                    request
                                  </p>
                                  <ul>
                                    <li>Meter Shifting</li>
                                    <li>Pole Shifting</li>
                                    <li>Service Cable Shifting</li>
                                    <li>Transformer Shifting</li>
                                    <li>Penal Feeder Box Shifting</li>
                                  </ul>
                                  <p className="card_cont">
                                    For the shifting related request an
                                    application along with the copy of id proof
                                    and last paid bill is required (please
                                    follow the link to be submitted at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV . Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday. After verification of
                                    documents, site visit is arranged to check
                                    the feasibility to fulfil the request and
                                    then a survey report is prepared and
                                    accordingly estimate is generated as per
                                    Regulatory guidelines. Once the payment of
                                    estimate is done then shifting will be
                                    executed
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What Documents are required for Load
                                      Enhancement/ Load Reduction/ Name Change/
                                      Category Change?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Please <a href="#">click Here.</a>
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is the TAT for completion of
                                      Service Application?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of estimate payment
                                    against your Service request it will take
                                    next billing cycle to get implemented in the
                                    system during this period you may receive 1
                                    bill with previous load/ category, except
                                    shifting of mains , as it depends upon site
                                    condition and availability of material.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Where we can contact in case of any
                                      information required for service request.?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. To know the status of application you
                                    may WhatsApp us on 0120-6226666 , to know
                                    the short codes follow the link or can write
                                    us at crm@noidapower.com or visit us at our
                                    Customer Care Oﬃce, Knowledge Park-I or
                                    Techzone-IV . Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What are the payment options for
                                      estimate payment ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Yes , you can make payment online thru
                                    NPCL website or you may visit our Customer
                                    Care Office at Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday to apply inperson for
                                    payment of estimate.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is the basis of calculation of
                                      Security Deposit?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. The security deposit maintainable for a
                                    consumer is equivalent to 2 times the
                                    average bill value for the ﬁnancial year
                                    under reference. For a new applicant, the
                                    security deposit will be determined on the
                                    basis of load applied.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my application is been Pending since
                                      long.?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once you complete the documentation
                                    process, the application goes for several
                                    checks via different departments during
                                    which it might have not been cleared under
                                    LCC/CF Check, To know the status of
                                    application you may WhatsApp us on
                                    0120-6226666 , to know the short codes
                                    follow the link or can write us at
                                    crm@noidapower.com or visit us at our
                                    Customer Care Oﬃce, Knowledge Park-I or
                                    Techzone-IV . Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Where to contact in case of application
                                      rejected due to LCC/Theft?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You may visit Customer Care Office at
                                    Knowledge Park-I or Techzone-IV Timings
                                    10:00 am to 5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.When am I required to pay Additional
                                      Security Deposit (ASD)?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Each year the security deposit is
                                    reviewed in the month of April on the basis
                                    of last one year consumption. The statement
                                    of Security Deposit account is given in the
                                    bill for the month of April. If the
                                    maintainable Security Deposit is more than
                                    the security Deposit “held” amount,
                                    additional security deposit is required to
                                    be paid.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What are the documents required in case
                                      of Small Correction in Connection Details
                                      such as Spelling of Name, inadequate
                                      Address?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. The documentary proof for the desired
                                    change along with latest paid bill receipt
                                    along with correction form by the property
                                    owner. You may visit Customer Care Office at
                                    Knowledge Park-I or Techzone-IV . Timings
                                    10:00 am to 5:00 pm , Monday to Friday
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What happens if the Security Deposit
                                      amount held is more than the Security
                                      Deposit Maintainable amount?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. In the above scenario, no ASD request
                                    will be placed however, as per the RBI
                                    guidelines interest gets credited for the
                                    held security amount.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How do I get refund of Security Deposit
                                      after account closure?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. After necessary adjustment, the
                                    Security Deposit amount held with us is
                                    refunded with an A/c payee cheque in the
                                    name of the registered consumer at the
                                    recorded address.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.When I can apply for Security Refund
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Consumer may apply for Security Refund
                                    in below given senarios :
                                  </p>
                                  <ul>
                                    <li>
                                      *Once the connection is Permanently
                                      Disconnected
                                    </li>
                                    <li>
                                      * Once the property is sold and also name
                                      mutation has been completed in records and
                                      earlier connection gets permanently
                                      disconnected, the Old/Earlier property
                                      holder may apply for Security Refund
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q.What are the documents required for
                                      Security Refund
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    <a href="#">Click here</a>
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Who is eligible to get Security Refund
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Only the registered consumer or Old
                                    owner (in case of property sold out) can
                                    apply for Security Refund
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.Can I get Final Bill ?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. After meter removal from site final
                                    bill get generated within 15 days and final
                                    copy may be provided whrein consumer
                                    himself/herself, have requested for
                                    permannet disconnection and having relevant
                                    notification no. too.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.How to apply for NOC ?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once you get the final bill copy and
                                    upon making necessary bill payments (if
                                    any), consumer may apply for NOC from our
                                    customer care office at Knowledge Park-I or
                                    Techzone-IV ..Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why My New Connection service request is
                                      pending for Site Visit ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Please note that after completion of
                                    documentation process, the application goes
                                    for several checks via different departments
                                    during which it might have not been cleared
                                    under LCC/CF Check. After neccessary
                                    clearance from respective team, you shall be
                                    notified with the Estimate Amount which is
                                    to paid before executing the siite work. In
                                    case, payment is been done and work still
                                    pending. To know the status of a New
                                    Connection request, consumer may use
                                    following options.
                                  </p>
                                  <ul>
                                    <li>
                                      You may WhatsApp us on 0120-6226666,NSC
                                      Notification No. (to know New Service
                                      connection application status)
                                    </li>
                                    <li>Can write us at crm@noidapower.com</li>
                                    <li>
                                      Visit us at our Customer Care Office,
                                      Knowledge Park-I or Techzone-IV Timings
                                      10:00 am to 5:00 pm , Monday to Friday.
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why Estimate not yet generated for My
                                      New Connection service request?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of notification
                                    generation against your Service request it
                                    will take 7-10 days time for document
                                    verification followed with site visit &
                                    after receving site report, Estimate get
                                    generated & sent for necessary approvals. We
                                    shall keep you posted with SMS/EMail alert
                                    containing payment link of estimate.In Case
                                    you have received any rejection SMS/Email on
                                    registred contact details, you may reach out
                                    to us via our Whatsapp services on
                                    0120-6226666 NSC Notification No. For any
                                    further requirement, you may please visit us
                                    at our Customer Care Office, Knowledge
                                    Park-I or Techzone-IV Timings 10:00 am to
                                    5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.I have paid my estimate agaist new
                                      service request than why meter
                                      installation is still pending ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of Estimate payment
                                    against your Service request it will take
                                    7-10 days time for meter installation. Only
                                    if there is any right of way issue, it may
                                    take more time for completing the assigned
                                    work and it may only be completed once clear
                                    way will be provided by consumer to our
                                    service team. You may reach out to us via
                                    our Whatsapp services on 0120-6226666 NSC
                                    Notification No. For any further assistance,
                                    you may please visit us at our Customer Care
                                    Office, Knowledge Park-I or Techzone-IV
                                    Timings 10:00 am to 5:00 pm , Monday to
                                    Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why Estimate not yet generated for My
                                      Name Mutation service request?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of notification
                                    generation against your Service request it
                                    will take 7 days time for document
                                    verification and necessary approvals. In
                                    Case you have received any rejection
                                    SMS/Email on registred contact details, you
                                    may reach out to us via our Whatsapp
                                    services on 0120-6226666 NM Notification No.
                                    For any further requirement, you may please
                                    visit us at our Customer Care Office,
                                    Knowledge Park-I or Techzone-IV Timings
                                    10:00 am to 5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my request for Name Mutation is
                                      still pending ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of Estimate Payment
                                    against your Service request it will take
                                    next billing cycle to get implemented in the
                                    system during this period you may receive 1
                                    bill with previous details. In case of any
                                    doubt you may reach out to us via our
                                    Whatsapp services on 0120-6226666 NM
                                    Notification No.For any further requirement,
                                    you may please visit us at our Customer Care
                                    Office, Knowledge Park-I or Techzone-IV
                                    Timings 10:00 am to 5:00 pm , Monday to
                                    Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why Estimate not yet generated for My
                                      Load Augmentation service request?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of notification
                                    generation against your Service request it
                                    will take 7-10 days time for document
                                    verification followed with site visit &
                                    after receving site report, Estimate get
                                    generated & sent for necessary approvals. We
                                    shall keep you posted with SMS/EMail alert
                                    containing payment link of estimate. In Case
                                    you have received any rejection SMS/Email on
                                    registred contact details, you may reach out
                                    to us via our Whatsapp services on
                                    0120-6226666 LA Notification No. For any
                                    further requirement, you may please visit us
                                    at our Customer Care Office, Knowledge
                                    Park-I or Techzone-IV Timings 10:00 am to
                                    5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why My Load Augmentation service request
                                      is pending for Site Visit ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Please note that after completion of
                                    documentation process, the application goes
                                    for several checks via different departments
                                    during which it might have not been cleared
                                    under LCC/CF Check. After neccessary
                                    clearance from respective team, you shall be
                                    notified with the Estimate Amount which is
                                    to paid before executing the site work. In
                                    case, payment is been done and work still
                                    pending you may visit us at our Customer
                                    Care Office, Knowledge Park-I or Techzone-IV
                                    Timings 10:00 am to 5:00 pm , Monday to
                                    Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.I have paid my estimate agaist Load
                                      Augmentation request than why meter
                                      installation is still pending ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of Estimate payment
                                    against your Service request it will take
                                    7-10 days time for meter installation. Only
                                    if there is any right of way issue, it may
                                    take more time for completing the assigned
                                    work and it may only be completed once clear
                                    way will be provided by consumer to our
                                    service team. You may reach out to us via
                                    our Whatsapp services on 0120-6226666 LA
                                    Notification No. For any further assistance,
                                    you may please visit us at our Customer Care
                                    Office, Knowledge Park-I or Techzone-IV
                                    Timings 10:00 am to 5:00 pm , Monday to
                                    Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my request for Category Change is
                                      still pending ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of Estimate Payment
                                    against your Service request it will take
                                    next billing cycle to get implemented in the
                                    system during this period you may receive 1
                                    bill with previous details. In case of any
                                    doubt you may reach out to us via our
                                    Whatsapp services on 0120-6226666 CT
                                    Notification No. For any further
                                    requirement, you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why Estimate not yet generated for My
                                      Category Change service request?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of notification
                                    generation against your Service request it
                                    will take 7 days time for document
                                    verification and necessary approvals. In
                                    Case you have received any rejection
                                    SMS/Email on registred contact details, you
                                    may reach out to us via our Whatsapp
                                    services on 0120-6226666 CT Notification No.
                                    For any further requirement, you may please
                                    visit us at our Customer Care Office,
                                    Knowledge Park-I or Techzone-IV Timings
                                    10:00 am to 5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why Estimate not yet generated for My
                                      Load Reduction service request?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of notification
                                    generation against your Service request it
                                    will take 7-10 days time for document
                                    verification followed with site visit (As &
                                    when required) & after receving site report,
                                    Estimate get generated & sent for necessary
                                    approvals. We shall keep you posted with
                                    SMS/EMail alert containing payment link of
                                    estimate. In Case you have received any
                                    rejection SMS/Email on registred contact
                                    details, you may reach out to us via our
                                    Whatsapp services on 0120-6226666 LR
                                    Notification No. For any further
                                    requirement, you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my request for Load Reduction is
                                      still pending ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of Estimate Payment
                                    against your Service request it will take
                                    next billing cycle to get implemented in the
                                    billing, during this period you may receive
                                    1 bill with previous load details. In case
                                    of any doubt you may reach out to us via our
                                    Whatsapp services on 0120-6226666 LR
                                    Notification No. For any further
                                    requirement, you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why My Shifting request is pending for
                                      Site Visit ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Please note that after completion of
                                    documentation process, the application goes
                                    for several checks via different departments
                                    during which it might have not been cleared
                                    under LCC/CF Check. After neccessary
                                    clearance from respective team, you shall be
                                    notified with the Estimate Amount which is
                                    to paid before executing the site work. In
                                    case, payment is been done and work still
                                    pending you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why Estimate not yet generated for My
                                      Shifting request?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of notification
                                    generation against your Shifting request it
                                    will take 7-10 days time for document
                                    verification followed with site visit &
                                    after receving site report, Estimate get
                                    generated & sent for necessary approvals. We
                                    shall keep you posted with SMS/EMail alert
                                    containing payment link of estimate. In Case
                                    you have received any rejection SMS/Email on
                                    registred contact details, you may please
                                    visit us at our Customer Care Office,
                                    Knowledge Park-I or Techzone-IV Timings
                                    10:00 am to 5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why there is no action taken at site ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Please note that after completion of
                                    documentation process, the application goes
                                    for several checks via different departments
                                    during which it might have not been cleared
                                    under LCC/CF Check. After neccessary
                                    clearance from respective team, you shall be
                                    notified with the Estimate Amount which is
                                    to paid before executing the siite work. In
                                    case, payment is been done and work still
                                    pending you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my Name Correction request not
                                      done/completed ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Ans. From the date of notification
                                    generation against your Service request it
                                    will take next billing cycle to get
                                    implemented in the system during this period
                                    you may receive 1 bill with previous
                                    details. In case of any doubt you may reach
                                    out to us via our Whatsapp services on
                                    0120-6226666 NAC Notification No. For any
                                    further requirement, you may please visit us
                                    at our Customer Care Office, Knowledge
                                    Park-I or Techzone-IV Timings 10:00 am to
                                    5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my Name correction is still pending
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once you complete the documentation
                                    process, the application goes for several
                                    checks via different departments during
                                    which it might have not been cleared under
                                    LCC/CF Check, you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my Address Correction request not
                                      done/completed ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Ans. From the date of notification
                                    generation against your Service request it
                                    will take next billing cycle to get
                                    implemented in the system during this period
                                    you may receive 1 bill with previous
                                    details. In case of any doubt you may reach
                                    out to us via our Whatsapp services on
                                    0120-6226666 NAC Notification No. For any
                                    further requirement, you may please visit us
                                    at our Customer Care Office, Knowledge
                                    Park-I or Techzone-IV Timings 10:00 am to
                                    5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my Address Correction is still
                                      pending
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once you complete the documentation
                                    process, the application goes for several
                                    checks via different departments during
                                    which it might have not been cleared under
                                    LCC/CF Check, you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Can I apply new connections floor wise?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. In domestic high rise building
                                    electricity connection to individual
                                    owner/occupant are provided on multipoint
                                    scheme as per the guidelines of 13th
                                    Ammendmant of Electricity Supply Code, 2005.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What should be the file type and size to
                                      upload for new connection online?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. File size can't exceed 15 mb per
                                    documentation type.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Can I apply new connection through
                                      mobile app?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Yes, you can apply new connection from
                                    NPCL mobile app.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How much I have to pay for Prepaid
                                      Connection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You shall have to pay the applicable
                                    processing fee, service charges and security
                                    (if applicable) as specified in Cost Data
                                    Book which shall be calculated based upon
                                    your application and site survey.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Who can apply for Temporary Connection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If you are intending to carry out any
                                    construction work at your premise and
                                    seeking of electricity connection for
                                    construction activity, then, you will have
                                    to apply for temorary connection.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What are the Documentation requirement
                                      for Temporary Connection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Applicant has to fill application form
                                    for new connection and provide all the
                                    requisite documentation which are mentioned
                                    on the back side of new connection
                                    application form.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How to convert from temporary to
                                      permanent?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once you complete construction work and
                                    obtains building completion certificate from
                                    concerned authority i.e. GNIDA/UPSIDC, you
                                    can apply for Permanent connection.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What are the charges of temporary
                                      connection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You shall have to pay the applicable
                                    processing fee, security deposit and service
                                    charges as specified in Cost Data Book which
                                    shall be calculated based upon your
                                    application and site survey
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is B&L form and why it is required
                                      in load augmentation?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. B&L form or Work completion certificate
                                    form is filled by the licensed electrical
                                    contractor who has carried out electrical
                                    wiring/installation of premise. This
                                    certifies that electrical
                                    wiring/installation is done following all
                                    the regualtions and includes the equipments
                                    details installed, insulation test result of
                                    the wiring/installation.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Whom to contact for status of Online New
                                      Connection application
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. To know the status of application you
                                    may WhatsApp us on 0120-6226666 , to know
                                    the short codes follow the link or can write
                                    us at crm@noidapower.com or visit us at our
                                    Customer Care Oﬃce, Knowledge Park-I or
                                    Techzone-IV . Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="collapsed card-link"
                                data-toggle="collapse"
                                href="#"
                                onClick={() => setShowSupply(!showSupply)}
                              >
                                Supply Related
                              </a>
                            </div>
                            {showSupply ? (
                              <div
                                id="collapseNine"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q. How to resolve supply-related
                                      complaints on power interruptions or
                                      unsatisfactory voltage profile?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Register the complaint at NPCL's
                                    helpline no. +91-0120 6226666 along with
                                    10-digit consumer number printed at the
                                    upper left corner of your electricity bill
                                    and one complaint number will be intimated
                                    to you for future reference. NPCL
                                    fault-repairing crew will attend to the
                                    cause of the complaint and resolve the same
                                    within the following time limits:
                                  </p>
                                  <ul>
                                    <li>Urban: 3 Hours</li>
                                    <li>Rural: 12 Hours</li>
                                    <li>
                                      Industrial & Instittutional : 4 Hours
                                    </li>
                                    <li>Transformer failure : 72 Hours</li>
                                  </ul>
                                  <p className="card_cont">
                                    You may reach out us through WhatsApp no.
                                    0120-6226666 (NoPower Consumer No.) or Send
                                    SMS at 7840002288 ( #NoPower Consumer No.)
                                    May also download our mobile app for easy &
                                    quick access.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How to address the problem of frequent
                                      tripping of MCB or blowing of fuse?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Contact a Licensed Electrical
                                    contractor (L.E.C.) to locate the cause. If
                                    there is a fault/defect in your wiring
                                    installations, rectify the same.
                                  </p>
                                  <p className="card_cont">
                                    If the load of your installation is more
                                    than the load allowed by NPCL, please apply
                                    for enhancement of load by filling up a form
                                    available at NPCL office KP-1 & know more
                                    about the same.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {augmentation ? (
                      <div className="col-sm-12 bg_cols">
                        <div className="form_div">
                          <div className="tab-content tab_main">
                            <div
                              className="tab-pane  show active"
                              id="newconnection"
                            >
                              <div className="tab-pane " id="faq">
                                <div className="bs-example bs-example-1">
                                  <div
                                    className="tab-pane fade show active"
                                    id="newconnection"
                                  >
                                    <ul
                                      className="nav nav-tabs nav_tbds"
                                      style={{ overflow: "hidden" }}
                                    >
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation"
                                          className="nav-link"
                                          data-toggle="tab"
                                        >
                                          Calculate Your Load
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation-doc"
                                          className="nav-link"
                                          data-toggle="tab"
                                        >
                                          Document Checklist
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation-sample"
                                          className="nav-link"
                                          data-toggle="tab"
                                        >
                                          Sample Documents
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation-faq"
                                          className="nav-link active"
                                          data-toggle="tab"
                                        >
                                          FAQs
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation-apply"
                                          className="nav-link"
                                          data-toggle="tab"
                                        >
                                          Apply
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation-status"
                                          className="nav-link"
                                          data-toggle="tab"
                                        >
                                          Application Status
                                        </Link>
                                      </li>
                                      <li className="nav-item">
                                        <Link
                                          to="/load-reduction-augmentation-feedback"
                                          className="nav-link"
                                          data-toggle="tab"
                                        >
                                          Feedback
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>{" "}
                                </div>

                                <h4
                                  className="mt-2"
                                  style={{ marginBottom: "20px" }}
                                >
                                  {" "}
                                  FAQs{" "}
                                </h4>
                                <div id="accordion">
                                  <div className="card">
                                    <div className="card-header">
                                      <a
                                        className="collapsed card-link"
                                        data-toggle="collapse"
                                        onClick={() => setShowMeter(!showMeter)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Meter Related
                                      </a>
                                    </div>

                                    {showMeter ? (
                                      <div id="collapseOne">
                                        <div className="card-body">
                                          <p className="card_cont">
                                            <span>
                                              Q. What should be done in case
                                              there is no supply in the meter ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            In case the nearby are is under
                                            Supply Outage, you may wait until
                                            the supply is restored. If there is
                                            no power even after the supply is
                                            restored Consumer may contact at our
                                            24X7 CALL CENTER - 0120 6226666 You
                                            may reach out us through WhatsApp
                                            no. 0120-6226666 (NoPower Consumer
                                            No.) or Send SMS at 7840002288 (
                                            #NoPower Consumer No.)
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What to do in case of meter is
                                              running fast/ excess billing?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Domestic Consumer: May please
                                            check the internal wiring by any
                                            private electrician. If no such
                                            issue persist may kindly contact
                                            24X7 Call Centre No. 0120-6226666 or
                                            visit Customer Care Office at KP-I
                                            to register request for Meter
                                            Testing. Demand note for meter
                                            testing fees will be generated as
                                            per UPERC guidelines.
                                          </p>
                                          <p className="card_cont">
                                            Industrial & Institutional Consumers
                                            : May please check the Power Factor
                                            & Neutral disturbance issue to avoid
                                            any demand shoot-up in billing. if
                                            persist may kindly contact 24X7 Call
                                            Centre No. 0120-6226666
                                          </p>
                                          <p className="card_cont">
                                            You may reach out us through
                                            WhatsApp no. 0120-6226666
                                            (BillDispute Consumer No.) or Send
                                            SMS at 7840002288 ( #BILLDISPUTE
                                            Consumer No.) or else you may also
                                            visit our Customer Care office at
                                            Knowledge Park-I or Techzone-IV .
                                            Timings 10:00 am to 5:00 pm , Monday
                                            to Friday.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. How to analysis correct meter
                                              consumption/ abnormal consumption?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. In a normal house hold having 1
                                            or 2 A/C’s along with Geyser, Fridge
                                            etc., the average units consumed may
                                            be between 500 to 1000 Units per
                                            month depending upon actual use. If
                                            consumer feel that the consumption
                                            is more as compared to the usage and
                                            load, we request consumers to please
                                            get their wiring checked by
                                            competent electrician with respect
                                            to below mentioned points:
                                          </p>
                                          <ul>
                                            <li>
                                              To check any Earth Leakage in the
                                              internal wiring or not.
                                            </li>
                                            <li>
                                              In case of multiple connections in
                                              the building, whether your
                                              internal wiring has a separate
                                              neutral wire and should not be
                                              mixed with other connections.
                                            </li>
                                            <li>
                                              Check if someone else is also
                                              using supply from your meter or
                                              not.
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            However, it is to inform that, NPCL
                                            will not be responsible for higher
                                            consumptions due to any of the above
                                            reasons but taking action by
                                            consumer will reduce the
                                            consumption. After having checked
                                            all the above, If consumption is
                                            still not reduced as per consumer’s
                                            expectation, then he is having an
                                            option to get the meter tested by
                                            contacting our helpline
                                            number+91-120-2333555 Apply online
                                            for meter testing or visit Customer
                                            Care Office at Knowledge Park-I or
                                            Techzone-IV . Timings 10:00 am to
                                            5:00 pm , Monday to Friday. and get
                                            the offline requests generated for
                                            meter checking . Meter Testing fees
                                            will be generated & added in
                                            subsequent bill as per UPERC
                                            guidelines.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the various reasons of
                                              meter change?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. A Distribution Licensee may
                                            change the meter from time to time
                                            for any of the following reasons:
                                          </p>
                                          <ul>
                                            <li>Version Upgrade</li>
                                            <li>Meter is burnt</li>
                                            <li>Meter is faulty</li>
                                            <li>Meter is damaged</li>
                                            <li>No display in the meter</li>
                                            <li>Suspected to be tampered</li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the
                                              timelines/Resolutions for Meter
                                              Testing, Fast, Slow, Burnt,
                                              Damaged, Stop, Faulty, Shifting?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Time period for compliances
                                          </p>
                                          <ul>
                                            <li>
                                              <b>Meter Stop/Meter Damaged</b>:
                                              14 working days for meter
                                              replacement.
                                            </li>
                                            <li>
                                              <b>Meter Slow/Fast</b>: 14 working
                                              days after estimate payment for
                                              testing & another 14 working days
                                              for replacement if required
                                            </li>
                                            <li>
                                              <b>Meter Burnt</b>: 3 Working Days
                                              for replacement of the burnt meter
                                            </li>
                                            <li>
                                              <b>Meter Shifting</b>: 7 Working
                                              Days as per site conditions
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the charges for Meter
                                              testing ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Meter Testing Charges are as
                                            follows:
                                          </p>
                                          <ul>
                                            <li>Single Phase Rs.207</li>
                                            <li>Three Phase Rs.413</li>
                                            <li>LT/CT, CT/HT Meter Rs.1180</li>
                                          </ul>
                                          <p className="card_cont">
                                            *All Applicable taxes on the meter
                                            testing fee shall also be charged as
                                            per the norms.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. How to convert post-paid
                                              connection to prepaid connection?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. To get the post-paid connection
                                            to prepaid connection, consumer can
                                            visit our Customer Care Office at
                                            Knowledge Park -1 or Techzone-4,
                                            Greater Noida . Consumer has to
                                            apply under category conversion
                                            services by fill an application form
                                            and submit their ID copy Knowledge
                                            Park-I or Techzone-IV . Timings
                                            10:00 am to 5:00 pm , Monday to
                                            Friday.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the security deposit
                                              charges for conversion from
                                              post-paid to pre-paid or for new
                                              connection installation in
                                              Pre-paid?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Meter charges to paid as per
                                            UPERC cost data book enforce
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What is the meaning of
                                              downloading of parameter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Various parameters measured and
                                            recorded by the meter are finally
                                            downloaded for billing/ monitoring
                                            purpose. The downloading of
                                            parameter means transferring the
                                            recorded data from meter to the
                                            records of the company. Downloading
                                            can be automatic (AMR) or using a
                                            gadget. However we can capture data
                                            / parameter by reading the display
                                            recording on a notebook.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Who can apply for Prepaid
                                              Meter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Consumers pertatining to below
                                            mentioned categories can apply for
                                            prepaid meter:
                                          </p>
                                          <ul>
                                            <li>LMV-1</li>
                                            <li>LMV-2</li>
                                            <li>LMV-5</li>
                                            <li>LMV-9</li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. How to recharge prepaid meter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Prepaid meter recharge coupon
                                            are available at cash collection
                                            centre and can be paid via
                                            cash/DD/Credit or debit card from
                                            our customer care office at
                                            Knowledge Park-I or Techzone-IV .
                                            Timings 10:00 am to 5:00 pm , Monday
                                            to Friday.. Recharge can be done
                                            online via NPCL’s website or PAYTM.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. How does the alarm work in
                                              Pre-paid meter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. If your credit gets below
                                            Rs.100/-, you will have an alarm for
                                            30 seconds. Press any button to turn
                                            it off. The alarm will be repeated
                                            every half an hour until recharged.
                                            It is advised that once meter
                                            reached its minimum limit recharge
                                            immediately to avoid disconnection.
                                            You can also set minimum limit as
                                            per your convenience.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Whom to contact in case of
                                              misplacing of Recharge Coupon?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Simply visit the NPCL Cash
                                            Collection Center for re-issuing of
                                            recharge coupon which will be done
                                            free of cost. Consumer have to
                                            mention BP no./ Meter no. to get the
                                            recharge coupon. May please note-
                                            the coupon is specific to your meter
                                            hence cannot be used elsewhere.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. How to convert post-paid
                                              connection into prepaid
                                              connection?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. To get the post-paid connection
                                            to prepaid connection, consumer can
                                            visit our Customer Care Office at
                                            Knowledge Park -1 or Techzone-4,
                                            Greater Noida . Consumer has to
                                            apply under category conversion
                                            services by fill an application form
                                            and submit their ID copy Knowledge
                                            Park-I or Techzone-IV . Timings
                                            10:00 am to 5:00 pm , Monday to
                                            Friday.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the security deposit
                                              charges for conversion from
                                              post-paid to pre-paid or for new
                                              connection installation in
                                              Pre-paid?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the documents required
                                              if meter get stolen?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. You have to submit an
                                            application, original FIR, ID proof
                                            of registered consumer at our
                                            Customer Care office at Knowledge
                                            Park-I or Techzone-IV . Timings
                                            10:00 am to 5:00 pm , Monday to
                                            Friday.
                                          </p>
                                          <p className="card_cont">
                                            Note: Meter charges are applicable
                                            as per UPERC cost data book.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the New Meter cost in
                                              Meter stolen cases?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Once the consumer process the
                                            application for re-installation of
                                            meter in case of Meter Stolen,
                                            applicable meter charges to paid as
                                            per UPERC cost data book enforce.
                                          </p>
                                          <p className="card_cont">
                                            *All Applicable taxes on the new
                                            meter cost shall also be charged as
                                            per the norms.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Whom to contact if meter is not
                                              at the premises?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Please contact our Call at our
                                            24X7 CALL CENTER - 0120 6226666 or
                                            you may visit Customer Care Office
                                            at Knowledge Park-I or Techzone-IV .
                                            Timings 10:00 am to 5:00 pm , Monday
                                            to Friday.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Why my meter screen is blank.?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Please check if the supply is
                                            On in the area and your supply is
                                            not coming, if Yes, you may kindly
                                            contact 24X7 Call Centre No.
                                            0120-6226666 to register complaint
                                            for No Supply or WhatsApp on
                                            0120-6226666 (NoPower Consumer No.)
                                            or send SMS at 7840002288 ( #NOPOWER
                                            Consumer No.) or visit atCustomer
                                            Care office at Knowledge Park-I or
                                            Techzone-IV . Timings 10:00 am to
                                            5:00 pm , Monday to Friday.. In case
                                            supply is ON but no display on
                                            meter, register complaint for meter
                                            display problem on above given
                                            numbers. Our Team will visit to do
                                            the needful within 7 working days.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. If my meter box has a husky
                                              display, what should I do ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Is your meter box is having
                                            husky display.Kindly contact 24X7
                                            Call Centre No. 0120-6226666 or
                                            visit at our Customer Care office at
                                            Knowledge Park-I or Techzone-IV .
                                            Timings 10:00 am to 5:00 pm , Monday
                                            to Friday. and register complaint
                                            for meter display problem. Our Team
                                            will visit to do the needful within
                                            7 working days.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Where to report incase of No
                                              Display in the meter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Consumer may contact at our
                                            24X7 CALL CENTER - 0120 6226666 or
                                            visit at our Customer Care office at
                                            Knowledge Park-I or Techzone-IV .
                                            Timings 10:00 am to 5:00 pm , Monday
                                            to Friday and register complaint for
                                            meter display problem. Our Team will
                                            visit to do the needful within 7
                                            working days.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the charges of meter
                                              testing and why is it mandatory to
                                              pay ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Meter Testing Charges are as
                                            follows:
                                          </p>
                                          <ul>
                                            <li>Single Phase Rs.207</li>
                                            <li>Three Phase Rs.413</li>
                                            <li>LT/CT, CT/HT Meter Rs.1180</li>
                                          </ul>
                                          <p className="card_cont">
                                            *All Applicable taxes on the meter
                                            testing fee shall also be charged as
                                            per the norms.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. What are the different options
                                              available for Meter Testing?
                                            </span>
                                          </p>
                                          <ul>
                                            <li>
                                              Online from our website
                                              www.noidapower.com and refer
                                              consumer services tab wherein
                                              "Online Meter Testing" option is
                                              available.
                                            </li>
                                            <li>
                                              You may also visit Customer Care
                                              Office at Knowledge Park-I or
                                              Techzone-IV . Timings 10:00 am to
                                              5:00 pm , Monday to Friday.
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. How can I know the status of
                                              Meter Testing Request ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Consumer may visit our website
                                            www.noidapower.com and refer
                                            consumer services tab wherein
                                            "Online Meter Testing" option is
                                            available : You may also download
                                            our Mobile application and apply for
                                            meter testing or else it can be
                                            placed by calling to our 24X7
                                            helpline numbers on or 0120-6226666
                                            You may also visit Customer Care
                                            Office at Knowledge Park-I or
                                            Techzone-IV . Timings 10:00 am to
                                            5:00 pm , Monday to Friday.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. On what basis the meter testing
                                              fee is imposed?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Meter testing fees is charged
                                            on "type of meter" and it is
                                            applicable as per UPERC Tariff
                                            Order.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Would you refund the testing
                                              fee if meter found ok?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. There is no provision for
                                            refund of testing fees.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Will I receive testing report
                                              on meter testing? What is the
                                              procedure of complaining if I
                                              don’t receive one?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Everytime when we will test
                                            meter a test reprt is issued to our
                                            consumer. In case you have not
                                            received it, you may write us at
                                            CRM@noidapower.com. You may also
                                            visit Customer Care Office at
                                            Knowledge Park-I or Techzone-IV .
                                            Timings 10:00 am to 5:00 pm , Monday
                                            to Friday
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Which parameter define that
                                              meter is faulty or meter testing
                                              is Ok?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Kindly note that meter testing
                                            is done on many factors such as
                                            meter accuracy results , display
                                            parameters & downloaded data
                                            analysis on which testing engineer
                                            will confirm whether meter is faulty
                                            or meter is working normal.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Why meters are changed without
                                              any complaint?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. In case of technology
                                            upgradation or periodic replacement
                                            from Old to New Generation meter as
                                            per the Guideline of Hon’ble
                                            Regulatory Commission.
                                          </p>
                                          <p className="card_cont">
                                            A Distribution Licensee may change
                                            the meter from time to time for any
                                            of the following reasons:
                                          </p>
                                          <ul>
                                            <li>Version Upgrade</li>
                                            <li>Meter is burnt</li>
                                            <li>Meter is faulty</li>
                                            <li>Meter is damaged</li>
                                            <li>No display in the meter</li>
                                            <li>Suspected to be tampered</li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. How much time will you take to
                                              replace the faulty meter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Time period for compliances :
                                          </p>
                                          <ul>
                                            <li>
                                              <b>Meter Stop/Meter Damaged</b>:
                                              14 working days for meter
                                              replacement
                                            </li>
                                            <li>
                                              <b>Meter Slow/Fast</b>: 14 working
                                              days after estimate payment for
                                              testing & another 14 working days
                                              for replacement if required
                                            </li>
                                            <li>
                                              <b>Meter Burnt</b>: 3 Working Days
                                              for replacement of the burnt meter
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. Whom to contact if there is No
                                              power after successful recharge?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Time period for compliances :
                                          </p>
                                          <ul>
                                            <li>
                                              For Secure Prepaid meters
                                              <ul>
                                                <li>
                                                  Contact your maintenance
                                                  office it may be your main MCB
                                                  be off which is located in
                                                  Electric shaft in societies/
                                                  check your MCB status
                                                </li>
                                                <li>
                                                  Contact NPCL Customer Care
                                                  office at KP-1 or Techzone-4
                                                  :During 10:00 A.M to 5:00 P.M
                                                  (Mon-Fri) to know more about
                                                  the same. Knowledge Park-I or
                                                  Techzone-IV.
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              Radius Smart Meters(AEW Meters)
                                              <ul>
                                                <li>
                                                  Check balance / supply status
                                                  in mobile aap.
                                                </li>
                                                <li>
                                                  Contact your maintenance
                                                  office.
                                                </li>
                                                <li>
                                                  Contact NPCL Customer Care
                                                  office at KP-1 or Techzone-4 :
                                                </li>
                                                <li>
                                                  Contact NPCL Customer Care
                                                  office at KP-1 or Techzone-4
                                                  :During 10:00 A.M to 5:00 P.M
                                                  (Mon-Fri) to know more about
                                                  the same. Knowledge Park-I or
                                                  Techzone-IV.
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. Will I receive a bill for
                                              Pre-paid meter
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Monthly e-bill will be sent to
                                            registered email id & phone number
                                            post bill generation. It can also be
                                            downloaded from our website via
                                            Quick E-bill Download : or follow
                                            direct link: No payment is required
                                            to be made against the bill. Ensure
                                            sufficient balance in prepaid meter
                                            through timely recharge in order to
                                            avail uninterrupted power supply.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Whom to contact if the meter
                                              doesn’t show the credit amount
                                              after recharge?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Please refer our prepaid meter
                                            manual available at our website and
                                            refer page no. 6 & 7 Or In case,
                                            payment is been done but credit
                                            still pending, you may contact at
                                            our helpline no. +91-0120 6226666 or
                                            else if issue still persist, please
                                            visit our customer care oﬃce, KP-1
                                            or Techzone-4 in person :During
                                            10:00 A.M to 5:00 P.M (Mon-Fri) to
                                            know more about the same. Knowledge
                                            Park-I or Techzone-IV .
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Why meter is beeping when it is
                                              already recharge?
                                            </span>
                                          </p>
                                          <ul>
                                            <li>
                                              There is a provision in meter to
                                              set recharge limit if balance will
                                              go down from this limit then only
                                              meter will start beeping at low
                                              balance . This feature is
                                              configurable ,consumer itself can
                                              set this limit as per their
                                              consumption pattern.
                                            </li>
                                            <li>
                                              Freedom Bluetooth unit may be
                                              faulty ( if Showing Con. Fault in
                                              display ).
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. What options are available for
                                              pre-paid meter recharge?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. (A) Secure Prepaid Meters
                                          </p>
                                          <ul>
                                            <li>
                                              Paytm, (there are many options to
                                              pay amount in paytm ex- Through
                                              UPl, Debit Card,credit Card and
                                              Net Banking)
                                            </li>
                                            <li>
                                              Consumer can get token from NPCL
                                              Customer Care Office, KP-1,
                                              Greater Noida
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            (B) Radius Smart Meters( AEW Meters)
                                          </p>
                                          <ul>
                                            <li>
                                              Paytm, Bill Desk (there are many
                                              options to pay amount in paytm ex-
                                              Through UPl, Debit Card,credit
                                              Card and Net Banking)
                                            </li>
                                            <li>
                                              NPCL Multipoint App., NPCL web
                                              site
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. What is “CON FAULT”, what is to
                                              be done to get it corrected?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. CON FAULT” means connection
                                            faulty, meter will be displaying
                                            digit 17 when your connection from
                                            meter to display unit is not proper.
                                            Kindly register you’re compliant on
                                            our call center contact no. -
                                            0120-6226666/.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Can supply gets disconnected if
                                              meter is not recharged or do I get
                                              any reminder?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. (A) Secure Prepaid Meters
                                          </p>
                                          <ul>
                                            <li>
                                              Reminder beep will come in minimun
                                              available balance. Supply will be
                                              disconnected in negative balance.
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            (B) Radius Smart Meters(AEW Meters)
                                          </p>
                                          <ul>
                                            <li>
                                              Go to NPCL Multipoint App by
                                              turning on low money
                                              notifications, consumer can get a
                                              reminder.
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. How to check the balance?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. (A) Secure Prepaid Meters
                                          </p>
                                          <ul>
                                            <li>
                                              Meter Automatically showing
                                              Balance & other details in Meter
                                              display.
                                            </li>
                                            <li>
                                              Consumers may check their account
                                              balance by pressing # button in
                                              key pad of meter display unit &
                                              for single phase meters - Press
                                              key "1" single time to check
                                              balance.
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            (B) Radius Smart Meters(AEW Meters)
                                          </p>
                                          <ul>
                                            <li>
                                              Login in NPCL Multipoint App/ web
                                              and check balance.
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            <span>
                                              Q. What should be the minimum
                                              balance to maintain pre-paid
                                              meter?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Consumer can change minimum
                                            balance in meter acocording to
                                            consumption and requirement.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. If meter is showing different
                                              date from actual does it mean that
                                              it is faulty ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. It is a matter of meter testing
                                            & inspection then only we will
                                            confirm the same. Based on the
                                            testing report our team shall
                                            analyse the data.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Whom to contact when there is
                                              no display in the meter but supply
                                              is connected?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. Please check if the supply is
                                            On in the area and your supply is
                                            not coming, if Yes, you may kindly
                                            contact 24X7 Call Centre No.
                                            0120-6226666 to register complaint
                                            for No Supply or WhatsApp on
                                            0120-6226666 (NoPower Consumer No.)
                                            or send SMS at 7840002288 ( #NOPOWER
                                            Consumer No.)
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. Red light in meter indicates
                                              what?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. it’s a calibration LED in meter
                                            which indicates pulse / revolution
                                            of meter , if Red light is blinking
                                            in meters it means meter is running
                                            on load.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. How can I change my meter from
                                              post-paid to pre-paid and what
                                              documentation is required?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. To get the post-paid connection
                                            to prepaid connection, consumer can
                                            visit our Customer Care Office at
                                            Knowledge Park -1 or Techzone-4,
                                            Greater Noida . Consumer has to
                                            apply under category conversion
                                            services by fill an application form
                                            and submit their ID copy Knowledge
                                            Park-I or Techzone-IV . Timings
                                            10:00 am to 5:00 pm , Monday to
                                            Friday.
                                          </p>
                                          <p className="card_cont">
                                            <span>
                                              Q. How can I see my consumption
                                              pattern in pre-paid meter ?
                                            </span>
                                          </p>
                                          <p className="card_cont">
                                            Ans. (A) Secure Prepaid Meters
                                          </p>
                                          <ul>
                                            <li>
                                              By pressing Key"2" consumer can
                                              check consumptions of today,
                                              previous day, previous Week and
                                              current month)
                                            </li>
                                            <li>
                                              By Pressing key"7" consumer can
                                              check monthly consumptions of
                                              present calendar Year)
                                            </li>
                                          </ul>
                                          <p className="card_cont">
                                            (B) Radius Smart Meters ( Aew
                                            Meters)
                                          </p>
                                          <ul>
                                            <li>
                                              Visit NPCL Multipoint App and
                                              consumer can see daily
                                              consumption( Current month) and
                                              Monthly consumptions( Present
                                              Calendar Year) by selecting
                                              "Report" option.
                                            </li>
                                            <li>
                                              Visit NPCL Multipoint Web, conumer
                                              can download and see consumptions
                                              by selecting "Report" option.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header">
                                  <a
                                    className="collapsed card-link"
                                    data-toggle="collapse"
                                    href="#"
                                    onClick={() => setShowPayment(!showPayment)}
                                  >
                                    Payment Related
                                  </a>
                                </div>

                                {showPayment ? (
                                  <div id="collapseTwo">
                                    <div className="card-body">
                                      <p className="card_cont">
                                        <span>
                                          Q.What are the different modes of
                                          payments available?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. Modes of payments are as follows:
                                      </p>
                                      <p className="card_cont">
                                        <b>A. Digital Payment through:-</b>
                                      </p>
                                      <ul>
                                        <li>NPCL Website</li>
                                        <li>NPCL’s Mobile App and BHIM App</li>
                                        <li>
                                          PayTM, Mobiquick, and Other wallets
                                        </li>
                                        <li>Bharat QR (printed on bills)</li>
                                        <li>
                                          Bharat Bill Payment System (BBPS)
                                        </li>
                                        <li>
                                          Utility bill payment option in
                                          Consumer’s internet banking accounts
                                        </li>
                                        <li>
                                          Auto- Debit facility through NACH
                                          (National Automated Clearing House)
                                        </li>
                                        <li>RTGS/ NEFT to NPCL’s accounts</li>
                                        <li>
                                          Credit & Debit cards to Village
                                          Contact Persons and at Customer Care
                                          Office, KP-1 or Techzone-4*.
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        <b>
                                          Non-Digital Payment Modes are as
                                          follows:-
                                        </b>
                                      </p>
                                      <ul>
                                        <li>
                                          Cash / Cheque / DD at Customer Care
                                          Office, KP-1 or Techzone-4* or to
                                          Village Contact Persons.
                                        </li>
                                        <li>
                                          Cheque/DD at NPCL’s Cheque Drop Boxes.
                                        </li>
                                        <li>
                                          Cash at nearby BBPS authorised retail
                                          outlets For further details please
                                          visit NPCL’s website.
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        *You may visit Customer Care Office at
                                        Knowledge Park-I or Techzone-IV .
                                        Timings 10:00 am to 5:00 pm , Monday to
                                        Friday.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.Where can I make cash payment?
                                        </span>
                                      </p>
                                      <ul>
                                        <li>
                                          In case of Rural Consumers -
                                          Authorised Village Contact Persons
                                          (VCPs) or cash collection centre,
                                          Tugalpur, Knowledge Park-1 or
                                          Techzone-4, Greater Noida between
                                          10:00 AM to 5:00 PM, Monday to
                                          Saturday (Except 2nd & 4th Saturdays
                                          and Gazetted Holidays) (On 1st, 3rd &
                                          5th Saturdays counter will be open
                                          from 10:00 AM to 04:30 PM).
                                        </li>
                                        <li>
                                          In case of Urban / Industrial /
                                          Institutional Consumers - Cash
                                          Collection Centre.
                                        </li>
                                        <li>
                                          Any consumer - Nearby BBPS Authorised
                                          Retail Outlets. There are more than
                                          1500 authorised BBPS retails outlets
                                          in and around Greater Noida area.
                                          Consumers may locate nearest BBPS
                                          Authorised Retails Outlets on NPCL
                                          website through web-link “Find Nearest
                                          Bharat Bill Pay Outlet”.
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        For more details you may also visit NPCL
                                        website.
                                      </p>
                                      <p className="card_cont">
                                        You may visit Customer Care Office at
                                        Knowledge Park-I or Techzone-IV .
                                        Timings 10:00 am to 5:00 pm , Monday to
                                        Friday.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.What is the upper limit for Cash
                                          Payment.?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. As per section 6.10 of Electricity
                                        Supply code 2005, you can make cash
                                        payment up to Rs.20,000/- only. In case
                                        your bill is more than the above
                                        mentioned value, remaining payment shall
                                        be made via any mode other than cash.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.How to make online payment of Bills?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. Online Payment of Bills can be made
                                        through below mentioned modes :
                                      </p>
                                      <ul>
                                        <li>NPCL Website</li>
                                        <li>NPCL’s Mobile App and BHIM App</li>
                                        <li>
                                          PayTM, Mobiquick, and Other wallets
                                        </li>
                                        <li>Bharat QR (printed on bills)</li>
                                        <li>
                                          Bharat Bill Payment System (BBPS)
                                        </li>
                                        <li>
                                          Utility bill payment option in
                                          Consumer’s internet banking accounts
                                        </li>
                                        <li>
                                          Auto- Debit facility through NACH
                                          (National Automated Clearing House)
                                        </li>
                                        <li>RTGS/ NEFT to NPCL’s accounts</li>
                                      </ul>
                                      <p className="card_cont">
                                        For more details you may also visit NPCL
                                        website.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.Do I need to pay extra charges if I
                                          wish to pay Online through NPCL
                                          website/Mobile App ?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. NPCL do not levy any extra charge
                                        for online payment. However, the Payment
                                        Gateway , Wallets and Other service
                                        providers may levy service charges
                                        along-with applicable taxes for
                                        transactions above Rs. 4,000/-. In case
                                        the transaction is made through Paytm
                                        Gateway service charges would be levied
                                        at following rate:
                                      </p>
                                      <p className="card_cont">
                                        ** For credit/debit card transactions,
                                        1.10% of the transaction amount being
                                        paid
                                      </p>
                                      <p className="card_cont">
                                        * For Net banking transactions,
                                        Rs.4/-per transaction
                                      </p>
                                      <p className="card_cont">
                                        In case the transaction is made through
                                        Bill desk Gateway service charges would
                                        be levied at following rate:
                                      </p>
                                      <ul>
                                        <li>
                                          * For credit/debit card transactions,
                                          1% of the transaction amount being
                                          paid
                                        </li>
                                        <li>
                                          * For Net banking transactions,
                                          Rs.3/-per transaction
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        <span>
                                          Q.Is it safe to give my bank details
                                          on NPCL website ?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. Yes, It is completely safe. NPCL
                                        follows highest level of security
                                        standards to ensure that the data
                                        entered by consumers remains
                                        confidential and not stored anywhere on
                                        NPCL Server.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.How can I make Cheque Payments
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. Consumer can drop cheque at
                                        following Cheque Collection Centres of
                                        NPCL along with latest bill stub (Lower
                                        portion of bill). The cheques should be
                                        in favour of ""Noida Power Company
                                        Limited"". Write down the cheque with
                                        credentials like your Name, Consumer
                                        Number and mobile number at the back of
                                        cheque.
                                      </p>
                                      <ul>
                                        <li>
                                          NPCL Customer Care Centre (Sector
                                          KP-1)
                                        </li>
                                        <li>
                                          NPCL Customer Care Centre
                                          (Techzone-IV)
                                        </li>
                                        <li>
                                          NPCL Sub-station atAlpha-1, Opp Ryan
                                          International School
                                        </li>
                                        <li>
                                          NPCL Sub-station, Sector Omega-I,
                                          Builders Area, Near AWHO Apartments
                                        </li>
                                        <li>
                                          NPCL Sub-station at Surajpur, Near
                                          Surajpur Police Station
                                        </li>
                                        <li>
                                          Bar Association Office, Surajpur
                                          District Court
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        <span>
                                          Q.Can I make payment through Post
                                          dated cheques ?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. Post-dated and outstation cheques
                                        are not accepted at NPCL.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.What are the charges applicable on
                                          cheque bounce?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. In case the Cheque gets bounced due
                                        to insufficient funds or exceed
                                        arrangement, you will not be able to
                                        make payment through cheque in that
                                        Financial Year. Charges applicable are
                                        mentioned below:
                                      </p>
                                      <ul>
                                        <li>
                                          Cheque amount of less than Rs. 5000 :
                                          Rs.250
                                        </li>
                                        <li>
                                          Cheque amount from Rs. 5000 & up to
                                          Rs. 10000 : Rs. 500
                                        </li>
                                        <li>
                                          Cheque amount from Rs. 10000 & up to
                                          Rs. 1 Lac : Rs. 1000
                                        </li>
                                        <li>
                                          Cheque amount of Rs. 1 Lac and above :
                                          Rs. 5000
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        <span>
                                          Q.Can I issue another cheque, if my
                                          cheque bounces?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. In case the Cheque got bounced due
                                        to insufficient funds or exceed
                                        arrangements, the consumer will not be
                                        able to make payment through cheque in
                                        that Financial Year.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.Can I issue cheque, if my supply is
                                          Disconnected ?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. Yes, consumer can pay their bill
                                        amount through cheque (Considering no
                                        cheque bounce history).
                                      </p>
                                      <p className="card_cont">
                                        Payment can be done for outstanding bill
                                        and reconnection charges as below:-
                                      </p>
                                      <ul>
                                        <li>
                                          Bill payment via cheque and
                                          reconnection charge via cash and vice
                                          versa Bill payment and reconnection
                                          charge together via cash.
                                        </li>
                                        <li>
                                          Bill payment and reconnection charge
                                          together via cheque . Please note, if
                                          in case the same get dishonoured, the
                                          supply will be disconnected and the
                                          Reconnection amount has to be paid
                                          again.
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        <span>
                                          Q.When will my online payment be
                                          updated in my account?
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. All payments made through other
                                        that RTGS / NEFT mode are updated on
                                        Real-time basis in consumers account
                                        except during system
                                        shutdown/maintenance when it may take up
                                        to 2 to 3 working days.
                                      </p>
                                      <p className="card_cont">
                                        Q.Where can I approach to register my
                                        payment related complaint?
                                      </p>
                                      <p className="card_cont">
                                        Ans. Complaints can be registered at:
                                      </p>
                                      <ul>
                                        <li>NPCL Website.</li>
                                        <li>NPCL Mobile App.</li>
                                        <li>
                                          Our 24X7 Call centre at 0120-6226666 /
                                          2333555.
                                        </li>
                                        <li>
                                          You may visit Customer Care Office at
                                          Knowledge Park-I or Techzone-IV .
                                          Timings 10:00 am to 5:00 pm , Monday
                                          to Friday.
                                        </li>
                                      </ul>
                                      <p className="card_cont">
                                        <span>
                                          Q.What are the GST % will be charged
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. GST will be levied as per GST
                                        regulations and rates applicable to
                                        various services provided by NPCL.
                                      </p>
                                      <p className="card_cont">
                                        <span>
                                          Q.For which services I have to pay GST
                                        </span>
                                      </p>
                                      <p className="card_cont">
                                        Ans. As per GST Circular No.
                                        34/08/2018-GST dated 01st March 2018,
                                        GST is applicable on all the services
                                        provided by the NPCL except energy
                                        charges.
                                      </p>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="collapsed card-link"
                                data-toggle="collapse"
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowBill(!showBill)}
                              >
                                Billing Related
                              </a>
                            </div>

                            {showBill ? (
                              <div
                                id="collapseThree"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q.How are bills prepared if the meter is
                                      not read?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If the actual meter reading is not
                                    acquired due to any reason; provisional bill
                                    will be raised which is subjected to
                                    adjustment upon receipt of actual meter
                                    reading in the subsequent months.
                                    Provisional bills are raised on average
                                    consumption of last 3 months.
                                    Inaccessibility to premises for 3 months or
                                    more could lead to disconnection of supply
                                    as per Electricity ̀ Supply Code 2005.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is the normal reading span for
                                      monthly Meter Reading?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Meter reading is normally taken between
                                    a period of 28 to 32 days from the last
                                    meter reading date. The same is intimated to
                                    you through SMS if your mobile number is
                                    registered with us. Fixed Charges and slabs
                                    for Energy charges are calculated
                                    proportionally for the billing period.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How to calculate / understand
                                      electricity bill?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Ans. Please refer back side of
                                    electricity bill and check for applicable
                                    Tariff and Bill Calculation. Additionally,
                                    you can reach out to us at
                                    crm@noidapower.com
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is the meter reading system?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Reading of three phase meters is taken
                                    remotely through AMR or LPR facility. For
                                    Single Phase meters, meter readers go
                                    door-to-door and record readings in Hand
                                    Held Devices (HHD) / Meter Reading Terminals
                                    (MRT). Reading information can also be
                                    conveyed through SMS/Email registered with
                                    us.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why is my meter not read regularly?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Any one of the following can be the
                                    reasons:
                                  </p>
                                  <ul>
                                    <li>
                                      Your premises are vacant / locked for long
                                      period of time or constantly during
                                      daytime resulting in inaccessibility of
                                      meter to the reader. Code 2005.
                                    </li>
                                    <li>
                                      Discrepancy in address in our database
                                      with the actual address may result in
                                      meter reader missing out your premises.
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    If you observe such shortcomings in the
                                    address (as printed in bill), please bring
                                    the same to the notice of CR(Front Desk) at
                                    NPCL Customer Care Office immediately for
                                    corrections.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Where to approach when meter is not read
                                      ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If consumer premises is found locked,
                                    meter reader will stick a ""miss you""
                                    sticker intimating about the failed reading
                                    attempt alongwith a contact number to
                                    coordinate the day & time of reading. Or you
                                    may:
                                  </p>
                                  <ul>
                                    <li>
                                      Call at our 24X7 CALL CENTER - 0120
                                      6226666
                                    </li>
                                    <li>
                                      Mail your concern at crm@noidapower.com
                                    </li>
                                    <li>
                                      Whatsapp on 0120-6226666 WrongReading
                                      Consumer Number
                                    </li>
                                    <li>
                                      Send SMS at 7840002288 #WRONGREADING
                                      Consumer Number (Only which is showing in
                                      KWH)
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why fixed charges are not constant?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Fixed Charges defined by Monthly Tariff
                                    applies to a period of 30 days. When billing
                                    period is not 30 days, applicable fixed
                                    charges are calculated on prorata basis.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.What are Penal Demand Charges</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Use of load beyond the sanctioned load
                                    is a cognisable oﬀence. Penal demand charges
                                    are levied in excess to ﬁxed charges based
                                    on demand usage over and above the
                                    sanctioned load. It is thus advised to
                                    enhance your contracted load to avoid penal
                                    demand charges.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.How to calculate slab ?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Slab based energy rates defined by
                                    Tariff is applicable for 30 days per month
                                    and is prorated on simple unitary method
                                    based on number of billing days i.e.
                                    difference between current reading date and
                                    previous reading date (both dates inclusive)
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.What are Makeup Charges?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Ans. : As per approved Tariﬀ, consumers
                                    who are part of LMV-2 (Commercial Supply)
                                    and LMV-9 (Temporary Supply) are subjected
                                    to load based minimum charges. If the sum of
                                    Fixed Charges (for LMV-2 only) and Energy
                                    Charges is less than applicable monthly
                                    minimum charge then diﬀerential amount is
                                    levied as Make Up Charges. Makeup charge =
                                    minimum charge - (ﬁxed charges + energy
                                    charge).
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Wrong reading mentioned on my bill. How
                                      to get it rectified?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Ans. Request for wrong reading can be
                                    registered through following touchpoints
                                  </p>
                                  <ul>
                                    <li>
                                      Call at our 24X7 CALL CENTER - 0120
                                      6226666
                                    </li>
                                    <li>
                                      Email your concern at crm@noidapower.com.
                                      Do attach meter reading photograph for
                                      convenience.
                                    </li>
                                    <li>
                                      Whatsapp on 0120-6226666 WrongReading
                                      Consumer Number.
                                    </li>
                                    <li>
                                      Send SMS at 7840002288 #WRONGREADING
                                      Consumer Number Reading (Only which is
                                      showing in KWH)
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q.What to do if I am not satisfied with
                                      the meter reading?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If you are not satisfied with your
                                    bill, you may
                                  </p>
                                  <ul>
                                    <li>
                                      Call at our 24X7 CALL CENTER - 0120
                                      6226666
                                    </li>
                                    <li>
                                      Mail your concern at crm@noidapower.com
                                    </li>
                                    <li>
                                      Whatsapp on 0120-6226666 WrongReading
                                      Consumer Number.
                                    </li>
                                    <li>
                                      Send SMS at 7840002288 #SELFREADING
                                      Consumer Number Reading (Only which is
                                      showing in KWH)
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is the Frequency of billing ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Billing for every consumer is done on
                                    Monthly basis
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How electricity bills are delivered?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Bills are delivered through third party
                                    vendor every month. You are requested to
                                    subscribe for e-bill for on-time and assured
                                    delivery of bill.
                                  </p>
                                  <p className="card_cont">
                                    In case you need duplicate bill copy, may
                                    please click on below link given on our
                                    website : You may reach out us through
                                    WhatsApp no. 0120-6226666 (DupBill Consumer
                                    No.) or Send SMS at 7840002288 ( #DUPBILL
                                    Consumer No.)
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.How to get duplicate bill ?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. In case you need duplicate bill copy,
                                    may please click on below link given on our
                                    website : You may also login to our website
                                    or Mobile www.noidapower.com App using your
                                    credentials (Username & Password) for
                                    downloading / viewing last 6 bills.
                                  </p>
                                  <p className="card_cont">
                                    In case you have not registered on our
                                    website, kindly go through the “Need help in
                                    Sign In” tab under My Account tab.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.What is DPS and Rebate?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Payment before Due Date allows
                                    consumers to avail rebate of 1% on Fixed and
                                    Energy Charges. DPS means Delayed Payment
                                    Surcharge which is applicable due to
                                    non-payment of bills upto due date.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How DPS is charged in the bill?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If a consumer fails to pay his
                                    electricity bill by due date specified
                                    therein, a late payment surcharge shall be
                                    levied at 1.25% on the dues per month up-to
                                    first three months of delay. Post three
                                    months, DPS will be charged at 2.00% per
                                    month on the unpaid dues. In case of
                                    constant non-payment, your elctricity
                                    connection can be disconnected.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q. DPS waiver.?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. DPS is levied as per Rate Schedule
                                    approved by Hon'ble UPERC and no-one can
                                    waive the same
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How to lodge complaint through SMS?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You can raise your complaint & avail
                                    other important information by sending SMS
                                    on 7840002288 from your registered mobile
                                    number. To see list of short codes with
                                    mandatory details, click on
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Is there any rebate if I pay bills
                                      online?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. No, there is no additional rebate
                                    provided for online payments. However, a
                                    rebate of 1% against sum of Demand Charges &
                                    Energy Charges is provided upon payment
                                    within due date.
                                  </p>
                                  <p className="card_cont">
                                    {" "}
                                    Q.Why there are different colour bills? It
                                    denotes what ?
                                  </p>
                                  <p className="card_cont">
                                    Ans. Multi colour bills are designed to
                                    reflect upon varied consumer behaviour
                                    towards payment of dues and usage of
                                    electricity.
                                  </p>
                                  <ul>
                                    <li>
                                      Blue Bill denotes timely bill payments
                                    </li>
                                    <li>
                                      Green Bill denotes payment through digital
                                      mode and/or Solar Net Metering Consumer
                                    </li>
                                    <li>
                                      Orange Bill denotes delayed payment and/or
                                      arrears in current bill
                                    </li>
                                    <li>
                                      Red bill cautions consumer to abstain from
                                      unauthorized use of electricity based on
                                      site reporting.
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q.How much interest paid on Security
                                      Deposit?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Interest is credited to consumers
                                    against existing security deposit on annual
                                    basis as per the bank rate prescribed by the
                                    RBI.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What would be the disconnection date
                                      once arrear is paid from the current month
                                      bill?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. It is advised to clear entire dues in
                                    event of arrears amount in current bill.
                                    However, if payment is made only against
                                    arrears, then disconnection date against
                                    current dues is 15 days from the due date as
                                    mentioned on the electricity bill.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Who can submit self meter reading ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. The facility of self submission of
                                    meter reading is available for single phase
                                    consumers. Reading can be submitted through
                                    SMS, Website or App in the dedicated slot
                                    provided every month.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Will I receive a bill for Pre-paid
                                      meter? Do I need to make payment against
                                      bill?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Monthly e-bill will be sent to
                                    registered email id & phone number post bill
                                    generation. It can also be downloaded from
                                    our website via Quick E-bill Download or
                                    follow direct link:
                                  </p>
                                  <p className="card_cont">
                                    No payment is required to be made against
                                    the bill. Ensure sufficient balance in
                                    prepaid meter through timely recharge in
                                    order to avail uninterrupted power supply.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How & when self reading can be provided
                                      by consumer?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Reading submission slot is 1st to 10th
                                    day of every month. You can easily reach out
                                    to us by sending SMS at 7840002288
                                    (#SELFREADING Consumer No. Reading) or
                                    WHATSAPP us at 0120-6226666 (SelfReading
                                    Consumer No. Reading). The service is also
                                    available on our website & app.
                                  </p>
                                  <p className="card_cont">
                                    * Please note that reading should be in KWH
                                    parameter
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.What is power factor?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Power factor for the month is
                                    determined as the ratio of KWH (Active Power
                                    Consumption) to KVAH(Apparent power
                                    consumption).
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is the minimum Power Factor to be
                                      maintained?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. As per the regulations of U.P.
                                    Electricity Supply Code, 2005 minimum power
                                    factor to be maintained is 0.75. If recorded
                                    power factor is less than 0.75,suitable
                                    action as per the provisions shall be
                                    initiated by NPCL.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What can be the reason which may cause
                                      dip in Power Factor? How it is to be
                                      maintained?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Majority of three phase connections
                                    constitutes of inductive load which draws
                                    non working / reactive power thereby
                                    increasing the KVAH(Apparent power
                                    consumption). Use of capacitor banks is
                                    helpful in offsetting this non working power
                                    requirement, but it should be regulated
                                    according to variance in load requirement by
                                    installing Automated Power Factor
                                    Controller(APFC). Regular check up and
                                    maintenance is also advised.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why it is important to maintain Power
                                      Factor?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Consumers above 10 KW are billed on
                                    KVAH basis as per the applicable tariff
                                    provisions. Dip in power factor(P.F.) cause
                                    increase in KVAH consumption which in turn
                                    results in higher energy charges for the
                                    consumer. Also, it reduces the effective
                                    distribution network capacity of NPCL which
                                    can lead to power outage in the area.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What are the consequences if power
                                      factor controller is not working properly?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Failure of power factor controller i.e.
                                    APFC/Capacitor banks will lead to
                                    inadequate/over compensation of reactive
                                    power requirement. In both scenarios, KVAH
                                    consumption will suddenly increase leading
                                    to higher energy charges being billed.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Can NPCL help in improving Power Factor?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. No, it is consumer's responsibility to
                                    maintain their power factor from time to
                                    time
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What is the Frequency of billing ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Billing for every consumer is done on
                                    Monthly basis
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How electricity bills are delivered?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Bills are delivered through third party
                                    vendor every month. You are requested to
                                    subscribe for e-bill for on-time and assured
                                    delivery of bill.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q. How to get duplicate bill ?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. "You are required to login on our
                                    website or Mobile www.noidapower.com App
                                    using your credentials (Username & Password)
                                    for downloading / viewing last 6 bills.
                                  </p>
                                  <p className="card_cont">
                                    In case you have not registered on our
                                    website, kindly go through the “Need help in
                                    Sign In” tab under My Account tab".
                                  </p>
                                  <p className="card_cont">
                                    <span>Q. What is DPS and Rebate?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Payment before Due Date allows
                                    consumers to avail rebate of 1% on Fixed and
                                    Energy Charges. DPS means Delayed Payment
                                    Surcharge which is applicable due to
                                    non-payment of bills upto due date.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How DPS is charged in the bill?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If a consumer fails to pay his
                                    electricity bill by due date specified
                                    therein, a late payment surcharge shall be
                                    levied at 1.25% on the dues per month up-to
                                    first three months of delay. Post three
                                    months, DPS will be charged at 2.00% per
                                    month on the unpaid dues. In case of
                                    constant non-payment, your elctricity
                                    connection can be disconnected.
                                  </p>
                                  <p className="card_cont">Q. DPS waiver.?</p>
                                  <p className="card_cont">
                                    Ans. DPS is levied as per Rate Schedule
                                    approved by Hon'ble UPERC and no-one can
                                    waive the same.
                                  </p>
                                  <p className="card_cont"></p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="card-link"
                                data-toggle="collapse"
                                onClick={() => setShowTheft(!showTheft)}
                                style={{ cursor: "pointer" }}
                              >
                                Theft Related
                              </a>
                            </div>
                            {showTheft ? (
                              <div
                                id="collapseFour"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q. Where I can contact in case of Direct
                                      theft booked on my consumer number.?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You may visit to Our Office at KP-II
                                    from 10:00 A.M to 3:00 P.M (Mon-Fri) for any
                                    detailed information about theft booked or
                                    may contact our 24X7 CALL CENTER - 0120
                                    6226666 or WhatsApp on 0120-6226666, write
                                    Theft (to report electricity theft) may
                                    please click on given link to register
                                    online complaint.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q. How to report Direct Theft</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You can call and complain against theft
                                    of electricity / tampering of meter through
                                    following avenues:
                                  </p>
                                  <ul>
                                    <li>Helpline Number : 9891222240</li>
                                    <li>
                                      <Link to="/">Noidapower.com</Link>
                                    </li>
                                    <li>Mobile App</li>
                                    <li>CALL CENTER - 0120 6226666</li>
                                  </ul>
                                  <p className="card_cont">
                                    Your identity shall remain confidential and
                                    action will be taken at the earliest.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What is meant by “Direct Theft”?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Section 135(1) of Electricity Act, 2003
                                    as amended by Electricity (amendment) Act ,
                                    2007, Theft of electricity is defined as,
                                    whoever dishonestly,
                                  </p>
                                  <ul>
                                    <li>
                                      a) Taps Electricity line:
                                      <ul>
                                        <li>- overhead</li>
                                        <li>- underground</li>
                                      </ul>
                                    </li>
                                    <li>
                                      b) Tampers meter:
                                      <ul>
                                        <li>- usage of tampered meter</li>
                                        <li>- current reversing transformer</li>
                                        <li>- loop connection</li>
                                        <li>
                                          - other device interferes with
                                          accurate/proper registration. c) Other
                                          device/method result in a manner
                                          whereby:
                                        </li>
                                        <li>- electricity is stolen</li>
                                        <li>
                                          - damages/destroys an electric meter/
                                          apparatus/equipment/wire/ cause
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      c) Uses electricity through a tampered
                                      meter
                                    </li>
                                    <li>
                                      d) Uses electricity for purposes other
                                      than that which was authorized.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="collapsed card-link"
                                data-toggle="collapse"
                                href="#"
                                onClick={() =>
                                  setShowDisconnection(!showDisconnection)
                                }
                              >
                                Disconnection Related
                              </a>
                            </div>
                            {showDisconnection ? (
                              <div
                                id="collapseFive"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q. How to apply for permanent
                                      disconnection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If you want your connection to be
                                    permanently disconnected, you have to submit
                                    the ID proof, last paid bill with our
                                    written application form at Customer Care
                                    Oﬃce at KP-I or Techzone-4*. 2 Years Lock-in
                                    Period is applicable for all consumers
                                    except LMV-9 (Temporary Connection) from
                                    last date of any change in contract. Please
                                    follow the link to know documentation
                                    requirement *Knowledge Park-I or Techzone-IV
                                    . Timings 10:00 am to 5:00 pm , Monday to
                                    Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What are the Timelines of Permanent
                                      Disconnection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Disconnection of supply will completed
                                    within 30 working days.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. Can I apply for the same meter after
                                      surrendering of current meter?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once the connection is permanently
                                    disconnected, consumer has to follow new
                                    connection application process for getting
                                    an electricity connection.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How to apply for refunding of security
                                      funds?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You may visit Consumer Care Centre at
                                    KP-I or Techzone-4, and submit all the
                                    requisite documents which are mentioned at
                                    the back side of application form. Please
                                    follow the link to know documentation
                                    requirement Knowledge Park-I or Techzone-IV
                                    . Timings 10:00 am to 5:00 pm , Monday to
                                    Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. Why and when MCG charges are applicable
                                      for Permanent Disconnection application?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. MCG calculated as per provisions of
                                    Electricity Supply Code, 2005. The Tariff
                                    Orders issued by Hon’ble Uttar Pradesh
                                    Electricity Regulatory Commission (“State
                                    Commission”) from time to time.Clause 4.14
                                    (Agreement) - Point (h) However, if the
                                    agreement is to be terminated before
                                    completion of 2 years: 30 (i) Consumer shall
                                    be liable to pay the minimum charges (or the
                                    demand / fixed charges, if no minimum
                                    charges are prescribed for that category)
                                    for a period of 6 months or the period by
                                    which the total duration of the agreement
                                    falls short of 2 years, whichever is lower.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="collapsed card-link"
                                data-toggle="collapse"
                                href="#"
                                onClick={() =>
                                  setShowReconnection(!showReconnection)
                                }
                              >
                                Reconnection Related
                              </a>
                            </div>
                            {showReconnection ? (
                              <div
                                id="collapseSix"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q. How to apply for reconnection after
                                      making the part payment of outstanding
                                      amount?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Reconnection is not allowed on part
                                    payment.
                                  </p>
                                  <p className="card_cont"></p>
                                  <p className="card_cont">
                                    <table className="table table-responsive">
                                      <thead>
                                        <tr>
                                          <td>
                                            Criteria (load &amp; Category){" "}
                                          </td>
                                          <td>DR charges (Rs.)* </td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            Pre-paid Meters - Single phase{" "}
                                          </td>
                                          <td>- Zero</td>
                                        </tr>
                                        <tr>
                                          <td>Pre-paid Meters -3 phase </td>
                                          <td>- Zero</td>
                                        </tr>
                                        <tr>
                                          <td>Smart Meters - Single phase </td>
                                          <td>- 100</td>
                                        </tr>
                                        <tr>
                                          <td>Smart Meters - 3 phase </td>
                                          <td>- 200</td>
                                        </tr>
                                        <tr>
                                          <td>&gt;75 KW/100BHP </td>
                                          <td>- 2000</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            LMV-6 (having load &lt;=75KW/100BHP){" "}
                                          </td>
                                          <td>- 1000</td>
                                        </tr>
                                        <tr>
                                          <td>All others categories </td>
                                          <td>- 600</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </p>
                                  <p className="card_cont">
                                    Note: * 18% GST is applicable on DR charges
                                    and supply will be reconnected within 48
                                    hrs. for Rural and 24 hrs. for urban after
                                    payment of Outstanding dues along with
                                    reconnection charges.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What are the Timelines for Reconnection
                                      after temporary disconnection of supply?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Timeline for reconnection of supply
                                    will be Maximum of 48 Hrs for Rural and 24
                                    hrs in Urban after payment of bill &
                                    Reconnection charges.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How to apply for reconnection when
                                      meter already removed at site?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. There is no such provision
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How to apply for reconnection after
                                      final bill generation?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. There is no such provision of
                                    reconnection. Once the payment of final bill
                                    is done, consumer has to apply for new
                                    connection with all required documents.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="collapsed card-link"
                                data-toggle="collapse"
                                href="#"
                                onClick={() => setShowWebSite(!showWebSite)}
                              >
                                Website / Mobile App Related
                              </a>
                            </div>
                            {showWebSite ? (
                              <div
                                id="collapseSeven"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q. What is the procedure to Login on{" "}
                                      <Link to="https://www.noidapower.com">www.noidapower.com ?</Link>
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Go to www.noidapower.com. Click on
                                    “Consumer Login” and register your Consumer
                                    Number or click on the link: www.npcl.com
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What are the various
                                      services/information available on NPCL's
                                      website?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans.The main features of website are as
                                    follows:
                                  </p>
                                  <ul>
                                    <li>
                                      About NPCL and Contact Details of
                                      registered offices
                                    </li>
                                    <li>Bill Payment Option</li>
                                    <li>Outage Alerts</li>
                                    <li>Service Application and Complaint</li>
                                    <li>Tariff Details</li>
                                    <li>Report a Concern</li>
                                    <li>Account Registration</li>
                                    <li>CSR activities</li>
                                    <li>Self Meter Reading</li>
                                    <li>Quick Ebill Download</li>
                                    <li>Quick Bill Payment</li>
                                    <li>Contact Us</li>
                                    <li>Online Application</li>
                                    <li>Payment Options</li>
                                    <li>Pre-paid Meter Recharge</li>
                                    <li>
                                      Consumer Services for Application form,
                                      Document Checklist, Online Meter Testing,
                                      SMS Based Services, WhatsApp based
                                      services, Customer Care office Queue
                                      Status, Single to Multipoint Conversion,
                                      Consumer Awareness and Many more.
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>Q. How to reset password ?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Password can be reset after clicking
                                    forgot password option. You have to punch
                                    your registered email id and consumer number
                                    to receive an updated password , if problem
                                    still persist please write us at crm@noida
                                    power.com)
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How and from where a consumer can
                                      update his contact details ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. For updating contact details (mobile
                                    number/ email-id) consumer can login to our
                                    website or mobile app. www.noidapower.com
                                    You may also visit our customer care oﬃce,
                                    Knowledge Park-I or Techzone-IV . Timings
                                    10:00 am to 5:00 pm , Monday to Friday,
                                    along with the BP No. and ID proof of
                                    registered consumer or company. You may also
                                    write us at crm@noidapower.com
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. Is it safe to give my bank details on
                                      NPCL website?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Yes, It is completely safe. NPCL
                                    follows highest level of security standards
                                    to ensure that the data entered by consumers
                                    remains conﬁdential.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. Do I need to pay extra charges for
                                      online payment?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. NPCL does not levy any extra charge for
                                    online payment. However, the Payment
                                    Gateway, Wallets and other service providers
                                    may levy service charges along with
                                    applicable taxes for transaction above Rs.
                                    4,000/-.
                                  </p>
                                  <p className="card_cont">
                                    In case the transaction is made through
                                    Paytm Gateway service charges would be
                                    levied at following rate:
                                  </p>
                                  <ul>
                                    <li>
                                      For credit/debit card transaction, 1.10%
                                      of the transaction amount being paid
                                    </li>
                                    <li>
                                      For Net banking transaction, Rs.4/-per
                                      transaction
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    In case the transaction is made through Bill
                                    desk Gateway service charges would be levied
                                    at following rate:
                                  </p>
                                  <ul>
                                    <li>
                                      For credit/debit card transaction, 1% of
                                      the transaction amount being paid
                                    </li>
                                    <li>
                                      For Net banking transaction, 3% per
                                      transaction
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q. Why this month bill is not updated on
                                      Website Mobile app ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Bills are generated from time to time
                                    from commercial department. It might be
                                    possible due to some certain reason bill of
                                    this month may be delayed and it’s not
                                    generated yet. Once it will have generated
                                    in SAP system then PDF and bill details will
                                    appear on Website.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What happen if my ID is locked in NPCL
                                      Website?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. USERID will be automatic unlock in 24
                                    hours for the website users
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. What all the features available on
                                      Mobile App ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. After Login fetures are as follows :
                                  </p>
                                  <ul>
                                    <li>Bill and Payment History</li>
                                    <li>Register Request & Complaints</li>
                                    <li>Consumer Consumption Analysis</li>
                                    <li>Energy Calculator</li>
                                    <li>Request & Complaint Status</li>
                                    <li>Subscribe for E-bill</li>
                                    <li>Self-Meter Reading</li>
                                    <li>Quick Payment</li>
                                    <li>Energy Tips</li>
                                    <li>KYC Updation</li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q. What is Un-Authenticated Sender issue.
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. User details like USERNAME, Email ID
                                    and Password are not matched when user face
                                    this kind of problem.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="collapsed card-link"
                                data-toggle="collapse"
                                href="#"
                                onClick={() => setShowComplaint(!showComplaint)}
                              >
                                Complaint Against Services Related
                              </a>
                            </div>
                            {showComplaint ? (
                              <div
                                id="collapseEight"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q. What is the process of applying for New
                                      Connection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Applicant can download or ﬁll the
                                    online New Connection Service form from our
                                    website and upload necessary documents
                                    www.noidapower.com as mentioned in the
                                    documents checklist. You can also visit our
                                    Customer Care Oﬃce at Knowledge Park-I or
                                    Techzone-IV . Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday, to apply in person.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. Within how much time should the
                                      connection be provided to me?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once the application and necessary
                                    documents are reviewed at NPCL, and estimate
                                    gets generated which needs to be paid by the
                                    consumer. Post which it will take
                                    approximately 7 working days* for the
                                    apparatus to be installed at the consumers
                                    premises and for the energization of supply.
                                  </p>
                                  <p className="card_cont">
                                    *This SLA is applicable for the urban
                                    domestic connection where no augmentation of
                                    main is required.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why I have not received my estimate
                                      intimation?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once you have successfully submitted
                                    your new service application form at our
                                    Customer Care Office or Online , we process
                                    the application in our system and it gets
                                    forwarded to concerned department for the
                                    Commercial & LCC Dues, Technical feasibility
                                    check. Once clearance is received from all
                                    the departments it is followed by estimate
                                    preparation. During this process system
                                    generated messages are triggered, after
                                    completion of necessary stages. Thus, you
                                    are requested to please wait for the same as
                                    we will be working on 1st cum 1st serve
                                    basis.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Can I pay the Estimate amount Online
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Yes , you can make payment online thru
                                    NPCL website or you may visit our Customer
                                    Care Office at Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday to apply in person for
                                    payment of estimate.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Estimate paid but meter not installed
                                      yet?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Post payment of estimated amount, New
                                    connection will be provided within 7 days
                                    where no Augmentation of existing mains are
                                    required.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Whom to contact for the status of
                                      application?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. To know the status of application you
                                    may WhatsApp us on 0120-6226666 , to know
                                    the short codes follow the link or can write
                                    us at crm@noidapower.com or visit us at our
                                    Customer Care Oﬃce, Knowledge Park-I or
                                    Techzone-IV . Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How to apply for refunding of security
                                      fund
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You may visit our Customer Care Office
                                    at Knowledge Park-I or Techzone-IV Timings
                                    10:00 am to 5:00 pm , Monday to Friday to
                                    apply for security refund, please follow the
                                    link to know documentation requirement
                                    (https://www.noidapower.com/Services/Document_Checklist)
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Paper Formalities Required for Load
                                      Reduction?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Consumer has to fill application and
                                    provide all the requisite documentation
                                    which are mentioned on the back side of
                                    application form. Please follow the link to
                                    know documentation requirement. Please note
                                    for load reduction consumer can only apply
                                    after two years from date of energization.
                                    For Temp. Supply, consumer can apply for
                                    load reduction at any point of time.
                                  </p>
                                  <p className="card_cont">
                                    Q.How can consumer find the status of
                                    request against New Connection?
                                  </p>
                                  <p className="card_cont">
                                    Ans. To know the status of a New Connection
                                    request, consumer may use following options.
                                  </p>
                                  <ul>
                                    <li>
                                      You may WhatsApp us on 0120-6226666,NSC
                                      Notification No. (to know New Service
                                      connection application status)
                                    </li>
                                    <li>Can write us at crm@noidapower.com</li>
                                    <li>
                                      Visit us at our Customer Care Office,
                                      Knowledge Park-I or Techzone-IV Timings
                                      10:00 am to 5:00 pm , Monday to Friday.
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>Q.Estimate not Received Yet</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. In case you have not received any
                                    update after 7-10 days of applying for
                                    request.To know the status of application
                                    you may WhatsApp us on 0120-6226666 , to
                                    know the short codes follow the link or can
                                    write us at crm@noidapower.com or visit us
                                    at our Customer Care Oﬃce, Knowledge Park-I
                                    or Techzone-IV . Timings 10:00 am to 5:00 pm
                                    , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is minimum charges & why it is to
                                      be paid.?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Consumer has to bear the minimum
                                    charges based on the total sanctioned load &
                                    as per the applicable tariff rate it is been
                                    calculated for remaining Tenure in case you
                                    have not completed 2 yrs. to the connection
                                    or recent load enhancement/reduction
                                    process. In Case the maximum demand recorded
                                    in any of the last 2 billing cycles is
                                    higher than the reduced contracted load
                                    consumer can not opt for the proposed
                                    contracted load
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is Augmentation? How much time it
                                      take to complete?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Augmentation means
                                    improvement/enhancement of the system
                                    capacity to be able to cater to the
                                    increased electricity load requirement. Such
                                    augmentation needs capital investment.
                                    Sometimes, only after requests received for
                                    new connections or load enhancement;
                                    particularly if the requested load is high.
                                    Different types of Augmentation may be
                                    required such as:
                                  </p>
                                  <ul>
                                    <li>Low Tension (LT) Line Extension</li>
                                    <li>Transformer Capacity Augmentation</li>
                                    <li>
                                      {" "}
                                      High Tension ( 11 KV) Line Extension
                                    </li>
                                    <li>
                                      Complete electrification of an
                                      un-electrified area
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    Timeline for different type of augmentations
                                    are as follows:
                                  </p>
                                  <p className="card_cont">
                                    <table className="table table-responsive">
                                      <thead>
                                        <tr>
                                          <td>
                                            Type of augmentation Timeline
                                            required for augmentation{" "}
                                          </td>
                                          <td>(Working Days) </td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>LT Network augmentation </td>
                                          <td>- 60</td>
                                        </tr>
                                        <tr>
                                          <td>Transformer Augmentation </td>
                                          <td>- 120</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            HT - 11 KV Network Strengthening{" "}
                                          </td>
                                          <td>- 180 </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Un-electrified Area or New Grid
                                            Required{" "}
                                          </td>
                                          <td>- 180 </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How and where to approach for Shifting
                                      of Mains ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. We accept application for following
                                    request
                                  </p>
                                  <ul>
                                    <li>Meter Shifting</li>
                                    <li>Pole Shifting</li>
                                    <li>Service Cable Shifting</li>
                                    <li>Transformer Shifting</li>
                                    <li>Penal Feeder Box Shifting</li>
                                  </ul>
                                  <p className="card_cont">
                                    For the shifting related request an
                                    application along with the copy of id proof
                                    and last paid bill is required (please
                                    follow the link to be submitted at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV . Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday. After verification of
                                    documents, site visit is arranged to check
                                    the feasibility to fulfil the request and
                                    then a survey report is prepared and
                                    accordingly estimate is generated as per
                                    Regulatory guidelines. Once the payment of
                                    estimate is done then shifting will be
                                    executed
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What Documents are required for Load
                                      Enhancement/ Load Reduction/ Name Change/
                                      Category Change?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Please <a href="#">click Here.</a>
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is the TAT for completion of
                                      Service Application?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of estimate payment
                                    against your Service request it will take
                                    next billing cycle to get implemented in the
                                    system during this period you may receive 1
                                    bill with previous load/ category, except
                                    shifting of mains , as it depends upon site
                                    condition and availability of material.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Where we can contact in case of any
                                      information required for service request.?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. To know the status of application you
                                    may WhatsApp us on 0120-6226666 , to know
                                    the short codes follow the link or can write
                                    us at crm@noidapower.com or visit us at our
                                    Customer Care Oﬃce, Knowledge Park-I or
                                    Techzone-IV . Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What are the payment options for
                                      estimate payment ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Yes , you can make payment online thru
                                    NPCL website or you may visit our Customer
                                    Care Office at Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday to apply inperson for
                                    payment of estimate.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is the basis of calculation of
                                      Security Deposit?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. The security deposit maintainable for a
                                    consumer is equivalent to 2 times the
                                    average bill value for the ﬁnancial year
                                    under reference. For a new applicant, the
                                    security deposit will be determined on the
                                    basis of load applied.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my application is been Pending since
                                      long.?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once you complete the documentation
                                    process, the application goes for several
                                    checks via different departments during
                                    which it might have not been cleared under
                                    LCC/CF Check, To know the status of
                                    application you may WhatsApp us on
                                    0120-6226666 , to know the short codes
                                    follow the link or can write us at
                                    crm@noidapower.com or visit us at our
                                    Customer Care Oﬃce, Knowledge Park-I or
                                    Techzone-IV . Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Where to contact in case of application
                                      rejected due to LCC/Theft?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You may visit Customer Care Office at
                                    Knowledge Park-I or Techzone-IV Timings
                                    10:00 am to 5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.When am I required to pay Additional
                                      Security Deposit (ASD)?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Each year the security deposit is
                                    reviewed in the month of April on the basis
                                    of last one year consumption. The statement
                                    of Security Deposit account is given in the
                                    bill for the month of April. If the
                                    maintainable Security Deposit is more than
                                    the security Deposit “held” amount,
                                    additional security deposit is required to
                                    be paid.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What are the documents required in case
                                      of Small Correction in Connection Details
                                      such as Spelling of Name, inadequate
                                      Address?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. The documentary proof for the desired
                                    change along with latest paid bill receipt
                                    along with correction form by the property
                                    owner. You may visit Customer Care Office at
                                    Knowledge Park-I or Techzone-IV . Timings
                                    10:00 am to 5:00 pm , Monday to Friday
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What happens if the Security Deposit
                                      amount held is more than the Security
                                      Deposit Maintainable amount?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. In the above scenario, no ASD request
                                    will be placed however, as per the RBI
                                    guidelines interest gets credited for the
                                    held security amount.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How do I get refund of Security Deposit
                                      after account closure?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. After necessary adjustment, the
                                    Security Deposit amount held with us is
                                    refunded with an A/c payee cheque in the
                                    name of the registered consumer at the
                                    recorded address.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.When I can apply for Security Refund
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Consumer may apply for Security Refund
                                    in below given senarios :
                                  </p>
                                  <ul>
                                    <li>
                                      *Once the connection is Permanently
                                      Disconnected
                                    </li>
                                    <li>
                                      * Once the property is sold and also name
                                      mutation has been completed in records and
                                      earlier connection gets permanently
                                      disconnected, the Old/Earlier property
                                      holder may apply for Security Refund
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q.What are the documents required for
                                      Security Refund
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    <a href="#">Click here</a>
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Who is eligible to get Security Refund
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Only the registered consumer or Old
                                    owner (in case of property sold out) can
                                    apply for Security Refund
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.Can I get Final Bill ?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. After meter removal from site final
                                    bill get generated within 15 days and final
                                    copy may be provided whrein consumer
                                    himself/herself, have requested for
                                    permannet disconnection and having relevant
                                    notification no. too.
                                  </p>
                                  <p className="card_cont">
                                    <span>Q.How to apply for NOC ?</span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once you get the final bill copy and
                                    upon making necessary bill payments (if
                                    any), consumer may apply for NOC from our
                                    customer care office at Knowledge Park-I or
                                    Techzone-IV ..Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why My New Connection service request is
                                      pending for Site Visit ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Please note that after completion of
                                    documentation process, the application goes
                                    for several checks via different departments
                                    during which it might have not been cleared
                                    under LCC/CF Check. After neccessary
                                    clearance from respective team, you shall be
                                    notified with the Estimate Amount which is
                                    to paid before executing the siite work. In
                                    case, payment is been done and work still
                                    pending. To know the status of a New
                                    Connection request, consumer may use
                                    following options.
                                  </p>
                                  <ul>
                                    <li>
                                      You may WhatsApp us on 0120-6226666,NSC
                                      Notification No. (to know New Service
                                      connection application status)
                                    </li>
                                    <li>Can write us at crm@noidapower.com</li>
                                    <li>
                                      Visit us at our Customer Care Office,
                                      Knowledge Park-I or Techzone-IV Timings
                                      10:00 am to 5:00 pm , Monday to Friday.
                                    </li>
                                  </ul>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why Estimate not yet generated for My
                                      New Connection service request?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of notification
                                    generation against your Service request it
                                    will take 7-10 days time for document
                                    verification followed with site visit &
                                    after receving site report, Estimate get
                                    generated & sent for necessary approvals. We
                                    shall keep you posted with SMS/EMail alert
                                    containing payment link of estimate.In Case
                                    you have received any rejection SMS/Email on
                                    registred contact details, you may reach out
                                    to us via our Whatsapp services on
                                    0120-6226666 NSC Notification No. For any
                                    further requirement, you may please visit us
                                    at our Customer Care Office, Knowledge
                                    Park-I or Techzone-IV Timings 10:00 am to
                                    5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.I have paid my estimate agaist new
                                      service request than why meter
                                      installation is still pending ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of Estimate payment
                                    against your Service request it will take
                                    7-10 days time for meter installation. Only
                                    if there is any right of way issue, it may
                                    take more time for completing the assigned
                                    work and it may only be completed once clear
                                    way will be provided by consumer to our
                                    service team. You may reach out to us via
                                    our Whatsapp services on 0120-6226666 NSC
                                    Notification No. For any further assistance,
                                    you may please visit us at our Customer Care
                                    Office, Knowledge Park-I or Techzone-IV
                                    Timings 10:00 am to 5:00 pm , Monday to
                                    Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why Estimate not yet generated for My
                                      Name Mutation service request?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of notification
                                    generation against your Service request it
                                    will take 7 days time for document
                                    verification and necessary approvals. In
                                    Case you have received any rejection
                                    SMS/Email on registred contact details, you
                                    may reach out to us via our Whatsapp
                                    services on 0120-6226666 NM Notification No.
                                    For any further requirement, you may please
                                    visit us at our Customer Care Office,
                                    Knowledge Park-I or Techzone-IV Timings
                                    10:00 am to 5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my request for Name Mutation is
                                      still pending ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of Estimate Payment
                                    against your Service request it will take
                                    next billing cycle to get implemented in the
                                    system during this period you may receive 1
                                    bill with previous details. In case of any
                                    doubt you may reach out to us via our
                                    Whatsapp services on 0120-6226666 NM
                                    Notification No.For any further requirement,
                                    you may please visit us at our Customer Care
                                    Office, Knowledge Park-I or Techzone-IV
                                    Timings 10:00 am to 5:00 pm , Monday to
                                    Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why Estimate not yet generated for My
                                      Load Augmentation service request?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of notification
                                    generation against your Service request it
                                    will take 7-10 days time for document
                                    verification followed with site visit &
                                    after receving site report, Estimate get
                                    generated & sent for necessary approvals. We
                                    shall keep you posted with SMS/EMail alert
                                    containing payment link of estimate. In Case
                                    you have received any rejection SMS/Email on
                                    registred contact details, you may reach out
                                    to us via our Whatsapp services on
                                    0120-6226666 LA Notification No. For any
                                    further requirement, you may please visit us
                                    at our Customer Care Office, Knowledge
                                    Park-I or Techzone-IV Timings 10:00 am to
                                    5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why My Load Augmentation service request
                                      is pending for Site Visit ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Please note that after completion of
                                    documentation process, the application goes
                                    for several checks via different departments
                                    during which it might have not been cleared
                                    under LCC/CF Check. After neccessary
                                    clearance from respective team, you shall be
                                    notified with the Estimate Amount which is
                                    to paid before executing the site work. In
                                    case, payment is been done and work still
                                    pending you may visit us at our Customer
                                    Care Office, Knowledge Park-I or Techzone-IV
                                    Timings 10:00 am to 5:00 pm , Monday to
                                    Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.I have paid my estimate agaist Load
                                      Augmentation request than why meter
                                      installation is still pending ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of Estimate payment
                                    against your Service request it will take
                                    7-10 days time for meter installation. Only
                                    if there is any right of way issue, it may
                                    take more time for completing the assigned
                                    work and it may only be completed once clear
                                    way will be provided by consumer to our
                                    service team. You may reach out to us via
                                    our Whatsapp services on 0120-6226666 LA
                                    Notification No. For any further assistance,
                                    you may please visit us at our Customer Care
                                    Office, Knowledge Park-I or Techzone-IV
                                    Timings 10:00 am to 5:00 pm , Monday to
                                    Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my request for Category Change is
                                      still pending ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of Estimate Payment
                                    against your Service request it will take
                                    next billing cycle to get implemented in the
                                    system during this period you may receive 1
                                    bill with previous details. In case of any
                                    doubt you may reach out to us via our
                                    Whatsapp services on 0120-6226666 CT
                                    Notification No. For any further
                                    requirement, you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why Estimate not yet generated for My
                                      Category Change service request?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of notification
                                    generation against your Service request it
                                    will take 7 days time for document
                                    verification and necessary approvals. In
                                    Case you have received any rejection
                                    SMS/Email on registred contact details, you
                                    may reach out to us via our Whatsapp
                                    services on 0120-6226666 CT Notification No.
                                    For any further requirement, you may please
                                    visit us at our Customer Care Office,
                                    Knowledge Park-I or Techzone-IV Timings
                                    10:00 am to 5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why Estimate not yet generated for My
                                      Load Reduction service request?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of notification
                                    generation against your Service request it
                                    will take 7-10 days time for document
                                    verification followed with site visit (As &
                                    when required) & after receving site report,
                                    Estimate get generated & sent for necessary
                                    approvals. We shall keep you posted with
                                    SMS/EMail alert containing payment link of
                                    estimate. In Case you have received any
                                    rejection SMS/Email on registred contact
                                    details, you may reach out to us via our
                                    Whatsapp services on 0120-6226666 LR
                                    Notification No. For any further
                                    requirement, you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my request for Load Reduction is
                                      still pending ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of Estimate Payment
                                    against your Service request it will take
                                    next billing cycle to get implemented in the
                                    billing, during this period you may receive
                                    1 bill with previous load details. In case
                                    of any doubt you may reach out to us via our
                                    Whatsapp services on 0120-6226666 LR
                                    Notification No. For any further
                                    requirement, you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why My Shifting request is pending for
                                      Site Visit ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Please note that after completion of
                                    documentation process, the application goes
                                    for several checks via different departments
                                    during which it might have not been cleared
                                    under LCC/CF Check. After neccessary
                                    clearance from respective team, you shall be
                                    notified with the Estimate Amount which is
                                    to paid before executing the site work. In
                                    case, payment is been done and work still
                                    pending you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why Estimate not yet generated for My
                                      Shifting request?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. From the date of notification
                                    generation against your Shifting request it
                                    will take 7-10 days time for document
                                    verification followed with site visit &
                                    after receving site report, Estimate get
                                    generated & sent for necessary approvals. We
                                    shall keep you posted with SMS/EMail alert
                                    containing payment link of estimate. In Case
                                    you have received any rejection SMS/Email on
                                    registred contact details, you may please
                                    visit us at our Customer Care Office,
                                    Knowledge Park-I or Techzone-IV Timings
                                    10:00 am to 5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why there is no action taken at site ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Please note that after completion of
                                    documentation process, the application goes
                                    for several checks via different departments
                                    during which it might have not been cleared
                                    under LCC/CF Check. After neccessary
                                    clearance from respective team, you shall be
                                    notified with the Estimate Amount which is
                                    to paid before executing the siite work. In
                                    case, payment is been done and work still
                                    pending you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my Name Correction request not
                                      done/completed ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Ans. From the date of notification
                                    generation against your Service request it
                                    will take next billing cycle to get
                                    implemented in the system during this period
                                    you may receive 1 bill with previous
                                    details. In case of any doubt you may reach
                                    out to us via our Whatsapp services on
                                    0120-6226666 NAC Notification No. For any
                                    further requirement, you may please visit us
                                    at our Customer Care Office, Knowledge
                                    Park-I or Techzone-IV Timings 10:00 am to
                                    5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my Name correction is still pending
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once you complete the documentation
                                    process, the application goes for several
                                    checks via different departments during
                                    which it might have not been cleared under
                                    LCC/CF Check, you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my Address Correction request not
                                      done/completed ?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Ans. From the date of notification
                                    generation against your Service request it
                                    will take next billing cycle to get
                                    implemented in the system during this period
                                    you may receive 1 bill with previous
                                    details. In case of any doubt you may reach
                                    out to us via our Whatsapp services on
                                    0120-6226666 NAC Notification No. For any
                                    further requirement, you may please visit us
                                    at our Customer Care Office, Knowledge
                                    Park-I or Techzone-IV Timings 10:00 am to
                                    5:00 pm , Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Why my Address Correction is still
                                      pending
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once you complete the documentation
                                    process, the application goes for several
                                    checks via different departments during
                                    which it might have not been cleared under
                                    LCC/CF Check, you may please visit us at our
                                    Customer Care Office, Knowledge Park-I or
                                    Techzone-IV Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Can I apply new connections floor wise?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. In domestic high rise building
                                    electricity connection to individual
                                    owner/occupant are provided on multipoint
                                    scheme as per the guidelines of 13th
                                    Ammendmant of Electricity Supply Code, 2005.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What should be the file type and size to
                                      upload for new connection online?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. File size can't exceed 15 mb per
                                    documentation type.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Can I apply new connection through
                                      mobile app?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Yes, you can apply new connection from
                                    NPCL mobile app.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How much I have to pay for Prepaid
                                      Connection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You shall have to pay the applicable
                                    processing fee, service charges and security
                                    (if applicable) as specified in Cost Data
                                    Book which shall be calculated based upon
                                    your application and site survey.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Who can apply for Temporary Connection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. If you are intending to carry out any
                                    construction work at your premise and
                                    seeking of electricity connection for
                                    construction activity, then, you will have
                                    to apply for temorary connection.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What are the Documentation requirement
                                      for Temporary Connection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Applicant has to fill application form
                                    for new connection and provide all the
                                    requisite documentation which are mentioned
                                    on the back side of new connection
                                    application form.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.How to convert from temporary to
                                      permanent?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Once you complete construction work and
                                    obtains building completion certificate from
                                    concerned authority i.e. GNIDA/UPSIDC, you
                                    can apply for Permanent connection.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What are the charges of temporary
                                      connection?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. You shall have to pay the applicable
                                    processing fee, security deposit and service
                                    charges as specified in Cost Data Book which
                                    shall be calculated based upon your
                                    application and site survey
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.What is B&L form and why it is required
                                      in load augmentation?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. B&L form or Work completion certificate
                                    form is filled by the licensed electrical
                                    contractor who has carried out electrical
                                    wiring/installation of premise. This
                                    certifies that electrical
                                    wiring/installation is done following all
                                    the regualtions and includes the equipments
                                    details installed, insulation test result of
                                    the wiring/installation.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q.Whom to contact for status of Online New
                                      Connection application
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. To know the status of application you
                                    may WhatsApp us on 0120-6226666 , to know
                                    the short codes follow the link or can write
                                    us at crm@noidapower.com or visit us at our
                                    Customer Care Oﬃce, Knowledge Park-I or
                                    Techzone-IV . Timings 10:00 am to 5:00 pm ,
                                    Monday to Friday.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="card">
                            <div className="card-header">
                              <a
                                className="collapsed card-link"
                                data-toggle="collapse"
                                href="#"
                                onClick={() => setShowSupply(!showSupply)}
                              >
                                Supply Related
                              </a>
                            </div>
                            {showSupply ? (
                              <div
                                id="collapseNine"
                                className="collapse show"
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  <p className="card_cont">
                                    <span>
                                      Q. How to resolve supply-related
                                      complaints on power interruptions or
                                      unsatisfactory voltage profile?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Register the complaint at NPCL's
                                    helpline no. +91-0120 6226666 along with
                                    10-digit consumer number printed at the
                                    upper left corner of your electricity bill
                                    and one complaint number will be intimated
                                    to you for future reference. NPCL
                                    fault-repairing crew will attend to the
                                    cause of the complaint and resolve the same
                                    within the following time limits:
                                  </p>
                                  <ul>
                                    <li>Urban: 3 Hours</li>
                                    <li>Rural: 12 Hours</li>
                                    <li>
                                      Industrial & Instittutional : 4 Hours
                                    </li>
                                    <li>Transformer failure : 72 Hours</li>
                                  </ul>
                                  <p className="card_cont">
                                    You may reach out us through WhatsApp no.
                                    0120-6226666 (NoPower Consumer No.) or Send
                                    SMS at 7840002288 ( #NoPower Consumer No.)
                                    May also download our mobile app for easy &
                                    quick access.
                                  </p>
                                  <p className="card_cont">
                                    <span>
                                      Q. How to address the problem of frequent
                                      tripping of MCB or blowing of fuse?
                                    </span>
                                  </p>
                                  <p className="card_cont">
                                    Ans. Contact a Licensed Electrical
                                    contractor (L.E.C.) to locate the cause. If
                                    there is a fault/defect in your wiring
                                    installations, rectify the same.
                                  </p>
                                  <p className="card_cont">
                                    If the load of your installation is more
                                    than the load allowed by NPCL, please apply
                                    for enhancement of load by filling up a form
                                    available at NPCL office KP-1 & know more
                                    about the same.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default LoadReductionAugmentationFaq;
