import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Announcement from "../components/Announcement";

const ChequeDropBox = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/payment-collection.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center"></div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center"></div>
            <div className="col-sm-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                   <Link to="#">Home </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link href="#" className="active">
                    Payment Options
                  </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/check-drop-box" className="">
                    Cheque Drop Box
                  </Link>
                </li>
              </ol>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <ul className="nav nav-tabs nav_tbds">
                        <li className="nav-item">
                          <Link
                            to="/cash-collection-centres"
                            className="nav-link  "
                            data-toggle="tab"
                          >
                            Cash/Cheque Collection Centres
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            href="https://iwebapps.noidapower.com:8032/DigitalPayment_Centre.aspx"
                            className="nav-link"
                          >
                              Digital Payment Centres
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/cheque-drop-box"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            Cheque Drop Box
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/online-payment"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Online Payment
                          </Link>
                        </li>
              
                        <li className="nav-item">
                          <Link
                            to="/rtgs-and-neft-payment"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            RTGS and NEFT Payment
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/billing-complaint-resolution"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Billing Complaint Resolution
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/nach"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            NACH
                          </Link>
                        </li>
                      </ul>

                      <div className="tab-content tab_main">
                        <div className="row cashcollectioncenter">
                          <legend>CHEQUE DROP BOX</legend>
                          <div className="innwrper">
                            <p>NPCL Customer Care, Sector KP-I Tugalpur</p>
                          </div>
                          <div className="innwrper">
                            <p>
                              NPCL Customer Care, Sector Techzone – IV, Opp.
                              Cherry County Society
                            </p>
                          </div>
                          <div className="innwrper">
                            <p>
                              NPCL Substation , Sector Alpha-I Opp. Ryan
                              International School
                            </p>
                          </div>
                          <div className="innwrper">
                            <p>
                              NPCL Substation , Sector Omega-I Builders Area
                              Near AWHO Apartments
                            </p>
                          </div>
                          <div className="innwrper">
                            <p>NPCL Substation , Surajpur Opp. Police Chowki</p>
                          </div>
                          <div className="innwrper">
                            <p>
                              Bar Association Office, Surajpur District Court
                            </p>
                          </div>
                          <div>
                            <legend>
                              <a href="chequeguidelines.html" target="blank">
                                Guidelines for filling Cheque
                              </a>
                            </legend>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ChequeDropBox;
