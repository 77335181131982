import React from "react";
//import { Link } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
//const navigate = useNavigate();
export const PageNotFound = () => {
  let HomeURL = "https://www.noidapower.com/"; 
  return (

    //navigate("/home", { replace: true });

     <div>
       <a href="https://www.noidapower.com/">
         <img src="assets/images/Page_not_found.png" alt="page not find" />
       </a>
     </div>
  );
};
