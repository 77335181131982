import React from "react";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Announcement from "../components/Announcement";
import { Link } from "react-router-dom";
import { FcCheckmark } from "react-icons/fc";

const MobileApplication = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/contact-banner.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/mobile-application" className="active">
                      Mobile Application
                    </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <ul className="nav nav-tabs nav_tbds">
                        <li className="nav-item">
                          <Link
                            to="/callcenter"
                            className="nav-link "
                            data-toggle="tab"
                          >
                            24*7 Call Centre
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            href="https://iwebapps.noidapower.com:8032/callcenter_email.aspx"
                            className="nav-link "
                            target="_blank"
                          >
                            Customer Care Email Id
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/customer-care-office"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Customer Care Office
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/mobile-application"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            Mobile Application
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/solar-rooftop-net-metering"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Solar Roof Top Net Metering
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="row padtops">
                      <div className="col-sm-4">
                        <legend style={{ marginLeft: "30px" }}>
                          MOBILE APPLICATION
                        </legend>
                        <div className="mobile menus_fullwidth">
                          <ul
                            className="mb_application mob"
                            style={{ marginLeft: "27px" }}
                          >
                            <li style={{ listStyle: "none" }}>
                              {" "}
                              Quick Bill Payment
                            </li>
                            <li style={{ listStyle: "none" }}>
                              Lodge a Complaint & View Status of lodged
                              Complaint
                            </li>
                            <li style={{ listStyle: "none" }}>
                              View,Download past 12 months bills & Payment
                              history
                            </li>
                            <li style={{ listStyle: "none" }}>
                              Update profile Link or Delink your consumer number
                            </li>
                            <li style={{ listStyle: "none" }}>
                              Supply Outage Alerts
                            </li>
                            <li style={{ listStyle: "none" }}>
                              Report a Concern
                            </li>
                            <li style={{ listStyle: "none" }}>
                              Share your Feedback
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="row padtops">
                          <div className="col-sm-4">
                            <img
                              src="assets/img/sel/MApp1.jpg"
                              className="mobile"
                              style={{ width: "13vw" }}
                            />
                          </div>
                          <div className="col-sm-4">
                            <img
                              src="assets/img/sel/MApp2.jpg" alt=""
                              className="mobile"
                              style={{ width: "13vw" }}
                            />
                          </div>
                          <div className="col-sm-4">
                            <img
                              src="assets/img/sel/Untitled-2.jpg"
                              className="mobile"
                              style={{ width: "13vw" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MobileApplication;
