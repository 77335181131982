import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const SinglePointToMultipontConversion = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section class="consumer_inner_bg">
          <img src="assets/images/single-point-banner.jpg" />
          <div class="container-fluid">
            <div class="apply_title">
              <h1>Single Point To Multipont Conversion</h1>
            </div>
          </div>
        </section>
        <section class="main_sectiods">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 brdcum_designs">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span class="icondsds">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li class="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Portal</Link>
                    <span class="icondsds">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li class="breadcrumb-item">
                    <Link to="/information-board">Information Board</Link>
                    <span class="icondsds">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li class="breadcrumb-item">
                    <Link
                      to="/single-point-to-multipont-conversion"
                      class="active"
                    >
                      Single Point To Multipont Conversion
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div class="row justify-content-center padtops">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-3 imphdmdev">
                    <div class="inner_boxs">
                      <div class="our_other_links2">
                        <h3>Other Links</h3>
                        <ul>
                          <li>
                            <Link to="/new-service-connection-calculate">
                              Calculate Energy Consumption
                            </Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres">
                              Payment Options
                            </Link>
                          </li>
                          <li>
                            <Link to="/online-vendor-registration">
                              Vendor Registration
                            </Link>
                          </li>
                          <li>
                            <a href="#">Regulation &amp; Statutory Bodies</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-9 bg_cols">
                    <div class="form_div cashcollectioncenter">
                      <legend>Single Point to Multipont Conversion</legend>
                      <p class="single_cont">
                        <Link to="/allottees-multi-storied-buildings">
                          Important Notice for Owners / Allottees of
                          Multi-Storied Buildings in Greater Noida published on
                          TOI & NBT dated 14th March 2021.
                        </Link>
                      </p>
                      <p class="single_cont">
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/Noida_Power_Company_Limited_Public_Notice_Appeal_to_Residents_AOA_RWA_SA.pdf"
                          target="_blank"
                        >
                          Noida Power Company Limited Public Notice - Appeal to
                          Residents / AOA / RWA / SAS to take Individual
                          Connection.
                        </a>
                      </p>
                      <p class="single_cont">
                        <Link to="/allottees-multi-storied-buildings">
                          Important Notice for Owners / Allottees of
                          Multi-Storied Buildings in Greater Noida published on
                          TOI & NBT dated 1st November 2020.
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SinglePointToMultipontConversion;
