import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const CashCollectionCentres = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/payment-collection.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center"></div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center"></div>
            <div className="col-sm-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#">Home </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link href="#" className="active">
                    Payment Options
                  </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/cash-collection-centres" className="">
                    Cash Collection Centres
                  </Link>
                </li>
              </ol>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <ul className="nav nav-tabs nav_tbds">
                        <li className="nav-item">
                          <Link
                            to="/cash-collection-centres"
                            className="nav-link active "
                            data-toggle="tab"
                          >
                            Cash/Cheque Collection Centres
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            href="https://iwebapps.noidapower.com:8032/DigitalPayment_Centre.aspx"
                            className="nav-link"
                          >
                              Digital Payment Centres
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/cheque-drop-box"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Cheque Drop Box
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/online-payment"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Online Payment
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/rtgs-and-neft-payment"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            RTGS and NEFT Payment
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/billing-complaint-resolution"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Billing Complaint Resolution
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/nach"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            NACH
                          </Link>
                        </li>
                      </ul>

                      <div className="tab-content tab_main">
                        <div
                          className="tab-pane fade show active"
                          id="checkcollection"
                        >
                          <div className="row cashcollectioncenter">
                            <legend>Cash Collection Centres</legend>
                            <div className="innwrper">
                              <h5>Customer Care Centre (Sector KP-1)</h5>
                              <p>Mon to Fri: 10.00 Hr to 17.00 Hr</p>
                              <p>
                                Saturday: 10.00 Hr to 16.30 Hr (1st, 3rd & 5th
                                Sat only)
                              </p>
                            </div>

                            <div className="innwrper">
                              <h5>Customer Care Centre (Sector Techzone-IV)</h5>
                              <p>Mon to Fri: 10.00 Hr to 17.00 Hr</p>
                            </div>
                            <legend>
                              <a
                                href="http://iwebapps.noidapower.com:8032/download/Guidelines of Payment throught cheque.pdf"
                                target="blank"
                              >
                                Guidelines for filling Cheque
                              </a>
                            </legend>

                            <p>
                              Apart from above Consumers may also pay cash at
                              retail outlets of {""}
                              <a
                                href="https://www.bharatbillpay.com/"
                                target="_blank"
                              >
                                <img src="assets/images/BBPS_Logo.png" />
                              </a>
                            </p>
                            <p>
                              To locate nearby BBPS outlet through Pincode,
                              Visit {""}
                              <a
                                href="https://www.bharatbillpay.com/"
                                target="_blank"
                              >
                                “Find Nearest Bharat Bill Pay Outlet”.
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CashCollectionCentres;
