import React from "react";

const Announcement = () => {
  return (
    <>
       <div className="topiconlist"> 
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-sm-6 p-0">
              <div className="anadd">
                <div className="row justify-content-end p-0 m-0">
                  <div className="col-sm-3 p-0">
                    <h4>ANNOUNCEMENT</h4>
                  </div>
                  <div className="col-sm-9 p-0">
                    <div className="flex5258wrap fleright1">
                      <marquee>


                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download/P2P_Energy_Trading_using_Blockchain_Pilot-Addendum-2.pdf" target="_blank" rel="noreferrer"> Inviting Bids for Pilot of “P2P Energy trading using Blockchain”- Pre-bid Query Addendum-2 </a> 
                        </div>
                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download/RFP_for_Virtual_Reality_Lab_Setup.pdf" target="_blank" rel="noreferrer"> RFP for the Implementation of Virtual Reality Lab Setup at NPCL </a> 
                        </div>
                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/SPtoMC_for_MultiStoried_Building_New.aspx" target="_blank" rel="noreferrer">Important Notice for Owners / Allottees of Multi-Storied Buildings in Greater Noida published on TOI &amp; NBT dated 14th March 2021 </a> 
                        </div>
                         <div className="sliderannoucement">
                         <a href="https://iwebapps.noidapower.com:8032/download/UPERC.pdf" target="_blank" rel="noreferrer"> UPERC Preventive Guidelines for Corona Virus </a> 
                        </div> 
                        <div className="sliderannoucement">

                        <a href="https://iwebapps.noidapower.com:8032/download/Banking_tender_for_01.11.2022_to_30.09.2023.pdf" target="_blank" rel="noreferrer">Tender for banking of power for 01.11.2022 to 30.09.2023 on Short Term Basis</a> 
                        </div>

                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download/Public Notice on website_Oxigen.pdf" target="_blank"  rel="noreferrer">Public Notice for Termination of CMS agreement with Oxigen</a>
                        </div>

                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download/13thAmendmentElectricitysupplycode2005-pdf810201862458PM.pdf" target="_blank" rel="noreferrer"> UP Electricity Supply Code � 13th Amendment 2018</a> 
                        </div>

                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download/Public Notice (Mar 25 2018).pdf" target="_blank"  rel="noreferrer">Public Notice for Termination of CMS agreement with Suvidhaa</a> 
                        </div>

                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download_mob_app.aspx">Download Mobile App.</a> 
                        </div>
                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download/suo_moto_Order_of_UPERC_dated_16_09_2014_regarding_change_in_rates_of_Meter_&amp;_Meter_Box.pdf" target="_blank" rel="noreferrer">Amendment in rates of meters by order of The Hon'ble UPERC dated 16.09.2014.</a>
                        </div>


                      </marquee>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 p-0">
              <div className="flex5258wrap">
                <marquee>
                <a href="https://www.noidapower.com/tenders" target="_blank" rel="noreferrer" className="highLigh">	Notice Inviting Tenders for ""Request for Selection (RfS) for Long Term Procurement of 300 MW Wind Solar Hybrid Power from Grid Connected Projects Under Tariff Based Competitive Bidding, RfS No: NPCL/LT/Hybrid/24-25 dated 08-November-2024</a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||||||||||||||||||||||||||||||||||&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <a href="https://www.noidapower.com/NPCL-Tariff-Petition-ARR-2024-25" target="_blank" rel="noreferrer" className="highLigh"> NPCL Tariff Petition for True-up for FY 2022-23, APR for FY 2023-24 and ARR & Tariff for FY 2024-25  </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||||||||||||||||||||||||||||||||||&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <a href="https://www.noidapower.com/Fuel-and-Power-Purchase-Cost-Adjustment" target="_blank" rel="noreferrer" className="highLigh"> Public Notice - Inviting comments on NPCL's Submission for Fuel and Power Purchase Cost Adjustment (FPPCA)  </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||||||||||||||||||||||||||||||||||&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                      
                NPCL bill payments now available at Digital Payment Centres near you. The service is available free of cost. 
                        <a href="https://iwebapps.noidapower.com:8032/DigitalPayment_Centre.aspx" target="_blank" rel="noreferrer" className="highLigh"> Click here  </a>  to know more.  
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||||||||||||||||||||||||||||||||||&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           
                        Consumers can log complaint &amp; avail information by sending SMS to 7840002288 from their registered Mobile No. Click   <a href="https://tinyurl.com/yc9sjqfj" target="_blank">
                        here
                          </a> to view the SMS short codes and their purpose.
                     
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||||||||||||||||||||||||||||||||||&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Single Phase consumers can now submit Self Meter Reading with photo proof between 1st to 10th Day of every month,<a href="https://iwebapps.noidapower.com:8032/self_meter_reading.aspx" target="_blank">
                        click here
                          </a>
                </marquee>
              </div>
            </div>
          </div>
        </div>
       </div> 
    </>
  );
};

export default Announcement;
