import React from "react";

const CompProfile = () => {
  return (
    <section className="companyprofile">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-8">
            <h3>COMPANY PROFILE</h3>
            <p>
              Developed as an industrial hub and urban settlement, Noida Power
              Company Limited is a power distribution company that illuminates
              Greater Noida by reaching out to a population of about 7 lacs
              sprawling across hamlets, villages, and a new township spanning an
              area of 335 sq. km.
            </p>
            <p>
              As a joint venture between the RP-Sanjiv Goenka Group, a leading
              business house in India, and Greater Noida Industrial Development
              Authority, an autonomous body of the U.P. Government responsible
              for town planning and infrastructure development, it gives a
              strategic dimension to the privatized distribution of electricity
              in North India. Since December 1993 after getting the grant of
              license by the U.P. Government, it has been spreading smiles all
              across the miles with its strong distribution network.
            </p>
            <a href="./company-profile">Read more...</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompProfile;
