import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const ThirdPartyMeterTesting = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="consumer_inner_bg">
          <img src="assets/images/third-party-banner.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Third Party Meter Testing</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Portal</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/knowledge-center">Knowledge Center</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/third-party-meter-testing" className="active">
                      Third Party Meter Testing
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="our_other_links2">
                        <h3>Other Links</h3>
                        <ul>
                          <li>
                            <a href="#">Calculate Energy Consumption</a>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres">
                              Payment Options
                            </Link>
                          </li>
                          <li>
                            <Link to="/online-vendor-registration">
                              Vendor Registration
                            </Link>
                          </li>
                          <li>
                            <a href="#">Regulation &amp; Statutory Bodies</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div">
                      <div className="cashcollectioncenter">
                        <div className="col-sm-12">
                          <legend>Third Party Meter Testing</legend>
                          <p>
                            Get your Electric Meters tested through NABL
                            Accredited Meter Testing Laboratory. An independent
                            laboratory having state of the Art Meter Testing
                            Bench with a capacity to test 20 Meters at a given
                            point of time.
                          </p>
                          <p>
                            Meter Testing Reports are validated by Assessors of
                            NABL (a government body) on yearly basis.
                          </p>
                          <p>
                            To know about the Meter Testing charges, please
                            write to us on nablmtl@noidapower.com or visit us at
                            the below mentioned address
                          </p>
                          <p>
                            <b>NPCL Meter Testing Laboratory</b>
                          </p>
                          <p>
                            33/11 KV Substation cum Office <br />
                            Near Overhead Water Reservoir
                            <br />
                            Sector 37, Greater Noida U.P. 201310
                            <br />
                            Timing: 10:00 to 18:00
                            <br />
                            Working Days: Monday- Friday
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ThirdPartyMeterTesting;
