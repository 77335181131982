import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const HolidayCalendar = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="assets/images/holiday-calendar-banner.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Holiday Calendar</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li classNameName="breadcrumb-item">
                    <Link to="/" style={{textDecoration:"none",color:"black"}}>Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Portal</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/knowledge-center">Knowledge Center</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/holiday-calendar" className="active">
                      Holiday Calendar
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="our_other_links2">
                        <h3>Other Links</h3>
                        <ul>
                          <li>
                            <Link to="#">Calculate Energy Consumption</Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres">
                              Payment Options
                            </Link>
                          </li>
                          <li>
                            <Link to="/online-vendor-registration">
                              Vendor Registration
                            </Link>
                          </li>
                          <li>
                            <a href="#">Regulation &amp; Statutory Bodies</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div">
                      <div className="cashcollectioncenter">
                        <div className="col-sm-12">
                          <legend>Holiday Calendar - 2022</legend>
                          <div className="tab-content" id="Calender">
                            <table
                              id="InteractionDetail"
                              className="table table-responsive  abcd "
                              style={{ border: "1px solid #ddd" }}
                            >
                              {" "}
                              <thead>
                                <tr> </tr>
                              </thead>{" "}
                              <tbody>
                                {" "}
                                <tr>
                                  <td className="col-xs-6 lr_gutter">New Year </td>
                                  <td className="col-xs-3 lr_gutter">
                                    1<sup>st</sup> January{" "}
                                  </td>
                                  <td>Saturday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Republic Day{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    26<sup>th</sup> January{" "}
                                  </td>
                                  <td>Wednesday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Maha Shivratri{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    1<sup>st</sup> March{" "}
                                  </td>
                                  <td>Tuesday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Holika Dahan{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    17<sup>th</sup> March{" "}
                                  </td>
                                  <td>Thursday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">Holi </td>
                                  <td className="col-xs-3 lr_gutter">
                                    18<sup>th</sup> March{" "}
                                  </td>
                                  <td>Friday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Ram Navami{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    10<sup>th</sup> April{" "}
                                  </td>
                                  <td>Sunday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Id-ul- Fitr{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    3<sup>rd</sup> May{" "}
                                  </td>
                                  <td>Tuesday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Id-ul-Zuha{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    10<sup>th</sup> July{" "}
                                  </td>
                                  <td>Sunday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Independence Day{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    15<sup>th</sup> August{" "}
                                  </td>
                                  <td>Monday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Raksha Bandhan{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    11<sup>th</sup> August{" "}
                                  </td>
                                  <td>Thursday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Janmashtami{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    19<sup>th</sup> August{" "}
                                  </td>
                                  <td>Friday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Gandhi Jayanti{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    2<sup>nd</sup> October{" "}
                                  </td>
                                  <td>Sunday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Maha Ashtami{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    3<sup>rd</sup> October{" "}
                                  </td>
                                  <td>Monday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Maha Navami{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    4<sup>th</sup> October{" "}
                                  </td>
                                  <td>Tuesday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">Dussehra </td>
                                  <td className="col-xs-3 lr_gutter">
                                    5<sup>th</sup> October{" "}
                                  </td>
                                  <td>Wednesday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Chhoti Diwali{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    23<sup>th</sup> October{" "}
                                  </td>
                                  <td>Sunday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">Diwali </td>
                                  <td className="col-xs-3 lr_gutter">
                                    24<sup>th</sup> October{" "}
                                  </td>
                                  <td>Monday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Govardhan Puja{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    25<sup>th</sup> October{" "}
                                  </td>
                                  <td>Tuesday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">Bhai Dooj </td>
                                  <td className="col-xs-3 lr_gutter">
                                    26<sup>th</sup> October{" "}
                                  </td>
                                  <td>Wednesday</td>
                                </tr>
                                <tr>
                                  <td className="col-xs-6 lr_gutter">
                                    Christmas Day{" "}
                                  </td>
                                  <td className="col-xs-3 lr_gutter">
                                    25<sup>th</sup> December{" "}
                                  </td>
                                  <td>Sunday</td>
                                </tr>
                                <tr>
                                  <td colspan="3" className="text-center"></td>
                                </tr>
                                <tr>
                                  <td colspan="3" className="text-center">
                                    * The festivals will be observed according
                                    to the local visibility of the moon.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default HolidayCalendar;
