import React from "react";
import Header2 from "../components/Header2";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import { useState } from "react";

const NewServiceConnection = () => {
  const [openDomestic, setOpenDomestic] = useState(true);
  const [openNonDomestic, setOpenNonDomestic] = useState(false);

  const open1 = () => {
    setOpenDomestic(true);
    setOpenNonDomestic(false);
  };
  const open2 = () => {
    setOpenNonDomestic(true);
    setOpenDomestic(false);
  };
  return (
    <>
      <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods consumersec">
          <div className="upperdesignds">
            <div className="row">
              <div className="col-md-1">
                <img
                  className="bgstlsd"
                  src="assets/img/small/payment-options.jpg"
                  alt=""
                />
              </div>
              <div className="col-md-11 textdesing">
                <h2 className="headtit">New Connection</h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div class="row justify-content-center padtops">
              <div class="col-sm-12 form_div" style={{ marginTop: "20px" }}>
                <ul className="parentClass">
                  <li>
                    <label>
                      <input
                        type="radio"
                        name="anything"
                        className="radioCls"
                        id="yes"
                        onClick={open1}
                        checked
                      />{" "}
                      Domestic
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        name="anything"
                        className="radioCls"
                        id="no"
                        onClick={open2}
                      />{" "}
                      Non-Domestic
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="" id="first">
              {openDomestic ? (
                <>
                  <ul className="nav nav-pills" role="tablist">
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        data-toggle="pill"
                        to="/new-service-connection"
                      >
                        Apply
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-toggle="pill"
                        to="/new-service-connection-calculate"
                      >
                        Calculate Your Load
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-toggle="pill"
                        to="/new-service-connection-document"
                      >
                        Document Checklist
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-toggle="pill"
                        to="/new-service-connection-sample"
                      >
                        Sample Documents
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-toggle="pill"
                        to="/new-service-connection-online"
                      >
                        Online Application
                      </Link>
                    </li>
                  </ul>{" "}
                  <div className="tab-content">
                    <div id="apply" className="container tab-pane show active">
                      <br />
                      <h4 className="mt-2"> Apply(Domestic) </h4>
                      <p className="nooutagedsabf">
                        <a href="https://iwebapps.noidapower.com:8032/Authenticate/Login.aspx">
                          Click here to Apply
                        </a>
                      </p>
                    </div>
                  </div>{" "}
                </>
              ) : null}

              {openNonDomestic ? (
                <>
                  <ul className="nav nav-pills" role="tablist">
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        data-toggle="pill"
                        to="/new-service-connection"
                      >
                        Apply
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-toggle="pill"
                        to="/new-service-connection-calculate"
                      >
                        Calculate Your Load
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-toggle="pill"
                        to="/new-service-connection-document"
                      >
                        Document Checklist
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-toggle="pill"
                        to="/new-service-connection-sample"
                      >
                        Sample Documents
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-toggle="pill"
                        to="/new-service-connection-online"
                      >
                        Online Application
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="apply" className="container tab-pane show active">
                      <br />
                      <h4 className="mt-2"> Apply(Non-Domestic) </h4>
                      <p className="nooutagedsabf">
                        <a href="https://iwebapps.noidapower.com:8032/Authenticate/Login.aspx">
                          Click here to Apply
                        </a>
                      </p>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NewServiceConnection;
