import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const RegisterComplain = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="consumer_inner_bg">
          <img src="assets/images/register-complain-banner.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Consumer Complaint</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home</a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Portal</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/register-complain" className="active">
                      Consumer Complaint
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <h2 className="online_heading">Register a Complaint</h2>

                    <div className="rediobuttonstyle">
                      <form className="form">
                        <div className="plan">
                          <ul className="box_online">
                            <li>
                              <Link to="/supply-related-comaplaint">
                                <img
                                  src="https://image.noidapower.com/image-photo/high-voltage-switchgear-equipment-600w-173614967.jpg"
                                  alt=""
                                />{" "}
                                Supply Related Complaint
                              </Link>
                            </li>
                            <li>
                              <Link to="/bill-related-complaint">
                                <img
                                  src="https://image.noidapower.com/image-photo/worker-inspector-engineer-checking-inspecting-600w-1407876536.jpg"
                                  alt=""
                                />{" "}
                                Bill Related Complaint
                              </Link>
                            </li>
                            <li>
                              <Link to="/meter-related-complaint">
                                <img
                                  src="https://image.noidapower.com/image-photo/electrician-works-electric-meter-tester-600w-1147515965.jpg"
                                  alt=""
                                />{" "}
                                Meter Related Complaint
                              </Link>
                            </li>
                            <li>
                              <Link to="/service-related-complaint">
                                <img
                                  src="assets/img/small/qms-real-time.jpg"
                                  alt=""
                                />{" "}
                                Service Related Complaint
                              </Link>
                            </li>
                            <li>
                              <Link to="/safety-related-complaint">
                                <img
                                  src="https://image.noidapower.com/image-photo/electrical-instrument-technician-measuring-voltage-600w-1032894379.jpg"
                                  alt=""
                                />{" "}
                                Safety Related Complaint
                              </Link>
                            </li>
                            <li>
                              <Link to="/theft-related-complaint">
                                <img
                                  src="https://image.noidapower.com/image-photo/athens-acharnes-stratopaido-kapota-greece-600w-1206413125.jpg"
                                  alt=""
                                />{" "}
                                Theft Related Complaint
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default RegisterComplain;
