import React, { useState } from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Announcement from "../components/Announcement";

const NACH = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/payment-collection.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center"></div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center"></div>
            <div className="col-sm-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                   <Link to="#">Home </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link href="#" className="active">
                    Payment Options
                  </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/nach" className="">
                    NACH
                  </Link>
                </li>
              </ol>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <ul className="nav nav-tabs nav_tbds">
                        <li className="nav-item">
                          <Link
                            to="/cash-collection-centres"
                            className="nav-link  "
                            data-toggle="tab"
                          >
                            Cash/Cheque Collection Centres
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            href="https://iwebapps.noidapower.com:8032/DigitalPayment_Centre.aspx"
                            className="nav-link"
                          >
                              Digital Payment Centres
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/cheque-drop-box"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Cheque Drop Box
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/online-payment"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Online Payment
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/rtgs-and-neft-payment"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            RTGS and NEFT Payment
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/billing-complaint-resolution"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Billing Complaint Resolution
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/nach"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            NACH
                          </Link>
                        </li>
                      </ul>

                      <div className="row cashcollectioncenter">
                        <legend>
                          NATIONAL AUTOMATED CLEARING HOUSE (NACH)
                        </legend>

                        <div id="accordion">
                          <div className="card">
                            <div
                              className="card-header"
                              onClick={() => setShow(!show)}
                            >
                              What is NACH
                            </div>

                            {show ? (
                              <p>
                                National Payments Corporation of India (NPCI)
                                has implemented “National Automated Clearing
                                House (NACH)” for Banks, Financial Institutions,
                                Corporates and Government a web-based solution
                                to facilitate interbank, high volume, electronic
                                transactions which are repetitive and periodic
                                in nature. Consumer can also make NPCL Bill
                                payment though NACH. Consumer is required to
                                give one time mandate for auto debit of NPCL
                                bill payment and amount will be deducted from
                                his bank account.
                                <p>
                                <img src="http://iwebapps.noidapower.com:8032/download/Nachimage.png" />
                                </p>
                                {/* <p><a className="lingcoldfsds"
                      href="http://iwebapps.noidapower.com:8032/download/Pictorial Representation of NACH Portal.pdf"
                      target="_blank" rel="noreferrer"
                    ><img src="assets/img/pdf.png" alt="" />  Please click here to download the Representation </a></p> */}
                              </p>
                            ) : null}
                          </div>
                          <div className="card">
                            <div
                              className="card-header"
                              onClick={() => setShow2(!show2)}
                            >
                              Benefits of NACH
                            </div>

                            {show2 ? (
                              <p>
                                <legend>Benefits</legend>
                                <ol>
                                  <li>No Need to remember bill due dates.</li>
                                  <li>
                                    Enjoy uninterrupted service without any
                                    payment-related disruptions.
                                  </li>
                                  <li>Ensure zero late payment charges.</li>
                                  <li>
                                    Receive payment confirmation as SMS on your
                                    Registered Mobile Number.
                                  </li>
                                </ol>
                              </p>
                            ) : null}
                          </div>
                          <div className="card">
                            <div
                              className="card-header"
                              onClick={() => setShow3(!show3)}
                            >
                              How to Register
                            </div>
                            {show3 ? (
                              <p>
                                <ol>
                                  <li>
                                    Physical Mandate (Hard Copy) Registration.
                                  </li>
                                  <li>
                                    To avail this service, please carry copy of
                                    cancelled cheque, Latest bill and your
                                    ID/Address proof and visit our KP-1 /
                                    Techzone IV Customer Care Center.
                                  </li>
                                  <li>
                                    On receipt of your duly completed and
                                    verified form along with a copy of cancelled
                                    cheque, your auto-pay facility through NACH
                                    will be activated within 10 working days.
                                  </li>
                                  <li>
                                    E- Mandate registration
                                    <span>*Coming Soon</span>
                                  </li>
                                </ol>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NACH;
