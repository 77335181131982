import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const PayAgainstApplication = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/quick-bill-payment-banner.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/quick-pay-pre" className="active">
                      Quick Pay
                    </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/pay-against-application" className="">
                      Pay Against Application
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <div className="innerhdnds">
                        <h2>QUICK PAY</h2>
                      </div>
                      <div className="bs-example">
                        <ul className="nav nav-tabs nav_tbds">
                          <li className="nav-item">
                            <Link
                              to="/quick-pay-pre"
                              className="nav-link "
                              data-toggle="tab"
                            >
                              Consumption Invoices
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/pay-against-application"
                              className="nav-link active"
                              data-toggle="tab"
                            >
                              Pay Against Application
                            </Link>
                          </li>
                        </ul>
                        <div className="tab-content tab_main">
                          <form>
                            <div className="main_wrappersd loginpgui">
                              <div className="col-md-12">
                                <div className="form-group form_gp_des">
                                  <label for="">
                                    Please type your estimate number
                                    <span>*</span> here
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="consumer number"
                                    name=""
                                    required
                                  />
                                  <div className="valid-feedback">Valid.</div>
                                  <div className="invalid-feedback">
                                    Please fill out this field.
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group form_gp_des">
                                  <label for="">
                                    Please type your Email Id <span>*</span>
                                    here
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Email Id"
                                    name=""
                                    required
                                  />
                                  <div className="valid-feedback">Valid.</div>
                                  <div className="invalid-feedback">
                                    Please fill out this field.
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group form_gp_des">
                                  <label for="">
                                    Please type your Mobile number
                                    <span>*</span> here
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Mobile number"
                                    name=""
                                    required
                                  />
                                  <div className="valid-feedback">Valid.</div>
                                  <div className="invalid-feedback">
                                    Please fill out this field.
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form_gp_des">
                                  <button
                                    type="submit"
                                    className="btn btn-primary form-control submit_btmns"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="hidden_parts">
                              <div className="col-md-12">
                                <p className="successmsgg">
                                  OTP has been sent on the email id and mobile
                                  no given above. Please use the same and
                                  proceed further
                                </p>
                                <div className="form-group form_gp_des">
                                  <label for="">Validate OTP</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Enter OTP"
                                    name=""
                                    required
                                  />
                                  <div className="valid-feedback">Valid.</div>
                                  <div className="invalid-feedback">
                                    Please fill out this field.
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <p className="error_msgs">Invalid OTP!!</p>
                                <div className="form-group form_gp_des">
                                  <button
                                    type="submit"
                                    className="btn btn-primary form-control submit_btmns"
                                  >
                                    Validate
                                  </button>
                                </div>
                              </div>
                            </div>
                            <table className="table table-responsive table_desns tablbtn_border">
                              <tr>
                                <th> Consumer Name </th>
                                <th> Notification No. </th>
                                <th> Notification Type </th>
                                <th> Net Payable Amount </th>
                                <th> View/ Download </th>
                                <th> Pay </th>
                              </tr>
                              <tr>
                                <td> 32296 </td>
                                <td>54367 </td>
                                <td> Notification Type </td>
                                <td> 6566</td>
                                <td> Click Here </td>
                                <td> 275 </td>
                              </tr>
                              <tr>
                                <td> 545446 </td>
                                <td>654778 </td>
                                <td> Notification Type </td>
                                <td> 65656</td>
                                <td> Click Here </td>
                                <td> 13409 </td>
                              </tr>
                              <tr>
                                <td> 207678 </td>
                                <td>6578896 </td>
                                <td> Notification Type </td>
                                <td> 78343</td>
                                <td> Click Here </td>
                                <td> 9735 </td>
                              </tr>
                              <tr>
                                <td> 2465676 </td>
                                <td>54796 </td>
                                <td> Notification Type </td>
                                <td> 90787</td>
                                <td> Click Here </td>
                                <td> 1588 </td>
                              </tr>
                              <tr>
                                <td> 43490878 </td>
                                <td>434678 </td>
                                <td> Notification Type </td>
                                <td> 33456</td>
                                <td> Click Here </td>
                                <td> 5454 </td>
                              </tr>
                            </table>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PayAgainstApplication;
