import React  from "react";
import Announcement from "../components/Announcement";
import Slider from "react-slick";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";


const LastPayment = ()=>
  {
    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
      };
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/quick-e-bill-download.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/last-payment" className="active">
                      Last Payment
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 bg_cols">
                  <div className="form_div cashcollectioncenter">

<legend>Last Payment Details</legend>  
<div className="col-md-9 form-group form_gp_des">
    <label>Enter Consumer Number <span>*</span></label>
    <input type="text" name="name" className="form-control" placeholder="Consumer Number" required/>
</div>
<div className="col-md-4 form-group form_gp_des">
    <button type="submit" id="submit" className="sub_btn2 submit" name="submit">Generate Receipt </button>
</div>
</div>    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )}

export default LastPayment;
