import React from "react";
import Header2 from "../components/Header2";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import {useState} from 'react'

const LoadReductionAugmentationFeedback = () => {
  const [loadReduction,setLoadReduction] = useState(true)
  const [augmentation,setAugmentation] = useState(false)

  const openLoad = ()=>{
    setLoadReduction(true);
    setAugmentation(false)
   }
   const openAug = ()=>{
     setLoadReduction(false);
    setAugmentation(true)
   }
  return (
    <>
         <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods consumersec">
        <div className="upperdesignds">
			<div className="row">
				<div className="col-md-1">
				<img className="bgstlsd" src="assets/img/small/payment-options.jpg" alt=""/>
			</div>
			<div className="col-md-11 textdesing">
							<h2 className="headtit">{loadReduction ? ('Load Reduction'):('load Augmentation')}</h2>
			</div>
			</div>
		</div>
        <div className="container">
        <section className="main_sectiods">
    <div className="container">
    <div className="row justify-content-center padtops">
    <div className="col-sm-12">
            <div className="col-lg-12 brdcum_designs">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#">Home</Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/consumer-portal" target="_blank">Consumer Portal</Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <a href="#" className="active">
                    Apply Online
                  </a>
                </li>
              </ol>
            </div>

            <div className="bs-example bs-example-1">
        <div className="tab-pane show active" id="newconnection">
        <ul className="nav">
        <li className="nav-item" onClick={openLoad}><Link className={`nav-link ${loadReduction ? "active":""}`} data-toggle="tab" style={{color:loadReduction?"blue":"red"}}>Load Reduction</Link></li>
        <li className="nav-item" onClick={openAug}><Link className={`nav-link ${augmentation ? "active":""}`} data-toggle="tab" style={{color:augmentation?"blue":"red"}}>Load Augmentation</Link></li>
        </ul>
        </div>
        </div>
    {loadReduction ? ( <div className="col-sm-12 bg_cols"> 
    <div className="form_div">
    <div className="tab-content tab_main">
    <div className="tab-pane fade show active" id="newconnection">
        <div className="bs-example bs-example-1">
                        <div
                          className="tab-pane fade show active"
                          id="newconnection"
                        >
                          <ul className="nav nav-tabs nav_tbds" style={{overflow:"hidden"}}>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Calculate Your Load
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-doc"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Document Checklist
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-sample"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Sample Documents
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-faq"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                FAQs
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-apply"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Apply
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-status"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Application Status
                              </Link>
                            </li>
                            <li className="nav-item" >
                              <Link
                                to="/load-reduction-augmentation-feedback"
                                className="nav-link active"
                                data-toggle="tab"
                              >
                                Feedback 
                              </Link>
                            </li>
                          </ul>
                        </div>{" "}
                      </div>
    <div className="row" style={{marginLeft:"20px"}}>
    <div className="col-md-12">
    <div className="tab-pane" id="feedback" >
    <h4 className="mt-2">  Feedback Form </h4>
			<div className="card-body" >
            <div className="row loginwidth">
            <div className="col-lg-6"> 
                        <div className="form-group form_gp_des">
                    <label>Consumer No. (उपभोक्ता संख्या)</label>
                       <input name="Consumer No" type="number" class="form-control" placeholder="Consumer No." required/>
                   </div></div>
                    <div className="col-lg-6">
                          <div className="form-group form_gp_des">
                           <label>Name of the Consumer (उपभोक्ता का नाम)</label>
                       <input name="password" type="text" class="form-control" placeholder="Consumer Name"/>
                   </div>
                    </div>
                    <div className="col-lg-6">
                         <div className="form-group form_gp_des">
                           <label>Contact No. (संपर्क नंबर)</label>
                       <input type="tel" id="phone" name="phone" class="form-control" placeholder="Contact No"/>
                   </div>
                    </div>
					<div className="col-lg-6">
                          <div className="form-group form_gp_des">
                           <label>E-Mail ID (ई-मेल आईडी)</label>
                       <input name="email" type="text" className="form-control" placeholder="Email"/>
                   </div>
                    </div>
            </div>
            <div className="col-lg-12">
					<div className="feedback">
		<p>Ease of Accessing Information on Website</p>
		<div className="feedback_ratings">
			<span className="feedback_rating" data-rate="1">😒 Poor</span>
			<span className="feedback_rating" data-rate="2">😐 OK</span>
			<span className="feedback_rating" data-rate="3">😏 Good</span>
			<span className="feedback_rating" data-rate="4">😁 Great</span>
		</div>
		<div className="feedback-n">
		</div>
	    </div>
		</div>
        <div className="col-lg-12">
                          <div className="form-group form_gp_des">
                           <label>Suggestion (सुझाव )</label>
                       <textarea rows="4" cols="100" class="form-control"></textarea>
                   </div>
                    </div>
					<div className="col-md-3">
					<button type="submit" id="submit" class="sub_btn2 submit" name="submit">Submit</button>
					</div>
		 </div>
    </div>
	</div>
    </div>
    </div>
    </div>
    </div>
    </div>):null}
    {augmentation ? ( <div className="col-sm-12 bg_cols"> 
    <div className="form_div">
    <div className="tab-content tab_main">
    <div className="tab-pane fade show active" id="newconnection">
        <div className="bs-example bs-example-1">
                        <div
                          className="tab-pane fade show active"
                          id="newconnection"
                        >
                          <ul className="nav nav-tabs nav_tbds" style={{overflow:"hidden"}}>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Calculate Your Load
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-doc"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Document Checklist
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-sample"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Sample Documents
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-faq"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                FAQs
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-apply"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Apply
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-status"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Application Status
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-feedback"
                                className="nav-link active"
                                data-toggle="tab"
                              >
                                Feedback
                              </Link>
                            </li>
                          </ul>
                        </div>{" "}
                      </div>
    <div className="row" style={{marginLeft:"20px"}}>
    <div className="col-md-12">
    <div className="tab-pane" id="feedback" >
    <h4 className="mt-2">  Feedback Form </h4>
			<div className="card-body" >
            <div className="row loginwidth">
            <div className="col-lg-6"> 
                        <div className="form-group form_gp_des">
                    <label>Consumer No. (उपभोक्ता संख्या)</label>
                       <input name="Consumer No" type="number" class="form-control" placeholder="Consumer No." required/>
                   </div></div>
                    <div className="col-lg-6">
                          <div className="form-group form_gp_des">
                           <label>Name of the Consumer (उपभोक्ता का नाम)</label>
                       <input name="password" type="text" class="form-control" placeholder="Consumer Name"/>
                   </div>
                    </div>
                    <div className="col-lg-6">
                         <div className="form-group form_gp_des">
                           <label>Contact No. (संपर्क नंबर)</label>
                       <input type="tel" id="phone" name="phone" class="form-control" placeholder="Contact No"/>
                   </div>
                    </div>
					<div className="col-lg-6">
                          <div className="form-group form_gp_des">
                           <label>E-Mail ID (ई-मेल आईडी)</label>
                       <input name="email" type="text" className="form-control" placeholder="Email"/>
                   </div>
                    </div>
            </div>
            <div className="col-lg-12">
					<div className="feedback">
		<p>Ease of Accessing Information on Website</p>
		<div className="feedback_ratings">
			<span className="feedback_rating" data-rate="1">😒 Poor</span>
			<span className="feedback_rating" data-rate="2">😐 OK</span>
			<span className="feedback_rating" data-rate="3">😏 Good</span>
			<span className="feedback_rating" data-rate="4">😁 Great</span>
		</div>
		<div className="feedback-n">
		</div>
	    </div>
		</div>
        <div className="col-lg-12">
                          <div className="form-group form_gp_des">
                           <label>Suggestion (सुझाव )</label>
                       <textarea rows="4" cols="100" class="form-control"></textarea>
                   </div>
                    </div>
					<div className="col-md-3">
					<button type="submit" id="submit" class="sub_btn2 submit" name="submit">Submit</button>
					</div>
		 </div>
    </div>
	</div>
    </div>
    </div>
    </div>
    </div>
    </div>):null}
    
    </div>
    </div>
    </div>
    </section>
        </div>
        </section>
        </div>
    </>
  )
}

export default LoadReductionAugmentationFeedback