import React,{useState} from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import Header from "../components/Header";
import Slider from "react-slick";
import FixMenu from "../components/FixMenu";

const CSRActivities = () => {
  const [show,setShow] = useState(false);
  const [show1,setShow1] = useState(false);
  const [show2,setShow2] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed:1900,
  };
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" +
                "assets/img/inner/corporatesocialresponsibility-banner.jpg" +
                ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/csractivities" className="active">
                      Lifestyle Improvement
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/corporate-social-responsibility"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Corporate Social Responsibility
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/lifestyle-improvement"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Lifestyle Improvement
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/community-development"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Community Development
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/commitment-to-environment"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Commitment to Environment
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/csractivities"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        CSR Activities
                      </Link>
                    </li>
                  </ul>
                  <legend>CSR INITIATIVES</legend>
                  <div className="col-sm-12">
                    <div className="row justify-content-center">
                      <div className="col-sm-10">
                        <div className="filterdesign slider main_div_dessd">
                          <Slider
                            ref={(slider) => (slider = slider)}
                            {...settings}
                          >
                            <div className="csrslider" >
                              <img src="assets/img/csr-activities-01.jpg" className="csrActivity" alt="NPCL-csr"/>
                              <p className="csrHead">Say “No. to Plastic”</p>
                              <p>
                              With a view to curb use of plastic bags thereby supporting governments “Swachh Bharat” mission, the company launched an initiative of “Say No to Plastic” under which Jute Bags were distributed to its consumers.
                               </p>
                            </div>
                            <div className="csrslider">
                              <img src="assets/img/csr-activities-02.jpg" className="csrActivity" alt="NPCL-csr"/>
                              <p className="csrHead">Say “No. to Plastic”</p>
                              <p>
                              With a view to curb use of plastic bags thereby supporting governments “Swachh Bharat” mission, the company launched an initiative of “Say No to Plastic” under which Jute Bags were distributed to its consumers.
                             </p>
                            </div>
                            <div className="csrslider">
                              <img src="assets/img/csr-activities-03.jpg" className="csrActivity" alt="NPCL-csr"/>
                              <p className="csrHead">Renovating a Govt. School in Greater Noida</p>
                              <p>
                              A Co-ed school in Tugalpur, Greater Noida with a strength of 492 students reading in Standard 1st to Vth was in a worn-out condition. NPCL constructed 2 new classrooms with a sitting capacity of 50 each, provided LED lights, fans, white boards and benches in each class room. Washrooms for both boys and girls were revamped and a covered canopy was constructed for better drinking water facility <span onClick={()=>setShow1(!show1)} >{show1 ? (<>show less</>):(<>read more.....</>)}</span></p>
                              
                              {show1 ? (<span>Mr. B. N. Singh, District Magistrate Gautam Buddha Nagar inaugurated the two new classrooms</span>):null}
                            </div>
                            <div className="csrslider">
                              <img src="assets/img/csr-activities-04.jpg" className="csrActivity" alt="NPCL-csr"/>
                              <p className="csrHead">Promoting Health & Hygiene through CSR Activity at Prathmik Vidhyalaya, Luksar, Greater Noida</p>
                              <p>NPCL in its endeavor to promote better health and hygiene in rural areas, continued its humble initiative of constructing washrooms in Government schools under CSR. 4 new washrooms were constructed at Prathmik Vidhyalaya, Fatehpur Rampur, Greater Noida which were handed over to the schools on 3rd Mar’20 over a small function where NPCL team created awareness among the students on Importance of education, electricity conservation tips, how to maintain basic health & hygiene <span onClick={()=>setShow(!show)} >{show ? (<>show less</>):(<>read more.....</>)}</span></p>
                             {show ? (<span>Imprinted Jute Bags with “Say no to plastic” were distributed along with safety pamphlets to childrens and entire staff of the School.</span>):null}
                            </div>
                            <div className="csrslider">
                              <img src="assets/img/csr-activities-05.jpg" className="csrActivity" alt="NPCL-csr"/>
                              <p className="csrHead">NPCL’s CSR initiative “ROSHNI”</p>
                              <p>A full day Health Check up Camp was organized by NPCL on 6th Sep’19 in collaboration with Sharda Hospital for the residents of Old Age home, Bilaspur. All 57 residents underwent Eye Test, Dental Screening, ECG, Urine Test, Blood Test & consultation by a physician.</p>
                            </div>
                            <div className="csrslider">
                              <img src="assets/img/csr-activities-06.jpg" className="csrActivity" alt="NPCL-csr"/>
                              <p className="csrHead">Renovating a Govt. School in Greater Noida</p>
                              <p>A Co-ed school in Tugalpur, Greater Noida with a strength of 492 students reading in Standard 1st to Vth was in a worn-out condition. NPCL constructed 2 new classrooms with a sitting capacity of 50 each, provided LED lights, fans, white boards and benches in each class room. Washrooms for both boys and girls were revamped and a covered canopy was constructed for better drinking water facility <span onClick={()=>setShow2(!show2)} style={{color:"red",cursor:"pointer"}}>{show2 ? (<>show less</>):(<>read more.....</>)}</span></p>
                               {show2 ? (<span>Mr. B. N. Singh, District Magistrate Gautam Buddha Nagar inaugurated the two new classrooms</span>):null} 
                            </div>
                            <div className="csrslider">
                              <img src="assets/img/csr-activities-07.jpg" className="csrActivity" alt="NPCL-csr"/>
                              <p className="csrHead">Distribution of Ration at Janipura Village, Greater Noida</p>
                              <p>NPCL distributed ration to 40 families of Janipura Village, Gautam Buddha Nagar, residing in kachcha house or jhuggi.
                              <br></br>Due to Covid-19 pandemic, most of the residents in this village have lost their jobs and are now dependent on others to provide them food and other essentials. Distribution of Anaaj to the residents will ensure that even the poor / needy does not sleep empty stomach at least for few weeks.</p>
                            </div>
                            <div className="csrslider">
                              <img src="assets/img/csr-activities-08.jpg" className="csrActivity" alt="NPCL-csr"/>
                              <p className="csrHead">Distribution of Ration at Janipura Village, Greater Noida</p>
                              <p>NPCL undertook this noble Initiative of giving “Roshni” to 5 Residents of Old Age Home ’Bilaspur, all between the age group of 63 – 67 and got them admitted on 17th Oct’19 in Sharda Hospital, Greater Noida for their eye surgeries.

                              <br></br>NPCL CSR team also ensured hassle free post-operative care and provided 1 month prescribed medicines along with Fruits and health drink.</p>
                            </div>


                            <div className="csrslider">
                              <img src="assets/img/csr-activities-09.jpg" className="csrActivity" alt="NPCL website"/>
                              <p className="csrHead"> Self Defence Workshop in 12 Government Schools of Greater Noida 
                              </p>
                              <p>NPCL in collaboration with Yodha Israeli Military Close Combat System (KRAV MAGA), organises Self Defence workshop for all the girls students and female teachers of the Government School. It will be a 5 days workshop in each school . NPCL will also provide Certificates to all the students and teachers  after successful completion of the workshop.</p>
                            </div>
                            <div className="csrslider">
                              <img src="assets/img/csr-activities-10.jpg" className="csrActivity" alt="NPCL website"/>
                              <p className="csrHead"> Mega Health drive in 12 Government Schools of Greater Noida 
                              </p>
                              <p>NPCL in collaboration with Sharda Welfare Foundation  Organises Mega Health Drive for all the students of 12 Government Schools wherein a team of Specialised doctors (Paediatric, Ophthalmologist and Dental) will be available  for health  Check-up of the Students.

                              <br></br>Awareness Session on Health & Hygiene will also be provided by the specialised doctors .</p>
                            </div>

                            <div className="csrslider">
                              <img src="assets/img/csr-activities-11.jpg" className="csrActivity" alt="NPCL website"/>
                              <p className="csrHead"> Installation of Fitness Equipment’s/Swings (Play Equipment's) in Government Schools
 
                              </p>
                              <p>In continuation to our CSR initiatives focused in Government Schools, NPCL has started an initiative to promote health and fitness thru Installation of Fitness Equipment’s/Swings (Play Equipment's) in 4 Government Schools of Greater Noida 
                                  NPCL installed 5 equipment’s (2 fitness equipment’s and 3 play equipment’s) in the playground of schools. Fitness equipment’s will help students to develop motor skills—grip strength, hand, arm and finger coordination, weight, etc. It will also develop core muscles of the children. 
                                  <br /><br />Installation work has been completed in Upper Primary School-Salempur Gurjar, Composite School-Pipalka Daudpur, Composite School-Fatehpur Rampur, Prathmikh Vidyalaya-Dabra
                              </p>
                            </div>


                            <div className="csrslider">
                              <img src="assets/img/csr-activities-12.jpg" className="csrActivity" alt="NPCL website"/>
                              <p className="csrHead"> Installation of Fitness Equipment’s/Swings (Play Equipment's) in Government Schools
 
                              </p>
                              <p>In continuation to our CSR initiatives focused in Government Schools, NPCL has started an initiative to promote health and fitness thru Installation of Fitness Equipment’s/Swings (Play Equipment's) in 4 Government Schools of Greater Noida 
                                  NPCL installed 5 equipment’s (2 fitness equipment’s and 3 play equipment’s) in the playground of schools. Fitness equipment’s will help students to develop motor skills—grip strength, hand, arm and finger coordination, weight, etc. It will also develop core muscles of the children. 
                                  <br /><br />Installation work has been completed in Upper Primary School-Salempur Gurjar, Composite School-Pipalka Daudpur, Composite School-Fatehpur Rampur, Prathmikh Vidyalaya-Dabra
                              </p>
                            </div>
                            <div className="csrslider">
                              <img src="assets/img/csr-activities-13.jpg" className="csrActivity" alt="NPCL website"/>
                              <p className="csrHead"> NPCL Equips 10 Government Schools With Smart Classes under CSR
                              </p>
                              <p> NPCL under CSR installed 1 interactive Smart Panel in 10 schools with an aim to improve learning experience of the students.


                              <br />This smart panel can also be used by teachers to write on it using a stylus pen, save the text for reference in future. The interactive Panel is equipped with NCERT prescribed syllabus for all subjects till Class XII. This shall be used by all classes of the respective schools on rotational basis. 
                              
                              <br />This was installed with a License of 5 years during which any update w.r.t. NCERT syllabus will be available to the schools free of cost.

                              </p>
                            </div>

                            <div className="csrslider">
                              <img src="assets/img/csr-activities-14.jpg" className="csrActivity" alt="NPCL website"/>
                              <p className="csrHead"> Joy of Giving </p>
                              <p> “Joy of Giving” initiative was launched by the company in which the employees enthusiastically demonstrated their social commitment by giving Clothes, Woollens & Toys which can be used by the under privileged.

                              <br /><br />NPCL team handed over 14 cartons clothes and toys to GOONJ, an NGO which will further distribute the clothes and toys to the needy under “ ODHA DO ZINDAGI INITIATIVE”
 
                              </p>
                            </div>

                            
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row" style={{ marginTop: "120px" }}>
                    <div className="col-md-12">
                      <p>
                        In continuation to our CSR Initiative; NPCL constructed
                        washrooms in the Advocates Chamber at Collectrate
                        Office, Greater Noida, District Gautam Buddha Nagar,
                        Uttar Pradesh These newly constructed washrooms were
                        inaugurated by Mr. B. N. Singh, District Magistrate
                        Gautam Buddha Nagar on 9th Jan’20.
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CSRActivities;
