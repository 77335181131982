import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Slider from "react-slick";

const Institutional = () => {
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background:
                "url(" + "assets/img/inner/outage-notification.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10 main_heading">
                  <h1>
                    Self Submission
                    <br /> of Meter Reading
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/get-outage-notification" className="active">
                      Get Outage Notification
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...settings2}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" alt="NPCL AWARDS" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg"  alt="NPCL AWARDS" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="our_other_links">
                        <h4>Other Links</h4>
                        <ul>
                          <li>
                            <Link to="#">
                              <span></span> Calculate Energy Consumption{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Payment Options{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online-vendor-registration"
                              target="_blank"
                            >
                              <span></span>Vendor Registration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Regulation &amp; Statutory Bodies{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div">
                      <div className="tab-content tab_main">
                        <div className="tab-pane fade show" id="newconnection">
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <h2>Getting Started </h2>
                            <div className="col-xs-12 newconst">
                              <p className="cont_desd">
                                <img
                                  src="assets/img/icon/new-icon.jpg"
                                  className="cont_desd" alt="NPCL"
                                />
                                To apply online , please fill{" "}
                                <span>
                                  <a
                                    href="https://iwebapps.noidapower.com:8032/Online_NewConnection.aspx"
                                    target="_blank" rel="noreferrer"
                                  >
                                    Application Form{" "}
                                  </a>{" "}
                                </span>
                                to generate New Connection Request{" "}
                              </p>

                              <p>
                                OR download and fill up the{" "}
                                <a
                                  href="pdf/Holiday_List_-2021.pdf"
                                  target="_blank"
                                >
                                  {" "}
                                  Domestic Connection Application Form{" "}
                                </a>{" "}
                                for{" "}
                                <span className="high dar">
                                  Domestic Connection{" "}
                                </span>{" "}
                                and{" "}
                                <a
                                  href="assets/pdf/Non_Domestic_Form.pdf"
                                  target="_blank"
                                >
                                  {" "}
                                  Non Domestic Connection Application Form{" "}
                                </a>{" "}
                                for{" "}
                                <span className="cont_desd">
                                  Non Domestic Connection.{" "}
                                </span>{" "}
                                Submit it at the Head Office along with
                                necessary documents. In case any help is needed,
                                the person staffing the Reception Counter will
                                be glad to be of assistance to you.{" "}
                              </p>
                              <p>
                                Apart from the Application Form, your
                                application should contain the following
                                documents
                              </p>
                              <table className="table table-striped table-responsive">
                                <tr>
                                  <td>Customer Category</td>
                                  <td>Document Required</td>
                                </tr>
                                <tr>
                                  <td>Industrial</td>
                                  <td>
                                    B&amp;L Form <br />
                                    SSI Registration (in case of Small Scale
                                    Industries)
                                  </td>
                                </tr>
                                <tr>
                                  <td>Customer Category</td>
                                  <td>Document Required</td>
                                </tr>
                                <tr>
                                  <td>Agricultural</td>
                                  <td>
                                    {" "}
                                    Kisan Bahi copy - as proof of ownership
                                    <br />
                                    B&amp;L Form
                                    <br />
                                    No Objection Certificate from other owners
                                    of Tube well
                                  </td>
                                </tr>
                              </table>

                              <p className="cont_desd">Follow Through</p>

                              <ul>
                                <li>
                                  Once you have submitted the above documents,
                                  make sure you have collected an acknowledgment
                                  of receipt of the Form from the Reception.
                                </li>
                                <li>
                                  In case installation or alteration of service
                                  required, an inspection along with
                                  verification for previous outstanding dues may
                                  be carried out at site.
                                </li>
                                <li>
                                  Kindly ensure that you or your accredited
                                  representative is present at the site during
                                  the inspection.
                                </li>
                                <li>
                                  A provisional Offer-cum-Bill stating the terms
                                  and conditions for acceptance of your
                                  application and details of charges involved,
                                  shall be issued at the Head Office.{" "}
                                </li>
                                <li>
                                  You are required to kindly deposit the amount
                                  mentioned in Offer-cum-Bill to the Cashier at
                                  the Head Office.{" "}
                                </li>
                                <li>
                                  After all necessary terms and conditions are
                                  complied with; installation/alteration of
                                  connection will be carried out.{" "}
                                </li>
                                <li>
                                  Meter will then be fixed and sealed and supply
                                  subsequently energized.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade show active"
                          id="outagenotification"
                        >
                          <div className="innerhdnds">
                            <h2>
                              {" "}
                              <i
                                className="fa fa-calendar-o"
                                aria-hidden="true"
                              ></i>{" "}
                              Outage Notification
                            </h2>
                          </div>
                          <ul className="nav nav-tabs nav_tbds">
                            <li className="nav-item">
                              <Link
                                to="/get-outage-notification"
                                className="nav-link "
                                data-toggle="tab"
                              >
                                <i
                                  className="fa fa-bar-chart"
                                  aria-hidden="true"
                                ></i>
                                Industrial
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/institutional"
                                className="nav-link active"
                                data-toggle="tab"
                              >
                                <i
                                  className="fa fa-graduation-cap"
                                  aria-hidden="true"
                                ></i>
                                Institutional
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/township"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                <i
                                  className="fa fa-building-o"
                                  aria-hidden="true"
                                ></i>
                                Township
                              </Link>
                            </li>
                          </ul>
                          <h4 class="mt-2"> Institutional </h4>

                          <p class="nooutagedsabf">No Outage Available</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Institutional;
