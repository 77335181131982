import React from "react";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Announcement from "../components/Announcement";
import { Link } from "react-router-dom";
import { FcServices } from "react-icons/fc";
import { FcQuestions } from "react-icons/fc";
import { MdOutlineQuestionAnswer } from "react-icons/md";

const CustomerCareOffice = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/contact-banner.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/customer-care-office" className="active">
                      Customer Care Office
                    </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <ul className="nav nav-tabs nav_tbds">
                        <li className="nav-item">
                          <Link
                            to="/callcenter"
                            className="nav-link "
                            data-toggle="tab"
                          >
                            24*7 Call Centre
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            href="https://iwebapps.noidapower.com:8032/callcenter_email.aspx"
                            className="nav-link "
                            target="_blank"
                          >
                            Customer Care Email Id
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/customer-care-office"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            Customer Care Office
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/mobile-application"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Mobile Application
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/solar-rooftop-net-metering"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Solar Roof Top Net Metering
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <legend style={{ marginLeft: "30px" }}>
                    CUSTOMER CARE OFFICE
                  </legend>
                  <div className="custom_req">
                    <h3>Service Request</h3>
                    <ul className="newreq">
                      <li>
                        <FcServices style={{ fontSize: "20px" }} /> New Service
                        Connection
                      </li>
                      <li>
                        <FcServices style={{ fontSize: "20px" }} /> Load
                        Augmentation{" "}
                      </li>
                      <li>
                        <FcServices style={{ fontSize: "20px" }} /> Load
                        Reduction{" "}
                      </li>
                      <li>
                        <FcServices style={{ fontSize: "20px" }} /> Name
                        Transfer{" "}
                      </li>
                      <li>
                        <FcServices style={{ fontSize: "20px" }} /> Category
                        Change{" "}
                      </li>
                      <li>
                        <FcServices style={{ fontSize: "20px" }} /> Name &amp;
                        Address Correction{" "}
                      </li>
                      <li>
                        <FcServices style={{ fontSize: "20px" }} /> Meter
                        Testing{" "}
                      </li>
                      <li>
                        <FcServices style={{ fontSize: "20px" }} /> Meter
                        Shifting
                      </li>
                      <li>
                        <FcServices style={{ fontSize: "20px" }} />{" "}
                        Disconnection / Reconnection{" "}
                      </li>
                      <li>
                        <FcServices style={{ fontSize: "20px" }} /> Security
                        Deposit/Refund{" "}
                      </li>
                      <li>
                        <FcServices style={{ fontSize: "20px" }} /> KYC Updation{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="custom_req">
                    <h3>COMPLAINT/QUERIES</h3>
                    <ul className="newreq">
                      <li>
                        <MdOutlineQuestionAnswer
                          style={{ fontSize: "20px", color: "red" }}
                        />
                        Bill Related
                      </li>
                      <li>
                        {" "}
                        <MdOutlineQuestionAnswer
                          style={{ fontSize: "20px", color: "red" }}
                        />
                        Meter Related
                      </li>
                      <li>
                        <MdOutlineQuestionAnswer
                          style={{ fontSize: "20px", color: "red" }}
                        />{" "}
                        Supply Related{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="custom_req">
                    <h3>OFFICE ADDRESS & TIMINGS</h3>
                    <ul className="newreq2">
                      <li>
                        NPCL customer care office, Plot No. ESS, KP-1, Tugalpur,
                        Behind Kailash Hospital, Pincode:- 201308
                      </li>
                      <li>
                        NPCL customer care office, Plot No. ESS, Techzone-IV,
                        Opposite Cherry County Society, Greater Noida (West) –
                        201009
                      </li>
                      <li>
                        Working Time – 10:00 a.m. to 5:00 p.m. Monday to Friday
                        (Excluding national holidays), Lunch Timing (1:30 pm to
                        2:00 pm)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CustomerCareOffice;
