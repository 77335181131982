import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const KnowledgeCenter = () => {
  return (
    <>
      <div id="divaddid">
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="/assets/images/knowledge-center-banner.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Knowledge Center</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Portal</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/knowledge-center" className="active">
                      Knowledge Center
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <h2 className="online_heading">Knowledge Center</h2>

                    <div className="rediobuttonstyle">
                      <form className="form">
                        <div className="plan">
                          <ul className="box_online">
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/Consumer_HandbookNEW.pdf"
                                target="_blank"
                              >
                                <img
                                  src="assets/images/consumer-handbook.jpg"
                                  alt=""
                                />{" "}
                                Consumer Handbook
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/Manual_of_practice_for_handling_customer_complaints.pdf"
                                target="_blank"
                              >
                                <img
                                  src="assets/images/consumer-complaints.jpg"
                                  alt=""
                                />{" "}
                                Manual of Practice for handling Consumer
                                Complaints
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/SOP_GUIDELINES.pdf"
                                target="_blank"
                              >
                                <img
                                  src="assets/images/sop19-guidelines.jpg"
                                  alt=""
                                />{" "}
                                SOP'19 Guidelines
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/MyFolder/Discom_Dictionary.pdf"
                                target="_blank"
                              >
                                <img
                                  src="assets/images/DiscomDict.jpg"
                                  alt=""
                                />{" "}
                                DISCOM Dictionary
                              </a>
                            </li>
                            <li>
                              <a href="/KnowYourPrepaidMeter" target="_blank">
                                <img
                                  src="assets/images/preapidMEter.jpg"
                                  alt=""
                                />{" "}
                                Know your Prepaid Meter
                              </a>
                            </li>
                            <li>
                              <Link to="/third-party-meter-testing">
                                <img
                                  src="assets/images/meter-testing.jpg"
                                  alt=""
                                />{" "}
                                Third Party Meter Testing
                              </Link>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/holiday.aspx"
                                target="_blank"
                              >
                                <img src="assets/images/holiday.jpg" alt="" />{" "}
                                Holiday Calendar
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/NPCL_creatives.aspx"
                                target="_blank"
                              >
                                <img
                                  src="assets/images/consumer_awareness.jpg"
                                  alt=""
                                />{" "}
                                Consumer Awareness
                              </a>
                            </li>
                            <li>
                              <Link to="/faq">
                                <img src="assets/images/Faq.jpg" alt="" /> FAQ
                              </Link>
                            </li>
                            <li>
                              <Link to="/Document-Checklist">
                                <img
                                  src="assets/images/documentCheckList.jpg"
                                  alt=""
                                />{" "}
                                Document Checklist
                              </Link>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/sampledocument.aspx"
                                target="_blank"
                              >
                                <img
                                  src="assets/images/Sampledocument.jpg"
                                  alt=""
                                />{" "}
                                Sample Documents
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/calculate_energy.aspx"
                                target="_blank"
                              >
                                <img
                                  src="assets/images/CalYourLoad.jpg"
                                  alt=""
                                />{" "}
                                Calculate Your Load
                              </a>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default KnowledgeCenter;
