import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const TarrifSchedule = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/tarrif-schedule" className="active">
                      Tariff Schedule
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <ul className="nav nav-tabs nav_tbds">
                      <li className="nav-item">
                        <Link
                          to="/cost-data-book"
                          className="nav-link "
                          data-toggle="tab"
                        >
                          Cost Data Book
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/tarrif-schedule"
                          className="nav-link active"
                          data-toggle="tab"
                        >
                          Tariff Schedule
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/up-electricity-supply-code"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          UP Electricity Supply Code - 2005
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content tab_main">
                      <div className="tab-pane fade" id="login1">
                        <div className="main_wrappersd">
                          <div className="row">
                            <div className="col-md-7">
                              <div className="companysection">
                                <legend>COST DATA BOOK</legend>
                                <p>
                                  The Electricity Supply Code 2005, as notified
                                  by the honourable U.P. Electricity Regulatory
                                  Commission and as amended till date, has
                                  provided that applicants desiring new
                                  electricity connection are required to pay
                                  Processing Fee, Security Deposit,Line Charges
                                  and System Loading Charges.
                                </p>
                                <p>
                                  To ensure that all Distribution Licensees
                                  under UPERC following the same structure of
                                  charges while submitting estimates and
                                  subsequent invoices to applicants, the
                                  Honourable Commission has notified a Cost Data
                                  Book, detailing the approved rates based on
                                  which charges are to be estimated. This Cost
                                  Data Book along with attendant Corrigenda are
                                  provided below as PDF documents, so that you
                                  download and view the same for your reference.
                                </p>
                                <div className="cost_pdf">
                                  <img src="assets/img/pdf.png" alt="" />
                                  <a href="#">Cost Data Book</a>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="companyimg">
                                <img src="assets/img/cost-data.jpg" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane fade show active" id="login2">
                        <div className="row">
                          <div className="col-md-7">
                            <div className="companysection">
                              <legend>TARIFF SCHEDULE</legend>
                              <div className="cost_pdf">
                                <img src="assets/img/pdf.png" alt="" />

                                <a
                                  href="https://iwebapps.noidapower.com:8032/download/Tariff%20applicable%20from%206th%20June%202023.pdf"
                                  target="_blank" rel="noreferrer"
                                >
                                  Tariff applicable from 6th June 2023
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TarrifSchedule;
