import React from "react";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Announcement from "../components/Announcement";
import { Link } from "react-router-dom";

const CallCenter = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/contact-banner.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/callcenter" className="active">
                      Call Centre
                    </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <ul className="nav nav-tabs nav_tbds">
                        <li className="nav-item">
                          <Link
                            to="/callcenter"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            24*7 Call Centre
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            href="https://iwebapps.noidapower.com:8032/callcenter_email.aspx"
                            className="nav-link "
                            target="_blank"
                          >
                            Customer Care Email Id
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/customer-care-office"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Customer Care Office
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/mobile-application"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Mobile Application
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/solar-rooftop-net-metering"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Solar Roof Top Net Metering
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-content tab_main">
                    <div className="main_wrappersd">
                      <legend style={{ marginLeft: "16px" }}>
                        HELP IS JUST A PHONE CALL AWAY !
                      </legend>
                      <div style={{ marginLeft: "20px" }}>
                        <p>
                          Dial <b>0120- 6226666 /</b> for all your queries /
                          complaints
                        </p>
                        <img
                          src="assets/img/call_center.jpg"
                          alt="Call Center"
                          className="panel_img"
                        />
                        <p>
                          We request you to go through our Frequently Asked
                          Questions (FAQs) before calling , for finding out the
                          answers to your queries or doubts instantaneously.
                        </p>
                        <p>
                          You can also download our mobile app for lodging
                          complaints, outage notifications, downloading and
                          viewing last twelve bills and their payments.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CallCenter;
