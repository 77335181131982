import React  from "react";
import Announcement from "../components/Announcement";
import Slider from "react-slick";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";


const ClaimCompensationStatus = ()=>
  {
    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
      };
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/quick-e-bill-download.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="active">
                      Claim Compensation
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...settings2}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="our_other_links">
                        <h4>Other Links</h4>
                        <ul>
                          <li>
                            <Link to="#">
                              <span></span> Calculate Energy Consumption{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres" target="_blank">
                              <span></span>Payment Options{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online-vendor-registration"
                              target="_blank"
                            >
                              <span></span>Vendor Registration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Regulation &amp; Statutory Bodies{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                  <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
					<li className="nav-item"><Link to="/claimCompensation" className="nav-link" data-toggle="tab">Claim Compensation  </Link></li>
					<li className="nav-item"><Link to="/claimCompensationStatus" className="nav-link active" data-toggle="tab">Compensation Status  </Link></li>
				</ul>
                <div className="tab-content tab_main">
                <div className="tab-pane fade show active" id="login1">
										<form className="loginpgui">
											<legend>Claim Compensation Status</legend>
											<div className="col-md-12 form-group form_gp_des">
												<label className="form-label">Please Enter Your Complaint Number</label>
												<input type="text" className="form-control" placeholder="10 Digit Complaint Number" />
											</div>
                                            <div className="col-md-12 form-group form_gp_des">
												<button type="submit" className="btn btn-primary form-control">Submit</button>
											</div>
										</form>
									</div>
                  </div>
                  </div>     
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )}

export default ClaimCompensationStatus;
