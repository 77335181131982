import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const OnlineVendorRegistration = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" +
                "assets/img/inner/online-vendor-registration.jpg" +
                ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>

        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home</a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Online Vendor Registration
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/online-vendor-registration"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Online Vendor Registration
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/contact-for-eprocurement"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Contact for e-Procurement
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a href="https://iwebapps.noidapower.com:8032/powerProcAddr.aspx"
                        className="nav-link" target="_blank" rel="noreferrer"
                      >
                        Contact for Power Procurement
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/tenders"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Tenders
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content tab_main">
                    <div className="tab-pane fade show active" id="venderreg">
                      <div className="main_wrappersd">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="companysection">
                              <legend>ONLINE VENDOR REGISTRATION</legend>
                              <p>
                                Please click here to {""}
                                <a
                                  href="https://gtw1.noidapower.com:8098/supplier/registration"
                                  target="_blank" rel="noreferrer"
                                >
                                  Online Registration
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OnlineVendorRegistration;
