import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
const CorporateSocialResponsibility = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" +
                "assets/img/inner/corporatesocialresponsibility-banner.jpg" +
                ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Corporate Social responsibilitty
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/corporate-social-responsibility"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Corporate Social Responsibility
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/lifestyle-improvement"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Lifestyle Improvement
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/community-development"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Community Development
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/commitment-to-environment"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Commitment to Environment
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/csractivities"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        CSR Activities
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content tab_main">
                    <div className="tab-pane fade show active" id="login1">
                      <div className="main_wrappersd">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="companysection">
                              <legend>CORPORATE SOCIAL RESPONSIBILITY</legend>
                              <p>
                                The business philosophy of NPCL is to endeavour
                                to improve the social environment in which it
                                operates and is reflected in its ‘Corporate
                                Social Responsibility’ Statement, which is as
                                under:
                              </p>
                              <p>
                                “We shall strive to enhance the quality of life
                                in villages through active interventions in
                                basic literacy and community empowerment
                                programmes.”
                              </p>
                            </div>
                            <div className="companysection">
                            <p style={{ fontWeight: 'bold' }}>
                             Impact Assessment Report for FY24
                              </p>
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL_CSR Impact Assessment Report 2024.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                <img src="assets/img/pdf.png" alt="" />
                              </a>
                            </div>
                            {/* <ul>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL_CSR Impact Assessment Report 2024.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                              </a>
                            </li>
                            </ul> */}
                          </div>
                          <div className="col-md-7">
                          <div className="companyimg">

                          {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/DeUY2D1L_5Q?si=9vF583zyA8suzh2j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}

                          <video width="560" height="315" controls>
                <source src="https://iwebapps.noidapower.com:8032/download/NPCL CSR Film_Hindi.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CorporateSocialResponsibility;
