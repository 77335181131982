import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const ValuesBeliefs = () => {
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/visionmission.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <div className="topiconlist">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-sm-6 p-0">
                  <div className="anadd">
                    <div className="row justify-content-end p-0 m-0">
                      <div className="col-sm-3 p-0">
                        <h4>ANNOUNCEMENT</h4>
                      </div>
                      <div className="col-sm-9 p-0">
                        <div className="flex5258wrap fleright1">
                          <marquee>
                            <div className="sliderannoucement">
                              <a href="#">
                                UPERC Preventive Guidelines for Corona Virus
                              </a>
                            </div>
                            <div className="sliderannoucement">
                              <a href="#">
                                UPERC Preventive Guidelines for Corona Virus
                              </a>
                            </div>
                            <div className="sliderannoucement">
                              <a href="#">
                                UPERC Preventive Guidelines for Corona Virus
                              </a>
                            </div>
                            <div className="sliderannoucement">
                              <a href="#">
                                UPERC Preventive Guidelines for Corona Virus
                              </a>
                            </div>
                          </marquee>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 p-0">
                  <div className="flex5258wrap">
                    <marquee>
                      Important Notice for Owners / Allottees of Multi-Storied
                      Buildings in Greater Noida published on TOI & NBT dated
                      14th March 2021. Consumers can log complaint & avail
                      information by sending SMS to 7840002288 from their
                      registered Mobile No. Click{" "}
                      <a href="#" target="_blank">
                        here
                      </a>{" "}
                      to view the SMS short codes and their purpose. ----------
                      Single Phase consumers can now submit Self Meter Reading
                      with photo proof between 1st to 10th Day of every month,
                      click{" "}
                      <a href="#" target="_blank">
                        {" "}
                        here
                      </a>
                    </marquee>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/values-beliefs" className="active">
                      Our Values
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-3 imphdmdev">
                  <div className="inner_boxs">
                    <div className="mainwrapper">
                      <h4>Awards</h4>
                      <div className="slideraward slider">
                        <Slider {...settings2}>
                          <div className="awslider">
                            <img src="assets/img/awards-1.jpg" />
                          </div>

                          <div className="awslider">
                            <img src="assets/img/awards-3.jpg" />
                          </div>
                        </Slider>
                      </div>
                    </div>
                    <div className="our_other_links">
                      <h4>Other Links</h4>
                      <ul>
                        <li>
                          <Link to="#">
                            <span></span> Calculate Energy Consumption{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span></span>Payment Options{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/online-vendor-registration"
                            target="_blank"
                          >
                            <span></span>Vendor Registration{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <span></span>Regulation &amp; Statutory Bodies{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-9 bg_cols">
                  <div className="form_div">
                    <div className="tab-content tab_main">
                      <div className="tab-pane fade show active" id="login2">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="companysection">
                              <legend>OUR VALUES</legend>
                              <ul className="policy_cont">
                                <li>Customer First</li>
                                <li>Execution Excellence</li>
                                <li>Credibility</li>
                                <li>Agility</li>
                                <li>Risk-Taking</li>
                                <li>Humaneness</li>
                                <li>Sustainability</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ValuesBeliefs;
