import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import SvgDesign from "../components/SvgDesign";

const VissionMission = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage: "url(" + "assets/img/inner/vission.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Vision Mission
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <ul className="nav nav-tabs nav_tbds">
                      <li className="nav-item">
                        <Link
                          to="/vission-mission"
                          className="nav-link active"
                          data-toggle="tab"
                        >
                          Vision & Mission
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/ourValues"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          Our Values
                        </Link>
                      </li>
                      
                      <li className="nav-item">
                        <Link
                          to="/rpsg-group-vision-and-core-values"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          RPSG Group Vision and Core Values
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content tab_main">
                      <div className="tab-pane fade show active" id="login1">
                        <div className="main_wrappersd">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="companysection">
                                <legend>VISION & MISSION</legend>
                                <p>
                                  <b>Vision</b>
                                  <br />
                                  To become the most preferred power
                                  distribution utility in the country by
                                  ensuring Zero Defects in service delivery.
                                </p>
                                <p>
                                  <b>Mission</b>
                                  <br />
                                  To consistently innovate and improve business
                                  processes, implement the best available
                                  technologies to provide error-free services to
                                  the customers through an empowered, committed,
                                  and creative workforce.
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="companyimg">
                                <img src="assets/img/inner/our-value.jpg" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default VissionMission;
