import React from "react";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import { Link } from "react-router-dom";

const Sustainability = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="assets/images/Sustaianable.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1> Sustainability </h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                 
                  <li className="breadcrumb-item">
                    <Link to="/Sustainability" className="active">
                    Sustainability
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 bg_cols">
                    <div className="form_div wrapper-pd-top-login">

                      <div className="bs-example bs-example-1">
                        <div
                          className="tab-pane fade show active"
                          id="newconnection"
                        >
                          <div className="innerhdnds">
                          <legend style= {{ borderBottom:'5px  solid #21acff', padding:'20px'}}>  Sustainability </legend>
                
                          </div>
                         
                        </div>{" "}
                      </div>
                      <div className="tab-content tab_main" style={{marginLeft:'20px'}}>
                
                        
             
                        <div className="companysection">
               

                        <div className="row">
                          <div className="col-md-7">
                          <h3> Our ESG Vision:  Statement from our MD&CEO with Picture of MD sir  </h3>
                        
                            <strong className="wrapper-strong-style">
                              Powering lives. Empowering dreams Powering lives. Empowering dreams Powering lives. Empowering dreams.
                            </strong>

                            <div className="padd-top20">
                              <legend> Our ESG Priorities  </legend>
                              <strong className="wrapper-strong-style">
                              NPCL Materiality Index 
                              </strong>
                              
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="companyimg">
                              <img src="assets/img/Green-energyimage.jpg" alt="NPCL" />
                            </div>
                          </div>
                        </div>



                        <div className="row">
                        <div className="col-md-12">
                            <div className="companysection">
                              <legend>Environment Tab  </legend>
                              <strong className="wrapper-strong-style">
                              Climate Change:
                              </strong>
                            
                            </div>
                          </div>
                        <div className="col-md-6 padd-top20">
                            <img src="assets/img/Decarbonization.jpg" className="img-responsive" alt="NPCL" />
                          </div>


                        </div>
                 

                        <div className="row powertogrow">
                        <h3> SDG FOCUS:  Affordable and Green Energy  </h3>

                          <div className="col-md-6">
                      
                            <div className="bakpower">
                            <img src="assets/img/cleanEnergy.jpg" className="img-responsive" alt="NPCL" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="companysection">
                              <h3>  Climate Action </h3>
                              <img src="assets/img/climateAction.jpg" className="img-responsive" alt="NPCL" />
                              
                            </div>
                          </div>
                        </div>


                        <div className="row">
                        <legend> 2030 Target   </legend>

                          <div className="col-md-12">
                              <ul>
                                <li>  Rooftop Solar development of 10MWp  </li>
                                <li> Commitment of EV fleet conversion </li>
                                <li> Commitment of new substation as green certified substation </li>
                                <li> T&D Loss Reduction from 7.95% to 5.95% </li>
                            </ul>
                            <h3> Our Approach: </h3>
                            <div className="row">
                            <div className="col-sm-4  text-center"><img src="assets/img/REIntegration.jpg" className="img-responsive" alt="NPCL" />
                            <p className="padd-top15"> RE Integration  </p>
                            </div>
                            <div className="col-sm-4 text-center"><img src="assets/img/EV-Adoption.jpg" className="img-responsive" alt="NPCL" />
                            <p className="padd-top15">EV Adoption </p> </div>
                            <div className="col-sm-4  text-center"><img src="assets/img/LossReduction.jpg" className="img-responsive" alt="NPCL" />
                            <p className="padd-top15">T&D Loss Reduction </p> </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                          <div className="companysection">
                          <legend>Key Initiative:</legend>
                              <h3> 1.	Leveraging Rooftop Solar for sustainable Power:</h3>
                              <p>We have Developed 400Kwp rooftop solar on our 12 office and substations buildings. NPCL has 66 Number of substations which have potential of 18MWp rooftop solar. We are planning to develop 10 MWp rooftop solar by 2030. </p>
                              <h3> 2.	Green Building Certification: </h3>
                              <p> NPCL as a environment conscious company has converted ….. square feet of operating area into certified green spaces. Two of our offices are LEED Gold Standard Green certified building by USGBC. We are committed through our efforts to ensure that all new buildings are certified Green Buildings and retrofit of all existing building is as per the Green Building guidelines. </p>
                              <h3> 3.	Adoption of Electric Vehicles:  </h3>
                              <p> At NPCL we are deeply committed to creating a sustainable future for our planet. In line with our vision, we have set a bold and ambitious target to replace 80% of our operational fleet with Electric Vehicles (EVs) by the year 2030.  <br/>
                              
                              Our journey towards achieving this target will involve fostering partnerships with leading EV manufacturers, investing in charging infrastructure, and incentivizing sustainable practices for our employees and stakeholders.
                               </p>
                               <h3> 4.	T&D Loss Reduction:   </h3>
                              <p> Our unwavering commitment to operational excellence, continuous improvement, and innovative solutions has enabled us to achieve industry-leading T&D loss figures. As we forge ahead, we remain steadfast in our pursuit of excellence, striving to set new standards for T&D loss reduction and contributing to a sustainable energy future for our nation.
                               </p>

                     
                            </div>
                          </div>
                        </div>




                        <div className="row">

                        <legend> Water Management:    </legend>
                              <p> At NPCL, we firmly believe that water is a precious and finite resource that demands our utmost respect and responsible stewardship. We continuously strive to reduce our water intensity through various water conservation, improving water efficiency and implementing zero liquid discharge initiatives and maximizing reuse and recycle of freshwater.
                               </p>

                        <h3> SDG FOCUS  </h3>

                          <div className="col-md-12">
                          <h3>  Clean water and Sanitation </h3>
                  
                            <img src="assets/img/cleanwater.jpg" className="img-responsive" alt="NPCL" />
                          
                          </div>
                          
                        </div>

                        <div className="clearfix padd-top15"></div>
                        <div className="row">
                        <legend> 2030 Target   </legend>

                          <div className="col-md-12">
                              <ul>
                                <li> Water Neutral company by 2030   </li>
  
                            </ul>
                            <h3> Our Approach: </h3>
                            <div className="row">
                            <div className="col-sm-4  text-center"><img src="assets/img/RainWaterHarvesting.jpg" className="img-responsive" alt="NPCL" />
                            <p className="padd-top15"> Rain Water Harvesting    </p>
                            </div>
                            <div className="col-sm-4 text-center"><img src="assets/img/waterEfficinecy.jpg" className="img-responsive" alt="NPCL" />
                            <p className="padd-top15">Improving Water Efficiency </p> </div>
                            <div className="col-sm-4  text-center"><img src="assets/img/waterrecycle.jpg" className="img-responsive" alt="NPCL" />
                            <p className="padd-top15">Implementing Zero Liquid Discharge  </p> </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                          <div className="companysection">
                          <legend>Key Initiative:</legend>
                              <h3> Rain Water Harvesting System: </h3>
                              <p>Our Rainwater Harvesting Initiative aims to harness the power of nature's gift by capturing and storing rainwater for various beneficial purposes. We at NPCL has developed …… KL Water harvesting system on our office and substations.  </p>
                              <h3> Commissioning of 50KL STP  </h3>
               
                               
                            </div>
                          </div>
                        </div>



                        <div className="row padd-top20">
                                      
                        <legend> Environment Management:   </legend>
                              <p> NPCL strives to be a leader in sustainable business practices, paving the way for a thriving and resilient future for our planet. We are fully committed to integrating sustainable environmental management practices into every aspect of our operations, products, and services.
                               </p>
                        <h3> SDG FOCUS  </h3>

                          <div className="col-md-12">
                          <h3> Responsible consumption and production and Life of land </h3>
                  
                            <img src="assets/img/responsibleconsumption.jpg" className="img-responsive" alt="NPCL" />
                            <p className="padd-top20">At NPCL we believe that effective waste management is integral to our commitment to environmental responsibility and sustainability. We are committed to adopting responsible waste practices throughout our operations, with the aim of minimizing our environmental impact and promoting a circular economy.</p>
                          

                          </div>


                        </div>


                        <div className="row">
                        <div className="col-md-6">
                            <img src="assets/img/zerowaste.jpg" className="img-responsive" alt="NPCL" />
                          </div>
                        <div className="col-md-6">
                            <div className="companysection">
                              <h3>Key Initiative:   </h3>
                              <h5> 1.	Reverse Vending Machine Installation at NPCL Three Office building to recycle Used plastic PET bottle and Aluminium Can:   </h5>
                              <p> NPCL has installed Reverse Vending machine at three strategic location on basis of consumer footfall to recycle used plastic bottle in Feb 2023. Within 5 months of installation, 10000 Plastic pet bottles recycled and converted into useful product like T shirts, Caps and Bags.    </p>
                              <h5> 2.	Electronic Waste Drop Box for employees to drop their e waste:   </h5>
                              <p>NPCL has installed E-Waste Drop at their corporate and customer care office to provide channel to employees for sustainable disposal of electronic waste. Certain Monterey benefit (on basis of electronic item) and Green Warrior Certificate is also provided to all who disposed their electronic waste.   </p>
                              <h5>3.	Paper Recycle Bin near all printer to segregate paper waste for recycling:   </h5>
                              <p> NPCL has installed Paper recycling bin to promote paper waste segregation at source. All collected paper waste is getting recycled through approved recyclers and convert it into biodegradable stationary item. 
                               </p>
                               <h5>4.	Organic Waste De composter for food waste management:  </h5>
                              <p> NPCL has installed 25kg/day De composter to convert food waste/kitchen waste  from canteen to compost and divert waste from landfill. 
                               </p>
                            
                            </div>
                          </div>

                        </div>

                       
                        <div className="clearfix padd-top15"></div>



                        <div className="row">
                        <legend> Social Tab:    </legend>
                          <h3> Community Development: </h3>
                          <div className="col-md-12">
    
                            <h3> SDG Focus, Target, Approach   </h3>
                            <strong> Theme based CSR project and 2030 goals </strong>

                            <h3 className="padd-top15"> Workforce Prosperity  </h3>
                            <p> We remain steadfast in our belief that by nurturing our employees' growth, fostering inclusivity, prioritizing well-being, and upholding ethics we inspire trust in our employees and stakeholders.  </p>

                            <div className="row">
                             <h3> SDG Focus  </h3>
                             
                            <div className="col-sm-6  text-center"><img src="assets/img/RainWaterHarvesting.jpg" className="img-responsive" alt="NPCL" />
                            <p className="padd-top15"> Rain Water Harvesting    </p>
                            </div>
                            <div className="col-sm-6 text-center"><img src="assets/img/waterEfficinecy.jpg" className="img-responsive" alt="NPCL" />
                            <p className="padd-top15">Improving Water Efficiency </p> </div>
                           
                            </div>
                        </div>
                        <div className="col-md-12">
                          <div className="companysection">
                          <h3> 2030 Target  </h3>
                              <ul>
                                  <li>1.	15% Women participation in workforce </li>
                                  <li>2.	 </li>

                              </ul>
                              <h3>Approach </h3>

                            </div>
                          </div>
                        </div>
                       


                        <div className="clearfix padd-top15"></div>



                                <div className="row">
                                  <legend> Occupational Health and safety     </legend>
                                  <p> At NPCL, the health, safety, and well-being of our employees, contractors, visitors, and the communities in which we operate are paramount. We are committed to maintaining the highest standards of Occupational Health and Safety (OHS) to ensure a safe and secure working environment for everyone associated with our operations.  </p>
                                
                                  <div className="col-sm-3"><img src="assets/img/RainWaterHarvesting.jpg" className="img-responsive" alt="NPCL" /></div>
                                  <div className="col-sm-9">
                                    <div className="companysection">
                                    <h3> SDG Focus  </h3>
                                    <h3 className="padd-top20"> 2030 Target  </h3>
                      
                                      <strong>Zero Incident in workplace and Zero Incident resulting from logistics and transportation and installation/repair of equipment</strong>
                                        
                                      </div>
                                    </div>


                                   
                                  <div className="col-sm-6 padd-top20">
                                    <div className="companysection">
                                    <h3> Our approach   </h3>
                                    <h3 className="padd-top20">Key Initiative:    </h3>
                                 
                                      </div>
                                    </div>
                                    <div className="col-sm-6"><img src="assets/img/healthandOccu.jpg" className="img-responsive" alt="NPCL" /></div>
                                  </div>
             
 <div className="clearfix padd-top15"></div>



<div className="row">
  <legend> Human Rights      </legend>
  <p> At NPCL, we firmly believe in the inherent dignity and worth of every individual. Our commitment to human rights is unwavering, and we strive to ensure that these rights are upheld in every aspect of our operations and interactions. Our commitment encompasses the following aspects of human rights. </p>

  <div className="col-sm-12"><img src="assets/img/responsibleconsumption.jpg" className="img-responsive" alt="NPCL" /></div>
  <div className="col-sm-12">
    <div className="companysection">
    <h3> SDG Focus  </h3>
    <h3 className="padd-top20"> Corporate Governance   </h3>

     <p>At NPCL, we believe that strong corporate governance is the foundation of our success and the key to building trust among our stakeholders. NPCL's corporate governance framework is designed to safeguard our values, preserve our reputation, and contribute to sustainable growth.</p>
        <p>Ethical Conduct: We uphold the highest standards of ethical behaviour in all our interactions. Our directors, officers, and employees are expected to act with honesty, integrity, and fairness in every decision and action they undertake.</p>
        <p>Transparency: We are committed to transparent communication and reporting. Our financial disclosures, operational performance, and decision-making processes are open and accessible to our stakeholders.</p>
        <p>Accountability: We take responsibility for our actions and outcomes. Our management team is held accountable for achieving our strategic objectives while adhering to our values and ethical principles.</p>
        <p>Board of Directors: Our Board of Directors is dedicated to overseeing the company's affairs in the best interests of shareholders and stakeholders. We have a diverse and competent board, with independent directors who bring valuable expertise.</p>
        <p>Risk Management: We prioritize robust risk management practices to identify, assess, and mitigate potential risks that could impact our operations, financial performance, and reputation.</p>
        <p>Stakeholder Engagement: We engage with our stakeholders, including employees, communities, customers, and suppliers, to understand their needs, expectations, and concerns. This informs our decisions and contributes to our sustainable growth.</p>
        <p>Legal and Regulatory Compliance: We adhere to all applicable laws, regulations, and standards. Our compliance efforts extend to all aspects of our operations to maintain our ethical standing.</p>
        <p>Continuous Improvement: We are committed to continuous improvement in our corporate governance practices. We regularly review our policies and procedures to ensure they remain aligned with best practices and evolving expectations.</p>
      </div>
    </div>


   
  <div className="col-sm-6 padd-top20">
    <div className="companysection">
    <legend> Sustainability’, our 7th Pillar  </legend>
     <p>‘Sustainability’ as a pillar is the recent addition to our list of Values. It has always been a driving pillar for ensuring long term value creation among our stakeholders and is embedded into our way of doing business. This also sets a benchmark and boosts our competitiveness in the market.</p>

    <h3>SDG Focus:</h3>
      <div className="row">
      <div className="col-sm-6 padd-top15">
          <img src="assets/img/decent.jpg" className="img-responsive" alt="NPCL" />
      </div>
      <div className="col-sm-6 padd-top15">
          <img src="assets/img/peace.jpg" className="img-responsive" alt="NPCL" />
      </div>
    </div>
    </div>
  </div>
    <div className="col-sm-6"><img src="assets/img/core-valueSUS.jpg" className="img-responsive" alt="NPCL" /></div>
  </div>




                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Sustainability;
