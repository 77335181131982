import React,{useState} from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import Header from "../components/Header";
import Slider from "react-slick";
import FixMenu from "../components/FixMenu";

const KioskServices = () => {
  const [show,setShow] = useState(false);
  const [show1,setShow1] = useState(false);
  const [show2,setShow2] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed:1900,
  };
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/qms-real-time.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1> Kiosk Services</h1> 
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="KioskServices" className="active">
                    Kiosk Services
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                 
                  

                  <div className="row">
                  <legend> Kiosk Services </legend>


                    <div className="col-md-7">
                    
                      <h6>
                      The self-help Kiosk are installed at following locations; Techzone IV, Sigma IV, Ecotech II, Surajpur (Stores), KPII,KP-I and  Girdharpur Substation to enhance reach and ease to consumers.
                      </h6>
                      <p>Placement of Self-Help Kiosk at decentralized locations was aimed towards catering to 80% consumers base. Consumers can access 24*7 kiosk at nearby decentralized locations.</p>
                      <p>KIOSK service is categorized in 3 heads e.g. Information, Complaints and Services. Consumer can register complaints pertaining to Supply, Bill and Meter.
Consumer can easily track bill & payment history for last 12 months and also print duplicate bill copy on a stub.
</p>
<p>

FAQ and Document checklist related with all operation & commercial applications are available for consumers ease. Online payment option is also available for consumer. 
</p>    


                            <div className="tab-pane show active">
                              <ol className="kiosK">
                                <li>
                                    33/11kV Substation at Sigma-IV;
                                </li>
                                <li>
                                    33/11kV Substation at Ecotech-II;
                                </li>
                                <li>
                                    33/11kV Substation cum Store Building at Surajpur;
                                </li>
                                <li>
                                    33/11kV Substation at Knowledge Park-II;
                                </li>
                                <li>
                                    33/11kV Substation at Girdharpur
                                </li>
                                <li>
                                    Customer care office ,KP-I
                                </li>
                                <li>
                                    Customer care office ,Techzone-IV
                                </li>
                              </ol>
               
                            </div>
                          </div>
            
               
                    <div className="col-md-5">
                    <img src="assets/images/KioskServices.jpg" alt="Kiosk Services" className="img-responsive" />
                    </div>

                  </div> 
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default KioskServices;
