import React,{useState} from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Announcement from "../components/Announcement";

const AwardsRecognition = (props) => {
  const [readMore,setReadMore] = useState(false);

  const more = ()=>{
    setReadMore(!readMore)
  }
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/aboutus-inner.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Awards & Recognition
                    </a>
                    <span className="icondsds"></span>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <ul className="nav nav-tabs nav_tbds">
                      <li className="nav-item">
                        <Link
                          to="/company-profile"
                          className="nav-link "
                          data-toggle="tab"
                        >
                          Profile
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/energy-services"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          Energy Services
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/initiativeundertaken"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          Initiative Undertaken
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/our-customer"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          Our Customer
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/challangeahead"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          Challenges Ahead
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/awards-recognition"
                          className="nav-link active"
                          data-toggle="tab"
                        >
                          Award & Recognition
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/credit-rating"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          Credit Rating
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content tab_main">
                      <div className="tab-pane fade" id="login1">
                        <div className="main_wrappersd">
                          <div className="row">
                            <div className="col-md-7">
                              <div className="companysection">
                                <legend>Awards and Recognitions</legend>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="companyimg">
                                <img src="assets/img/npcl-profile.jpg" />
                              </div>
                            </div>
                          </div>
                          <div className="row powertogrow">
                            <div className="col-md-6">
                              <div className="bakpower">
                                <h3>
                                  Power to grow.
                                  <br />
                                  Power to do.
                                </h3>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="companysection">
                                <h3>OPERATIONS</h3>
                                <p>
                                  The Company executed an Agreement with the
                                  erstwhile U.P. State Electricity Board (now
                                  U.P. Power Corporation Limited) in November
                                  1993 for transfer of the supply arrangements
                                  and sourcing of bulk power. Reflecting a
                                  steady increase in consumer demand, it
                                  executed the peak load to 268 MW as against 17
                                  MW in 1994-95
                                </p>
                                <p>
                                  From 4677 in 1993 to 1,06,707 in March 2021,
                                  it has been witnessing an expansion in its
                                  customer base. Its load profile is primarily
                                  dominated by large and heavy industries,
                                  constituting 48% of energy sales. Urban,
                                  rural, institutional, and smaller industrial
                                  consumers account for the balance of the
                                  business.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane fade" id="login2">
                        <div className="row">
                          <div className="col-md-7">
                            <div className="companysection">
                              <legend>SERVICES</legend>
                              <p>
                                The principal role of Noida Power is that of a
                                service provider to support economic and
                                lifestyle activity. Building an efficient and
                                reliable delivery system thus assumes top
                                priority to provide supply dependability. That
                                Greater Noida is witness to the setting up of
                                world-className manufacturing by multinationals
                                like Honda Cars, Yamaha Motors, New Holland
                                Tractors, LG Electronics, ST Microelectronics,
                                India Exposition Mart, GSC Toughened Glass etc.,
                                and is also home to an upwardly mobile
                                residential population, underscores this point
                                further.
                              </p>
                              <p>
                                The Company maintains and operates
                                round-the-clock emergency services to deal with
                                supply-related complaints and undertake
                                rectification works. The facilities have been
                                reinforced by establishing a Call Centre that
                                provides 24-hour messaging services to consumers
                                and improves trouble call monitoring. Complaint
                                management is computerised, enabling call
                                tracking from start to finish and generation of
                                ‘exception’ reports.
                              </p>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="companyimg">
                              <img src="assets/img/npcl-service.jpg" />
                            </div>
                          </div>
                        </div>
                        <div className="row com_profile">
                          <div className="col-md-5">
                            <div className="companyimg">
                              <img src="assets/img/energy-pic.jpg" />
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="companysection">
                              <h2>Energy Services</h2>
                              <p>
                                Streamlined administrative procedures, on-time
                                delivery of new supply and billing consistency
                                are the other facets of service that define the
                                Company's inter-relationships with customers.
                                Organisational processes and hierarchical
                                responsibilities are designed to meet the demand
                                for service in every aspect, including
                                settlement of customer claims.
                              </p>
                              <p>
                                In addition to internal measurements, the
                                Company relies on independent surveys as an
                                annual feature to assess the customers’ feedback
                                on their perception of service quality. Based on
                                the findings, process and systemic deficiencies
                                are addressed to bridge the gap between expected
                                and actual performance levels.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane fade" id="login3">
                        <legend>INITIATIVE UNDERTAKEN</legend>
                        <div className="row com_profile">
                          <p>
                            It has been our aim to build our core competence
                            around service differentiation and build a
                            competitive organization on the strength of cost and
                            productive efficiency to ensure long term sustenance
                            in an environment that places a premium on both
                            service quality and cost accountability.
                          </p>
                          <p>
                            Unleashing innovation and imagination is the key to
                            business success in the competitive marketplace. A
                            first-time measure has been to introduce social
                            intermediation in villages to promote the concept of
                            energy conservation and commercialisation and
                            facilitate metering of domestic supplies against the
                            promise of reliable and affordable electricity
                            supply. Consumers have responded by demonstrating
                            commercial behaviour, resulting in avoidance of
                            energy thefts and improving the collection of user
                            charges. Currently, 89% of the domestic consumer
                            population in villages is covered under metered
                            supply. A unique intervention has been to organize
                            basic literacy classNamees for village women to
                            reinforce the role of electricity in improving lives
                            and enhancing gender development. Success of the
                            measures is seen from the comments made by the U.S.
                            Secretary of the Treasury, who visited one such site
                            and stated that “seeing first-hand the tangible
                            quality of life improvements brought by reliable
                            electricity was memorable.
                          </p>
                          <p>
                            Pole/feeder pillar number has been printed on the
                            majority of consumer bills for rendering faster and
                            better consumer services. The same number is also
                            available physically painted on the nearby
                            corresponding object.
                          </p>
                          <p>
                            Innovations in network and demand side engineering
                            have paid rich dividends in curtailing energy
                            losses. A novel concept has been to introduce
                            LT-less distribution or agricultural connections and
                            facilitate energy-efficient pumping so as to
                            conserve both energy and water and avoid
                            environmental degradation. In addition, introduction
                            of LT-insulated wires for supply to households in
                            villages has reduced the propensity of energy thefts
                            while optimising network performance. Process
                            interventions in the areas of metering and billing
                            have ensured that the loopholes for revenue leakages
                            are effectively plugged. The Company follows a
                            fail-safe system of electronic data transfer of
                            meter readings to the bill-processing module,
                            thereby dispensing with manual entries. Daily
                            collections are monitored on-line via the WAN and
                            LAN infrastructure. Currently, the energy losses
                            stand at 8%, the lowest in the country, while the
                            gross collection rate is 99%.
                          </p>
                          <p>
                            IT and Automation are relied upon to handle the
                            growing size of the business, while retaining the
                            focus on improving productivity, cycle times and
                            customer relationships. Major initiatives include
                            integrating network, customer and asset management
                            by leveraging geo-spatial information, undertaking
                            remote network sensing and operation in real time
                            and improving daily work management by automating
                            the business processes of billing, collection and
                            human resource management on SAP platform.
                            Integration of IT and Operational Technologies has
                            provided thrust to efficient network grid and asset
                            management across the company. GIS based systems
                            tightly integrated with various applications of SAP
                            have been implemented through which many
                            mission-critical business applications are
                            reengineered to provide better consumer services in
                            the areas of providing new service connections,
                            meter testing and replacement etc. Furthermore, the
                            GIS system enables the company to do proper asset
                            management for all the assets spread across a wide
                            geographical area. The Outage Management System,
                            which requires the GIS data to address outage
                            related complaints, has been made functional. The
                            GIS based system also caters to planning of newly
                            emerging sectors /areas with power distribution plan
                            along with construction of substation and able to
                            handle upcoming new connections. The company is able
                            to determine the technical losses utilizing GIS and
                            real time data. The GIS based Energy Audit system is
                            being implemented to measure the losses, direct the
                            efforts to curb the electricity theft and thus
                            contributing for overall loss reduction.
                          </p>
                          <p>
                            The span of ADMS encompasses entire distribution
                            network from substations till LT distribution at
                            consumer premises with the backdrop geographical
                            representation and requisite network information
                            sourced from GIS repository, which are subjected to
                            timely updates through systematic mechanism. All the
                            existing 33/11 kV Substations are completely
                            automated and SCADA-enabled resulting into all
                            unmanned remote operations. The ADMS environment
                            offers an array of Distribution Management System
                            (DMS) applications for executing complex analytics
                            engineering on authentic SCADA information and
                            Outage Management System (OMS) system integrated
                            with GIS system serves as a centralized repository
                            and management for all outage related complaints of
                            company’s power distribution network and interfaces
                            with customer facing system viz., CRM system and
                            SCADA system.
                          </p>
                          <p>
                            Building Management System is deployed at Company’s
                            major buildings such as Customer care buildings and
                            Corporate office.
                          </p>
                          <p>
                            Company has established state-of-the-art new
                            Technology Centre, which houses both IT and OT
                            infrastructure. The Centre is managed through
                            Building Management System (BMS). The Centre hosts
                            SCADA and BMS control rooms with round the clock
                            operations monitoring facility for power systems
                            network operations and building facility management
                            respectively. Furthermore, OMS dispatch centre along
                            with integrated customer call centre also operates
                            on round the clock basis through a different
                            building. All such facilities have dedicated
                            operators working shift wise to provide seamless
                            service delivery. Division offices are also equipped
                            with web view of SCADA.
                          </p>
                          <p>
                            The company has devised a concept of smart
                            substations which are equipped with all necessary
                            safety and security centric BMS applications viz.,
                            Fire Alarm system, CCTV surveillance, Access Control
                            , Electric Fencing, Public Address System, Energy
                            Management , Facility Management, Rodent Repellent,
                            Water Leak Detection, Wicket gate monitoring etc.
                            Presently, two numbers completely unmanned smart
                            substations are deployed on pilot basis with all
                            safety and security features as mentioned above and
                            more such deployments are expected in coming years.
                          </p>
                          <p>
                            The critical nodes on optical fibre communication
                            network of the company are continuously monitored
                            through centralized Fibre Management and Monitoring
                            system (FMMS) for immediate and accurate fault
                            detection and location. Company has also deployed
                            eight weather-monitoring stations at strategic
                            locations for better weather monitoring and
                            subsequent load planning. Smart grid technology of
                            FLISR (Fault Location, Isolation and Service
                            Restoration) and Self-Healing grid application has
                            been deployed on selected 11kV RMU networks, thus
                            enabling faster fault identification, isolation and
                            restoration of healthy network with supervised and
                            automated decisions respectively as per technology
                            selection. Company is foreseeing to expand its
                            coverage on further viable power distribution
                            networks.
                          </p>
                          <p>
                            Company is also actively engaged in Smart Grid
                            initiatives. Various projects deployed / under
                            deployment at 33Kv and 11Kv distribution network
                            viz., real time control and monitoring of RMUs,
                            Distribution Transformer health and load monitoring,
                            fault passage indicators, load break switches ,
                            deployment of smart street light controllers,
                            monitoring of captive and consumer renewable energy
                            plants and identified HV consumers with high demand
                            over SCADA system etc.
                          </p>
                          <p>
                            In its endeavour to explore and adopt new
                            technologies in the power sector, Company is
                            consistently keeping tap on latest developments in
                            upcoming technologies in power sector. One such
                            application is Intelligent Load Shedding that
                            provides a boost to network management for
                            maintaining grid discipline via optimizing the load
                            demand as per available power.
                          </p>
                          <p>
                            In addition, the company has entered into the regime
                            of Machine Learning and Network Intelligence via
                            deployment of Integrated Load Forecasting Module,
                            which helps in better forecast of load demand.
                          </p>
                          <p>
                            Chatbot “Saathi” based on Artificial Intelligence
                            technology has been launched on company website,
                            which responses on explicit types of enquiry via
                            simplified conversation format and thus providing an
                            enriching experience to visitor on website by
                            creating a virtual helpdesk.
                          </p>
                          <p>
                            Company has introduced real time battery health
                            monitoring system (BHMS) for substation auxiliary
                            batteries, and is among the first such utility to
                            achieve in power distribution sector. Next
                            generation technologies like IoT are under
                            consideration for applications like DT monitoring
                            etc.
                          </p>
                          <p>
                            In compliance to directives vide 13th amendment of
                            the Electricity Supply Code and as per Hon’ble UPERC
                            order, Company has started converting the existing
                            housing societies from single point to multipoint
                            connection using smart pre-paid meters with dual
                            register and dual recharge feature for Grid as well
                            as DG supply using the RF communication
                            infrastructure for two-way data communication. Also,
                            as per Hon’ble UPERC order, the new connections of
                            multi-storey residential buildings are being
                            released by Company in multiple point mode only for
                            supply of power to individual owners/occupants.
                          </p>
                          <p>
                            Company has successfully tested the “Drone”
                            technology via conducting a proof of concept with
                            the objective of inspection of overhead distribution
                            line for preventive maintenance and vegetation
                            management, to arrest the pilferage at low-tension
                            mains and also to update land base as and when
                            required. The drone data processing software has
                            already been successfully deployed at GIS lab of the
                            company. Now company is reaping benefits from the
                            Drone technology as per their defined use cases on
                            the regular basis.
                          </p>
                          <p>
                            A sophisticated smart grid lab has been deployed at
                            one of the Company’s office location for the purpose
                            of learning, visualization and testing off new smart
                            grid technologies viz. GIS applications, SCADA
                            operations, field area network automation systems,
                            peak load management system, demand response
                            systems, microgrid system, power quality monitoring,
                            intelligent load shedding, self-healing system etc.
                            The Lab also has a test bench to perform
                            troubleshooting of installed systems and testing of
                            new technologies in Lab environment. The Lab is
                            under continual upgradation.
                          </p>
                          <p>
                            Company is also envisaging new technologies and
                            innovation in order to meet utility requirement in
                            automotive manner, for that Company has successfully
                            deployed the technology of Virtual reality (VR) with
                            the aim to create interactive and immersive
                            applications i.e. 360⁰ VR experience of any assets
                            with realistic and new-fangled visualization use for
                            training purpose and showcasing our assets remotely.
                            This is one of the foremost implementations in power
                            distribution utility with in-house expertise and
                            skills in this niche technology.
                          </p>
                          <p>
                            Due consideration is necessary to develop the power
                            infrastructure and meet the demand escalation
                            commensurate with the business growth. New avenues
                            are being explored to procure power from alternative
                            sources and supplement the current intake
                            arrangement so as to meet the increase in load
                            demand in the short and medium terms.
                          </p>
                        </div>
                      </div>

                      <div className="tab-pane fade" id="login4">
                        <legend>Customer Profie</legend>
                        <div className="row com_profile">
                          <table className="table table-striped">
                            <tbody>
                              <tr>
                                <th>Category of Consumers</th>
                                <th colSpan="2" className="text-center">
                                  No. of Connections as on
                                </th>
                              </tr>
                              <tr>
                                <td></td>
                                <td className="text-center">
                                  <span className="high dar"> 15.12.93 </span>
                                </td>
                                <td className="text-center">
                                  <span className="high dar">31.03.2021 </span>
                                </td>
                              </tr>

                              <tr>
                                <td>Large &amp; Heavy Industry</td>
                                <td className="text-center">38</td>
                                <td className="text-center"> 858 </td>
                              </tr>
                              <tr>
                                <td>Small &amp; Medium Industry</td>
                                <td className="text-center">201</td>
                                <td className="text-center"> 3324 </td>
                              </tr>
                              <tr>
                                <td>Domestic Light, Fan &amp; Power Supply</td>
                                <td className="text-center">3224</td>
                                <td className="text-center"> 95855 </td>
                              </tr>
                              <tr>
                                <td>Institutions</td>
                                <td className="text-center">-</td>
                                <td className="text-center"> 520 </td>
                              </tr>
                              <tr>
                                <td>Private Tubewell Connections</td>
                                <td className="text-center">1188</td>
                                <td className="text-center"> 1249 </td>
                              </tr>
                              <tr>
                                <td>Commercial Establishments</td>
                                <td className="text-center">-</td>
                                <td className="text-center"> 3554 </td>
                              </tr>
                              <tr>
                                <td>State Tube well Connections</td>
                                <td className="text-center">25</td>
                                <td className="text-center"> 8 </td>
                              </tr>
                              <tr>
                                <td>Public Lighting</td>
                                <td className="text-center">1</td>
                                <td className="text-center"> 342 </td>
                              </tr>
                              <tr>
                                <td>Public Water Works</td>
                                <td className="text-center">-</td>
                                <td className="text-center"> 246 </td>
                              </tr>
                              <tr>
                                <td>Temporary Supplies</td>
                                <td className="text-center">-</td>
                                <td className="text-center"> 751 </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Total</strong>
                                </td>
                                <td className="text-center">
                                  <strong>4677</strong>
                                </td>
                                <td className="text-center">
                                  <strong> 106707 </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="tab-pane fade" id="login5">
                        <div className="row companysection com_profile">
                          <div className="col-md-7">
                            <legend>CHALLAGES AHEAD</legend>
                            <p>
                              The challenge ahead lies in nurturing and
                              retaining talent in an environment of increasing
                              private sector participation in utility business.
                            </p>
                            <p>
                              Institutionalising HR practices will be the major
                              task in building a performance-driven organisation
                              while pursuing the business goal of being the
                              least cost supplier at affordable price and
                              acceptable quality.
                            </p>
                            <p>
                              The aim is to build a responsive organisation
                              around people, processes and practices so as to
                              produce the least turn-around time, provide the
                              ‘best in className’ service to customers and
                              manage growth with profitability.
                            </p>
                          </div>
                          <div className="col-md-5">
                            <div className="companyimg">
                              <img src="assets/img/challages-ahead.jpg" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane fade show active" id="login6">
                        <legend>AWARDS & RECOGNITION</legend>
                        <p>
                          NPCL continuously strives towards ensuring
                          unparalleled services to the consumers through astute
                          planning, sheer dedication, and undying commitment.
                          Awards and recognitions come its way naturally as a
                          by-product of its efforts towards achieving
                          excellence.
                        </p>
                        <div className="row wrapper-option-style">
                          <div className="col-md-2">
                            <p style={{ textAlign: "center" }}>Select Year</p>
                          </div>
                          <div className="col-md-4">
                            <select
                              name="ctl00$ContentPlaceHolder1$ddlyear"
                              //   onChange="javascript:setTimeout('__doPostBack(\'ctl00$ContentPlaceHolder1$ddlyear\',\'\')', 0)"
                              //   onChange={setTimeout(
                              //     "__doPostBack('ctl00$ContentPlaceHolder1$ddlyear','')",
                              //     0
                              //   )}
                              id="ContentPlaceHolder1_ddlyear"
                              className="form-control slt_category"
                            >
                              <option
                                selected={props.selected}
                                value="IMG_2021_22"
                              >
                                FY 2021-22
                              </option>
                              <option value="IMG_2020_21">FY 2020-21</option>
                              <option value="IMG_2019_20">FY 2019-2020</option>
                              <option value="IMG_2018_19">FY 2018-2019</option>
                              <option value="IMG_2017_18">FY 2017-2018</option>
                              <option value="IMG_2016_17">
                                FY 2016-2017 and before
                              </option>
                            </select>
                            <ul></ul>
                          </div>
                        </div>
                        <div className="row">
                          <ul className="awards">
                            <li>
                              <div className="thumb">
                                <img
                                  id="myImg"
                                  src="assets/img/awards-1.jpg"
                                  alt="Snow"
                                  style={{ width: "100%", maxWidth: "300px" }}
                                />
                                <div id="myModal" className="modal">
                                  <span className="close">&times;</span>
                                  <img className="modal-content" id="img01" />
                                  <div id="caption"></div>
                                </div>
                                <h4>Winner</h4>
                                <div
                                  className="truncate hasHidden"
                                  data-originalcontent="<p className=&quot;lDiv &quot;>at '5th CII National Energy Efficiency Circle Competition 2021' under 'Best Energy Efficient Commercial Building / Hotels / Hospitals' category</p>"
                                >
                                  <p className="lDiv">
                                    of '5th CII National Energy Efficiency
                                    Circle Competition 2021' under the category
                                    of {readMore ? ('Best Energy Efficient Commercial Building / Hotels / Hospitals'
                                    ):null} 
                                  </p>
                                  <p onClick={more} style={{marginBottom:"10px",color: readMore ? "red":"blue",cursor:"pointer"}}>
                                      {readMore ? ('Show Less'):('Read More')} 
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="thumb">
                                <img
                                  id="myImg"
                                  src="assets/img/awards-1.jpg"
                                  alt="Snow"
                                  style={{ width: "100%", maxwidth: "300px" }}
                                />
                                <div id="myModal" className="modal">
                                  <span className="close">&times;</span>
                                  <img className="modal-content" id="img01" />
                                  <div id="caption"></div>
                                </div>
                                <h4>2nd rank</h4>
                                <div
                                  className="truncate hasHidden"
                                  data-originalcontent="<p className=&quot;lDiv &quot;>at '5th CII National Energy Efficiency Circle Competition 2021' under 'Best Energy Efficient Commercial Building / Hotels / Hospitals' category</p>"
                                >
                                  <p className="lDiv ">
                                    at 'ICC & TERI‘s First Edition of Green Urja
                                    Awards' under the category of 'DISCOM Grid
                                    Connected RE Power'
                                  </p>
                                  {/* <div className="read-more">
                                    <a href="#" className="more">
                                      Read More
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="thumb">
                                <img
                                  id="myImg"
                                  src="assets/img/awards-1.jpg"
                                  alt="Snow"
                                  style={{ width: "100%", maxWidth: "300px" }}
                                />
                                <div id="myModal" className="modal">
                                  <span className="close">&times;</span>
                                  <img className="modal-content" id="img01" />
                                  <div id="caption"></div>
                                </div>
                                <h4>1st rank </h4>
                                <div
                                  className="truncate hasHidden"
                                  data-originalcontent="<p className=&quot;lDiv &quot;>at '5th CII National Energy Efficiency Circle Competition 2021' under 'Best Energy Efficient Commercial Building / Hotels / Hospitals' category</p>"
                                >
                                  <p className="lDiv ">
                                    at 'ICC & TERI‘s First Edition of Green Urja
                                    Awards' under the category of 'DISCOM Grid
                                    Connected Roof top Solar'
                                  </p>
                                  {/* <div className="read-more">
                                    <a href="#" className="more">
                                      Read More
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="thumb">
                                <img
                                  id="myImg"
                                  src="assets/img/awards-1.jpg"
                                  alt="Snow"
                                  style={{ width: "100%", maxwidth: "300px" }}
                                />
                                <div id="myModal" className="modal">
                                  <span className="close">&times;</span>
                                  <img className="modal-content" id="img01" />
                                  <div id="caption"></div>
                                </div>
                                <h4>3rd rank </h4>
                                <div
                                  className="truncate hasHidden"
                                  data-originalcontent="<p className=&quot;lDiv &quot;>at '5th CII National Energy Efficiency Circle Competition 2021' under 'Best Energy Efficient Commercial Building / Hotels / Hospitals' category</p>"
                                >
                                  <p className="lDiv ">
                                    at 'ICC 8th Innovation with Impact Awards
                                    for Discoms’ for ‘Quality of Service &
                                    Customer Empowerment’
                                  </p>
                                  {/* <div className="read-more">
                                    <a href="#" className="more">
                                      Read More
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="thumb">
                                <img
                                  id="myImg"
                                  src="assets/img/awards-1.jpg"
                                  alt="Snow"
                                  style={{ width: "100%", maxwidth: "300px" }}
                                />
                                <div id="myModal" className="modal">
                                  <span className="close">&times;</span>
                                  <img className="modal-content" id="img01" />
                                  <div id="caption"></div>
                                </div>
                                <h4>1st rank </h4>
                                <div
                                  className="truncate hasHidden"
                                  data-originalcontent="<p className=&quot;lDiv &quot;>at '5th CII National Energy Efficiency Circle Competition 2021' under 'Best Energy Efficient Commercial Building / Hotels / Hospitals' category</p>"
                                >
                                  <p className="lDiv ">
                                    at 'ICC 8th Innovation with Impact Awards
                                    for Discoms’ for ‘Technology Adoption’
                                  </p>
                                  {/* <div className="read-more">
                                    <a href="#" className="more">
                                      Read More
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="thumb">
                                <img
                                  id="myImg"
                                  src="assets/img/awards-1.jpg"
                                  alt="Snow"
                                  style={{ width: "100%", maxwidth: "300px" }}
                                />
                                <div id="myModal" className="modal">
                                  <span className="close">&times;</span>
                                  <img className="modal-content" id="img01" />
                                  <div id="caption"></div>
                                </div>
                                <h4>2nd runner up </h4>
                                <div
                                  className="truncate hasHidden"
                                  data-originalcontent="<p className=&quot;lDiv &quot;>at '5th CII National Energy Efficiency Circle Competition 2021' under 'Best Energy Efficient Commercial Building / Hotels / Hospitals' category</p>"
                                >
                                  <p className="lDiv ">
                                    at ‘33rd Quality Circle Competition:
                                    Regional Final’ in ‘Quality Circle Category’
                                    for the project
                                  </p>
                                  {/* <div className="read-more">
                                    <a href="#" className="more">
                                      Read More
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="tab-pane fade" id="login7">
                        <legend>CREDIT RATING</legend>
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              NPCL is one of select few distribution Company in
                              India to be assigned “AA” category of Credit
                              Rating. Its credit rating & outlook for Long Term
                              facilities have been upgraded 4 times in 5 years.
                              NPCL also secured highest credit rating of A1+ for
                              its short term borrowings moving up from initial
                              rating of A2+ within 5 years only. NPCL’s has been
                              assigned Credit Ratings under Basel-II norms by
                              India Ratings & Research (formerly Fitch Ratings).
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AwardsRecognition;
