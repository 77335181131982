import React from 'react'
import Announcement from "../components/Announcement";
import Slider from "react-slick";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";

const EasyWaysToLowerYourBill = () => {
    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
      }
  return (
    <>
        <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/login" className="active">
                    DO'S & DON'TS
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...settings2}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="our_other_links">
                        <h4>Other Links</h4>
                        <ul>
                          <li>
                            <Link to="#">
                              <span></span> Calculate Energy Consumption{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres" target="_blank">
                              <span></span>Payment Options{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online-vendor-registration"
                              target="_blank"
                            >
                              <span></span>Vendor Registration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Regulation &amp; Statutory Bodies{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div">
                    <div class="main_wrappersd">
								<div class="profile_cont">
                                <h2>EASY WAYS TO LOWER YOUR BILL
									</h2>
									<p><b>Save Electricity And Check Wastage
									</b></p>
									<ol>
										<li>Use more tube-lights in place of electric bulbs.
										</li>
										<li>Switch off lights and fans etc. while leaving the room.
										</li>
										<li>Keep the bulbs clean for better lighting.
										</li>
										<li>Do not open the fridge-door unnecessarily time and again.
										</li>
										<li>Use shunt capacitors for induction motor of 3 HP and above, and for welding transformer of 1 kVA and above.
										</li>
										<li>Do not use inferior quality material for electrical wiring installation. Use only ISI marked items to reduce electricity consumption and avoid possibility of accident.
										</li>
										<li>Install Bureau of Energy Efficiency (BEE) labeled equipment, which specifies energy efficiency, to reduce energy consumption.
										</li>
										<li>All establishments shall conduct energy audit through an accredited energy auditor in such manner and intervals as specified under the Energy Conservation Act 2001 and regulations under the Act. Directions of Energy Auditor shall be complied with for the purpose energy conservation.
										</li>
										<li>Comply with the provisions of the Energy Conservation Act 2001 and the regulations under the Act, for better energy usage and conservation.
										</li>
									</ol>
									<hr />
									<p><b>Tame the summer......save power in the bargain
									</b></p>
									<ol>
										<li>Use ceiling or table fan as the first line of defence against summer heat. Ceiling fans, for instance, cost about 30 paise an hour to operate – much less than air-conditioners (Rs.10.00 per hour).
										</li>
										<li>Set the thermostat of the air-conditioner at 25 degrees centigrade (77.F degree Farenheit) for least cost. The energy consumption will be three to five per cent less with each degree about 22oC (71.5oF).
										</li>
										<li>Using ceiling or room fans allow you to set the thermostat higher because the air movement will cool the room.
										</li>
										<li>A good air-conditioner will cool and dehumidify a room in about 30 minutes, So use a timer and leave the unit off for some time.
										</li>
										<li>Keep doors in air-conditioned rooms closed as often as possible.
										</li>
										<li>Clean ACs once a month. Clean filters to enable the unit to cool down quickly and use less energy.
										</li>
										<li>Get your internal wiring checked by a licensed electrical contractor before installing an air-conditioner.
										</li>
										<li>Contract the NPCL office and apprise him of the intended increase in load.
										</li>
									</ol>
									<p><b>SAVING ELECTRICITY IS PRODUCING ELECTRICITY
									</b></p>
								</div>
							</div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section>
        </div>
    </>
  )
}

export default EasyWaysToLowerYourBill