import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";

const CreditRating = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/about-banner.png" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <div className="innerpagebanner"></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home</a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/" className="active">
                      Credit Rating
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/company-profile"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Profile
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/energy-services"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Energy Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/initiativeundertaken"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Initiative Undertaken
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/our-customer"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Our Customer
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/challangeahead"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Challenges Ahead
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://iwebapps.noidapower.com:8032/awards_recognition.aspx"
                        target="_blank"
                        className="nav-link"
                        //data-toggle="tab"
                      >
                        Award & Recognition
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/credit-rating"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Credit Rating
                      </Link>
                    </li>
                  </ul>

                  <legend>CREDIT RATING</legend>
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        NPCL is one of select few distribution Company in India
                        to be assigned “AA” category of Credit Rating. Its
                        credit rating & outlook for Long Term facilities have
                        been upgraded 4 times in 5 years. NPCL also secured
                        highest credit rating of A1+ for its short term
                        borrowings moving up from initial rating of A2+ within 5
                        years only. NPCL’s has been assigned Credit Ratings
                        under Basel-II norms by India Ratings & Research
                        (formerly Fitch Ratings).
                      </p>
                      <p><a className="lingcoldfsds"
                      href="http://iwebapps.noidapower.com:8032/download/NPCL Rating.pdf"
                      target="_blank" rel="noreferrer"
                    ><img src="assets/img/pdf.png" alt="" />  Please click here to download </a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CreditRating;
