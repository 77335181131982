import React from 'react'
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";

const SafetyRelatedComplaintStatus = () => {
  return (
    <>
    <div id="bodyaddid">
    <Header />
   <FixMenu />
    <section className="consumer_inner_bg"  style={{
             background: "url(" + "assets/images/safety-related-complaint-banner.jpg" + ")",
             backgroundSize: "100% auto", height:"400px"
           }}>
   <div className="container-fluid">
   <div className="apply_title">
   <h1>Safety Related Complaint</h1>
   </div>
   </div>
   </section>

    <section className="main_sectiods">
    <div className="container">
      <div className="row"> 
      <div className="col-lg-12 brdcum_designs">
      <ol className="breadcrumb">
                   <li className="breadcrumb-item">
                       <Link to="#">Home</Link><span className="icondsds"><i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                   </li>
                   <li className="breadcrumb-item">
                       <Link to="/consumer-portal">Consumer Portal</Link><span className="icondsds"><i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                   </li>
                   <li className="breadcrumb-item">
                       <Link to="/register-complain">Register a Complaint</Link><span className="icondsds"><i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                   </li>
                   <li className="breadcrumb-item">
                       <Link to="#" className="active">Safety Related Complaint</Link>
                   </li>
               </ol>
      </div>
      </div>
      <div className="row justify-content-center padtops">
      <div className="col-sm-12">
        <div className="row">
        <div className="col-sm-12 bg_cols">
        <div className="form_div">
        <div className="tab-content tab_main">
        <div className="tab-pane fade show active" id="newconnection">
        <div className="innerhdnds">
                <h2>Safety Related Complaint</h2>
        </div>
        <ul className="nav nav-tabs nav_tbds">
        <li className="nav-item">
            <Link to="/safety-related-complaint" className="nav-link " data-toggle="tab">Register</Link>
        </li>
         <li className="nav-item">
            <Link to="/safety-related-complaint-status" className="nav-link active" data-toggle="tab">Status</Link>
        </li>
        <li className="nav-item">
            <Link to="/safety-related-complaint-feedback" className="nav-link" data-toggle="tab">Feedback</Link>
        </li>
        <li className="nav-item">
            <Link to="/safety-related-complaint-cmp" className="nav-link" data-toggle="tab">Complaint Management Process (Guide)</Link>
        </li>
		<li className="nav-item">
            <Link to="/safety-related-complaint-faq" className="nav-link " data-toggle="tab">FAQs</Link>
        </li>
                        </ul>

        <div className="tab-pane " id="status" style={{marginLeft:"20px"}}>    		
			<h4 clasName="mt-2">Status </h4>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
      </div>
      </div>
    </div>
    </section>
    </div>
   </>
  )
}

export default SafetyRelatedComplaintStatus