import React from "react";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const AllotteesMultiStoriedBuildings = () => {
  return (
    <>
      <Header />
      <FixMenu />
      <div id="bodyaddid">
        <section
          className="main-banner innerpagebanner innerwidths"
          style={{
            background: "url(" + "assets/img/inner/loginpage.jpg" + ")",
            backgroundSize: "100% auto",
          }}
        ></section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider slick-initialized slick-slider">
                          <div className="slick-prev slick-arrow">
                            <i className="fa fa-angle-left"></i>
                          </div>
                          <div
                            className="slick-list draggable"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="slick-track"
                              style={{
                                opacity: "1",
                                width: "1326px",
                                transform: "translate3d(-663px, 0px, 0px)",
                              }}
                            >
                              <div
                                className="slick-slide slick-cloned"
                                data-slick-index="-2"
                                id=""
                                aria-hidden="true"
                                tabIndex="-1"
                                style={{ width: "221px" }}
                              >
                                <div>
                                  <div
                                    className="awslider"
                                    style={{
                                      width: "100%",
                                      display: "inline-block",
                                    }}
                                  >
                                    <img src="assets/img/awards-1.jpg" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="slick-slide slick-cloned slick-center"
                                data-slick-index="-1"
                                id=""
                                aria-hidden="true"
                                tabIndex="-1"
                                style={{ width: "221px" }}
                              >
                                <div>
                                  <div
                                    className="awslider"
                                    style={{
                                      width: "100%",
                                      display: "inline-block",
                                    }}
                                  >
                                    <img src="assets/img/awards-3.jpg" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="slick-slide"
                                data-slick-index="0"
                                aria-hidden="true"
                                tabIndex="-1"
                                style={{ width: "221px" }}
                              >
                                <div>
                                  <div
                                    className="awslider"
                                    style={{
                                      width: "100%",
                                      display: "inline-block",
                                    }}
                                  >
                                    <img src="assets/img/awards-1.jpg" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="slick-slide slick-current slick-active slick-center"
                                data-slick-index="1"
                                aria-hidden="false"
                                style={{ width: "221px" }}
                              >
                                <div>
                                  <div
                                    className="awslider"
                                    style={{
                                      width: "100%",
                                      display: "inline-block",
                                    }}
                                  >
                                    <img src="assets/img/awards-3.jpg" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="slick-slide slick-cloned"
                                data-slick-index="2"
                                id=""
                                aria-hidden="true"
                                tabIndex="-1"
                                style={{ width: "221px" }}
                              >
                                <div>
                                  <div
                                    className="awslider"
                                    style={{
                                      width: "100%",
                                      display: "inline-block",
                                    }}
                                  >
                                    <img src="assets/img/awards-1.jpg" />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="slick-slide slick-cloned"
                                data-slick-index="3"
                                id=""
                                aria-hidden="true"
                                tabIndex="-1"
                                style={{ width: "221px" }}
                              >
                                <div>
                                  <div
                                    className="awslider"
                                    style={{
                                      width: "100%",
                                      display: "inline-block",
                                    }}
                                  >
                                    <img src="assets/img/awards-3.jpg" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="slick-next slick-arrow">
                            <i className="fa fa-angle-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div cashcollectioncenter">
                      <legend>
                        Single Point to Multipoint Connection for Multi Storied
                        Building
                      </legend>
                      <p>
                        <b>
                          Important Notice for Owners / Allottees of
                          Multi-Storied Buildings in Greater Noida
                        </b>
                      </p>
                      <p>
                        Advertisement published on TOI & NBT dated 1st November
                        2020
                      </p>
                      <p>
                        Pursuant to the 13th Amendment of U.P. Electricity
                        Supply Code 2005, this is to inform that the following
                        Multi-storied Buildings / Housing Societies, currently
                        having Single Point Electricity Connection will be
                        converted into Multi-point Individual Connections, as
                        under:"
                      </p>

                      <table
                        className="table table-responsive table-bordered"
                        style={{ border: "1px solid #ddd", fontSize: "12px" }}
                      >
                        <tbody>
                          <tr>
                            <th>SL. NO.</th>
                            <th>SOCIETY / CONSUMER NAME; ADDRESS</th>
                            <th>Estimated Time for Conversion #</th>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>
                              CELESTIAL PALACE; GRAHALAXMI SAS PVT. LTD.; PLOT
                              NO.-005, SECTOR 32 PI, CASIA ESTATE
                            </td>
                            <td>Under Conversion</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>
                              JAMIA APARTMENTS; JMI PROFESSIONAL EMPLOYEES
                              WELFARE SOCIETY; PLOT NO.-2, SECTOR-32 PI
                            </td>
                            <td>1 Month</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>
                              AMBE BHARTI PATRAKAR SAS LTD.; PLOT NO.-9,
                              SECTOR-32 PI
                            </td>
                            <td>1 Month</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>
                              EDANA; INOX AIR PRODUCTS PVT. LTD. (GROUP
                              HOUSING); PLOT NO.-GH-04, SECTOR-ALPHA-1
                            </td>
                            <td>2 Months</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>
                              AIG PARK AVENUE; AIG INFRATECH (INDIA). PVT. LTD.;
                              PLOT NO. GC-02/GH-01, SECTOR-4
                            </td>
                            <td>2 Months</td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>
                              ANAND ASHRAY; C.C.S.E. SAHAKARI
                              <span>&nbsp; </span>AWAS SAMITI
                              <span>&nbsp; </span>LTD.; PLOT NO.-GH-007A,
                              SECTOR-PHI-02
                            </td>
                            <td>2 Months</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>
                              HINDUSTAN PETROLIUM COOPERATIVE HOUSING; PLOT
                              NO.-14, SECTOR-32 PI
                            </td>
                            <td>2 Months</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>
                              POWER OFFICERS SAHAKARI AWAS SAMITI; PLOT NO 14,
                              POCKET P2
                            </td>
                            <td>2 Months</td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>
                              NRI CITY (PLOTTED); OMAXE LTD; PLOT NO.-GH-001,
                              SECTOR-OMEGA-1
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>
                              KENDRIYA LOKNIRMAN SAS LTD.; PLOT NO.-10, POCKET
                              P4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>11</td>
                            <td>
                              MEENAKSHI SAHAKARI AWAS SAMITI; PLOT NO.-7,
                              SECTOR-32 PI
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>12</td>
                            <td>
                              PRASAR KUNJ SAHAKARI AWAS SAMITI; PLOT NO.-8,
                              SECTOR-32 PI
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>13</td>
                            <td>
                              BANK EMPLOYEE SAS LTD.; PLOT NO 1, SECTOR-32 PI
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>14</td>
                            <td>
                              ANS APARTMENTS PVT. LTD.; PLOT NO.-10, SECTOR-33
                              PI-1&amp;2
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>15</td>
                            <td>
                              BSNL OSAS LIMITED; PLOT NO.-73, SECTOR - SIGMA-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>16</td>
                            <td>
                              SAYA ZION; SAYA BUILDCON CONSORTIUM PVT. LTD.;
                              GH-01, SECTOR-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>17</td>
                            <td>
                              ECOVILLAGE-1; SUPERTECH LTD.; GH-08, SECTOR-1
                              (Partially Completed Project)
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>18</td>
                            <td>UNITECH HORIZON; PLOT NO. 6, SECTOR -PI</td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>19</td>
                            <td>
                              ACE CITY; CREST PROMOTERS PVT. LTD.; PLOT
                              NO.-GH-01, SECTOR-1
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>
                              GRAND FORT; SATILILA SAS LTD.; PLOT NO.-76,
                              SECTOR-SIGMA-4
                            </td>
                            <td>2 Months</td>
                          </tr>
                          <tr>
                            <td>21</td>
                            <td>
                              LOTUS VILLA; RENOWNED BUILDTECH PVT. LTD.; K.N.
                              215-234, JALPURA
                            </td>
                            <td>2 Months</td>
                          </tr>
                          <tr>
                            <td>22</td>
                            <td>
                              SPRING MEADOWS; PIGEON BUILDHOME PVT. LTD.;
                              GH-07A, SECTOR-TECHZONE-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>23</td>
                            <td>
                              ECOVILLAGE-2; SUPERTECH LTD.; GH-01, SECTOR-16B
                              (Partially Completed Project)
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>
                              ELDECO MYSTIC GREEN; ELDECO INFRASTRUCTURE PVT.
                              LTD.; PLOT NO.-GH-003, SECTOR- OMICRON-I
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>25</td>
                            <td>
                              CZAR; SUPERTECH LTD.; GH-02, SECTOR-OMICRON-1
                              (Incomplete elect. Infra)
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>26</td>
                            <td>
                              ATS DOLCE; DOMUS GREEN PVT. LTD.; PLOT NO.-GH-12A,
                              SECTOR-ZETA-I (Ph-II Under Construction)
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>27</td>
                            <td>
                              FRENCH APARTMENTS; ANTHEM INFRASTRUCTURE PVT LTD.;
                              PLOT NO.-GH-07B, SECTOR-16B
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>28</td>
                            <td>
                              SAMRIDHI GRAND; SAMRIDHI REALTY HOMES PVT. LTD.;
                              PLOT NO.-GH-09D, SECTOR-TECHZONE-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>29</td>
                            <td>
                              HIMALAYA PRIDE; HIMALAYA REALESTATE PVT. LTD.;
                              GH-10B, SECTOR-TECHZONE-4
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>30</td>
                            <td>
                              PANCHSHEEL HYNISH; PANCHSHEEL BUILDTECH PVT.LTD.;
                              PLOT NO.-GH-08A, SECTOR-1
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>31</td>
                            <td>VALENCIA HOMES; PLOT NO.-GH-07B, SECTOR-1</td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>32</td>
                            <td>
                              SAVIOUR GREEN ARCH; NEW WAY HOMES PVT. LTD.; PLOT
                              NO.-GH-10A, SECTOR-TECHZONE-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>33</td>
                            <td>
                              ARIHANT ARDEN; ARIHANT ARDEN ASSOCIATION OF
                              APARTMENT OWNERS; PLOT NO.-GH-07A, SECTOR-1
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>34</td>
                            <td>
                              NIRAL ASPIRE; NIRALA HOUSING PVT. LTD.; PLOT NO.
                              GH-03, SECTOR 16
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>35</td>
                            <td>
                              10TH AVENUE, GAUR CITY -2; GAURSONS PROMOTERS
                              PVT.LTD.; PLOT NO.-GC-10/GH-3, SECTOR-16C
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>36</td>
                            <td>
                              GALAXY VEGA; PANCH TATVA PROMOTERS PVT. LTD.; PLOT
                              NO.-GH-08C, SECTOR-TECHZONE-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>37</td>
                            <td>
                              PANCHSHEEL GREENS; PANCHSHEEL BUILDTECH PVT.LTD.
                              (GREENS); PLOT NO.-GH-001A, SECTOR-16B
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>38</td>
                            <td>
                              GALAXY NORTH AVENUE-II; GALAXY DREAM HOME
                              DEVELOPERS; PLOT NO.-GC/3D, GH-3, SECTOR-16C
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>39</td>
                            <td>
                              1ST AVENUE, GAUR CITY -1; GAURSONS HI TECH
                              INFRASTRUCTURE PVT. LTD.; PLOT NO.-GC-01/GH-1,
                              SECTOR-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>
                              PRISTINE AVENUE; GEOTECH HOMZ PVT. LTD.; PLOT
                              NO.-GC-3F/GH-3, SECTOR-16C
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>41</td>
                            <td>
                              STELLAR MI CITIHOMES; SPRINGLEA DEVELOPERS PVT.
                              LTD.; PLOT NO.-GH-07A, SECTOR-OMICRON-III,
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>42</td>
                            <td>
                              PARSVNATH ESTATE RESIDENT WELFARE ASSOCIATION;
                              PLOT NO.-BRS-08, POCKET P2
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>43</td>
                            <td>
                              AVJ HOMES; AVJ DEVELOPERS<span>&nbsp; </span>PVT.
                              LTD.; PLOT NO.90, SECTOR-BETA-2
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>44</td>
                            <td>
                              PALM OLYMPIA; SAM INDIA ABHIMANYU HOUSING; PLOT
                              NO.-GH-02, SECTOR-16C
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>45</td>
                            <td>
                              14TH AVENUE, GAUR CITY -2; GAURSONS PROMOTERS PVT.
                              LTD.; PLOT NO.-GC-14/GH-3, SECTOR-16C
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>46</td>
                            <td>
                              16TH AVENUE, GAUR CITY-2; GAURSONS PROMOTERS
                              PVT.LTD.; PLOT NO.-GC-16/GH-3, SECTOR-16C
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>47</td>
                            <td>
                              ARIHANT AMBER; CITYCON BUILDWELL PVT LTD; GH-16C,
                              SECTOR-01
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>48</td>
                            <td>
                              FUSION HOMES; FUSION BUILDTECH PVT. LTD.; PLOT
                              NO.-GH-05A,SECTOR-TECHZONE-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>49</td>
                            <td>
                              TRIDENT HOMES; TRIDENT INFRAHOMES PVT. LTD.; PLOT
                              NO.-GH-05B, SECTOR-1
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>50</td>
                            <td>
                              SKA GREEN MANSION; SKA REALTECH PVT. LTD.; PLOT
                              NO.-GH-01N, SECTOR-12
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>51</td>
                            <td>
                              AJNARA HOMES; APV REALTY LTD.; PLOT NO.-GH-003,
                              SECTOR-16B
                            </td>
                            <td>5 Months</td>
                          </tr>
                          <tr>
                            <td>52</td>
                            <td>
                              SKY GARDEN; SJP INFRACON LTD.; PLOT NO.-GH-05,
                              SECTOR-16B
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>53</td>
                            <td>
                              AISHWARYAM; WALL ROCK INFRATECH PVT. LTD.; PLOT
                              NO.-GC-3G/GH-3, SECTOR-16C
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>54</td>
                            <td>
                              DIVYANSH FLOURA; DIVYANSH INFRA HEIGHT PVT. LTD.;
                              PLOT NO.-GC-3H/GH-3, SECTOR-16C
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>55</td>
                            <td>
                              EROS SAMPOORNAM; AJAY ENTERPRISES PVT. LTD.; PLOT
                              NO.-GH-01, SECTOR-2
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>56</td>
                            <td>
                              OMKAR ROYAL NEST; OMKAR NESTS PVT. LTD.; PLOT
                              NO.-GH-08B, SECTOR-TECHZONE-4
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>57</td>
                            <td>
                              ACE ASPIRE; STAR LANDCRAFT PVT. LTD.; PLOT
                              NO.-02A,ECTOR-TECHZONE-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>58</td>
                            <td>
                              ALPHA HOMES; MSX REALTORS PVT.LTD.; PLOT
                              NO.-GH-010, SECTOR-ALPHA-1
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>59</td>
                            <td>
                              AMRAPALI GRAND; BIHARIJI ISPAT UDYOG LTD.; PLOT
                              NO.-GH-009, SECTOR- ZETA-I
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>60</td>
                            <td>
                              AIR FORCE NAVAL HOUSHING BOARD; PLOT NO.-9,
                              BUILDERS SCHEME
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>61</td>
                            <td>
                              EXPRESS PARK VIEW-1; NIMBUS PROJECTS LIMITED; PLOT
                              NO.-10B, SECTOR-CHI-05
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>62</td>
                            <td>
                              IITL NIMBUS THE EXPRESS PARK VIEW; PLOT
                              NO.-GH-003, SECTOR-CHI-05
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>63</td>
                            <td>
                              EXPRESS PARK VIEW-1; IITL PROJECTS LIMITED; PLOT
                              NO.-GH-010A, SECTOR-CHI-05
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>64</td>
                            <td>
                              CASA GRAND 1; EARTHCON CONSTRUCTION PVT.LTD.; PLOT
                              NO.-GH-07A, SECTOR-CHI-05
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>65</td>
                            <td>
                              TODAY HOMES &amp; INFRASTRUCTURE PVT. LTD.; PLOT
                              NO.-BRS-07, SECTOR-OMEGA-1
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>66</td>
                            <td>
                              PLUMERIA GARDEN; UP TOWN SHIP PVT. LTD.; PLOT
                              NO.-GH-001, SECTOR-OMICRON-III
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>67</td>
                            <td>UNITECH HEIGHT; PLOT NO.-5,SECTOR-CHI-03</td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>68</td>
                            <td>
                              UNITECH VERVE; PLOT NO.-11, SECTOR-32 PI,PI-02
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>69</td>
                            <td>
                              LOTUS PARK; RENOWNED BUILDTECH PVT.LTD.;
                              <span>&nbsp; </span>K.N. 258-268, SURAJPUR
                            </td>
                            <td>2 Months</td>
                          </tr>
                          <tr>
                            <td>70</td>
                            <td>
                              CASA WOODSTOCK; ANGEL INFRAHEIGHT PVT LTD.;
                              GC-3E/GH-03, SECTOR-16C
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>71</td>
                            <td>UNITECH CASCADE; PLOT NO.-8, SECTOR-32PI</td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>72</td>
                            <td>
                              AMRAPALI CASTLE; ULTRA HOME CONSTRUCTION PVT.LTD.;
                              GH-04A, SECTOR-CHI-5
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>73</td>
                            <td>
                              PLATINUM APARTMENTS RESIDENTS WELFARE
                              ASSOCIATION.(PARWA); PLOT NO.-D-001(TAU),
                              SECTOR-31
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>74</td>
                            <td>
                              PARASDEEP SAHAKARI AWAS SAMITI; PLOT NO.-GHS-04,
                              SECTOR-32 PI
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>75</td>
                            <td>
                              MAHAGUN MY WOODS; MAHAGUN (INDIA) PVT.LTD.; PLOT
                              NO.-GH-04, SECTOR-16C
                            </td>
                            <td>5 Months</td>
                          </tr>
                          <tr>
                            <td>76</td>
                            <td>
                              NIRALA ESTATE; NIRALA INFRATECH PVT. LTD.; PLOT
                              NO-GH-04, SECTOR-TECHZONE-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>77</td>
                            <td>
                              VEDANTAM; JYOTIRMAY INFRACON PVT. LTD.; PLOT
                              NO.-GH-01B, SECTOR-16C
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>78</td>
                            <td>
                              GOLF GARDENIA; M.I.BUILDERS PVT. LTD; PLOT
                              NO.-GH-002, SECTOR-ALPHA-2
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>79</td>
                            <td>
                              PARSVANATH EDENS; EDENS FLAT OWNERS &amp;
                              RESIDENTS ASSOCIATION; PLOT NO.-GHS-05,
                              SECTOR-ALPHA-2
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>80</td>
                            <td>
                              OASIS HOMES; IDEAL REALTY SOLUTIONS PVT. LTD.;
                              PLOT NO-91, SECTOR-BETA-2
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>81</td>
                            <td>
                              ARMY WELFARE HOUSING ORGANISATION; PLOT NO.-10,
                              BUILDERS SCHEME
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>82</td>
                            <td>
                              OMAXE PALM GREENS; OMAXE BUILD HOME PVT. LTD.;
                              PLOT NO.-GH-002, SECTOR-MU
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>83</td>
                            <td>
                              VRINDA CITY; CCSE SAHKARI AWAS SAMITI LTD; PLOT
                              NO.-GH-002, SECTOR-PHI-04
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>84</td>
                            <td>
                              PARSVNATH PANORAMA; PLOT NO.-A-001, SWARN NAGRI
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>85</td>
                            <td>
                              NRI RESIDENCY; SDS INFRATECH PRIVATE LIMITED; PLOT
                              NO.-GH-001, SECTOR-OMEGA-2
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>86</td>
                            <td>UNITECH HABITAT; PLOT NO.-9, SECTOR PI-02</td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>87</td>
                            <td>
                              NIRALA GREENSHIRE; NIRALA PROJECTS PVT LTD ;
                              GH-03, SECTOR-2
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>88</td>
                            <td>
                              PURVANCHAL SILVER CITY-II; RWA PURVANCHAL SILVER
                              CITY-II; PLOT NO.-10, SECTOR-PHI-02
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>89</td>
                            <td>
                              MAHALUXMI GREEN MANSION; MAHALUXMI BUILDTECH
                              LIMITED; HRA-14, SECTOR-SITE-C
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>90</td>
                            <td>
                              SHIVALIK HOMES; COSMOS INFRAESTATE PVT.LTD.; PLOT
                              NO.-HRA-009, SITE C SURAJPUR HOUSING
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>91</td>
                            <td>
                              4TH AVENUE,GAUR CITY -1; GAURSONS HI TECH
                              INFRASTRUCTURE PVT LTD; PLOT NO.-GC-04/GH-1,
                              SECTOR-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>92</td>
                            <td>
                              5TH AVENUE,GAUR CITY -1; GAURSONS HI-TECH
                              INFRASTRUCTURE PVT .LTD; PLOT NO.-GC-05/GH-1,
                              SECTOR-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>93</td>
                            <td>
                              6TH AVENUE,GAUR CITY -1; GAURSONS HI-TECH
                              INFRASTRUCTURE PVT.LTD.; PLOT NO.-GC-6/GH-1,
                              SECTOR-4
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>94</td>
                            <td>
                              LA RESIDENTIA; LA RESIDENTIA DEVLOPERS PVT. LTD.;
                              PLOT NO-GH-06A,SECTOR-TECHZONE-4
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>95</td>
                            <td>
                              ACE PLATINUM; STAR CITY BUILDCON PVT. LTD.; PLOT
                              NO.-GH-012/1, SECTOR-ZETA-I
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>96</td>
                            <td>
                              PUTTING GREENS; OMAXE LTD.; PLOT NO.-GH-002,
                              POCKET P2
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>97</td>
                            <td>
                              PROVIEW TECHNO CITY APARTMENT; PROVIEW
                              CONSTRUCTION LIMITED; PLOT NO.-GH-009A,
                              SECTOR-CHI-05
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr>
                            <td>98</td>
                            <td>
                              VICTORY ONE CENTRAL; INTELLECT PROJECTS PVT LTD;
                              GH-02E, SECTOR-12 (Partially Complete Project)
                            </td>
                            <td>3 Months</td>
                          </tr>
                          <tr height="26">
                            <td className="xl70" height="26">
                              99
                            </td>
                            <td>
                              PANCHSHEEL GREENS-II; PANCHSHEEL BUILDTECH PVT.
                              LTD.; PLOT NO.-GH-01A, SECTOR-16
                            </td>
                            <td>Under Construction</td>
                          </tr>
                          <tr>
                            <td>100</td>
                            <td>
                              AJNARA LEE GARDEN; AJNARA REALTECH LTD.; PLOT NO.
                              GH 02, SECTOR 16C (Partially Complete Project)
                            </td>
                            <td>4 Months</td>
                          </tr>
                          <tr>
                            <td>101</td>
                            <td>
                              ECOVILLAGE-3; SUPERTECH LIMITED; PLOT NO.-GH-06,
                              SECTOR-16B (Partially Complete Project)
                            </td>
                            <td>6 Months</td>
                          </tr>
                          <tr>
                            <td>102</td>
                            <td>
                              AVJ HEIGHTS; AVJ DEVELOPERS PVT. LTD. (AVJ
                              HEIGHTS); GH-012/2, SECTOR ZETA-1
                            </td>
                            <td>Under NCLT</td>
                          </tr>
                          <tr>
                            <td>103</td>
                            <td>
                              JAYPEE GREENS; JAYPEE GREENS LTD.
                              <span>&nbsp; </span>(Mixed Load)
                            </td>
                            <td>6 Month</td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        #: Please note that the Conversion work will be
                        commenced upon receipt of Application in prescribed form
                        duly completed in all respect along with relevant
                        documents and payment, as approved by the Hon’ble
                        Commission vide its Order dated 5th August 2020, from at
                        least 50% of the Owners / Allottees.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AllotteesMultiStoriedBuildings;
