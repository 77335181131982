import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";


const ConsumerOwnChoiceMeter = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />


        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" +
                "assets/img/inner/self-meter-reading.jpg" +
                ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="/Home">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/ConsumerOwnChoiceMeter" className="active">
                     Vendor’s List 
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    {/* <ul className="nav nav-tabs nav_tbds">
                      <li className="nav-item">
                        <
                          to="https://iwebapps.noidapower.com:8032/download//Vendor's List.pdf" target="_blank"
                          className="nav-link "
                          data-toggle="tab" 
                        >
                          Vendor’s List 
                        </Link>
                      </li>
 
                      
                    </ul> */}
                    <legend> Vendor List </legend>
                    
                    <div className="cost_pdf">
                                 
                                  <a
                                    href="https://iwebapps.noidapower.com:8032/download//Vendor's List.pdf"
                                    target="_blank" rel="noreferrer"
                                  >
                                   <img src="assets/img/pdf.png" alt="" />
                                    Vendor’s List
                                  </a>
                                </div>

                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ConsumerOwnChoiceMeter;
