import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Slider from "react-slick";
import Modal from "../components/Modal2";
import { FaPlus } from 'react-icons/fa';

const NpclCreatives = () => {
   const [show,setShow] = useState(false)
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
      };

      const modal = ()=>{
       setShow(true)
      }
  
  return (
    <>
      <div id="bodyaddid">
         {show ? (<Modal show={show} setShow={setShow}/>):null} 
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="assets/images/supply-related-banner.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Supply Related Complaint</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Awareness</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>

                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="mainwrapper">
                      <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...settings}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="our_other_links">
                        <h4>Other Links</h4>
                        <ul>
                          <li>
                            <Link to="#">
                              <span></span> Calculate Energy Consumption{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres" target="_blank">
                              <span></span>Payment Options{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online-vendor-registration"
                              target="_blank"
                            >
                              <span></span>Vendor Registration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Regulation &amp; Statutory Bodies{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div wrapper-pd-top-login">
                      <div className="bs-example bs-example-1">
                        <div
                          className="tab-pane fade show active"
                          id="newconnection"
                        >
                          <div className="innerhdnds">
                            <h2>CONSUMER AWARENESS</h2>
                          </div>
                          <ul className="nav nav-tabs nav_tbds" style={{overflow:"hidden"}}>
                          <li className="nav-item">
                              <Link
                                to="/NPCL-creatives"
                                className="nav-link active"
                                data-toggle="tab"
                              >
                                Consumer Awareness
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/assets/pdf/Content_for_PF_Awareness.pdf"
                                className="nav-link"
                                data-toggle="tab"
                                target="_blank"
                              >
                                PF Awareness
                              </Link>
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                        <div className="mainClass">
                        <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass" >
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic1.jpg"
                                    className="img-responsive" 
                                  />
                                <div className="img-creative">
                                  <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic2.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic3.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic4.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic5.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic6.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic7.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic8.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic9.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic10.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic11.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic12.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic13.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic14.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic15.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic16.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic17.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic18.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic19.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic20.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic21.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic22.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic23.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper"> 
                                  <img
                                    src="assets/images/consumer-awarence-pic24.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic25.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic26.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic27.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic28.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic29.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">                   
                              <img
                                    src="assets/images/consumer-awarence-pic30.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic31.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image childClass">
                              <div className="img-wrapper">
                                  <img
                                    src="assets/images/consumer-awarence-pic32.jpg"
                                    className="img-responsive"
                                  />
                                <div className="img-creative">
                                <FaPlus style={{fontSize:"30px",color:"	#B22222"}} onClick={modal}/>
                                </div>
                              </div>
                            </div>
                        </div> 
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NpclCreatives;
