import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const ConsumerOfferSeasonal = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Customer Offer
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <ul className="nav nav-tabs nav_tbds">
                      <li className="nav-item">
                        <Link
                          to="/consumerOfferjuggi"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          JHUGGI/ HUTMENTS/ PATRI SHOPKEEPERS
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/consumerOfferseasonal"
                          className="nav-link active"
                          data-toggle="tab"
                        >
                          SEASONAL INDUSTRIES IN LMV-6 AND HV-2
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content tab_main">
                      <div className="tab-pane fade show active" id="login1">
                        <div className="main_wrappersd">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="companysection">
                                <legend>SEASONAL INDUSTRIES IN LMV-6 AND HV-2</legend>
                                <p>
                                Seasonal industries will be determined in accordance with the criteria laid down below. No exhaustive list can be provided but some examples of industries exhibiting such characteristics are sugar, ice, rice mill and cold storage. The industries which operate during certain period of the year, i.e. have seasonality of operation, can avail the benefits of seasonal industries provided:
                                </p>
                                <ul>
                                    <li style={{listStyle:"none"}}>The continuous period of operation of such industries shall be at least 4 (four) months but not more than 9 (nine) months in a financial year.</li>
                                    <li style={{listStyle:"none"}}>Any prospective consumer, desirous of availing the seasonal benefit, shall specifically declare his season at the time of submission of declaration / execution of agreement mentioning the period of operation unambiguously.</li>
                                    <li style={{listStyle:"none"}}>The seasonal period once notified cannot be reduced during the next consecutive 12 months. The off-season tariff is not applicable to composite units having seasonal and other category loads.</li>
                                    <li style={{listStyle:"none"}}>The off-season tariff is also not available to those units who have captive generation exclusively for process during season and who avail Licensees supply for miscellaneous loads and other non-process loads.</li>
                                    <li style={{listStyle:"none"}}>The consumer opting for seasonal benefit has a flexibility to declare his off seasonal maximum demand subject to a maximum of 25% of the contracted demand. The tariff rates (demand charge per kW / kVA and energy charge per kWh / kVAh) for such industries during off-season period will be the same as for normal period. Further, during the off season fixed charges shall be levied on the basis of maximum demand recorded by the meter (not on normal billable demand or on percentage contracted demand). Rates for the energy charges shall however be the same as during the operational season. Further, first violation in the season would attract full billable demand charges and energy charges calculated at the unit rate 50% higher than the applicable tariff during normal period but only for the month in which the consumer has defaulted. However, on second violation in the off-season, the consumer will forfeit the benefit of seasonal rates for the entire season and energy charges shall be calculated at the unit rate 50% higher than the applicable tariff during the normal period.</li>
                                </ul>
                               <p>All the papers issued in regard to this connection will boldly display that the same is a temporary connection and is not a proof of ownership of the said premises. These temporary connections shall be upto 2 KW only.</p>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ConsumerOfferSeasonal;
