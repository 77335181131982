import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const LifestyleImprovement = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" +
                "assets/img/inner/corporatesocialresponsibility-banner.jpg" +
                ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Lifestyle Improvement
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/corporate-social-responsibility"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Corporate Social Responsibility
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/lifestyle-improvement"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Lifestyle Improvement
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/community-development"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Community Development
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/commitment-to-environment"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Commitment to Environment
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/csractivities"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        CSR Activities
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content tab_main">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="companysection">
                          <legend>LIFESTYLE IMPROVEMENT</legend>
                          <p>
                            Quality of life has improved significantly in
                            villages consequent upon network engineering under
                            ‘ECO’ Project, resulting in reliable power supply.
                            Customer response is overwhelming, the number of
                            metered cases in villages having swelled to 11414 in
                            2007-08 and been targeted to reach 14000 by end of
                            FY 2008-09 to cover 80% of the rural domestic
                            consumer population.
                          </p>
                          <p>
                            Thanks to reliable power supply, a Computer
                            Institute has taken roots in one of the villages for
                            training the local boys and girls. Small trade has
                            flourished, while law and order is improved. More
                            importantly, villagers have access to lifestyle
                            amenities, like television.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="companyimg">
                          <img src="assets/img/lifestyle-improvement.jpg" />
                        </div>
                      </div>
                    </div>
                    <div className="row com_profile">
                      <div className="col-md-12 social_blk">
                        <div className="companysection">
                          <p>
                            Success of the measures implemented is seen from the
                            comments made by U.S. Secretary of the Treasury, who
                            visited one such site and stated:
                          </p>
                          <p>
                            “…seeing first-hand the tangible quality of life
                            improvements brought by reliable electricity was
                            memorable. It was especially impressive to hear from
                            the students about the real-life impact of power
                            reform in their day-to-day lives”.
                          </p>
                          <p>
                            In a subsequent visit, the Chargé d´Affaires,
                            <b>Mr. Robert O. Blake</b> , had commented:
                          </p>
                          <p>
                            <b>
                              “What is perhaps most impressive, though, is the
                              tremendous positive impact your organisation has
                              had on the lives of the people in the villages
                              that your project has touched. I am pleased that
                              the United States Government through USAID has
                              been able to participate in this”
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default LifestyleImprovement;
