import React from "react";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Announcement from "../components/Announcement";
import { Link } from "react-router-dom";

const CustomerCareEmailId = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/contact-banner.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/customercareemailId" className="active">
                      Customer Care Email Id
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <ul className="nav nav-tabs nav_tbds">
                        <li className="nav-item">
                          <Link
                            to="/callcenter"
                            className="nav-link "
                            data-toggle="tab"
                          >
                            24*7 Call Centre
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/customercareemailId"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            Customer Care Email Id
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/customer-care-office"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Customer Care Office
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/mobile-application"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Mobile Application
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/solar-rooftop-net-metering"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Solar Roof Top Net Metering
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <legend style={{ marginLeft: "30px" }}>
                    CUSTOMER CARE EMAIL ID
                  </legend>
                  <form>
                    <div className="row">
                      <div className="col-md-5">
                        <div
                          className="form-group"
                          style={{ marginLeft: "30px" }}
                        >
                          <select className="form-control xs-field slt_category">
                            <option value="Select Category">
                              Select Category
                            </option>
                            <option value="WWWW">Supply Related</option>
                            <option value="ZCOM">Bill Related</option>
                            <option value="YYYY">Meter Related</option>
                            <option value="ZZZZ">
                              Complaints against Service Request
                            </option>
                            <option value="ZLCC">Theft Related</option>
                            <option value="ZDCS">
                              Disconnection of Supply
                            </option>
                            <option value="ZRCS">Reconnection of Supply</option>
                            <option value="ZWEB">Website Related</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <select className="form-control xs-field slt_category">
                            <option value="Select Category">
                              Select SubCategory
                            </option>
                            <option value="cp$PR">Cash Payments</option>
                            <option value="obp$PR">Online Bill Payments</option>
                            <option value="chp$PR">Cheque Payment</option>
                            <option value="tpu$PR">
                              Timeline for payment update
                            </option>
                            <option value="grq$PR">GST related Query</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                  <img
                    src="assets/img/cc_Email.jpg"
                    alt="Email"
                    className="panel_img"
                    style={{ marginLeft: "30px" }}
                  />
                  <p>
                    <a href="#" className="write_btn">
                      Write Us : crm@noidapower.com
                    </a>
                  </p>
                  <p>
                    *Please include attachments of size less than 5 MB, as
                    attachments exceeding the specified limit may not reach our
                    inbox due to technical constraints.
                  </p>
                  <div className="note_bg">
                    <p className="note_title">Note:-</p>
                    <ol>
                      <li>
                        Preliminary acknowledgement will be received instantly,
                        whereas the actual redressal of query / request will
                        depend on the defined SLA.
                      </li>
                      <li>
                        You may also contact at our 24*7 Call Center on 0120-
                        6226666
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CustomerCareEmailId;
