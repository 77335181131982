import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";



class Selfmeterreading extends React.Component {

 settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

   onChange(value) {
    console.log("Captcha value:", value);
  }
  
  render(){



  return (
    <React.Fragment>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/meter.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10 main_heading">
                  <h1>
                    Self Submission
                    <br /> of Meter Reading
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                   <Link to="#">Home </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <a href="#" className="active">
                    Self Meter Reading
                  </a>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
              </ol>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...this.settings2}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="our_other_links">
                        <h4>Other Links</h4>
                        <ul>
                          <li>
                            <Link to="#">
                              <span></span> Calculate Energy Consumption{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres" target="_blank">
                              <span></span>Payment Options{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online-vendor-registration"
                              target="_blank"
                            >
                              <span></span>Vendor Registration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Regulation &amp; Statutory Bodies{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                      <div className="col-sm-9 bg_cols">
                <div className="row" style={{ padding: "10px 20px" }}>
                  <div className="col-sm-12 form_div">
                    <div className="outage_notificatiods">
                      <div className="bs-example">
                        <div className="tab-content tab_main">
                           <div className="wrapper-heading-login">
                                <h3>Self Meter Reading</h3>
                              </div>
                          <div
                            className="tab-pane fade show active"
                            id="selfsubmission"
                          >
                            <div className="row">
                              {/* <!-- <div className="popuip" style="cursor:pointer">popup</div>
          	<div className="popupmain"> 		          		          	
          		<div className="bg_des"></div>
          		<div className="popupinner">
<div className="cross_bttns">X</div>
          			<h1>Hello Yuvi</h1>
          		</div>

          	</div> --> */}
              <div className="main_wrappersd loginpgui">
                             
                              <form className="qbfsection loginwidth" >
                                <div className="row loginwidth">
                                  <div className="col-lg-12">
                                    <div className="form-group form_gp_des">
                                      <label>Consumer No</label>
                                      <input
                                        name="Consumer No"
                                        type="text"
                                        className="form-control"
                                        placeholder="Consumer No"
                                        required 
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="form-group form_gp_des">
                                      <label>Mobile No</label>
                                      <input
                                        name="MobileNo"
                                        type="text"
                                        className="form-control"
                                        placeholder="Mobile No"
                                      
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="form-group form_gp_des">
                                      <button className="form-control btn btn-primary manualbtns" type="submit">
                                       Submit
                                      </button>
                                    </div>
                                  </div>
                                  <div className="hidden_parts">
                                  <div className="col-md-12">
                                    <p className="successmsgg">
                                      OTP has been sent on the email id and
                                      mobile no given above. Please use the same
                                      and proceed further
                                    </p>
                                    <div className="form-group form_gp_des">
                                      <label for="">Validate OTP</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        placeholder="Enter OTP"
                                        name=""
                                        required
                                      />
                                      <div className="valid-feedback">
                                        Valid.
                                      </div>
                                      <div className="invalid-feedback">
                                        Please fill out this field.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <p className="error_msgs">Invalid OTP!!</p>
                                    <div className="form-group form_gp_des">
                                      <button
                                        type="submit"
                                        className="form-control btn btn-primary manualbtns"
                                      >
                                        Validate
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                </div>
                              </form>
                            </div>
  
                              <div className="col-md-12 left_divsd">
                                  <div className="wrapper-heading-login">
                                <h3>Self Meter Reading</h3>
                              </div>
                                <form
                                  action="/action_page.php"
                                  className="needs-validation"
                                  novalidate
                                >
                                  <div className="row">
                                    <div className="col-lg-6 col-sm-12 col-xs-12">
                                      <div className="form-group form_gp_des">
                                        <label for="mobilenu">
                                          Mobile No <span>*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="mobilenu"
                                          placeholder="Mobile"
                                          name="mobilenu"
                                          required
                                        />
                                        <div className="valid-feedback">
                                          Valid.
                                        </div>
                                        <div className="invalid-feedback">
                                          Please fill out this field.
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-xs-12">
                                      <div className="form-group form_gp_des">
                                        <label for="meterreading">
                                          Meter Reading Date <span>*</span>
                                        </label>
                                        <input
                                          type="date"
                                          className="form-control"
                                          id="meterreading"
                                          placeholder="date"
                                          name="meterreading"
                                          required
                                        />
                                        <div className="valid-feedback">
                                          Valid.
                                        </div>
                                        <div className="invalid-feedback">
                                          Please fill out this field.
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                      <div className="form-group form_gp_des">
                                        <label for="description_inci">
                                          KWH Reading <span>*</span>
                                        </label>
                                        <textarea
                                          className="form-control"
                                          id="description_inci"
                                          placeholder="Description"
                                          name="description_inci"
                                          required
                                        ></textarea>
                                        <div className="message_filew">
                                          For validating Mobile Number This
                                          service, will Return Meter Number.{" "}
                                        </div>

                                        <div className="valid-feedback">
                                          Valid.
                                        </div>
                                        <div className="invalid-feedback">
                                          Please fill out this field.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                      <div className="form-group form_gp_des">
                                        <label for="docupload">
                                          Upload an evidence(If any)
                                        </label>
                                        <input
                                          type="file"
                                          className="form-control"
                                          id="docupload"
                                          placeholder="Upload"
                                          name="docupload"
                                        />
                                        <div className="allowed_file">
                                          (Only JPEG, PDF or DOC file less than
                                          5 MB is allowed)
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                      <div className="form-group form-check checkbox_des">
                                        <label className="form-check-label">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="remember"
                                            required
                                          />
                                          I agree to the{" "}
                                          <a href="">Terms and Conditions</a>.
                                          <div className="valid-feedback">
                                            Valid.
                                          </div>
                                          <div className="invalid-feedback">
                                            Check this checkbox to continue.
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-5 col-sm-12 col-xs-12 ">
                                      <div
                                        className="col-md-6 recaptcha"
                                        id="g-recaptcha4"
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-5">
                                      <button
                                        type="submit"
                                        id="firesubmit"
                                        className="btn btn-primary form-control submit_btsd"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                    <div className="col-md-5">
                                      <button
                                        type="submit"
                                        id="cancelbtn"
                                        className="btn btn-secondary form-control cancelbtns"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  )}
};



export default Selfmeterreading;
