import React from "react";
import Header2 from "../components/Header2";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import {useState} from 'react'
import Table from "../components/Table";

const LoadReductionAugmentationApply = () => {
  const [loadReduction,setLoadReduction] = useState(true)
  const [augmentation,setAugmentation] = useState(false)

  const openLoad = ()=>{
    setLoadReduction(true);
    setAugmentation(false)
   }
   const openAug = ()=>{
     setLoadReduction(false);
    setAugmentation(true)
   }
  return (
    <>
         <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods consumersec">
        <div className="upperdesignds">
			<div className="row">
				<div className="col-md-1">
				<img className="bgstlsd" src="assets/img/small/payment-options.jpg" alt=""/>
			</div>
			<div className="col-md-11 textdesing">
							<h2 className="headtit">{loadReduction ? ('Load Reduction'):('load Augmentation')}</h2>
			</div>
			</div>
		</div>
        <div className="container">
        <section className="main_sectiods">
    <div className="container">
    <div className="row justify-content-center padtops">
    <div className="col-sm-12">
            <div className="col-lg-12 brdcum_designs">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#">Home</Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/consumer-portal" target="_blank">Consumer Portal</Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <a href="#" className="active">
                    Apply Online
                  </a>
                </li>
              </ol>
            </div>
            <div className="bs-example bs-example-1">
        <div className="tab-pane show active" id="newconnection">
        <ul className="nav">
        <li className="nav-item" onClick={openLoad}><Link className={`nav-link ${loadReduction ? "active":""}`} data-toggle="tab" style={{color:loadReduction?"blue":"red"}}>Load Reduction</Link></li>
        <li className="nav-item" onClick={openAug}><Link className={`nav-link ${augmentation ? "active":""}`} data-toggle="tab" style={{color:augmentation?"blue":"red"}}>Load Augmentation</Link></li>
        </ul>
        </div>
        </div>
    {loadReduction ? (<div className="col-sm-12 bg_cols"> 
    <div className="form_div">
    <div className="tab-content tab_main">
    <div className="tab-pane fade show active" id="newconnection">
        <div className="bs-example bs-example-1">
                        <div
                          className="tab-pane fade show active"
                          id="newconnection"
                        >
                          <ul className="nav nav-tabs nav_tbds" style={{overflow:"hidden"}}>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Calculate Your Load
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-doc"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Document Checklist
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-sample"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Sample Documents
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-faq"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                FAQs
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-apply"
                                className="nav-link active"
                                data-toggle="tab"
                              >
                                Apply
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-status"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Application Status
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-feedback"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Feedback
                              </Link>
                            </li>
                          </ul>
                        </div>{" "}
                      </div>
    <div className="row">
    <div className="col-md-12" style={{marginLeft:"20px"}}>
    <div className="tab-pane" id="apply">
            <h4 className="mt-2">  Apply </h4>
         
            <p className="nooutagedsabf"><Link to="/login">Click here to Apply</Link></p>
        </div>
		
	</div>
    </div>
    </div>
    </div>
    </div>
    </div>): null}
    {augmentation ? (<div className="col-sm-12 bg_cols"> 
    <div className="form_div">
    <div className="tab-content tab_main">
    <div className="tab-pane fade show active" id="newconnection">
        <div className="bs-example bs-example-1">
                        <div
                          className="tab-pane fade show active"
                          id="newconnection"
                        >
                          <ul className="nav nav-tabs nav_tbds" style={{overflow:"hidden"}}>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Calculate Your Load
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-doc"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Document Checklist
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-sample"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Sample Documents
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-faq"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                FAQs
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-apply"
                                className="nav-link active"
                                data-toggle="tab"
                              >
                                Apply
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-status"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Application Status
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/load-reduction-augmentation-feedback"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Feedback
                              </Link>
                            </li>
                          </ul>
                        </div>{" "}
                      </div>
    <div className="row">
    <div className="col-md-12" style={{marginLeft:"20px"}}>
    <div className="tab-pane" id="apply">
            <h4 className="mt-2">  Apply </h4>
         
            <p className="nooutagedsabf"><Link to="/login">Click here to Apply</Link></p>
        </div>
		
	</div>
    </div>
    </div>
    </div>
    </div>
    </div>): null}
    
    </div>
    </div>
    </div>
    </section>
        </div>
        </section>
        </div>
    </>
  )
}

export default LoadReductionAugmentationApply