import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const ReportAConcern = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background:
                "url(" + "assets/img/inner/report-a-concern.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10 main_heading">
                  <h1>
                    Self Submission
                    <br /> of Meter Reading
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="topiconlist">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-sm-6 p-0">
                  <div className="anadd">
                    <div className="row justify-content-end p-0 m-0">
                      <div className="col-sm-3 p-0">
                        <h4>ANNOUNCEMENT</h4>
                      </div>
                      <div className="col-sm-9 p-0">
                        <div className="flex5258wrap fleright1">
                          <marquee>
                            <div className="sliderannoucement">
                              <a href="#">
                                UPERC Preventive Guidelines for Corona Virus
                              </a>
                            </div>
                            <div className="sliderannoucement">
                              <a href="#">
                                UPERC Preventive Guidelines for Corona Virus
                              </a>
                            </div>
                            <div className="sliderannoucement">
                              <a href="#">
                                UPERC Preventive Guidelines for Corona Virus
                              </a>
                            </div>
                            <div className="sliderannoucement">
                              <a href="#">
                                UPERC Preventive Guidelines for Corona Virus
                              </a>
                            </div>
                          </marquee>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 p-0">
                  <div className="flex5258wrap">
                    <marquee>
                      Important Notice for Owners / Allottees of Multi-Storied
                      Buildings in Greater Noida published on TOI & NBT dated
                      14th March 2021. Consumers can log complaint & avail
                      information by sending SMS to 7840002288 from their
                      registered Mobile No. Click{" "}
                      <a href="#" target="_blank">
                        here
                      </a>{" "}
                      to view the SMS short codes and their purpose. ----------
                      Single Phase consumers can now submit Self Meter Reading
                      with photo proof between 1st to 10th Day of every month,
                      click{" "}
                      <a href="#" target="_blank">
                        {" "}
                        here
                      </a>
                    </marquee>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-lg-12 brdcum_designs">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Home</Link>
                      <span className="icondsds">
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/report-a-concern" className="active">
                        report a Concern
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <div className="outage_notificatiods">
                        <div className="innerhdnds">
                          <h2>
                            <i className="fa fa-phone" aria-hidden="true"></i>
                            Report a Concern
                          </h2>
                        </div>

                        <div className="bs-example">
                          <ul className="nav nav-tabs nav_tbds">
                            <li className="nav-item">
                              <Link
                                to="/report-a-concern"
                                className="nav-link active"
                                data-toggle="tab"
                              >
                                Report Fire
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/reportsafetyhazzard"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Report Safety Hazzard{" "}
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/reporttheft"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Report Theft{" "}
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/reportunethical"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Report Unethical{" "}
                              </Link>
                            </li>
                          </ul>
                          <div className="tab-content tab_main">
                            <div
                              className="tab-pane fade show active"
                              id="reportfire"
                            >
                              <div className="row">
                                <div className="col-lg-12 mainhding">
                                  <h2>FIRE / ACCIDENT</h2>
                                  <p className="">
                                    In case of any Emergency such as Fire from
                                    Meter Box, Feeder Pillar Box, Transformer,
                                    Electrical Network or Electric Shock to
                                    person or animal, kindly call NPCL 24*7
                                    Emergency No. 9718722222 to report these
                                    incidents.
                                  </p>
                                  <p>
                                    Alternatively, you may also report this
                                    issue:
                                  </p>
                                </div>
                                <div className="col-md-12 left_divsd">
                                  <form
                                    action="/action_page.php"
                                    className="needs-validation"
                                    novalidate
                                  >
                                    <div className="row">
                                      <div className="col-lg-6 col-sm-12 col-xs-12">
                                        <div className="form-group form_gp_des">
                                          <label for="locationincident">
                                            Location of incident <span>*</span>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="locationincident"
                                            placeholder="Location"
                                            name="locationincident"
                                            required
                                          />
                                          <div className="valid-feedback">
                                            Valid.
                                          </div>
                                          <div className="invalid-feedback">
                                            Please fill out this field.
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-sm-12 col-xs-12">
                                        <div className="form-group form_gp_des">
                                          <label for="landmark">
                                            Landmark <span>*</span>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="landmark"
                                            placeholder="Landmark"
                                            name="landmark"
                                            required
                                          />
                                          <div className="valid-feedback">
                                            Valid.
                                          </div>
                                          <div className="invalid-feedback">
                                            Please fill out this field.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-sm-12 col-xs-12">
                                        <div className="form-group form_gp_des">
                                          <label for="description_inci">
                                            Description of incident{" "}
                                            <span>*</span>
                                          </label>
                                          <textarea
                                            className="form-control"
                                            id="description_inci"
                                            placeholder="Description"
                                            name="description_inci"
                                            required
                                          ></textarea>
                                          <div className="valid-feedback">
                                            Valid.
                                          </div>
                                          <div className="invalid-feedback">
                                            Please fill out this field.
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-sm-12 col-xs-12">
                                        <div className="form-group form_gp_des">
                                          <label for="docupload">
                                            Upload an evidence(If any)
                                          </label>
                                          <input
                                            type="file"
                                            className="form-control uploadIflds"
                                            id="docupload"
                                            placeholder="Upload"
                                            name="docupload"
                                          />
                                          <div className="allowed_file">
                                            (Only JPEG, PDF or DOC file less
                                            than 5 MB is allowed)
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <p className="titl_details">
                                          Reporter Details
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-4 col-sm-12 col-xs-12">
                                        <div className="form-group form_gp_des">
                                          <label for="consumerno">
                                            Consumer No.
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="consumerno"
                                            placeholder="Consumer No"
                                            name="consumerno"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-sm-12 col-xs-12">
                                        <div className="form-group form_gp_des">
                                          <label for="yourname">
                                            Your Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="yourname"
                                            placeholder="Name"
                                            name="yourname"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-sm-12 col-xs-12">
                                        <div className="form-group form_gp_des">
                                          <label for="yourmobile">
                                            Your Mobile No.
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            id="yourmobile"
                                            placeholder="Mobile No."
                                            name="yourmobile"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-5 col-sm-12 col-xs-12">
                                        <div className="form-group form_gp_des">
                                          <span
                                            id="captcha"
                                            className="captcha_tcts form-control"
                                          >
                                            captcha text
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-lg-1 col-sm-12 col-xs-12">
                                        <img
                                          src="assets/img/refresh.png"
                                          id="refresh"
                                          className="refreshbtns"
                                        />
                                      </div>
                                      <div className="col-lg-6 col-sm-12 col-xs-12">
                                        <div className="form-group form_gp_des">
                                          <div id="captchaBackground">
                                            <input
                                              id="textBox"
                                              className="form-control"
                                              placeholder="Captcha Code"
                                              type="text"
                                              name="text"
                                              required
                                            />

                                            <span id="output"></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 col-sm-12 col-xs-12">
                                        <div className="form-group form-check checkbox_des">
                                          <label className="form-check-label">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              name="remember"
                                              required
                                            />
                                            I agree to the{" "}
                                            <a href="">Terms and Conditions</a>.
                                            <div className="valid-feedback">
                                              Valid.
                                            </div>
                                            <div className="invalid-feedback">
                                              Check this checkbox to continue.
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-3">
                                        <button
                                          type="submit"
                                          id="firesubmit"
                                          className="btn btn-primary form-control submit_btsd"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                      <div className="col-md-3">
                                        <button
                                          type="submit"
                                          id="cancelbtn"
                                          className="btn btn-secondary form-control cancelbtns"
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ReportAConcern;
