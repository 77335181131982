import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Home from "./Pages/Home.js";
import CashCollectionCentres from "./Pages/CashCollectionCentres";
import OnlineApplication from "./Pages/OnlineApplications";
import EbillDownload from "./Pages/EbillDownload";
import SelfMeterReading from "./Pages/SelfMeterReading";
import QuickPayPre from "./Pages/QuickPayPre";
import Footer from "./components/Footer.js";
import Copyright from "./components/Copyright.js";
import SiteMap from "./components/SiteMap.js";
import AskTez from "./components/AskTez.js";
import PrePaidMeterRecharge from "./Pages/PrePaidMeterRecharge.js";
import AwardsRecognition from "./Pages/AwardsRecognition.js";
import CompanyProfile from "./Pages/CompanyProfile.js";
import CorporatePolicies from "./Pages/CorporatePolicies.js";
import VissionMission from "./Pages/VissionMission.js";
import RpsgGroupVisionAndCoreValues from "./Pages/RpsgGroupVisionAndCoreValues.js";
import CorporateSocialResponsibility from "./Pages/CorporateSocialResponsibility.js";
import CostDataBook from "./Pages/CostDataBook.js";
import TarrifSchedule from "./Pages/TarrifSchedule.js";
import EnergyAudit from "./Pages/EnergyAudit";
import UpElectricitySupplyCode from "./Pages/UpElectricitySupplyCode.js";
import OnlineVendorRegistration from "./Pages/OnlineVendorRegistration.js";
import ContactForEprocurement from "./Pages/ContactForEprocurement.js";
import ContactForPowerProcurement from "./Pages/ContactForPowerProcurement.js";
import Tender from "./Pages/Tender.js";
import EnergyServices from "./Pages/EnergyServices.js";
import InitiativeUndertaken from "./Pages/InitiativeUndertaken.js";
import OurCustomer from "./Pages/OurCustomer.js";
import ChallagesAhead from "./Pages/ChallagesAhead.js";
import CreditRating from "./Pages/CreditRating.js";
import LifestyleImprovement from "./Pages/LifestyleImprovement.js";
import CommunityDevelopment from "./Pages/CommunityDevelopment.js";
import CommitmentToEnvironment from "./Pages/CommitmentToEnvironment.js";
import CSRActivities from "./Pages/CSRActivities.js";
import ConsumerPortal from "./Pages/ConsumerPortal.js";
import RegisterComplain from "./Pages/RegisterComplain.js";
import BillEstimatePayment from "./Pages/BillEstimatePayment.js";
import DigitalServices from "./Pages/DigitalServices.js";
import KnowledgeCenter from "./Pages/KnowledgeCenter.js";
import InformationBoard from "./Pages/InformationBoard.js";
import ConsumerEngagementAndAwareness from "./Pages/ConsumerEngagementAndAwareness.js";
import Feedback from "./Pages/Feedback.js";
import Sustainability from "./Pages/Sustainability.js";
import QuickLinks from "./Pages/QuickLinks.js";
import SafetyFirst from "./Pages/SafetyFirst.js";
import ChequeDropBox from "./Pages/ChequeDropBox.js";
import OnlinePayment from "./Pages/OnlinePayment.js";
import RTGSAndNEFTPayment from "./Pages/RTGSAndNEFTPayment.js";
import FPPCA from "./Pages/FPPCA.js";
import TariffPetetionARR2425 from "./Pages/TariffPetetionARR2425.js";
import BillingComplaintResolution from "./Pages/BillingComplaintResolution.js";
import NACH from "./Pages/NACH.js";
import PayAgainstApplication from "./Pages/PayAgainstApplication.js";
import CallCenter from "./Pages/CallCenter.js";
import CustomerCareEmailId from "./Pages/CustomerCareEmailId.js";
import CustomerCareOffice from "./Pages/CustomerCareOffice.js";
import MobileApplication from "./Pages/MobileApplication.js";
import SolarRoofTopNetMetering from "./Pages/SolarRoofTopNetMetering.js";
import GetOutageNotification from "./Pages/GetOutageNotification.js";
import Institutional from "./Pages/Institutional.js";
import Township from "./Pages/Township.js";
import NewConnection from "./Pages/NewConnection.js";
import Career from "./Pages/Career.js";
import CurrentOpenings from "./Pages/CurrentOpenings.js";
import ThirdPartyMeterTesting from "./Pages/ThirdPartyMeterTesting.js";
import HolidayCalendar from "./Pages/HolidayCalendar.js";
import ConsumerAwareness from "./Pages/ConsumerAwareness.js";
import Faq from "./Pages/Faq.js";
import AllotteesMultiStoriedBuildings from "./Pages/AllotteesMultiStoriedBuildings.js";
import SinglePointToMultipontConversion from "./Pages/SinglePointToMultipontConversion.js";
import CustomerCareOfficeQueueStatus from "./Pages/CustomerCareOfficeQueueStatus.js";
import CustomerCareOfficeQueueStatusTech from "./Pages/CustomerCareOfficeQueueStatusTech.js";
import SupplyRelatedComaplaint from "./Pages/SupplyRelatedComaplaint.js";
import Register from "./Pages/Register.js";
import Status from "./Pages/Status.js";
import FeedBackForm from "./Pages/FeedBackForm.js";
import ComplaintManagementProcess from "./Pages/ComplaintManagementProcess.js";
import Faqs from "./Pages/Faqs.js";
import BillRelatedComplaint from "./Pages/BillRelatedComplaint.js";
import BillRelatedComplaintStatus from "./Pages/BillRelatedComplaintStatus.js";
import BillRelatedComplaintFeedback from "./Pages/BillRelatedComplaintFeedback.js";
import BillRelatedComplaintCMP from "./Pages/BillRelatedComplaintCMP.js";
import BillRelatedComplaintFAQ from "./Pages/BillRelatedComplaintFAQ.js";
import ReportAConcern from "./Pages/ReportAConcern.js";
import ChequeGuidelines from "./Pages/ChequeGuidelines.js";
import ReportSafetyHazzard from "./Pages/ReportSafetyHazzard.js";
import Login from "./Pages/Login.js";
import ReportTheft from "./Pages/ReportTheft.js";
import ReportUnethical from "./Pages/ReportUnethical.js";
import ValuesBeliefs from "./Pages/ValuesBeliefs.js";
import NeedHelpInSignIn from "./Pages/NeedHelpInSignIn.js";
import NewUser from "./Pages/NewUser.js";
import ForgetPassword from "./Pages/ForgetPassword.js";
import LoginInfo from "./Pages/LoginInfo.js";
import Dosndnts from "./Pages/Dosndnts.js";
import EasyWaysToLowerYourBill from "./Pages/EasyWaysToLowerYourBill.js";
import SafetyRelatedComplaint from "./Pages/SafetyRelatedComplaint.js";
import SafetyRelatedComplaintStatus from "./Pages/SafetyRelatedComplaintStatus.js";
import SafetyRelatedComplaintFeedBack from "./Pages/SafetyRelatedComplaintFeedBack.js";
import SafetyRelatedComplaintCMP from "./Pages/SafetyRelatedComplaintCMP.js";
import SafetyRelatedComplaintFAQ from "./Pages/SafetyRelatedComplaintFAQ.js";
import LoadReductionAugmentation from "./Pages/LoadReductionAugmentation.js";
import LoadReductionAugmentationDoc from "./Pages/LoadReductionAugmentationDoc.js";
import Rebate from "./Pages/Rebate.js";
import RebatePayment from "./Pages/RebatePayment.js";
import RebateConsumer from "./Pages/RebateConsumer.js";
import RebateScheme from "./Pages/RebateScheme.js";
import RebateFacilitation from "./Pages/RebateFacilitation.js";
import RebatePower from "./Pages/RebatePower.js";
import RebateConsumerLMV from "./Pages/RebateConsumerLMV.js";
import RoofTopSolar from "./Pages/RoofTopSolar.js";
import SiteMap2 from "./Pages/SiteMap2.js";
import WhatsappBasedServices from "./Pages/WhatsappBasedServices.js";
import Safety from "./Pages/Safety.js";
import SafetyIndoors from "./Pages/SafetyIndoors.js";
import SafetyPrecautions from "./Pages/SafetyPrecautions.js";
import NewServiceConnection from "./Pages/NewServiceConnection.js";
import NewServiceConnectionOnline from "./Pages/NewServiceConnectionOnline.js";
import NewServiceConnectionCalculate from "./Pages/NewServiceConnectionCalculate.js";
import NewServiceConnectionDocument from "./Pages/NewServiceConnectionDocument.js";
import NewServiceConnectionSample from "./Pages/NewServiceConnectionSample.js";
import LoadReductionAugmentationSample from "./Pages/LoadReductionAugmentationSample.js";
import LoadReductionAugmentationFaq from "./Pages/LoadReductionAugmentationFaq.js";
import LoadReductionAugmentationApply from "./Pages/LoadReductionAugmentationApply.js";
import LoadReductionAugmentationStatus from "./Pages/LoadReductionAugmentationStatus.js";
import LoadReductionAugmentationFeedback from "./Pages/LoadReductionAugmentationFeedback.js";
import OurValues from "./Pages/OurValues.js";
import ConsumerOffer from "./Pages/ConsumerOffer.js";
import ConsumerOfferJuggi from "./Pages/ConsumerOfferJuggi.js";
import ConsumerOfferSeasonal from "./Pages/ConsumerOfferSeasonal.js";
import ContactOverPhone from "./Pages/ContactOverPhone.js";
import KnowYourPrepaidMeter from "./Pages/KnowYourPrepaidMeter.js";
import ConsumerTestimonial from "./Pages/ConsumerTestimonial.js";
import Legal from "./Pages/Legal.js";
import MeterTesting from "./Pages/MeterTesting.js";
import ConsumerContactProgram from "./Pages/ConsumerContactProgram.js";
import NukkadNatak from "./Pages/NukkadNatak.js";
import ConsumerWeek from "./Pages/ConsumerWeek.js";
import KioskServices from "./Pages/Kioskservices.js";
import OnlineMeterTesting from "./Pages/OnlineMeterTesting.js";
import OnlineMeterTestingStatus from "./Pages/OnlineMeterTestingStatus.js";
import ConsumerOwnChoiceMeter from "./Pages/OwnChoiceMeter.js";
import LastPayment from "./Pages/LastPayment.js";
import SMSBasedServices from "./Pages/SMSBasedServices.js";
import NameMutation from "./Pages/NameMutation";
import NameMutationSample from "./Pages/NameMutationSample";
import NameMutationFaqs from "./Pages/NameMutationFaqs";
import NameMutationApply from "./Pages/NameMutationApply";
import NameMutationFeedback from "./Pages/NameMutationFeedback";
import CategoryChange from "./Pages/CategoryChange";
import CategoryChangeSample from "./Pages/CategoryChangeSample";
import CategoryChangeFaqs from "./Pages/CategoryChangeFaqs";
import CategoryChangeApply from "./Pages/CategoryChangeApply.js";
import CategoryChangeFeedback from "./Pages/CategoryChangeFeedback";
import Permanent from "./Pages/Permanent.js";
import PermanentSample from "./Pages/PermanentSample.js";
import PermanentFaqs from "./Pages/PermanentFaqs";
import PermanentApply from "./Pages/PermanentApply.js";
import PermanentFeedback from "./Pages/PermanentFeedback.js";
import NameAndAddress from "./Pages/NameAndAddress.js";
import NameAndAddressSample from "./Pages/NameAndAddressSample.js";
import NameAndAddressFaqs from "./Pages/NameAndAddressFaqs.js";
import NameAndAddressApply from "./Pages/NameAndAddressApply.js";
import NameAndAddressFeedback from "./Pages/NameAndAddressFeedback.js";
import MeterRelatedComplaint from "./Pages/MeterRelatedComplaint.js";
import MeterRelatedComplaintStatus from "./Pages/MeterRelatedComplaintStatus.js";
import MeterRelatedComplaintFeedback from "./Pages/MeterRelatedComplaintFeedback.js";
import MeterRelatedComplaintCMP from "./Pages/MeterRelatedComplaintCMP.js";
import MeterRelatedComplaintFAQ from "./Pages/MeterRelatedComplaintFaq.js";
import ServiceRelatedComplaint from "./Pages/ServiceRelatedComplaint.js";
import ServiceRelatedComplaintStatus from "./Pages/ServiceRelatedComplaintStatus.js";
import ServiceRelatedComplaintFeedback from "./Pages/ServiceRelatedComplaintFeedback.js";
import ServiceRelatedComplaintCMP from "./Pages/ServiceRelatedComplaintCMP.js";
import ServiceRelatedComplaintFAQ from "./Pages/ServiceRelatedComplaintFAQ.js";
import TheftRelatedComplaint from "./Pages/TheftRelatedComplaint.js";
import TheftRelatedComplaintStatus from "./Pages/TheftRelatedComplaintStatus.js";
import TheftRelatedComplaintFeedback from "./Pages/TheftRelatedComplaintFeedback.js";
import TheftRelatedComplaintCMP from "./Pages/TheftRelatedComplaintCMP.js";
import TheftRelatedComplaintFAQ from "./Pages/TheftRelatedComplaintFaq.js";
import NpclCreatives from "./Pages/NpclCreatives.js";
import Grievence from "./Pages/Grievence.js";
import ClaimCompensation from "./Pages/ClaimCompensation.js";
import ClaimCompensationStatus from "./Pages/ClaimCompensationStatus.js";
import DocumentChecklist from "./Pages/DocumentChecklist.js";
import DMSwebsite from "./Pages/DMSwebsite.js";
import { PageNotFound } from "./Pages/PageNotFound.js";
import ReactGA from "react-ga";
ReactGA.initialize("UA-131113905-1");


const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
    <>
      <Switch>
        <Route exact path={["/", "/home"]} component={Home} />
        <Route
          exact
          path="/online-applications"
          component={OnlineApplication}
        />
        <Route
          exact
          path="/cash-collection-centres"
          component={CashCollectionCentres}
        />
        <Route exact path="/ebill-download" component={EbillDownload} />
        <Route exact path="/quick-pay-pre" component={QuickPayPre} />
        <Route exact path="/self-meter-reading" component={SelfMeterReading} />
        <Route
          exact
          path="/pre-paid-meter-recharge"
          component={PrePaidMeterRecharge}
        />
        <Route exact path="/awards-recognition" component={AwardsRecognition} />
        <Route exact path="/company-profile" component={CompanyProfile} />
        <Route exact path="/corporate-policies" component={CorporatePolicies} />
        <Route exact path="/vission-mission" component={VissionMission} />
        <Route
          exact
          path="/rpsg-group-vision-and-core-values"
          component={RpsgGroupVisionAndCoreValues}
        />
        <Route
          exact
          path="/corporate-social-responsibility"
          component={CorporateSocialResponsibility}
        />
        <Route exact path="/cost-data-book" component={CostDataBook} />
        <Route exact path="/tarrif-schedule" component={TarrifSchedule} />
        <Route exact path="/EnergyAudit" component={EnergyAudit} />
        <Route
          exact
          path="/up-electricity-supply-code"
          component={UpElectricitySupplyCode}
        />
        <Route
          exact
          path="/online-vendor-registration"
          component={OnlineVendorRegistration}
        />
        <Route
          exact
          path="/contact-for-eprocurement"
          component={ContactForEprocurement}
        />
        <Route
          exact
          path="/contact-for-power-procurement"
          component={ContactForPowerProcurement}
        />
        <Route exact path="/tenders" component={Tender} />
        <Route exact path="/energy-services" component={EnergyServices} />
        <Route
          exact
          path="/initiativeundertaken"
          component={InitiativeUndertaken}
        />
        <Route exact path="/our-customer" component={OurCustomer} />
        <Route exact path="/challangeahead" component={ChallagesAhead} />
        <Route exact path="/credit-rating" component={CreditRating} />
        <Route
          exact
          path="/lifestyle-improvement"
          component={LifestyleImprovement}
        />
        <Route
          exact
          path="/community-development"
          component={CommunityDevelopment}
        />
        <Route
          exact
          path="/commitment-to-environment"
          component={CommitmentToEnvironment}
        />
        <Route exact path="/csractivities" component={CSRActivities} />
        <Route exact path="/Sustainability" component={Sustainability} />
        <Route exact path="/consumer-portal" component={ConsumerPortal} />
        <Route exact path="/register-complain" component={RegisterComplain} />
        <Route
          exact
          path="/bill-estimate-payment"
          component={BillEstimatePayment}
        />
        <Route exact path="/digital-services" component={DigitalServices} />
        <Route exact path="/knowledge-center" component={KnowledgeCenter} />
        <Route
          exact
          path="/consumer-engagement-and-awareness"
          component={ConsumerEngagementAndAwareness}
        />
        <Route exact path="/feedback" component={Feedback} />
        <Route exact path="/information-board" component={InformationBoard} />
        <Route exact path="/quick-links" component={QuickLinks} />
        <Route exact path="/safety-first" component={SafetyFirst} />
        <Route exact path="/cheque-drop-box" component={ChequeDropBox} />
        <Route exact path="/online-payment" component={OnlinePayment} />
        <Route
          exact
          path="/rtgs-and-neft-payment"
          component={RTGSAndNEFTPayment}
        />
        <Route
          exact
          path="/billing-complaint-resolution"
          component={BillingComplaintResolution}
        />
        <Route exact path="/nach" component={NACH} />
        <Route
          exact
          path="/pay-against-application"
          component={PayAgainstApplication}
        />
        <Route exact path="/callcenter" component={CallCenter} />
        <Route
          exact
          path="/customercareemailId"
          component={CustomerCareEmailId}
        />
        <Route
          exact
          path="/customer-care-office"
          component={CustomerCareOffice}
        />
        <Route exact path="/mobile-application" component={MobileApplication} />
        <Route
          exact
          path="/solar-rooftop-net-metering"
          component={SolarRoofTopNetMetering}
        />
        <Route
          exact
          path="/get-outage-notification"
          component={GetOutageNotification}
        />
        <Route exact path="/institutional" component={Institutional} />
        <Route exact path="/township" component={Township} />
        <Route exact path="/newconnection" component={NewConnection} />
        <Route exact path="/career" component={Career} />
        <Route exact path="/current-openings" component={CurrentOpenings} />
        <Route
          exact
          path="/third-party-meter-testing"
          component={ThirdPartyMeterTesting}
        />
        <Route exact path="/holiday-calendar" component={HolidayCalendar} />
        <Route exact path="/consumer-awareness" component={ConsumerAwareness} />
        <Route exact path="/faq" component={Faq} />
        <Route
          exact
          path="/allottees-multi-storied-buildings"
          component={AllotteesMultiStoriedBuildings}
        />
        <Route
          exact
          path="/single-point-to-multipont-conversion"
          component={SinglePointToMultipontConversion}
        />
        <Route
          exact
          path="/customer-care-office-queue-status"
          component={CustomerCareOfficeQueueStatus}
        />
        <Route
          exact
          path="/customer-care-office-queue-status-tech"
          component={CustomerCareOfficeQueueStatusTech}
        />
        <Route
          exact
          path="/supply-related-comaplaint"
          component={SupplyRelatedComaplaint}
        />
        <Route exact path="/register" component={Register} />
        <Route exact path="/status" component={Status} />
        <Route exact path="/feedbackform" component={FeedBackForm} />
        <Route
          exact
          path="/complaintmanagementprocess"
          component={ComplaintManagementProcess}
        />
        <Route exact path="/faqs" component={Faqs} />
        <Route
          exact
          path="/bill-related-complaint"
          component={BillRelatedComplaint}
        />
        <Route
          exact
          path="/bill-related-complaint-status"
          component={BillRelatedComplaintStatus}
        />
        <Route
          exact
          path="/Fuel-and-Power-Purchase-Cost-Adjustment"
          component={FPPCA}
        />
        <Route
          exact
          path="/NPCL-Tariff-Petition-ARR-2024-25"
          component={TariffPetetionARR2425}
        />
        <Route exact path="/ConsumerOwnChoiceMeter" component={ConsumerOwnChoiceMeter}     />
        <Route
          exact
          path="/bill-related-complaint-feedback"
          component={BillRelatedComplaintFeedback}
        />
        <Route
          exact
          path="/bill-related-complaint-CMP"
          component={BillRelatedComplaintCMP}
        />
        <Route
          exact
          path="/bill-related-complaint-FAQ"
          component={BillRelatedComplaintFAQ}
        />
        <Route exact path="/chequeguidelines" component={ChequeGuidelines} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/new-user" component={NewUser} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <Route exact path="/login-info" component={LoginInfo} />

        <Route exact path="/needhelpinsignin" component={NeedHelpInSignIn} />
        <Route exact path="/report-a-concern" component={ReportAConcern} />
        <Route
          exact
          path="/reportsafetyhazzard"
          component={ReportSafetyHazzard}
        />
        <Route exact path="/reporttheft" component={ReportTheft} />
        <Route exact path="/reportunethical" component={ReportUnethical} />
        <Route exact path="/values-beliefs" component={ValuesBeliefs} />
        <Route exact path="/dosndnts" component={Dosndnts} />
        <Route
          exact
          path="/easy-ways-to-lower-your-bill"
          component={EasyWaysToLowerYourBill}
        />

        <Route
          exact
          path="/safety-related-complaint"
          component={SafetyRelatedComplaint}
        />
        <Route
          exact
          path="/safety-related-complaint-status"
          component={SafetyRelatedComplaintStatus}
        />
        <Route
          exact
          path="/safety-related-complaint-feedback"
          component={SafetyRelatedComplaintFeedBack}
        />
        <Route
          exact
          path="/safety-related-complaint-cmp"
          component={SafetyRelatedComplaintCMP}
        />
        <Route
          exact
          path="/safety-related-complaint-faq"
          component={SafetyRelatedComplaintFAQ}
        />

        <Route
          exact
          path="/load-reduction-augmentation"
          component={LoadReductionAugmentation}
        />
        <Route
          exact
          path="/load-reduction-augmentation-doc"
          component={LoadReductionAugmentationDoc}
        />
        <Route
          exact
          path="/load-reduction-augmentation-sample"
          component={LoadReductionAugmentationSample}
        />
        <Route
          exact
          path="/load-reduction-augmentation-faq"
          component={LoadReductionAugmentationFaq}
        />
        <Route
          exact
          path="/load-reduction-augmentation-apply"
          component={LoadReductionAugmentationApply}
        />
        <Route
          exact
          path="/load-reduction-augmentation-status"
          component={LoadReductionAugmentationStatus}
        />
        <Route
          exact
          path="/load-reduction-augmentation-feedback"
          component={LoadReductionAugmentationFeedback}
        />

        <Route exact path="/rebate" component={Rebate} />
        <Route exact path="/rebate-payment" component={RebatePayment} />
        <Route exact path="/rebate-consumer" component={RebateConsumer} />
        <Route exact path="/rebate-scheme" component={RebateScheme} />
        <Route
          exact
          path="/rebate-facilitation"
          component={RebateFacilitation}
        />
        <Route exact path="/rebate-power" component={RebatePower} />
        <Route
          exact
          path="/rebate-consumer-LMV"
          component={RebateConsumerLMV}
        />

        <Route exact path="/rooftop-solar" component={RoofTopSolar} />
        <Route exact path="/sitemap2" component={SiteMap2} />
        <Route
          exact
          path="/whatsapp_based_services"
          component={WhatsappBasedServices}
        />

        <Route exact path="/safety" component={Safety} />
        <Route exact path="/safetyIndoors" component={SafetyIndoors} />
        <Route exact path="/safetyPrecautions" component={SafetyPrecautions} />

        <Route
          exact
          path="/new-service-connection"
          component={NewServiceConnection}
        />
        <Route
          exact
          path="/new-service-connection-calculate"
          component={NewServiceConnectionCalculate}
        />
        <Route
          exact
          path="/new-service-connection-document"
          component={NewServiceConnectionDocument}
        />
        <Route
          exact
          path="/new-service-connection-sample"
          component={NewServiceConnectionSample}
        />
        <Route
          exact
          path="/new-service-connection-online"
          component={NewServiceConnectionOnline}
        />

        <Route exact path="/ourValues" component={OurValues} />
        <Route exact path="/consumerOffer" component={ConsumerOffer} />
        <Route
          exact
          path="/consumerOfferjuggi"
          component={ConsumerOfferJuggi}
        />
        <Route
          exact
          path="/consumerOfferseasonal"
          component={ConsumerOfferSeasonal}
        />
        <Route exact path="/contactOverPhone" component={ContactOverPhone} />
        <Route
          exact
          path="/KnowYourPrepaidMeter"
          component={KnowYourPrepaidMeter}
        />
        <Route
          exact
          path="/consumerTestimonial"
          component={ConsumerTestimonial}
        />
        <Route exact path="/CGRFs-Ombudsman" component={Legal} />
        <Route exact path="/meterTesting" component={MeterTesting} />
        <Route
          exact
          path="/consumerContactProgram"
          component={ConsumerContactProgram}
        />
        <Route exact path="/NukkadNatak" component={NukkadNatak} />
        <Route exact path="/ConsumerWeek" component={ConsumerWeek} />
        <Route exact path="/KioskServices" component={KioskServices} />
        <Route
          exact
          path="/onlineMeterTesting"
          component={OnlineMeterTesting}
        />
        <Route
          exact
          path="/onlineMeterTestingStatus"
          component={OnlineMeterTestingStatus}
        />
        <Route exact path="/last-payment" component={LastPayment} />
        <Route exact path="/SMS_based_Services" component={SMSBasedServices} />

        <Route exact path="/nameMutation" component={NameMutation} />
        <Route
          exact
          path="/nameMutationSample"
          component={NameMutationSample}
        />
        <Route exact path="/nameMutationFaqs" component={NameMutationFaqs} />
        <Route exact path="/nameMutationApply" component={NameMutationApply} />
        <Route
          exact
          path="/nameMutationFeedback"
          component={NameMutationFeedback}
        />

        <Route exact path="/categoryChange" component={CategoryChange} />
        <Route
          exact
          path="/categoryChangeSample"
          component={CategoryChangeSample}
        />
        <Route
          exact
          path="/categoryChangeFaqs"
          component={CategoryChangeFaqs}
        />
        <Route
          exact
          path="/categoryChangeApply"
          component={CategoryChangeApply}
        />
        <Route
          exact
          path="/categoryChangeFeedback"
          component={CategoryChangeFeedback}
        />

        <Route exact path="/permanent" component={Permanent} />
        <Route exact path="/permanentSample" component={PermanentSample} />
        <Route exact path="/permanentFaqs" component={PermanentFaqs} />
        <Route exact path="/permanentApply" component={PermanentApply} />
        <Route exact path="/permanentFeedback" component={PermanentFeedback} />

        <Route exact path="/nameAndAddress" component={NameAndAddress} />
        <Route
          exact
          path="/nameAndAddressSample"
          component={NameAndAddressSample}
        />
        <Route
          exact
          path="/nameAndAddressFaqs"
          component={NameAndAddressFaqs}
        />
        <Route
          exact
          path="/nameAndAddressApply"
          component={NameAndAddressApply}
        />
        <Route
          exact
          path="/nameAndAddressFeedback"
          component={NameAndAddressFeedback}
        />

        <Route
          exact
          path="/meter-related-complaint"
          component={MeterRelatedComplaint}
        />
        <Route
          exact
          path="/meter-related-complaint-status"
          component={MeterRelatedComplaintStatus}
        />
        <Route
          exact
          path="/meter-related-complaint-feedback"
          component={MeterRelatedComplaintFeedback}
        />
        <Route
          exact
          path="/meter-related-complaint-CMP"
          component={MeterRelatedComplaintCMP}
        />
        <Route
          exact
          path="/meter-related-complaint-FAQ"
          component={MeterRelatedComplaintFAQ}
        />

        <Route
          exact
          path="/service-related-complaint"
          component={ServiceRelatedComplaint}
        />
        <Route
          exact
          path="/service-related-complaint-status"
          component={ServiceRelatedComplaintStatus}
        />
        <Route
          exact
          path="/service-related-complaint-feedback"
          component={ServiceRelatedComplaintFeedback}
        />
        <Route
          exact
          path="/service-related-complaint-CMP"
          component={ServiceRelatedComplaintCMP}
        />
        <Route
          exact
          path="/service-related-complaint-FAQ"
          component={ServiceRelatedComplaintFAQ}
        />

        <Route
          exact
          path="/theft-related-complaint"
          component={TheftRelatedComplaint}
        />
        <Route
          exact
          path="/theft-related-complaint-status"
          component={TheftRelatedComplaintStatus}
        />
        <Route
          exact
          path="/theft-related-complaint-feedback"
          component={TheftRelatedComplaintFeedback}
        />
        <Route
          exact
          path="/theft-related-complaint-CMP"
          component={TheftRelatedComplaintCMP}
        />
        <Route
          exact
          path="/theft-related-complaint-FAQ"
          component={TheftRelatedComplaintFAQ}
        />

        <Route exact path="/NPCL-creatives" component={NpclCreatives} />
        <Route exact path="/grievence" component={Grievence} />
        <Route exact path="/claimCompensation" component={ClaimCompensation} />
        <Route
          exact
          path="/claimCompensationStatus"
          component={ClaimCompensationStatus}
        />
        <Route exact path="/Document-Checklist" component={DocumentChecklist} />
        <Route exact path="/DMS-Website" component={DMSwebsite} />
        <Route exact path="/PageNotFound" component={PageNotFound} />
        <Redirect to="/PageNotFound" />
      </Switch>
      <Footer />
      <Copyright />
      <SiteMap />
      <AskTez />
    </>
  );
};

export default App;
