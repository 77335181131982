import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const ConsumerOfferJuggi = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Customer Offer
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                 

                  <div className="form_div">
                    <ul className="nav nav-tabs nav_tbds">
                      <li className="nav-item">
                        <Link
                          to="/consumerOfferjuggi"
                          className="nav-link active"
                          data-toggle="tab"
                        >
                          JHUGGI/ HUTMENTS/ PATRI SHOPKEEPERS
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/consumerOfferseasonal"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          SEASONAL INDUSTRIES IN LMV-6 AND HV-2
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content tab_main">
                      <div className="tab-pane fade show active" id="login1">
                        <div className="main_wrappersd">
                          <div className="row">
                            <div className="col-md-7">
                              <div className="companysection">
                                <legend>JHUGGI/ HUTMENTS/ PATRI SHOPKEEPERS:</legend>
                                <p>
                                Connections to Jhuggi / hutments / Patri Shopkeepers shall be given as temporary connection only and shall be energized through pre-paid meters only and the prospective consumer has to provide
                                </p>
                                <ul>
                                    <li style={{listStyle:"none"}}>Aadhar Card /</li>
                                    <li style={{listStyle:"none"}}>Pan Card/</li>
                                    <li style={{listStyle:"none"}}>Ration Card/</li>
                                    <li style={{listStyle:"none"}}>Voter ID Card/</li>
                                    <li style={{listStyle:"none"}}>Driving License/</li>
                                    <li style={{listStyle:"none"}}>Bank Account of Nationalized Bank only (one of these).</li>
                                </ul>
                               <p>All the papers issued in regard to this connection will boldly display that the same is a temporary connection and is not a proof of ownership of the said premises. These temporary connections shall be upto 2 KW only.</p>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ConsumerOfferJuggi;
