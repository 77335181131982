import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Slider from "react-slick";

const GetOutageNotification = () => {
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background:
                "url(" + "assets/img/inner/outage-notification.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10 main_heading">
                  <h1>
                    Self Submission
                    <br /> of Meter Reading
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/get-outage-notification" className="active">
                      Get Outage Notification
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                    
                      <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...settings2}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="our_other_links">
                        <h4>Other Links</h4>
                        <ul>
                          <li>
                            <Link to="#">
                              <span></span> Calculate Energy Consumption{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres" target="_blank">
                              <span></span>Payment Options{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online-vendor-registration"
                              target="_blank"
                            >
                              <span></span>Vendor Registration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Regulation &amp; Statutory Bodies{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div">
                      <div className="tab-content tab_main">
                        <div
                          className="tab-pane fade show active"
                          id="outagenotification"
                        >
                          <div className="innerhdnds">
                            <h2>
                              {" "}
                              <i
                                className="fa fa-calendar-o"
                                aria-hidden="true"
                              ></i>{" "}
                              Outage Notification
                            </h2>
                          </div>
                          <ul className="nav nav-tabs nav_tbds">
                            <li className="nav-item">
                              <Link
                                to="/get-outage-notification"
                                className="nav-link active"
                                data-toggle="tab"
                              >
                                <i
                                  className="fa fa-bar-chart"
                                  aria-hidden="true"
                                ></i>
                                Industrial
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/institutional"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                <i
                                  className="fa fa-graduation-cap"
                                  aria-hidden="true"
                                ></i>
                                Institutional
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/township"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                <i
                                  className="fa fa-building-o"
                                  aria-hidden="true"
                                ></i>
                                Township
                              </Link>
                            </li>
                          </ul>
                          <div className="tab-content tab_main">
                            <h4 className="mt-2">Industrial</h4>
                            <div className="card maincards">
                              <div className="card-header">
                                <div className="row">
                                  <div className="col-md-4">
                                    <p>Area Affected</p>
                                  </div>
                                  <div className="col-md-8 area_ptsd">
                                    <p>
                                      PART OF TOY CITY, MUP-1, MUP-2,96 UDYOG
                                      KENDRA ECOTECH-III
                                    </p>
                                    <span>Planned</span>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-4 mainhdnsdf">
                                    <p>From</p>
                                    <p>To</p>
                                    <p className="highlightsec">Remarks</p>
                                  </div>
                                  <div className="col-md-8 area_ptsd">
                                    <p>16/9/2021,17:30 hrs</p>
                                    <p>16/9/2021,18:30 hrs</p>
                                    <p className="highlightsec2">
                                      Supply interruption due to planned
                                      shutdown of electric network from 17:30
                                      hrs, 16-Sep-21. Expected time of
                                      restoration 18:30 hrs, 16-Sep-21.-NPCL
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card maincards">
                              <div className="card-header">
                                <div className="row">
                                  <div className="col-md-4">
                                    <p>Area Affected</p>
                                  </div>
                                  <div className="col-md-8 area_ptsd">
                                    <p>
                                      PART OF TOY CITY, MUP-1, MUP-2,96 UDYOG
                                      KENDRA ECOTECH-III
                                    </p>
                                    <span>Planned</span>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-4 mainhdnsdf">
                                    <p>From</p>
                                    <p>To</p>
                                    <p className="highlightsec">Remarks</p>
                                  </div>
                                  <div className="col-md-8 area_ptsd">
                                    <p>16/9/2021,17:30 hrs</p>
                                    <p>16/9/2021,18:30 hrs</p>
                                    <p className="highlightsec2">
                                      Supply interruption due to planned
                                      shutdown of electric network from 17:30
                                      hrs, 16-Sep-21. Expected time of
                                      restoration 18:30 hrs, 16-Sep-21.-NPCL
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card maincards">
                              <div className="card-header">
                                <div className="row">
                                  <div className="col-md-4">
                                    <p>Area Affected</p>
                                  </div>
                                  <div className="col-md-8 area_ptsd">
                                    <p>
                                      PART OF TOY CITY, MUP-1, MUP-2,96 UDYOG
                                      KENDRA ECOTECH-III
                                    </p>
                                    <span>Planned</span>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-4 mainhdnsdf">
                                    <p>From</p>
                                    <p>To</p>
                                    <p className="highlightsec">Remarks</p>
                                  </div>
                                  <div className="col-md-8 area_ptsd">
                                    <p>16/9/2021,17:30 hrs</p>
                                    <p>16/9/2021,18:30 hrs</p>
                                    <p className="highlightsec2">
                                      Supply interruption due to planned
                                      shutdown of electric network from 17:30
                                      hrs, 16-Sep-21. Expected time of
                                      restoration 18:30 hrs, 16-Sep-21.-NPCL
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default GetOutageNotification;
