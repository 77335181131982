import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const FppCA  = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/Fuel-and-Power-Purchase-Cost-Adjustment" className="active">
                      Fuel and Power Purchase Cost Adjustment (FPPCA)
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    
                    <div className="tab-content tab_main">
                     

                      <div className="tab-pane fade show active" id="login2">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="companysection">
                              <legend> Fuel and Power Purchase Cost Adjustment (FPPCA) </legend>
                              
                                  <b>  Q1 FY 2023-24 </b>
                                  <hr></hr>
                              
                                  <ul className="tariff_standard">
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/FPPCA/FPPCA Public Notice_Q1 FY 2023-24.pdf"
                                target="_blank" rel="noreferrer"
                              >
                               1. FPPCA Public Notice_Q1 FY 2023-24
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/FPPCA/NPCL's Submission for Q1 FY 2023-24.pdf"
                                target="_blank" rel="noreferrer"
                              >
                              2. NPCL's Submission for Q1 FY 2023-24
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/FPPCA/Annexure-1 FPPCA FY24 (Qtr-1).pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                3. Annexure-1 FPPCA FY24 (Qtr-1)
                              </a>
                            </li>

                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/FPPCA/Hon'ble UPERC Order Q1 dt-28-08-2023.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                4. Hon'ble UPERC Order dt. 28.08.2023
                              </a>
                            </li>

                            <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/FPPCA/Annexure-1 FPPCA FY24 (Qtr-1).xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                                5.Annexure-1 FPPCA FY24 (Qtr-1)
                              </a>
                            </li>
                          </ul>

                        <div className="clearfix padd-top20 padd-bottom20"></div>

                          <b>  Q4 FY 2022-23 </b>
                                  <hr></hr>
                              
                                  <ul className="tariff_standard">
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/FPPCA/FPPCA Public Notice_Q4 FY 2022-23.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                1. FPPCA Public Notice_Q4 FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/FPPCA/NPCL's Submission for Q4 FY 2022-23.pdf"
                                target="_blank" rel="noreferrer"
                              >
                                2. NPCL's Submission for Q4 FY 2022-23
                              </a>
                            </li>
                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/FPPCA/Annexure-1 FPPCA FY23 (Qtr-4).pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                3. Annexure-1 FPPCA FY23 (Qtr-4)
                              </a>
                            </li>

                            <li>
                              <img src="assets/img/pdf.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/FPPCA/Hon'ble UPERC Order Q4 dt-28-08-2023.pdf"
                                target="_blank" rel="noreferrer" 
                              >
                                4. Hon'ble UPERC Order dt. 28.08.2023
                              </a>
                            </li>

                          <li>
                              <img src="assets/img/excel-icon.png" alt="" />
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/FPPCA/Annexure-1 FPPCA FY23 (Qtr-4).xlsx"
                                target="_blank" rel="noreferrer" 
                              >
                            5. Annexure-1 FPPCA FY23 (Qtr-4)
                              </a>
                            </li>
                          </ul>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default FppCA;
