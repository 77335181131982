import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const ConsumerAwareness = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="assets/images/consumer-awareness-banner.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Consumer Awareness</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="consumer-portal.html">Consumer Portal</a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/knowledge-center">Knowledge Center</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-awareness" className="active">
                      Consumer Awareness
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="our_other_links2">
                        <h3>Other Links</h3>
                        <ul>
                          <li>
                            <Link to="#">Calculate Energy Consumption</Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres">
                              Payment Options
                            </Link>
                          </li>
                          <li>
                            <Link to="/online-vendor-registration">
                              Vendor Registration
                            </Link>
                          </li>
                          <li>
                            <a href="#">Regulation &amp; Statutory Bodies</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                  
                    <div className="form_div cashcollectioncenter">
                      <legend>Consumer Awareness</legend>
                      <div id="gallery">
                        <div id="image-gallery">
                          <div className="row">

                          
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic1.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic1.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic2.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic2.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic3.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic3.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic4.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic4.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic5.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic5.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic6.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic6.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic7.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic7.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic8.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic8.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic9.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic9.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic10.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic10.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic11.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic11.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic12.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic12.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic13.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic13.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic14.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic14.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic15.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic15.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic16.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic16.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic17.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic17.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic18.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic18.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic19.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic19.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic20.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic20.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic21.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic21.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic22.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic22.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic23.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic23.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic24.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic24.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic25.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic25.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic26.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic26.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic27.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic27.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic28.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic28.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic29.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic29.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic30.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic30.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="images/consumer-awarence-pic31.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic31.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                              <div className="img-wrapper">
                                <a href="assets/images/consumer-awarence-pic32.jpg">
                                  <img
                                    src="assets/images/consumer-awarence-pic32.jpg"
                                    className="img-responsive"
                                  />
                                </a>
                                <div className="img-overlay">
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ConsumerAwareness;
