import React, { useEffect } from "react";
import Announcement from "../components/Announcement";
import Slider from "react-slick";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import SampleNextArrow from "../components/SampleNextArrow";
import SamplePrevArrow from "../components/SamplePrevArrow";

const ConsumerTestimonial = () => {
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows:true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/loginpage.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="active">
                      Consumer Testimonial
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 bg_cols">
                    <div className="form_div wrapper-pd-top-login">
                      <div className="bs-example bs-example-1">
                        <div className="tab-content tab_main">
                          <legend>Consumer Testimonial</legend>
                          <div
                            className="slideraward slider"
                            style={{ background: "#eee" }}
                          >
                            <Slider {...settings2} id="a">
                              <img src="assets/images/Consumer Testimonial updated.png" alt="subs" />

                              <img src="assets/images/sdgtr.png" alt="subs" />

                              <img src="assets/images/dfgrthty.png" alt="subs" />
                              <img src="assets/images/gyugyg.png" alt="subs" />
                              <img src="assets/images/zxasdsd.png" alt="subs" />
                              <img src="assets/images/Consumer Testimonial updatedsdtr.jpg" alt="subs" />
                              <img src="assets/images/khugty.png" alt="subs" />
                              <img src="assets/images/Consumer Testimonial erghrfhrt.png" alt="subs" />
                              <img src="assets/images/sdger.png" alt="subs" />
                              <img src="assets/images/Consumer Testimonial updated.png" alt="subs" />
                              <img src="assets/images/yugyutf.png" alt="subs" />
                              <img src="assets/images/sdsgeds.png" alt="subs" />

                              <img src="assets/images/ed.png" alt="subs" />
                              <img src="assets/images/Slide9.png" alt="subs" />
                              <img src="assets/images/Slide8.png" alt="subs" />
                              <img src="assets/images/Slide7.png" alt="subs" />
                              <img src="assets/images/Slide6.png" alt="subs" />

                              <img src="assets/images/Slide5.png" alt="subs" />
                              <img src="assets/images/Slide4.png" alt="subs" />
                              <img src="assets/images/Slide3.png" alt="subs" />
                              <img src="assets/images/Slide2.png" alt="subs" />
                              <img src="assets/images/Slide1.png" alt="subs" />
                            </Slider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ConsumerTestimonial;
