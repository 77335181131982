import React from "react";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";

const SafetyRelatedComplaint = () => {
  return (
    <>
     <div id="bodyaddid">
     <Header />
    <FixMenu />
     <section className="consumer_inner_bg"  style={{
              background: "url(" + "assets/images/safety-related-complaint-banner.jpg" + ")",
              backgroundSize: "100% auto", height:"400px"
            }}>
    <div className="container-fluid">
    <div className="apply_title">
    <h1>Safety Related Complaint</h1>
    </div>
    </div>
    </section>

     <section className="main_sectiods">
     <div className="container">
       <div className="row"> 
       <div className="col-lg-12 brdcum_designs">
       <ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to="#">Home</Link><span className="icondsds"><i className="fa fa-chevron-right" aria-hidden="true"></i></span>
					</li>
					<li className="breadcrumb-item">
						<Link to="/consumer-portal">Consumer Portal</Link><span className="icondsds"><i className="fa fa-chevron-right" aria-hidden="true"></i></span>
					</li>
                    <li className="breadcrumb-item">
						<Link to="/register-complain">Register a Complaint</Link><span className="icondsds"><i className="fa fa-chevron-right" aria-hidden="true"></i></span>
					</li>
                    <li className="breadcrumb-item">
						<Link to="#" className="active">Safety Related Complaint</Link>
					</li>
				</ol>
       </div>
       </div>
       <div className="row justify-content-center padtops">
       <div className="col-sm-12">
         <div className="row">
         <div className="col-sm-12 bg_cols">
         <div className="form_div" style={{marginLeft:"20px"}}>
         <div className="tab-content tab_main">
         <div className="tab-pane fade show active" id="newconnection">
         <div className="innerhdnds">
                <h2>Safety Related Complaint</h2>
        </div>
        <ul className="nav nav-tabs nav_tbds">
        <li className="nav-item">
            <Link to="/safety-related-complaint" className="nav-link active" data-toggle="tab">Register</Link>
        </li>
         <li className="nav-item">
            <Link to="/safety-related-complaint-status" className="nav-link" data-toggle="tab">Status</Link>
        </li>
        <li className="nav-item">
            <Link to="/safety-related-complaint-feedback" className="nav-link" data-toggle="tab">Feedback</Link>
        </li>
        <li className="nav-item">
            <Link to="/safety-related-complaint-cmp" className="nav-link" data-toggle="tab">Complaint Management Process (Guide)</Link>
        </li>
		<li className="nav-item">
            <Link to="/safety-related-complaint-faq" className="nav-link " data-toggle="tab">FAQs</Link>
        </li>
                        </ul>
    <div className="tab-content tab_main">
    <div className="tab-pane fade show active" id="sampledoc">
    <h4 className="mt-2">New Registration</h4>
            <p><b>Welcome to NPCL Supplier Registration Portal</b></p>
            <div className="companysection">
            <div className="reg_frm">
                <div className="row">
                <div className="col-md-6 form-group form_gp_des">
                      <label>Email Id <span>*</span></label>
                      <input type="text" name="name" className="form-control" placeholder="Enter your email id" required/>
                 </div>
                 <div className="col-md-6 form-group form_gp_des">
                      <label>Consumer Name <span>*</span></label>
                      <input type="text" name="name" className="form-control" placeholder="Enter company name" required/>
                </div>
                <div className="col-md-6 form-group form_gp_des">
                <label>Contact A/C <span>*</span></label>
                <input type="text" name="name" className="form-control" required/>
              </div>
              <div className="col-md-6 form-group form_gp_des">
                <label>Contact Number <span>*</span></label>
                <input type="text" name="name" className="form-control" required/>
              </div>
              <div className="col-md-6 form-group form_gp_des">
                <label>Mobile Number <span>*</span></label>
                <input type="text" name="name" className="form-control" required/>
              </div>
              <div className="col-md-6 form-group form_gp_des">
                      <label>Address <span>*</span></label>
                      <input type="text" name="name" className="form-control" placeholder="Enter company name" required/>
                </div>
              <div className="col-md-6 form-group form_gp_des">
                      <label>Region <span>*</span></label>
                      <select className="form-control">
                       <option>Select Region</option>
                     </select>
                </div>
                <div className="col-md-6 form-group form_gp_des">
                    <label>City <span>*</span></label>
                    <select className="form-control">
                     <option>Select City</option>
                   </select>
                 </div>
                 <div className="col-md-6 form-group form_gp_des">
                  <label>District <span>*</span></label>
                  <select className="form-control">
                   <option>Select District</option>
                 </select>
               </div>
               <div className="col-md-6 form-group form_gp_des">
                <label>Postal Code <span>*</span></label>
                <input type="text" name="name" className="form-control" required/>
              </div>
              </div>
              <div className="row">
   <div className="col-md-12 form_gp_des">
     <p><b>Declaration</b></p>
     <input type="checkbox" className="inputbtns" name=""/> <span>*</span> Yes I have read the data privacy statement and accept the terms.
   </div>
   <div className="col-md-4">
     <button type="submit" id="submit" className="sub_btn2 submit" name="submit">Submit</button>
   </div>
 </div>
              </div>
            </div>
    </div>
    </div>
         </div>
         </div>
         </div>
         </div>
         </div>
       </div>
       </div>
     </div>
     </section>
     </div>
    </>
  )
}

export default SafetyRelatedComplaint