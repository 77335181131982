import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Announcement from "../components/Announcement";

const RTGSAndNEFTPayment = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/payment-collection.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center"></div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center"></div>
            <div className="col-sm-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                   <Link to="#">Home </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link href="#" className="active">
                    Payment Options
                  </Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/rtgs-and-neft-payment" className="">
                    RTGS and NEFT Payment
                  </Link>
                </li>
              </ol>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <ul className="nav nav-tabs nav_tbds">
                        <li className="nav-item">
                          <Link
                            to="/cash-collection-centres"
                            className="nav-link  "
                            data-toggle="tab"
                          >
                            Cash/Cheque Collection Centres
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            href="https://iwebapps.noidapower.com:8032/DigitalPayment_Centre.aspx"
                            className="nav-link"
                          >
                              Digital Payment Centres
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/cheque-drop-box"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Cheque Drop Box
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/online-payment"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Online Payment
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/rtgs-and-neft-payment"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            RTGS and NEFT Payment
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/billing-complaint-resolution"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Billing Complaint Resolution
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/nach"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            NACH
                          </Link>
                        </li>
                      </ul>
                      <div className="row cashcollectioncenter">
                        <legend>RTGS AND NEFT PAYMENT</legend>
                        <p>
                          We would like to bring to your notice that you may now
                          make payment of your monthly electricity consumption
                          bills through the mode of RTGS and NEFT from your own
                          bank account.
                        </p>
                        <p>
                          You are required to enter the following details for
                          making the payment through RTGS/NEFT:
                        </p>
                        <div className="col-sm-12 row innwrper">
                          <div className="col-sm-9">
                            <h5>Beneficiary Account No.</h5>
                            <p>NPCLTDXXXXXX</p>
                            <p>
                              'xxxxxx' should be Customer's Contract Account
                              Number as mentioned on Bill.
                            </p>
                            <p>
                              (It is cautioned that the Business Partner or
                              Consumer numbers should not be used here, kindly
                              ensure that only Contract Account Number as
                              mentioned on bill is used)
                            </p>
                          </div>
                          <div className="col-sm-3">
                            <img src="assets/Images/Contract-No-Img.jpg" />
                          </div>
                        </div>

                        <div className="innwrper">
                          <h5>Beneficiary Name</h5>
                          <p>NOIDA POWER COMPANY LIMITED</p>
                        </div>

                        <div className="innwrper">
                          <h5>Beneficiary Address</h5>
                          <p>
                            Electric Sub-station, Knowledge Park – IV, Greater
                            Noida, Gautam Buddha Nagar, UP – 201310
                          </p>
                        </div>

                        <div className="innwrper">
                          <h5>Bank Name</h5>
                          <p>HDFC BANK LTD</p>
                        </div>
                        <div className="innwrper">
                          <h5>Branch Name</h5>
                          <p>Sandoz Branch, Mumbai</p>
                        </div>

                        <div className="innwrper">
                          <h5>IFSC Code</h5>
                          <p>HDFC0000240</p>
                        </div>

                        <p>
                          It would also be necessary for you to intimate us,
                          i.e. NPCL after making the first payment in order to
                          initiate the new arrangement.
                        </p>
                        <p>
                          To send the intimation, please click here.
                          <a
                            href="https://iwebapps.noidapower.com:8032/Neftdetails.aspx"
                            target="_blank"
                          >
                            {" "}
                            click here.
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default RTGSAndNEFTPayment;
