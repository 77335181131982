import React from "react";
import Announcement from "../components/Announcement";
import Slider from "react-slick";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";

const RebatePayment = () => {
    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
      };
  return (
    <>
        <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/login" className="active">
                      Rebates
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="row">
                <div className="col-sm-4 imphdmdev">
                <div className="inner_boxs">
                <div className="our_other_links">
                <ul className="ahdul">
                <li className="nav-item"><Link to="/rebate" className="nav-link" >Pre-Paid Meters / Automatic Meter Reading System  </Link></li>
                     <li className="nav-item"><Link to="/rebate-payment" className="nav-link">Payment on or Before Due Date </Link></li>
                     <li className="nav-item"><Link to="/rebate-consumer" className="nav-link">Consumer Who Shift from Unmetered to Metered Connections </Link></li>
                     <li className="nav-item"><Link to="/rebate-scheme" className="nav-link">Scheme for Advanced deposit for future monthly energy bills  </Link></li>
                     <li className="nav-item"><Link to="/rebate-facilitation" className="nav-link">Facilitation Charge For online Payment</Link></li>
                      <li className="nav-item"><Link to="/rebate-power" className="nav-link">Power Looms LMV-2 and LMV-6 Categories </Link></li>
                     <li className="nav-item"><Link to="/rebate-consumer-LMV" className="nav-link">Consumers getting supply as per rural schedule LMV-6  </Link></li>
                 </ul>
                </div>
                </div>
                </div>
                <div className="col-sm-8 bg_cols">
                <div className="form_div wrapper-pd-top-login">
                <div className="bs-example bs-example-1">
                <legend>Rebates</legend>
                 <div className="profile_cont">
                 <h2>Payment on or Before Due Date </h2>
	                      <ol>
	                    	<li>A rebate at 1.00% (5.00% for LMV-5) of current month charges (Fixed charges + Energy Charges) shall be given in case full payment (including arrears) is made on or before the due date. The consumers who have made advance deposit against their future monthly energy bills shall also be eligible for the above rebate
                         </li>
		                </ol>
                 </div>
                </div>
                 </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
    </>
  )
}

export default RebatePayment