import React from "react";
import Announcement from "../components/Announcement";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import { useState } from "react";

const SiteMap2 = () => {
  const [aboutusOpen, setAboutusOpen] = useState(false);
  const [myAccountOpen, setMyAccountOpen] = useState(false);
  const [regulationOpen, setRegulationOpen] = useState(false);
  const [procurementOpen, setProcurementOpen] = useState(false);
  const [careerOpen, setCareerOpen] = useState(false);
  const [consumerOpen, setConsumerOpen] = useState(false);
  const [knowledgeOpen, setKnowledegeOpen] = useState(false);
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Sitemap
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="row">
                    <div className="form_div">
                      <legend>Sitemap</legend>
                      <div className="row">
                        <div className="col-md-4">
                          <img
                            src="assets/images/sitemap.jpg"
                            className="sitemap_pic"
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="nav-side-menu">
                            <div className="brand">Home</div>
                            <i
                              className="fa fa-bars fa-2x toggle-btn"
                              data-toggle="collapse"
                              data-target="#menu-content"
                            ></i>
                            <div className="menu-list">
                              <ul
                                id="menu-content"
                                className="menu-content collapse out"
                              >
                                <li
                                  data-toggle="collapse"
                                  data-target="#products"
                                  className="collapsed"
                                  onClick={() => setAboutusOpen(!aboutusOpen)}
                                >
                                  <a>
                                    About Us <span className="arrow"></span>
                                  </a>
                                </li>

                                {aboutusOpen ? (
                                  <ul className="sub-menu " id="products">
                                    <li>
                                      <Link
                                        to="/company-profile"
                                        target="_blank"
                                      >
                                        Company Profile
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/corporate-policies"
                                        target="_blank"
                                      >
                                        Corporate Policies
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/energy-services"
                                        target="_blank"
                                      >
                                        Energy Services
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/credit-rating" target="_blank">
                                        Credit Rating
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/initiativeundertaken"
                                        target="_blank"
                                      >
                                        Initiative Undertaken
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/challangeahead"
                                        target="_blank"
                                      >
                                        Challenges Ahead
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/lifestyle-improvement"
                                        target="_blank"
                                      >
                                        LifeStyle Improvement
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/csractivities" target="_blank">
                                        CSR Activities
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/commitment-to-environment"
                                        target="_blank"
                                      >
                                        Commitment To Environment
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/community-development"
                                        target="_blank"
                                      >
                                        Community Development
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/our-customer" target="_blank">
                                        Our Customer
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/awards_recognition.aspx"
                                        target="_blank"
                                      >
                                        Award & Recognition
                                      </a>
                                    </li>
                                    <li>
                                      <Link
                                        to="/vission-mission"
                                        target="_blank"
                                      >
                                        Vision & Mission
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/rpsg-group-vision-and-core-values"
                                        target="_blank"
                                      >
                                        RPSG Group Vision and Core Values
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/corporate-social-responsibility"
                                        target="_blank"
                                      >
                                        Corporate Social Responsibility
                                      </Link>
                                    </li>
                                  </ul>
                                ) : null}

                                <li
                                  data-toggle="collapse"
                                  data-target="#products"
                                  className="collapsed"
                                  onClick={() =>
                                    setMyAccountOpen(!myAccountOpen)
                                  }
                                >
                                  <a>
                                    My Account <span className="arrow"></span>
                                  </a>
                                </li>

                                {myAccountOpen ? (
                                  <ul className="sub-menu " id="products">
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/Authenticate/Login.aspx"
                                        target="_blank"
                                      >
                                        User Login
                                      </a>
                                    </li>
                                    <li>
                                      <Link
                                        to="/needhelpinsignin"
                                        target="_blank"
                                      >
                                        Need Help In Sign In
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/help/Need_Help_in_SignIn.html"
                                        target="_blank"
                                      >
                                        New User Sign In
                                      </a>
                                    </li>
                                    {/* <li>
                                      <Link to="/login-info" target="_blank">
                                        Login Info
                                      </Link>
                                    </li> */}
                                    {/* <li>
                                      <Link to="/login" target="_blank">
                                        Update profile
                                      </Link>
                                    </li> */}
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/Authenticate/registration-form.aspx"
                                        target="_blank"
                                      >
                                        New User Registration
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/Authenticate/ForgotPwd.aspx"
                                        target="_blank"
                                      >
                                        Forget Password
                                      </a>
                                    </li>
                                    {/* <li>
                                      <Link
                                        to="/online-vendor-registration"
                                        target="_blank"
                                      >
                                        Vender Registration
                                      </Link>
                                    </li> */}
                                  </ul>
                                ) : null}

                                <li
                                  data-toggle="collapse"
                                  data-target="#service"
                                  className="collapsed"
                                  onClick={() =>
                                    setRegulationOpen(!regulationOpen)
                                  }
                                >
                                  <a>
                                    Regulation <span className="arrow"></span>
                                  </a>
                                </li>

                                {regulationOpen ? (
                                  <ul className="sub-menu " id="service">
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/download/Tariff%20applicable%20from%204th%20August%202022.pdf"
                                        target="_blank"
                                      >
                                        Tariff applicable from 4th August 2022
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/download/NPCL%20Tariff%20Order%20for%20FY%202022-23%20dated%2020th%20July%202022.pdf"
                                        target="_blank"
                                      >
                                        NPCL Tariff Order for FY 2022-23 dated
                                        20th July 2022
                                      </a>
                                    </li>
                                    <li>
                                      <Link
                                        to="/cost-data-book"
                                        target="_blank"
                                      >
                                        Cost Data Book
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/download/RSPV%20Regulation%202019%20dt.%2004-01-2019.pdf"
                                        target="_blank"
                                      >
                                        UPERC Roof top Solar PV regulations 2019
                                      </a>
                                    </li>
                                    <li>
                                      <Link
                                        to="/tarrif-schedule"
                                        target="_blank"
                                      >
                                        Tariff Schedule
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/up-electricity-supply-code"
                                        target="_blank"
                                      >
                                        UP Electricity Supply Code – upto 12th
                                        Amendment
                                      </Link>
                                    </li>
                                  </ul>
                                ) : null}

                                <li
                                  data-toggle="collapse"
                                  data-target="#new"
                                  className="collapsed"
                                  onClick={() =>
                                    setProcurementOpen(!procurementOpen)
                                  }
                                >
                                  <a>
                                    Procurement <span className="arrow"></span>
                                  </a>
                                </li>

                                {procurementOpen ? (
                                  <ul className="sub-menu" id="new">
                                    <li>
                                      <a
                                        href="https://gtw1.noidapower.com:8098/supplier/registration"
                                        target="_blank"
                                      >
                                        Online Vendor Registration
                                      </a>
                                    </li>
                                    <li>
                                      <Link
                                        to="/contact-for-eprocurement"
                                        target="_blank"
                                      >
                                        Contact for e-Procurement
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/contact-for-power-procurement"
                                        target="_blank"
                                      >
                                        Contact for Power-Procurement
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/tenders" target="_blank">
                                        Tenders
                                      </Link>
                                    </li>
                                  </ul>
                                ) : null}

                                <li
                                  data-toggle="collapse"
                                  data-target="#career"
                                  className="collapsed"
                                  onClick={() => setCareerOpen(!careerOpen)}
                                >
                                  <a>
                                    Careers <span className="arrow"></span>
                                  </a>
                                </li>

                                {careerOpen ? (
                                  <ul className="sub-menu" id="career">
                                    <li>
                                      <Link to="/career" target="_blank">
                                        Current Openings
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/career" target="_blank">
                                        Upload Your CV
                                      </Link>
                                    </li>
                                  </ul>
                                ) : null}

                                <li
                                  data-toggle="collapse"
                                  data-target="#career"
                                  className="collapsed"
                                  onClick={() => setConsumerOpen(!consumerOpen)}
                                >
                                  <a>
                                    Consumer Portal{" "}
                                    <span className="arrow"></span>
                                  </a>
                                </li>

                                {consumerOpen ? (
                                  <ul className="sub-menu" id="career">
                                    <li>
                                      <Link to="/rebate" target="_blank">
                                        Rebates
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/rebate-payment"
                                        target="_blank"
                                      >
                                        Rebate Payment
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/rebate-consumer"
                                        target="_blank"
                                      >
                                        Rebate Consumer
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/rebate-scheme" target="_blank">
                                        Rebate Scheme
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/rebate-facilitation"
                                        target="_blank"
                                      >
                                        Rebate Facilitation
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/rebate-power" target="_blank">
                                        Rebate Power
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/rebate-consumer-LMV"
                                        target="_blank"
                                      >
                                        Rebate Consumer LMV
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/callcenter" target="_blank">
                                        Call Center
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/new-service-connection-document"
                                        target="_blank"
                                      >
                                        Document CheckList
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/self_meter_reading.aspx"
                                        target="_blank"
                                      >
                                        Self Meter Reading
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/apl/quick_pay.aspx"
                                        target="_blank"
                                      >
                                        Pay Against Application
                                      </a>
                                    </li>
                                    <li>
                                      <Link
                                        to="/mobile-application"
                                        target="_blank"
                                      >
                                        Mobile Application
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/callcenter_email.aspx"
                                        target="_blank"
                                      >
                                        Customer Care E-Mail ID
                                      </a>
                                    </li>
                                    <li>
                                      <Link
                                        to="/customer-care-office"
                                        target="_blank"
                                      >
                                        Customer Care Center
                                      </Link>
                                    </li>
                                    {/* <li>
                                      <Link
                                        to="/mobile-application"
                                        target="_blank"
                                      >
                                        Mobile Application
                                      </Link>
                                    </li> */}
                                    <li>
                                      <Link
                                        to="/complaintmanagementprocess"
                                        target="_blank"
                                      >
                                        Complaint Management
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/online-applications"
                                        target="_blank"
                                      >
                                        Online Application
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/Customer_Feedback_5.aspx"
                                        target="_blank"
                                      >
                                        Feedback Form
                                      </a>
                                    </li>
                                    <li>
                                      <Link to="/feedback" target="_blank">
                                        Feedback
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/consumer-portal"
                                        target="_blank"
                                      >
                                        Consumer Portal
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/NPCL_creatives.aspx"
                                        target="_blank"
                                      >
                                        Consumer Awareness
                                      </a>
                                    </li>
                                    <li>
                                      <Link
                                        to="/information-board"
                                        target="_blank"
                                      >
                                        Information Center
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/new-service-connection-calculate"
                                        target="_blank"
                                      >
                                        Calculate Your Load
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/third-party-meter-testing"
                                        target="_blank"
                                      >
                                        Third Part Meter Testing
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/pre-paid-meter-recharge"
                                        target="_blank"
                                      >
                                        Know Your Prepaid Meter
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/report_safety_hazards.aspx"
                                        target="_blank"
                                      >
                                        Report Safety Hazzard
                                      </a>
                                    </li>
                                    {/* <li>
                                      <Link
                                        to="/safety-related-complaint"
                                        target="_blank"
                                      >
                                        Safety Related Complaints
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/safety-related-complaint-status"
                                        target="_blank"
                                      >
                                        Safety Related Complaints Status
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/safety-related-complaint-feedback"
                                        target="_blank"
                                      >
                                        Safety Related Complaints Feedback
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/safety-related-complaint-cmp"
                                        target="_blank"
                                      >
                                        Safety Related Complaints CMP
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/safety-related-complaint-faq"
                                        target="_blank"
                                      >
                                        Safety Related Complaints FAQ
                                      </Link>
                                    </li> */}
                                    <li>
                                      <Link
                                        to="/rebate-consumer-LMV"
                                        target="_blank"
                                      >
                                        Seasonal Industries in in LMV-6 and HV-2
                                      </Link>
                                    </li>
                                  </ul>
                                ) : null}

                                <li
                                  data-toggle="collapse"
                                  data-target="#career"
                                  className="collapsed"
                                  onClick={() =>
                                    setKnowledegeOpen(!knowledgeOpen)
                                  }
                                >
                                  <a>
                                    Knowledge Center{" "}
                                    <span className="arrow"></span>
                                  </a>
                                </li>

                                {knowledgeOpen ? (
                                  <ul className="sub-menu" id="career">
                                    <li>
                                      <Link to="/dosndnts" target="_blank">
                                        Do & Dont's
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/safety-first" target="_blank">
                                        Safety Tips
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        href="https://iwebapps.noidapower.com:8032/holiday.aspx"
                                        target="_blank"
                                      >
                                        Holiday Calendar
                                      </a> 
                                    </li>
                                    <li>
                                      <Link to="/faq" target="_blank">
                                        FAQ
                                      </Link>
                                    </li>
                                  </ul>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SiteMap2;
