import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const ConsumerEngagementAndAwareness = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="assets/images/asdf.png" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Consumer Engagement and Awareness</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home</a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Portal</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to="/consumer-engagement-and-awareness"
                      className="active"
                    >
                      Consumer Engagement and Awareness
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <h2 className="online_heading">
                      Consumer Engagement and Awareness
                    </h2>

                    <div className="rediobuttonstyle">
                      <form className="form">
                        <div className="plan">
                          <ul className="box_online">
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/consumer_Contact.aspx"
                                target="_blank"
                              >
                                <img
                                  src="assets/images/ConsumerContactProgram.jpg"
                                  alt=""
                                />{" "}
                                Consumer Contact Programme
                              </a>
                            </li>
                            <li>
                              <a href="/ConsumerWeek">
                                <img
                                  src="assets/images/consumerweek4.jpg"
                                  alt=""
                                />{" "}
                                Consumer Week
                              </a>
                            </li>
                            <li>
                              <Link to="/NukkadNatak">
                                <img
                                  src="assets/images/nukkad-natak-05.jpg"
                                  alt=""
                                />
                                Nukkad Natak
                              </Link>
                            </li>
                            <li>
                              <a
                                href="https://www.youtube.com/channel/UCtR4-FW_xyalLl8nPRBfxMw?view_as=subscriber"
                                target="_blank"
                              >
                                <img src="assets/images/youtube.jpg" alt="" />{" "}
                                Youtube
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/NPCL_creatives.aspx"
                                target="_blank"
                              >
                                <img
                                  src="assets/images/creative_guide.jpg"
                                  alt=""
                                />{" "}
                                Creatives (Guide)
                              </a>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ConsumerEngagementAndAwareness;
