import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const ReportTheft = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background:
                "url(" + "assets/img/inner/report-a-concern.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10 main_heading">
                  <h1>
                    Self Submission
                    <br /> of Meter Reading
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="topiconlist">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-sm-6 p-0">
                  <div className="anadd">
                    <div className="row justify-content-end p-0 m-0">
                      <div className="col-sm-3 p-0">
                        <h4>ANNOUNCEMENT</h4>
                      </div>
                      <div className="col-sm-9 p-0">
                        <div className="flex5258wrap fleright1">
                          <marquee>
                            <div className="sliderannoucement">
                              <a href="#">
                                UPERC Preventive Guidelines for Corona Virus
                              </a>
                            </div>
                            <div className="sliderannoucement">
                              <a href="#">
                                UPERC Preventive Guidelines for Corona Virus
                              </a>
                            </div>
                            <div className="sliderannoucement">
                              <a href="#">
                                UPERC Preventive Guidelines for Corona Virus
                              </a>
                            </div>
                            <div className="sliderannoucement">
                              <a href="#">
                                UPERC Preventive Guidelines for Corona Virus
                              </a>
                            </div>
                          </marquee>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 p-0">
                  <div className="flex5258wrap">
                    <marquee>
                      Important Notice for Owners / Allottees of Multi-Storied
                      Buildings in Greater Noida published on TOI & NBT dated
                      14th March 2021. Consumers can log complaint & avail
                      information by sending SMS to 7840002288 from their
                      registered Mobile No. Click{" "}
                      <a href="#" target="_blank">
                        here
                      </a>{" "}
                      to view the SMS short codes and their purpose. ----------
                      Single Phase consumers can now submit Self Meter Reading
                      with photo proof between 1st to 10th Day of every month,
                      click{" "}
                      <a href="#" target="_blank">
                        {" "}
                        here
                      </a>
                    </marquee>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-lg-12 brdcum_designs">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Home</Link>
                      <span className="icondsds">
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/reporttheft" className="active">
                        Report Theft
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <div className="outage_notificatiods">
                        <div className="innerhdnds">
                          <h2>
                            <i className="fa fa-phone" aria-hidden="true"></i>
                            Report a Concern
                          </h2>
                        </div>

                        <div className="bs-example">
                          <ul className="nav nav-tabs nav_tbds">
                            <li className="nav-item">
                              <Link
                                to="/report-a-concern"
                                className="nav-link "
                                data-toggle="tab"
                              >
                                Report Fire
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/reportsafetyhazzard"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Report Safety Hazzard{" "}
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/reporttheft"
                                className="nav-link active"
                                data-toggle="tab"
                              >
                                Report Theft{" "}
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/reportunethical"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Report Unethical{" "}
                              </Link>
                            </li>
                          </ul>
                          <div className="row">
                            <div className="col-lg-12 mainhding">
                              <h2>ELECTRIC THEFT</h2>
                              <p>
                                Theft cases can also be reported on 9891222240
                                or 24*7 Call Centre No. 0120 6226666 . Your
                                identity will be kept confidential.
                              </p>
                            </div>
                            <div className="col-md-12 left_divsd">
                              <form
                                action="/action_page.php"
                                className="needs-validation"
                                novalidate
                              >
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="offendersname">
                                        Offender’s Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="offendersname"
                                        placeholder="Name"
                                        name="offendersname"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="offenderconno">
                                        Offender’s Consumer No.
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="offenderconno"
                                        placeholder="Consumer No."
                                        name="offenderconno"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="offaddress">
                                        Offender’s Address<span>*</span>
                                      </label>
                                      <textarea
                                        className="form-control"
                                        id="offaddress"
                                        placeholder="Address"
                                        name="offaddress"
                                        required
                                      ></textarea>
                                      <div className="valid-feedback">
                                        Valid.
                                      </div>
                                      <div className="invalid-feedback">
                                        Please fill out this field.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="theftlandmark">
                                        Landmark<span>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="theftlandmark"
                                        placeholder="Landmark"
                                        name="theftlandmark"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="docupload">
                                        Upload an evidence(If any)
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control uploadIflds"
                                        id="docupload"
                                        placeholder="Upload"
                                        name="docupload"
                                      />
                                      <div className="allowed_file">
                                        (Only JPEG, PDF or DOC file less than 5
                                        MB is allowed)
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12 col-sm-12 col-xs-12">
                                    <div className="form-group form_gp_des">
                                      <label for="destheft">
                                        Description of Theft
                                      </label>
                                      <textarea
                                        className="form-control"
                                        id="destheft"
                                        placeholder="Description"
                                        name="destheft"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 col-sm-12 col-xs-12 ">
                                    <div className="col-lg-12 col-sm-12 col-xs-12 ">
                                      <h5
                                        className="assured_nbnt"
                                        style={{ display: "none" }}
                                      >
                                        We assure of keeping your identity
                                        confidential
                                      </h5>

                                      <div className="form-group form-check checkbox_des">
                                        <label className="form-check-label">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="remember"
                                            required
                                          />
                                          I agree to the{" "}
                                          <a href="">Terms and Conditions</a>.
                                          <div className="valid-feedback">
                                            Valid.
                                          </div>
                                          <div className="invalid-feedback">
                                            Check this checkbox to continue.
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-3 col-sm-12 col-xs-12">
                                      <button
                                        type="submit"
                                        className="btn btn-primary form-control submit_btsd"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                    <div className="col-lg-3 col-sm-12 col-xs-12 ">
                                      <button
                                        type="submit"
                                        className="btn btn-secondary form-control cancelbtns"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>{" "}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ReportTheft;
