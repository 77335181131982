import React, { useState } from "react";

const AskTez = () => {
  const [show, setShow] = useState(false);

  return (
    <>
     <div id="ChatBot">


      {show ? (
        <div className="chatbot_Open" id="chatBox" style={{ display: "block" }}>
          <div className="col-xs-1 pull-right lr_gutter chatbot_mini" id="mini">
            <img
              src="assets/img/minimize1.png"
              className="img-responsive sathiMiNI"
              alt="minimize" 
              onClick={() => setShow(false) 
              }
            />
          </div>
          <iframe
            src="https://gtw1.noidapower.com:7133/"
            width={385}
            height={475}
            title="chatWind"
            id="myiframe"
            scrolling="no"
            allow="microphone"
          />
        </div>
      ) :       <div className="sathi_pic">
        <img src="assets/img/saathiNew.png" alt="NPCL Chatbot" className="img-responsive sathiPIC" onClick={() => setShow(true)} />
      </div>}
      </div>
    </>
  );
};

export default AskTez;
