import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Slider from "react-slick";
import Chart from 'react-apexcharts'

const CustomerCareOfficeQueueStatusTech = () => {
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  const series2= [{
    name: "Previous Working Day Token Status",
    data: [44, 55, 41, 64, 22, 43, 21]
  }, {
    name: "Current Date Token Status",
    data: [53, 52, 53, 0,0, 0, 0]
  }]

  const options2 = {
    chart: {
      type: 'bar',
    }, plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff']
    },
    tooltip: {
      shared: true,
      intersect: false
    }, xaxis: {
      categories: ["10 AM","11 AM","12 AM","1 PM","2 PM","3 PM","4 PM","5 PM"],
    },
   }

  return (
    <>
      <Header />
      <FixMenu />
      <section className="consumer_inner_bg">
        <img src="assets/images/customer-care-office-banner.jpg" />
        <div className="container-fluid">
          <div className="apply_title">
            <h1>Customer Care Office Queue Status</h1>
          </div>
        </div>
      </section>
      <section className="main_sectiods">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 brdcum_designs">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#">Home</Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/consumer-portal">Consumer Portal</Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/information-board">Information Board</Link>
                  <span className="icondsds">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#" className="active">
                    Customer Care Office Queue Status
                  </Link>
                </li>
              </ol>
            </div>
          </div>
          <div className="row justify-content-center padtops">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-3 imphdmdev">
                  <div className="inner_boxs">
                  <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...settings2}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                    <div className="our_other_links2">
                      <h3>Other Links</h3>
                      <ul>
                        <li>
                          <Link to="/customer-care-office-queue-status">
                            Calculate Energy Consumption
                          </Link>
                        </li>
                        <li>
                          <Link to="/cash-collection-centres">
                            Payment Options
                          </Link>
                        </li>
                        <li>
                          <Link to="/online-vendor-registration">
                            Vendor Registration
                          </Link>
                        </li>
                        <li>
                          <a href="#">Regulation &amp; Statutory Bodies</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-9 bg_cols">
                  <div className="form_div cashcollectioncenter">
                    <legend>Customer Care Office Queue Status</legend>
                    <div className="form_div">
                   <ul className="nav nav-tabs nav_tbds">
                     <li className="nav-item">
                      <Link to="/customer-care-office-queue-status" className="nav-link">
                       KP-1 Office</Link>
                       </li>
                      <li className="nav-item">
                      <Link to="/customer-care-office-queue-status-tech" className="nav-link active">
                      Techzone–4 Office</Link>
                     </li>
                 </ul>
                 <div className="tab-content tab_main">
                 <div className="tab-pane fade show active" id="kpffice">
                 <div className="row">
                  <div className="col-sm-2">
                    <div className="blincagecircle">
                      <div className="blincage"></div>
                      <p className="bgcsolds">Live Status</p>
                      <img src="img/NPCL_Mascot.png" alt=""/>
                    </div>
                    </div>
                    <div className="col-sm-5">
                    <div className="mainwrapppdr">
                      <div className="divdsngs">
                       <div className="aerosections">
                       </div>
                       <div className="bgcolores"></div>
                       <div className="contentsdes">High Traffic</div>
                     </div>
                     <div className="divdsngs">
                      <div className="aerosections">
                      </div>
                      <div className="bgcolores"></div>
                      <div className="contentsdes">Medium Traffic</div>
                    </div>
                    <div className="divdsngs">
                      <div className="aerosections">
                        <img className="blincageaero" src="assets/img/icon/fast-forward.png"/>
                      </div>
                      <div className="bgcolores"></div>
                      <div className="contentsdes">Low Traffic</div>
                    </div>
                     </div>
                     <button className="btn btn-primary">Customer Care Office- TechZone-4</button>
                    </div>
                    <div className="col-sm-4">
                    <Chart options={options2} series={series2} type="bar" height={430} width={300}/>
                    </div>
                  </div>
                 </div>
                 </div>
                 </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CustomerCareOfficeQueueStatusTech;
