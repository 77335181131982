import React  from "react";
import Announcement from "../components/Announcement";
import Slider from "react-slick";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";


class ConsumerContactProgram extends React.Component {

 settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

   onChange(value) {
    console.log("Captcha value:", value);
  }
  
  render(){
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/loginpage.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/login" className="active">
                      Consumer Contact Program
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...this.settings2}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="our_other_links">
                        <h4>Other Links</h4>
                        <ul>
                          <li>
                            <Link to="#">
                              <span></span> Calculate Energy Consumption{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres" target="_blank">
                              <span></span>Payment Options{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online-vendor-registration"
                              target="_blank"
                            >
                              <span></span>Vendor Registration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Regulation &amp; Statutory Bodies{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div cashcollectioncenter">
                    <legend>Consumer Contact Programme</legend>  
		<p>As part of NPCL's ongoing endeavors to reach out to Consumers, we have started organizing Customer Contact Programs (CCPs) at the Consumers' own localities, through Residential Housing Societies or local clubs.</p>
		<p>‘Customer Contact Program’ is an interactive experience where Consumers of the society / Locality can talk to our Customer Relations Team.</p>
		<p>CCPs are also an important platform for NPCL to inform Consumers about its Services and generate awareness regarding the new Customer-centric initiatives, especially the e-services. Consumer suggestions /feedbacks received are analyzed for further improvement in our services.</p>
		<p>If you are interested, please register below.</p>
        <div className="row">
        <div className="col-md-6 form-group form_gp_des">
            <label>Name (Housing Society / Club) <span>*</span></label>
            <input type="text" name="name" className="form-control" placeholder="Name (Housing Society / Club)" required/>
        </div>
        <div className="col-md-6 form-group form_gp_des">
            <label>Name of President / Secretary <span>*</span></label>
            <input type="text" name="name" class="form-control" placeholder="Name of President / Secretary" required/>
        </div>
		<div className="col-md-6 form-group form_gp_des">
            <label>Mobile No. <span>*</span></label>
            <input type="text" name="name" className="form-control" placeholder="Mobile No" required/>
        </div>
		<div className="col-md-6 form-group form_gp_des">
            <label>Address</label>
            <input type="text" name="name" className="form-control" placeholder="Address" required/>
        </div>
		<div className="col-md-6 form-group form_gp_des">
            <label>Pin Code</label>
            <input type="text" name="name" className="form-control" placeholder="Pin code" required/>
        </div>
		<div className="col-md-6 form-group form_gp_des">
            <label>Street</label>
            <input type="text" name="name" className="form-control" placeholder="Street" required/>
        </div>
		<div className="col-md-6 form-group form_gp_des">
            <label>Contact Person <span>*</span></label>
            <input type="text" name="name" className="form-control" placeholder="Contact Person" required/>
        </div>
		<div className="col-md-6 form-group form_gp_des">
            <label>Proposed Meeting Month <span>*</span></label>
            <input type="date" className="form-control" name="birthday"/>
        </div>
		<div className="col-md-4">
     <button type="submit" id="submit" className="sub_btn2 submit" name="submit">Submit</button>
   </div>
        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )}
};



export default ConsumerContactProgram;
