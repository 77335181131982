import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const QuickPayPre = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/quick-bill-payment-banner.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/quick-pay-pre" className="active">
                      Quick Pay
                    </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/quick-pay-pre" className="">
                      Consumption Invoices
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <div className="innerhdnds">
                        <h2>QUICK PAY</h2>
                      </div>
                      <div className="bs-example">
                        <ul className="nav nav-tabs nav_tbds">
                          <li className="nav-item">
                            <Link
                              to="/quick-pay-pre"
                              className="nav-link active"
                              data-toggle="tab"
                            >
                              Consumption Invoices
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/pay-against-application"
                              className="nav-link"
                              data-toggle="tab"
                            >
                              Pay Against Application
                            </Link>
                          </li>
                        </ul>
                        <div className="tab-content tab_main">
                          <div className="main_wrappersd">
                            <form className="qbfsection">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group form_gp_des">
                                    <label for="">
                                      Please type your consumer number
                                      <span>*</span> here
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      placeholder="consumer number"
                                      name=""
                                      required
                                    />
                                    <div className="valid-feedback">Valid.</div>
                                    <div className="invalid-feedback">
                                      Please fill out this field.
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group form_gp_des">
                                    <label for="">
                                      Please type your Email Id <span>*</span>
                                      here
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      placeholder="Email Id"
                                      name=""
                                      required
                                    />
                                    <div className="valid-feedback">Valid.</div>
                                    <div className="invalid-feedback">
                                      Please fill out this field.
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group form_gp_des">
                                    <label for="">
                                      Please type your Mobile number
                                      <span>*</span> here
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      placeholder="Mobile number"
                                      name=""
                                      required
                                    />
                                    <div className="valid-feedback">Valid.</div>
                                    <div className="invalid-feedback">
                                      Please fill out this field.
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group form_gp_des">
                                    <button
                                      type="submit"
                                      className="btn btn-primary form-control submit_btmns"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                                <div className="hidden_parts">
                                  <div className="col-md-6">
                                    <p className="successmsgg">
                                      OTP has been sent on the email id and
                                      mobile no given above. Please use the same
                                      and proceed further
                                    </p>
                                    <div className="form-group form_gp_des">
                                      <label for="">Validate OTP</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        placeholder="Enter OTP"
                                        name=""
                                        required
                                      />
                                      <div className="valid-feedback">
                                        Valid.
                                      </div>
                                      <div className="invalid-feedback">
                                        Please fill out this field.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <p className="error_msgs">Invalid OTP!!</p>
                                    <div className="form-group form_gp_des">
                                      <button
                                        type="submit"
                                        className="btn btn-primary form-control submit_btmns"
                                      >
                                        Validate
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <form className="qbssection">
                              <div className="col-md-12">
                                <table className="table table-responsive table_desns">
                                  <tr>
                                    <td>Consumer Name</td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>Consumer No.</td>
                                    <td>2000101211</td>
                                  </tr>
                                  <tr>
                                    <td>Email Id</td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>Mobile No.</td>
                                    <td>9871053413</td>
                                  </tr>
                                  <tr className="finalamtns">
                                    <td>Net Payable Amount:</td>
                                    <td className="final_amtas">Rs. 1199</td>
                                  </tr>
                                </table>
                                <div className="row">
                                  <label for="">
                                    If you wish to view your Bill. Please type
                                    your Registered Mobile Number
                                    <span className="error_msgs">*</span>
                                  </label>
                                  <div className="col-md-6">
                                    <div className="form-group form_gp_des">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        placeholder="Registered Mobile Number"
                                        name=""
                                        required
                                      />
                                      <div className="valid-feedback">
                                        Valid.
                                      </div>
                                      <div className="invalid-feedback">
                                        Please fill out this field.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group form_gp_des">
                                      <button
                                        type="submit"
                                        className="btn btn-secondary form-control"
                                      >
                                        View E-bills
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group form_gp_des">
                                      <button
                                        type="submit"
                                        className="btn btn-primary submit_btmns form-control"
                                      >
                                        Confirm
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group form_gp_des">
                                      <a
                                        href="pdf/2000010869.pdf"
                                        className="imagesd_iconds"
                                        target="blank"
                                      >
                                        <img
                                          src="assets/img/icon/pdf-icon.png"
                                          alt=""
                                        />
                                        Please Click here to view your Bill
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <form className="qbtsection">
                              <div className="col-md-12">
                                <div className="row">
                                  <p>
                                    Please select payment gateway partner given
                                    below
                                  </p>
                                  <div className="col-md-6">
                                    <div className="bill_wrapper">
                                      <div className="gateway_wrapper billdesks">
                                        <img
                                          src="assets/img/icon/bill-desk-icon.png"
                                          alt=""
                                        />
                                        <img
                                          src="assets/img/icon/tick.png"
                                          className="check_iconds"
                                          alt=""
                                        />
                                      </div>

                                      <p>
                                        In case the amount being paid is above
                                        <strong>Rs.4000/-,</strong> BillDesk
                                        will charge you a Convenience Fee for
                                        rendering online payment service at the
                                        following rate:
                                      </p>
                                      <ul>
                                        <li>
                                          For Credit/Debit Card transactions, 1%
                                          of the amount being paid
                                        </li>
                                        <li>
                                          For NetBanking transactions, Rs. 10/-
                                          per transaction
                                        </li>
                                      </ul>
                                      <p>
                                        Applicable taxes on the Convenience Fee
                                        shall also be charged by BillDesk as per
                                        the norms.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="bill_wrapper">
                                      <div className="gateway_wrapper paytms">
                                        <img
                                          src="assets/img/icon/paytm-icon.png"
                                          alt=""
                                        />
                                        <img
                                          src="assets/img/icon/tick.png"
                                          className="check_iconds"
                                          alt=""
                                        />
                                      </div>

                                      <p>
                                        In case the amount being paid is above
                                        <strong>Rs.4000/-,</strong> Paytm will
                                        charge you a Convenience Fee for
                                        rendering online payment service at the
                                        following rate:
                                      </p>
                                      <ul>
                                        <li>
                                          For Credit/Debit Card transactions,
                                          1.10% of the amount being paid
                                        </li>
                                        <li>
                                          For NetBanking transaction, Rs. 4/-
                                          per transaction
                                        </li>
                                        <li>
                                          For transactions made using Paytm
                                          Wallet, 1.25% of the amount being
                                          paid.
                                        </li>
                                      </ul>
                                      <p>
                                        Applicable taxes on the Convenience Fee
                                        shall also be charged by Paytm as per
                                        the norms.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <table className="table table-responsive table_desns">
                                    <tr>
                                      <td>Consumer Name</td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td>Consumer No.</td>
                                      <td>2000101211</td>
                                    </tr>
                                    <tr>
                                      <td>Contract Account</td>
                                      <td>10834</td>
                                    </tr>
                                    <tr>
                                      <td>Email Id</td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td>Mobile No.</td>
                                      <td>9871053413</td>
                                    </tr>
                                    <tr className="finalamtns">
                                      <td>Net Payable Amount:</td>
                                      <td className="final_amtas">Rs. 1199</td>
                                    </tr>
                                  </table>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group form_gp_des">
                                        <button
                                          type="submit"
                                          className="btn btn-secondary form-control"
                                        >
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group form_gp_des">
                                        <button
                                          type="submit"
                                          className="btn btn-primary form-control"
                                        >
                                          Proceed
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <form className="qbtsection">
                              <div className="col-md-12">
                                <p className="alertnsgs">
                                  Transactions made online would go through a
                                  process of validation before being updated in
                                  the NPCL accounts. This may take up to 3
                                  working days. Therefore please avoid making
                                  payment using this facility in case your bill
                                  contains unpaid arrears of previous month(s)
                                  to avoid disconnection of supply in the
                                  interim period.
                                </p>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="col-md-12 col-sm-12 col-xs-12 inner_msgsd">
                                    <h4>Please note: </h4>
                                    <ol>
                                      <li>
                                        Please open noidapower website with www
                                        i.e.initiate the transaction from the
                                        Url: http://www.noidapower.com to avoid
                                        error at payment gateway.
                                      </li>
                                      <li>
                                        In case your payment is deducted by the
                                        payment gateway and you are not
                                        redirected to this site due to network
                                        error or disconnection and / or the
                                        acknowledgement is not displayed or
                                        emailed to you, please do not pay again.
                                      </li>
                                      <li>
                                        Please check your Credit/Debit Card or
                                        Bank Account if the amount has been
                                        debited and you find the entry in your
                                        Credit/Debit Card or Bank account,
                                        please Contact Us with all relevant
                                        details.
                                      </li>
                                      <li>
                                        Such cases will be processed manually
                                        and may take up to five working days.
                                      </li>
                                    </ol>

                                    <p className="im_mgds">
                                      Please check and confirm your connection
                                      related details and payable amount given
                                      above before proceeding further.
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <table className="table table-responsive table_desns fontdes">
                                    <tr>
                                      <td>Consumer Name</td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td>Consumer No.</td>
                                      <td>2000101211</td>
                                    </tr>
                                    <tr>
                                      <td>Contract Account</td>
                                      <td>10834</td>
                                    </tr>
                                    <tr className="finalamtns">
                                      <td className="amount_beightpadi">
                                        Amount Being Paid
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="amounvlsd form-control"
                                          value="1199"
                                          name=""
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </form>
                            <form className="qbssection">
                              <table className="col-md-12 tabmp">
                                <tr>
                                  <td>
                                    Pay Your NPCL Bills Online using
                                    Credit/Debit Card or Netbanking
                                  </td>
                                  <td></td>
                                </tr>
                              </table>
                              <div className="row">
                                <div className="col-md-12">
                                  <table className="table table-responsive table_desns tablbtn_border">
                                    <tr>
                                      <td>Consumer No.</td>
                                      <td>2000101211</td>
                                    </tr>
                                    <tr>
                                      <td>Contract Account:</td>
                                      <td>10867</td>
                                    </tr>
                                    <tr>
                                      <td>Payment Order No: </td>
                                      <td>3950502136 </td>
                                    </tr>
                                    <tr>
                                      <td>Net Payable Amount: </td>
                                      <td>Rs : 00</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>

                              <table className="col-md-12 tabmp">
                                <tr>
                                  <td>
                                    This online payment facility is being
                                    provided by our Payment Gateway Partner
                                    Paytm (One97 Communications Limited) and you
                                    are being re-directed to the website of
                                    Paytm(One97 Communications Limited). Please
                                    ensure that popups are enabled on your
                                    browser.
                                  </td>
                                  <td></td>
                                </tr>
                              </table>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group form_gp_des">
                                    <button
                                      type="submit"
                                      className="btn btn-secondary form-control"
                                    >
                                      Back
                                    </button>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group form_gp_des">
                                    <button
                                      type="submit"
                                      className="btn btn-primary form-control"
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>

                            <form className="qbssection">
                              <table className="table col-md-12 tabmp">
                                <tr>
                                  <td>
                                    Online Payment Transaction Reference No:
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font_clds text-center">
                                    Sorry, Transaction was Unsuccessful!!
                                  </td>
                                </tr>
                              </table>
                              <div className="row">
                                <div className="col-md-12">
                                  <table className="table table-responsive table_desns tablbtn_border">
                                    <tr>
                                      <td>Consumer No.</td>
                                      <td>2000101211</td>
                                    </tr>
                                    <tr>
                                      <td>Contract Account:</td>
                                      <td>10867</td>
                                    </tr>
                                    <tr>
                                      <td>Payment Order No: </td>
                                      <td>3950502136 </td>
                                    </tr>
                                    <tr>
                                      <td>Date of Transaction: </td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td> Transaction Amount </td>
                                      <td>Rs : 00</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>

                              <table className=" table col-md-12 tabmp">
                                <tr>
                                  <td className="font_clds">
                                    Cause of Failure : Your payment has been
                                    declined by your bank. Please contact your
                                    bank for any queries. If money has been
                                    deducted from your account, your bank will
                                    inform us within 48 hrs and we will refund
                                    the same
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Please check your Credit Card/ Bank
                                    account/Wallet and if you find the amount
                                    has been debited to your Credit Card/ Bank
                                    account/Wallet, it will be credited back to
                                    your account within 2-3 working days. In
                                    case the amount is not credited back to your
                                    account within specified time, please
                                    <a href="#">Click Here</a> to write to us
                                    online with all relevant details. Such cases
                                    will be processed manually and can take up
                                    to five working days.
                                  </td>
                                </tr>
                              </table>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group form_gp_des">
                                    <button
                                      type="submit"
                                      className="btn btn-secondary form-control"
                                    >
                                      Back
                                    </button>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group form_gp_des">
                                    <button
                                      type="submit"
                                      className="btn btn-primary form-control"
                                    >
                                      Print
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default QuickPayPre;
