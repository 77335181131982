import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const EbillDownload = () => {
  return (
    <React.Fragment>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/bill5.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center"></div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link href="#" className="active">
                      E-Bill Download
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form_div">
                    <form className="loginpgui">
                      <legend>Quick E-Bill Download</legend>

                      <div className="col-md-12 form-group form_gp_des">
                        <label className="form-label">
                          Please type your 10 Digit Consumer Number * here
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="10 Digit Consumer Number"
                        />
                      </div>
                      <div className="col-md-12 form-group form_gp_des">
                        <label className="form-label">
                          Please type your 10 Digit Mobile Number * here
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="10 Digit Mobile Number"
                        />
                      </div>

                      <div className="col-md-12 form-group form_gp_des">
                        <label className="form-label">Captcha Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Captcha Code"
                        />
                      </div>
                      <div className="col-md-12 form-group form_gp_des">
                        <button
                          type="submit"
                          className="btn btn-primary form-control"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default EbillDownload;
