import React from "react";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";
import Announcement from "../components/Announcement";
import { Link } from "react-router-dom";

const SolarRoofTopNetMetering = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/contact-banner.jpg" + ")",
              backgroundPosition: "center",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/solar-rooftop-net-metering" className="active">
                      Solar Roof Top Net Metering
                    </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row bg_cols">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <ul className="nav nav-tabs nav_tbds">
                        <li className="nav-item">
                          <Link
                            to="/callcenter"
                            className="nav-link "
                            data-toggle="tab"
                          >
                            24*7 Call Centre
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            href="https://iwebapps.noidapower.com:8032/callcenter_email.aspx"
                            className="nav-link "
                            target="_blank"
                          >
                            Customer Care Email Id
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/customer-care-office"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Customer Care Office
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/mobile-application"
                            className="nav-link"
                            data-toggle="tab"
                          >
                            Mobile Application
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/solar-rooftop-net-metering"
                            className="nav-link active"
                            data-toggle="tab"
                          >
                            Solar Roof Top Net Metering
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <legend style={{ marginLeft: "30px" }}>
                    CONTACT FOR SOLAR ROOF-TOP NET-METERING
                  </legend>
                  <div style={{ marginLeft: "35px" }}>
                    <p>
                      Net Metering Application & Registration Forms, List of
                      documents required will be provided by the following
                      officers of NPCL:
                    </p>
                    <p>
                      <b>Name:</b> Mr.Prosenjit Das
                    </p>
                    <p>
                      <b>Email Id:</b> rooftopsolar@noidapower.com
                    </p>
                    <p>
                      <b>Contact Number:</b> 01206226606 / 9911998252
                    </p>
                    <p>
                      <b>For more details, you may visit at:</b>
                    </p>
                    <p>
                      Noida Power Company Limited
                      <br />
                      33/11 KV Sub-station,
                      <br />
                      Knowledge Park – III,
                      <br />
                      Greater Noida-201310
                      <br />
                      Contact No.:{" "}
                      <b>
                        01206226606 (From 10.00AM to 5.00PM, Monday to Friday)
                      </b>
                      <br />
                      Email ID: rooftopsolar@noidapower.com
                    </p>
                    <p>
                      For Net Metering Process, Fee and Charges{" "}
                      <Link to="/rooftop-solar" target="_blank">
                        Click here..
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SolarRoofTopNetMetering;
