import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const QuickLinks = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="assets/images/register-complain-banner.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Quick Links</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Portal</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/quick-links" className="active">
                      Quick Links
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/quick-links" className="active">
                      Quick Links
                    </Link>
                  </li>
  
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <h2 className="online_heading">Quick Links</h2>

                    <div className="rediobuttonstyle">
                      <form className="form">
                        <div className="plan">
                          <ul className="box_online">
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/quick_pay_pre.aspx"
                                target="_blank" rel="noreferrer"
                              >
                                <img
                                  src="assets/images/QuickBillPayment.jpg"
                                  alt=""
                                />{" "}
                                Quick Bill Pay
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/self_meter_reading.aspx"
                                target="_blank" rel="noreferrer"
                              >
                                <img
                                  src="assets/images/selfMeterReading.jpg"
                                  alt=""
                                />{" "}
                                Self-Meter Reading
                              </a>
                            </li>
                            <li>
                              <Link to="/pre-paid-meter-recharge">
                                <img
                                  src="assets/images/preapidMEter.jpg"
                                  alt=""
                                />{" "}
                                Prepaid Meter Recharge
                              </Link>
                            </li>
                            {/* <li>
                              <a href="#">
                                <img
                                  src="assets/images/npcl-sathi.jpg"
                                  alt=""
                                />{" "}
                                Sathi
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default QuickLinks;
