import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";

const DigitalServices = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <section className="consumer_inner_bg">
          <img src="assets/images/digital-service-banner.jpg" alt="digital services" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Digital Serivce</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Portal</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/digital-services" className="active">
                      Digital Serivce
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <h2 className="online_heading">Digital Services</h2>

                    <div className="rediobuttonstyle">
                      <form className="form">
                        <div className="plan">
                          <ul className="box_online">
                          <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/CE_Know_Your_BP.aspx"
                                target="_blank"  rel="noreferrer"
                              >
                                <img
                                  src="assets/images/knowBPnumber.jpg"
                                  alt=""
                                />{" "}
                                Know Your Consumer Number
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/quick_pay.aspx"
                                target="_blank"  rel="noreferrer"
                              >
                                <img
                                  src="assets/images/paymentGateway.jpg"
                                  alt=""
                                />{" "}
                                Payment Gateways
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/SMS_based_Services.aspx"
                                target="_blank"  rel="noreferrer"
                              >
                                <img
                                  src="assets/images/SMSservices.jpg"
                                  alt=""
                                />{" "}
                                2 Way SMS Services
                              </a>
                            </li>
                            <li>
                              <Link to="/whatsapp_based_services">
                                <img src="assets/images/whatsapp.jpg" alt="" />{" "}
                                WhatsApp
                              </Link>
                            </li>
                            <li>
                              <Link to="/mobile-application">
                                <img src="assets/images/mobileapp.jpg" alt="" />{" "}
                                Mobile App
                              </Link>
                            </li>
                            <li>
                              <Link to="/callcenter">
                                <img src="assets/img/small/qms-real-time.jpg" alt="" /> 24x7
                                Call Centre
                              </Link>
                            </li>

                            <li>
                              <Link to="/Kioskservices">
                                <img src="assets/images/24x7.jpg" alt="" /> 24x7
                                Kiosk services
                              </Link>
                            </li>

                            <li>
                            
                              <Link to="/online-applications">
                                <img
                                  src="assets/images/applyOnline.jpg"
                                  alt=""
                                />{" "}
                                Apply Online
                              </Link>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/complaint_new.aspx"
                                target="_blank"  rel="noreferrer"
                              >
                                <img
                                  src="assets/images/registerComplaint.jpg"
                                  alt=""
                                />{" "}
                                Register a Complaint
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/Online_KYCUpdate.aspx"
                                target="_blank"  rel="noreferrer"
                              >
                                <img src="assets/images/KYCUpdate.jpg" alt="" />
                                KYC Update
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/Appointment_Based_Service.aspx"
                                target="_blank"  rel="noreferrer"
                              >
                                <img src="assets/images/ABS.jpg" alt="" />
                                Appointment Based Services
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/Authenticate/login.aspx"
                                target="_blank"  rel="noreferrer"
                              >
                                <img src="assets/images/isolation.jpg" alt="" />
                                Apply for Isolation / Revocation
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/OnlineDocumentUpload.aspx"
                                target="_blank"  rel="noreferrer"
                              >
                                <img
                                  src="assets/images/documentSubmission.jpg"
                                  alt=""
                                />
                                Document Submission
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/NotificationStatus.aspx"
                                target="_blank"  rel="noreferrer"
                              >
                                <img
                                  src="assets/images/notificationStatus.jpg"
                                  alt=""
                                />
                                Application Status Check
                              </a>
                            </li>
                            {/* <li>
                              <a href="">
                                <img
                                  src="assets/images/npcl-sathi.jpg"
                                  alt=""
                                />{" "}
                                NPCL Saathi
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default DigitalServices;
