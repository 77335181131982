import React from "react";
import { Link } from "react-router-dom";

const Header2 = () => {
  function openFuncation() {
    var element = document.getElementById("search-wrap");
    element.classList.add("searchactive");
  }

  function closeFuncation() {
    var element = document.getElementById("search-wrap");
    element.classList.remove("searchactive");
  }
  function menuopenFuncation() {
    var element = document.getElementById("fixmnu");
    element.classList.add("active");

    var element = document.getElementById("bodyaddid");
    element.classList.add("scrollstop");
  }

  function menuclaseFuncation() {
    var element = document.getElementById("fixmnu");
    element.classList.remove("active");

    var element = document.getElementById("bodyaddid");
    element.classList.remove("scrollstop");
  }
  function menuopenFunction() {
    var element = document.getElementById("fixmenuds1");
    element.classList.add("active");

    var element = document.getElementById("scrollhead");
    element.classList.add("scrollstop");
  }

  function closemencation() {
    var element = document.getElementById("fixmenuds1");
    element.classList.remove("active");

    var element = document.getElementById("scrollhead");
    element.classList.remove("scrollstop");
  }
  function closemenus() {
    var element = document.getElementById("fixmenuds1");
    element.classList.remove("active");

    var element = document.getElementById("scrollhead");
    element.classList.remove("scrollstop");
  }
  window.onscroll = function () {
    headerFunction();
  };

  function headerFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("headerbottom").className =
        "headerbottom designtime";
      document.getElementById("navbar-collapse").className =
        "navbar-collapse mobilemenu designtime";
      document.getElementById("navbar-collapse1").className =
        "collapse navbar-collapse designtime";
    } else {
      document.getElementById("headerbottom").className = "headerbottom";
      document.getElementById("navbar-collapse").className =
        "navbar-collapse mobilemenu";
      document.getElementById("navbar-collapse1").className =
        "collapse navbar-collapse";
    }
  }
  function show1() {
    document.getElementById("div1").style.display = "none";
  }
  function show2() {
    document.getElementById("div1").style.display = "block";
  }

  return (
    <>
      <header id="scrollhead" className="whitebg">
        <div className="headerbottom" id="headerbottom">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <a className="navbar-brand" href="https://www.noidapower.com/">
                <img  alt="NPCL Logo" src="assets/img/logo.png" />
              </a>

              <div className="navbar-collapse mobilemenu" id="navbar-collapse">
                <li className="loginclick bg_dark">
                  <a href="registration-form.html" className="bg_dark">
                    Sign In/ Register
                  </a>
                </li>
                <div
                  className="searchicon25 searchopen"
                  onClick={openFuncation}
                >
                  <i className="fa fa-search"></i>
                </div>
                <div
                  className="wrapper-menu togglemnu"
                  onClick={menuopenFuncation}
                >
                  <div className="line-menu half start"></div>
                  <div className="line-menu"></div>
                  <div className="line-menu half end"></div>
                </div>
              </div>
              <div className="collapse navbar-collapse" id="navbar-collapse1">
                <ul className="navbar-nav darkcol ms-auto mb-2 mb-lg-0 w-100 num-top">
                  <li>
                    <i className="fa fa-phone"></i> Emergency{" "}
                    <a href="#">+91-9718722222</a>
                  </li>{" "}
                  <li>
                    <span className="bg_dark">24X7</span> Call Center -{" "}
                    <a href="#">0120 6226666</a>
                  </li>
                </ul>

                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 w-100 consumerpadingxs">
                  <li className="nav-item dropdown">
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </li>

                  <li className="nav-item dropdown dropdownstat">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      data-toggle="dropdown"
                    >
                      About NPCL
                    </a>
                    <ul className="dropdown-menu dropdown-menu-large wrapper-arrow-on">
                      <div className="row">
                        <div className="col-sm-4">
                          <ul>
                            <li className="dropdown-header">
                              NPCL At A Glance
                            </li>
                            <li>
                              {" "}
                              <Link
                                to="/company-profile"
                                className="dropdown-item"
                              >
                                Company Profile
                              </Link>{" "}
                            </li>
                            <li>
                              <Link
                                to="/corporate-policies"
                                className="dropdown-item"
                              >
                                Corporate Policies
                              </Link>
                            </li>
                            <li>
                      <a className="dropdown-item"
                          href="https://iwebapps.noidapower.com:8032/download/Notice of 31st AGM-Website.pdf"
                          target="_blank"  rel="noreferrer"
                        >
                          Notice of 31<sup>st</sup> AGM
                        </a>
                      </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://iwebapps.noidapower.com:8032/awards_recognition.aspx"
                                target="_blank"
                              >
                                Award & Recognition
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-4 lr_gutter lw_gutter">
                          <ul>
                            <li className="dropdown-header">
                              Our Value Systems
                            </li>
                            <li>
                              <Link
                                to="vission-mission.html"
                                className="dropdown-item"
                              >
                                Vision &amp; Mission
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/rpsg-group-vision-and-core-values"
                                className="dropdown-item"
                              >
                                RPSG Group Vision and Core Values
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-4 lr_gutter lw_gutter">
                          <ul>
                            <li className="dropdown-header">
                              Corporate Citizenship
                            </li>
                            <li>
                              <Link
                                to="/corporate-social-responsibility"
                                className="dropdown-item"
                              >
                                Corporate Social Responsibility
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </ul>
                  </li>

                  <li className="nav-item dropdown dropdownstat">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      data-toggle="dropdown"
                    >
                      Regulations
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-large wrapper-arrow-on">
                      <li className="dropdown-header">Tariff</li>
                      <div className="row">
                        <div className="col-sm-6">
                          <ul>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/Tariff%20applicable%20from%204th%20August%202022.pdf"
                                target="_blank"
                                className="dropdown-item"
                              >
                                Tariff applicable from 4th August 2022
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/NPCL%20Tariff%20Order%20for%20FY%202022-23%20dated%2020th%20July%202022.pdf"
                                target="_blank"
                                className="dropdown-item"
                              >
                                NPCL Tariff Order for FY 2022-23 dated 20th July
                                2022
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-6 lr_gutter lw_gutter">
                          <ul>
                            <li>
                              <Link
                                to="/cost-data-book"
                                className="dropdown-item"
                              >
                                Cost Data Book
                              </Link>
                            </li>
                            <li>
                              <a
                                href="https://iwebapps.noidapower.com:8032/download/RSPV%20Regulation%202019%20dt.%2004-01-2019.pdf"
                                target="_blank"
                                className="dropdown-item"
                              >
                                UPERC Roof top Solar PV regulations 2019{" "}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <Link
                                href="/tarrif-schedule"
                                className="dropdown-item"
                              >
                                Tariff Schedule
                              </Link>{" "}
                            </li>
                            <li>
                              <Link
                                to="/up-electricity-supply-code"
                                className="dropdown-item"
                              >
                                UP Electricity Supply Code
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </ul>
                  </li>

                  <li className="nav-item dropdown dropdownstat">
                    <Link
                      className="nav-link border-end-0  dropdown-toggle"
                      href="#"
                      data-toggle="dropdown"
                    >
                      Procurement
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-smls wrapper-arrow-on">
                      <li className="dropdown-header">Procurement</li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/online-vendor-registration"
                        >
                          Online Vendor Registration
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/contact-for-eprocurement"
                        >
                          Contact for e-Procurement
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          href="contact-for-power-procurement.html"
                        >
                          Contact for Power-Procurement
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/tenders">
                          Tenders
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown dropdownstat">
                    <Link
                      className="nav-link border-end-0  dropdown-toggle"
                      href="/career"
                      data-toggle="dropdown"
                    >
                      Careers
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-smls wrapper-arrow-on">
                      <li className="dropdown-header">Life @ NPCL</li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://iwebapps.noidapower.com:8032/current_opening.aspx"
                          target="_blank"
                        >
                          Current Openings
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://iwebapps.noidapower.com:8032/cv_upload.aspx"
                          target="_blank"
                        >
                          Upload Your CV
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item  lastmenu">
                    <Link
                      className="nav-link border-end-0"
                      to="/consumer-portal"
                    >
                      Consumer Portal
                    </Link>
                  </li>
                  <li className="loginclick bg_dark">
                    <a
                      href="https://iwebapps.noidapower.com:8032/Authenticate/Login.aspx"
                      className="bg_dark"
                      target="_blank"
                    >
                      Sign In/ Register
                    </a>
                  </li>

                  {/* <li className="searchtop">
                    <a href="#" className="searchopen" onClick={openFuncation}>
                      <i className="fa fa-search"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <div className="search-wrap" id="search-wrap">
        <div className="search-middle">
          <div className="close-btn" onClick={closeFuncation}>
            X
          </div>
          <div className="container text-center">
            <input
              type="text"
              className="st-default-search-input"
              placeholder="Search..."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header2;
