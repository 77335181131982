import React from "react";
import Header2 from "../components/Header2";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import {useState} from 'react'
import ModalConnection from "../components/ModalConnection";
import ModalName from "../components/ModalName";
import ModalChange from "../components/ModalChange";
import ModalLoad from "../components/ModalLoad";

const NewServiceConnectionOnline = () => {
    const [openDomestic,setOpenDomestic] = useState(true);
    const [openNonDomestic,setOpenNonDomestic] = useState(false);
    const [openImage,setOpenImage] = useState(false);
    const [openPdf,setOpenPdf]=useState(false);
    const [openPdf1,setOpenPdf1]=useState(false);
    const [openPdf2,setOpenPdf2]=useState(false);

    const open1 = ()=>{
        setOpenDomestic(true)
        setOpenNonDomestic(false)
      }
      const open2 = ()=>{
        setOpenNonDomestic(true)
        setOpenDomestic(false)
      }

    const [openNewCon,setOpenNewCon] = useState(false);
    const [openNameMutation,setOpenNameMutation] = useState(false);
    const [openCategoryChange,setOpenCategoryChange] = useState(false);
    const [openPermanent,setOpenPermanent] = useState(false);
    const [openLoadReduction,setOpenLoadReduction] = useState(false);
    const [openName,setOpenName] = useState(false);
    const [openNotInUse,setOpenNotInUse] = useState(false);
    const [openShifting,setOpenShifting] = useState(false);

    const selectedValue = (e)=>{
        if(e.target.value === "LIST1"){
            setOpenNewCon(true)
             setOpenNameMutation(false)
             setOpenShifting(false)
             setOpenNotInUse(false)
             setOpenName(false)
             setOpenLoadReduction(false)
             setOpenPermanent(false)
             setOpenCategoryChange(false)
             setOpenPdf(false)
             setOpenPdf1(false)
             setOpenPdf2(false)
         }
         if(e.target.value === "LIST2"){
                setOpenNameMutation(true) 
                 setOpenImage(false)
                setOpenNewCon(false) 
                setOpenShifting(false)
                setOpenNotInUse(false)
                setOpenName(false)
                setOpenLoadReduction(false)
                setOpenPermanent(false)
                setOpenCategoryChange(false)
                setOpenPdf1(false)
                setOpenPdf2(false)
        }
        if(e.target.value === "LIST3"){
            setOpenCategoryChange(true)
            setOpenImage(false)
            setOpenNameMutation(false) 
            setOpenNewCon(false) 
            setOpenShifting(false)
            setOpenNotInUse(false)
            setOpenName(false)
            setOpenLoadReduction(false)
            setOpenPermanent(false)
            setOpenPdf(false)
            setOpenPdf2(false)
        }
        if(e.target.value === "LIST4"){
            setOpenPermanent(true)
            setOpenImage(false)
            setOpenCategoryChange(false)
            setOpenNameMutation(false) 
            setOpenNewCon(false) 
            setOpenShifting(false)
            setOpenNotInUse(false)
            setOpenName(false)
            setOpenLoadReduction(false)
            setOpenPdf(false)
            setOpenPdf1(false)
            setOpenPdf2(false)
        }
        if(e.target.value === "LIST5"){
            setOpenLoadReduction(true)
            setOpenImage(false)
            setOpenPermanent(false)
            setOpenCategoryChange(false)
            setOpenNameMutation(false) 
            setOpenNewCon(false) 
            setOpenShifting(false)
            setOpenNotInUse(false)
            setOpenName(false)
            setOpenPdf(false)
            setOpenPdf1(false)
        }
        if(e.target.value === "LIST6"){
            setOpenName(true)
            setOpenImage(false)
            setOpenLoadReduction(false)
            setOpenPermanent(false)
            setOpenCategoryChange(false)
            setOpenNameMutation(false) 
            setOpenNewCon(false) 
            setOpenShifting(false)
            setOpenNotInUse(false)
            setOpenPdf(false)
            setOpenPdf1(false)
            setOpenPdf2(false)
        }
        if(e.target.value === "LIST7"){
            setOpenNotInUse(true)
            setOpenImage(false)
            setOpenName(false)
            setOpenLoadReduction(false)
            setOpenPermanent(false)
            setOpenCategoryChange(false)
            setOpenNameMutation(false) 
            setOpenNewCon(false) 
            setOpenShifting(false)
            setOpenPdf(false)
            setOpenPdf1(false)
            setOpenPdf2(false)
        }
        if(e.target.value === "LIST8"){
            setOpenShifting(true)
            setOpenImage(false)
            setOpenNotInUse(false)
            setOpenName(false)
            setOpenLoadReduction(false)
            setOpenPermanent(false)
            setOpenCategoryChange(false)
            setOpenNameMutation(false) 
            setOpenNewCon(false) 
            setOpenPdf(false)
            setOpenPdf1(false)
            setOpenPdf2(false)
        }
    }

    const closeFunction = ()=>{
        setOpenImage(false)
        setOpenPdf(false)
        setOpenPdf1(false)
        setOpenPdf2(false)
    }

  
  return (
    <>
         <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods consumersec">
        <div className="upperdesignds">
			<div className="row">
				<div className="col-md-1">
				<img className="bgstlsd" src="assets/img/small/payment-options.jpg" alt=""/>
			</div>
			<div className="col-md-11 textdesing">
							<h2 className="headtit">New Connection</h2>
			</div>
			</div>
		</div>
        <div className="container">
        <div class="row justify-content-center padtops">
        <div class="col-sm-12 form_div"  style={{marginTop:"20px"}}>
        <ul className="parentClass">
            <li><label><input type="radio" value name="anything" className="radioCls" id="yes" onClick={open1} checked/> Domestic</label></li>
            <li><label><input type="radio" value name="anything" className="radioCls" id="no" onClick={open2}/> Non-Domestic</label></li>
          </ul>
        </div>
        </div>
        {openDomestic ? ( <div className="" id="first">
        <ul className="nav nav-pills" role="tablist">
	 	 <li className="nav-item">
         <Link className="nav-link" data-toggle="pill" to="/new-service-connection">Apply</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-calculate">Calculate Your Load</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-document">Document Checklist</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-sample">Sample Documents</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link active" data-toggle="pill" to="/new-service-connection-online">Online Application</Link>
       </li>
        </ul>
        <div class="tab-content">
        <div id="checklist" class="container"><br/>
        <h4 class="mt-2">Application Form(Domestic) </h4>
        <div class="row">
         <div class="col-md-4">
         <label for="country">Select Form Application <span>*</span></label>
	     <select name="country" id="country" class="slt_check"  onClick={(e)=>selectedValue(e)}>
          <option disabled selected="selected">Select</option>
          <option value="LIST1">New Connection</option>
          <option value="LIST2">Name Mutation</option>
          <option value="LIST3">Categogy Change</option>
          <option value="LIST4">Permanent Disconnection & Security Refund</option>
           <option value="LIST5">Load Reduction/Load Augmentation</option>
          <option value="LIST6">Name & Address Correction</option>
          <option value="LIST7">Not in Use Declaration</option>
          <option value="LIST8">Shifting of Service / Meter</option>
        </select>
         </div>
         {openNewCon ? ( <div style={{marginTop:"15px"}}>For filling up the online application form please <div onClick={()=>setOpenImage(true)} style={{textDecoration:"underline",cursor:"pointer"}}>Click Here</div><Link to="assets/pdf/Non_Domestic_Form.pdf" target="_blank" style={{textDecoration:"none",cursor:"pointer"}}>Click Here to download the application form</Link></div>):null }
        {openImage ? (<div id="mainn">
        <div id="pop-ups">
        <button id='close-btnn' onClick={()=>closeFunction()}>X</button>
        <img src="assets/pdf/non.jpg" style={{width:"70%"}}/>
        </div></div>):null}

        {openNameMutation ? (<div style={{marginTop:"15px"}}>For filling up the online application form please <div onClick={()=>setOpenPdf(true)} style={{textDecoration:"underline",cursor:"pointer"}}>Click Here</div> <Link to="assets/pdf/n.pdf" target="_blank" style={{textDecoration:"none",cursor:"pointer"}}>Click Here to download the application form</Link>
        </div>):null}
        {openPdf ? (<div id="mainn">
        <div id="pop-ups">
        <button id='close-btnn' onClick={()=>closeFunction()}>X</button>
        <img src="assets/pdf/Name-Mutation.jpg" style={{width:"70%"}}/>
        </div>
      </div>):null}

        {openCategoryChange ? (<div style={{marginTop:"15px"}}>For filling up the online application form please <div onClick={()=>setOpenPdf1(!openPdf1)} style={{textDecoration:"underline",cursor:"pointer"}}>Click Here</div><Link to="assets/pdf/Category_Conversion.pdf" target="_blank" style={{textDecoration:"none"}}>Click Here to download the application form</Link></div>):null}
        {openPdf1 ? (<div id="mainn">
        <div id="pop-ups">
        <button id='close-btnn' onClick={()=>closeFunction()}>X</button>
        <img src="assets/pdf/Category-Change.jpg" style={{width:"70%"}}/>
        </div>
      </div>):null}

        {openPermanent ? (<Link to="assets/pdf/PD_SD_FORM.pdf" target="_blank" style={{textDecoration:"none",marginTop:"15px"}}>Click Here to download the application form</Link>):null}

        {openLoadReduction ? (<div>For filling up the online application form please <div onClick={()=>setOpenPdf2(!openPdf2)} style={{textDecoration:"underline",cursor:"pointer"}}>Click Here</div> <br/><Link to="assets/pdf/LA LR application form.pdf" target="_blank" style={{textDecoration:"none"}}>Click Here to download the application form</Link></div>):null}
        {openPdf2 ? (<div id="mainn">
        <div id="pop-ups">
        <button id='close-btnn' onClick={()=>closeFunction()}>X</button>
        <img src="assets/pdf/Load-reduction.jpg" style={{width:"70%"}}/>
        </div>
      </div>):null}

        {openName ? (<Link to="assets/pdf/name_&_address_correction.pdf" target="_blank" style={{textDecoration:"none",marginTop:"15px"}}>Click here to download Application Form</Link>):null}

        {openNotInUse ? (<Link to="assets/pdf/not in use declaration.pdf" target="_blank" style={{textDecoration:"none",marginTop:"15px"}}>Click here to download Application Form</Link>):null}
        {openShifting ? (<Link to="assets/pdf/Shifting_of_Service_mains_form.pdf" target="_blank" style={{textDecoration:"none",marginTop:"15px"}}>Click here to download Application Form</Link>):null}
         </div>
        </div>
        </div>
        </div>):null}
        
        {openNonDomestic ? ( <div className="" id="first">
        <ul className="nav nav-pills" role="tablist">
	 	 <li className="nav-item">
         <Link className="nav-link" data-toggle="pill" to="/new-service-connection">Apply</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-calculate">Calculate Your Load</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-document">Document Checklist</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-sample">Sample Documents</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link active" data-toggle="pill" to="/new-service-connection-online">Online Application</Link>
       </li>
        </ul>
        <div class="tab-content">
        <div id="checklist" class="container"><br/>
        <h4 class="mt-2">Application Form(Non-Domestic) </h4>
        <div class="row">
         <div class="col-md-4">
         <label for="country">Select Form Application <span>*</span></label>
	     <select name="country" id="country" class="slt_check"  onClick={(e)=>selectedValue(e)}>
          <option disabled selected="selected">Select</option>
          <option value="LIST1">New Connection</option>
          <option value="LIST2">Name Mutation</option>
          <option value="LIST3">Categogy Change</option>
          <option value="LIST4">Permanent Disconnection & Security Refund</option>
           <option value="LIST5">Load Reduction/Load Augmentation</option>
          <option value="LIST6">Name & Address Correction</option>
          <option value="LIST7">Not in Use Declaration</option>
          <option value="LIST8">Shifting of Service / Meter</option>
        </select>
         </div>
         {openNewCon ? ( <div style={{marginTop:"15px"}}>For filling up the online application form please <div onClick={()=>setOpenImage(true)} style={{textDecoration:"underline",cursor:"pointer"}}>Click Here</div><Link to="assets/pdf/Non_Domestic_Form.pdf" target="_blank" style={{textDecoration:"none",cursor:"pointer"}}>Click Here to download the application form</Link></div>):null }
        {openImage ? (<div id="mainn">
        <div id="pop-ups">
        <button id='close-btnn' onClick={()=>closeFunction()}>X</button>
        <img src="assets/pdf/non.jpg" style={{width:"70%"}}/>
        </div></div>):null}

        {openNameMutation ? (<div style={{marginTop:"15px"}}>For filling up the online application form please <div onClick={()=>setOpenPdf(true)} style={{textDecoration:"underline",cursor:"pointer"}}>Click Here</div> <Link to="assets/pdf/n.pdf" target="_blank" style={{textDecoration:"none",cursor:"pointer"}}>Click Here to download the application form</Link>
        </div>):null}
        {openPdf ? (<div id="mainn">
        <div id="pop-ups">
        <button id='close-btnn' onClick={()=>closeFunction()}>X</button>
        <img src="assets/pdf/Name-Mutation.jpg" style={{width:"70%"}}/>
        </div>
      </div>):null}

        {openCategoryChange ? (<div style={{marginTop:"15px"}}>For filling up the online application form please <div onClick={()=>setOpenPdf1(!openPdf1)} style={{textDecoration:"underline",cursor:"pointer"}}>Click Here</div><Link to="assets/pdf/Category_Conversion.pdf" target="_blank" style={{textDecoration:"none"}}>Click Here to download the application form</Link></div>):null}
        {openPdf1 ? (<div id="mainn">
        <div id="pop-ups">
        <button id='close-btnn' onClick={()=>closeFunction()}>X</button>
        <img src="assets/pdf/Category-Change.jpg" style={{width:"70%"}}/>
        </div>
      </div>):null}

        {openPermanent ? (<Link to="assets/pdf/PD_SD_FORM.pdf" target="_blank" style={{textDecoration:"none",marginTop:"15px"}}>Click Here to download the application form</Link>):null}

        {openLoadReduction ? (<div>For filling up the online application form please <div onClick={()=>setOpenPdf2(!openPdf2)} style={{textDecoration:"underline",cursor:"pointer"}}>Click Here</div> <br/><Link to="assets/pdf/LA LR application form.pdf" target="_blank" style={{textDecoration:"none"}}>Click Here to download the application form</Link></div>):null}
        {openPdf2 ? (<div id="mainn">
        <div id="pop-ups">
        <button id='close-btnn' onClick={()=>closeFunction()}>X</button>
        <img src="assets/pdf/Load-reduction.jpg" style={{width:"70%"}}/>
        </div>
      </div>):null}

        {openName ? (<Link to="assets/pdf/name_&_address_correction.pdf" target="_blank" style={{textDecoration:"none",marginTop:"15px"}}>Click here to download Application Form</Link>):null}

        {openNotInUse ? (<Link to="assets/pdf/not in use declaration.pdf" target="_blank" style={{textDecoration:"none",marginTop:"15px"}}>Click here to download Application Form</Link>):null}
        {openShifting ? (<Link to="assets/pdf/Shifting_of_Service_mains_form.pdf" target="_blank" style={{textDecoration:"none",marginTop:"15px"}}>Click here to download Application Form</Link>):null}
         </div>
        </div>
        </div>
        </div>):null}

        </div>
        </section>
        </div>
    </>
  )
}

export default NewServiceConnectionOnline