import React from "react";
import Header2 from "../components/Header2";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import {useState} from 'react'

const PermanentApply= () => {
   
  return (
    <>
         <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods consumersec">
        <div className="upperdesignds">
			<div className="row">
				<div className="col-md-1">
				<img className="bgstlsd" src="assets/img/small/payment-options.jpg" alt=""/>
			</div>
			<div className="col-md-11 textdesing">
							<h2 className="headtit">Permanent Disconnection & Securty Refund</h2>
			</div>
			</div>
		</div>
        <div className="container">
        <div class="row justify-content-center padtops">
        <div class="col-sm-12 form_div" style={{marginTop:"20px"}}>
          
        </div>
        </div>
       <div className="" id="first">
        
        <ul className="nav nav-pills" role="tablist">
        <li className="nav-item">
         <Link className="nav-link" data-toggle="pill" to="/permanent">Document Checklist</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/permanentSample">Sample Documents</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/permanentFaqs">FAQ'S</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link active" data-toggle="pill" to="/permanentApply">Apply</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/permanentFeedback">Feedback</Link>
       </li>
        </ul> 
        <div className="tab-content">
        <div id="apply" className="container tab-pane show active"><br/>
        <h4 className="mt-2">  Apply </h4>
            <p className="nooutagedsabf"><Link to="/login" style={{textDecoration:"none"}}>Click here to Apply</Link></p>
        </div>
        </div>
        
        </div>
        </div>
        </section>
        </div>
    </>
  )
}

export default PermanentApply