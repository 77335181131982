import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";

const CommitmentToEnvironment = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" +
                "assets/img/inner/corporatesocialresponsibility-banner.jpg" +
                ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Commitment to Environment
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/corporate-social-responsibility"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Corporate Social Responsibility
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/lifestyle-improvement"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Lifestyle Improvement
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/community-development"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Community Development
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/commitment-to-environment"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Commitment to Environment
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/csractivities"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        CSR Activities
                      </Link>
                    </li>
                  </ul>
                  <div className="row companysection com_profile">
                    <div className="col-md-6">
                      <legend>GOVERNMENT & REGULATORS</legend>
                      <p>
                        The Company has an important role in developing the
                        concept of water-energy nexus and promoting optimum
                        groundwater usage.
                      </p>
                      <p>
                        A novel financing scheme is offered to farmers for
                        employing energy-efficient pumps in exchange of their
                        existing sets to help economise groundwater pumping to
                        suit current cropping patterns.
                      </p>
                      <p>
                        Farmers gain as their monthly spend on energy is
                        reduced, arising out of economic pumping. Concurrently,
                        environmental cause is served as over-pumping is
                        avoided, thereby protecting the water table and local
                        ecology. As a unique step, the pumps are metered to
                        account for the energy consumed.
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="companyimg">
                        <img src="assets/img/challages-ahead.jpg" />
                      </div>
                    </div>
                  </div>
                  \
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CommitmentToEnvironment;
