import React from "react";
import Header2 from "../components/Header2";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import {useState} from 'react'

const NewServiceConnectionDocument = () => {

    const [openDomestic,setOpenDomestic] = useState(true)
    const [openNonDomestic,setOpenNonDomestic] = useState(false)
  
    const open1 = ()=>{
    setOpenDomestic(true);
    setOpenNonDomestic(false);
    }
    const open2 = ()=>{
      setOpenNonDomestic(true);
      setOpenDomestic(false);
    }

    const [openNewCon,setOpenNewCon] = useState(false);
    const [openNameMutation,setOpenNameMutation] = useState(false);
    const [openCategoryChange,setOpenCategoryChange] = useState(false);
    const [openPermanent,setOpenPermanent] = useState(false);
    const [openLoadReduction,setOpenLoadReduction] = useState(false);
    const [openName,setOpenName] = useState(false);
    const [openNotInUse,setOpenNotInUse] = useState(false);
    const [openShifting,setOpenShifting] = useState(false);

    const selectedValue = (e)=>{
     if(e.target.value === "LIST1"){
        setOpenNewCon(true)
         setOpenNameMutation(false)
         setOpenShifting(false)
         setOpenNotInUse(false)
         setOpenName(false)
         setOpenLoadReduction(false)
         setOpenPermanent(false)
         setOpenCategoryChange(false)
     }
     if(e.target.value === "LIST2"){
            setOpenNameMutation(true) 
            setOpenNewCon(false) 
            setOpenShifting(false)
            setOpenNotInUse(false)
            setOpenName(false)
            setOpenLoadReduction(false)
            setOpenPermanent(false)
            setOpenCategoryChange(false)
    }
    if(e.target.value === "LIST3"){
        setOpenCategoryChange(true)
        setOpenNameMutation(false) 
        setOpenNewCon(false) 
        setOpenShifting(false)
        setOpenNotInUse(false)
        setOpenName(false)
        setOpenLoadReduction(false)
        setOpenPermanent(false)
    }
    if(e.target.value === "LIST4"){
        setOpenPermanent(true)
        setOpenCategoryChange(false)
        setOpenNameMutation(false) 
        setOpenNewCon(false) 
        setOpenShifting(false)
        setOpenNotInUse(false)
        setOpenName(false)
        setOpenLoadReduction(false)
    }
    if(e.target.value === "LIST5"){
        setOpenLoadReduction(true)
        setOpenPermanent(false)
        setOpenCategoryChange(false)
        setOpenNameMutation(false) 
        setOpenNewCon(false) 
        setOpenShifting(false)
        setOpenNotInUse(false)
        setOpenName(false)
    }
    if(e.target.value === "LIST6"){
        setOpenName(true)
        setOpenLoadReduction(false)
        setOpenPermanent(false)
        setOpenCategoryChange(false)
        setOpenNameMutation(false) 
        setOpenNewCon(false) 
        setOpenShifting(false)
        setOpenNotInUse(false)
    }
    if(e.target.value === "LIST7"){
        setOpenNotInUse(true)
        setOpenName(false)
        setOpenLoadReduction(false)
        setOpenPermanent(false)
        setOpenCategoryChange(false)
        setOpenNameMutation(false) 
        setOpenNewCon(false) 
        setOpenShifting(false)
    }
    if(e.target.value === "LIST8"){
        setOpenShifting(true)
        setOpenNotInUse(false)
        setOpenName(false)
        setOpenLoadReduction(false)
        setOpenPermanent(false)
        setOpenCategoryChange(false)
        setOpenNameMutation(false) 
        setOpenNewCon(false) 
    }
    }
  
  return (
    <>
         <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods consumersec">
        <div className="upperdesignds">
			<div className="row">
				<div className="col-md-1">
				<img className="bgstlsd" src="assets/img/small/payment-options.jpg" alt=""/>
			</div>
			<div className="col-md-11 textdesing">
							<h2 className="headtit">New Connection</h2>
			</div>
			</div>
		</div>
        <div className="container">
        <div class="row justify-content-center padtops">
        <div class="col-sm-12 form_div" style={{marginTop:"20px"}}>
        <ul className="parentClass">
            <li><label><input type="radio" value name="anything" className="radioCls" id="yes" onClick={open1} checked /> Domestic</label></li>
            <li><label><input type="radio" value name="anything" className="radioCls" id="no" onClick={open2}/> Non-Domestic</label></li>
          </ul>
        </div>
        </div>
        
        {openDomestic ? (<div className="" id="first">
        <ul className="nav nav-pills" role="tablist">
        <li className="nav-item">
         <Link className="nav-link" data-toggle="pill" to="/new-service-connection">Apply</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-calculate">Calculate Your Load</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link active" data-toggle="pill" to="/new-service-connection-document">Document Checklist</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-sample">Sample Documents</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-online">Online Application</Link>
       </li>
        </ul>
        <div class="tab-content">
        <div id="checklist" class="container"><br/>
        <h4 class="mt-2">Document Checklist(Domestic) </h4>
        <div class="row">
         <div class="col-md-4">
         <label for="country">Select Checklist <span>*</span></label>
	     <select name="country" id="country" class="slt_check" onClick={(e)=>selectedValue(e)}>
          <option disabled selected="selected">Select</option>
          <option value="LIST1">New Connection</option>
          <option value="LIST2">Name Mutation</option>
          <option value="LIST3">Categogy Change</option>
          <option value="LIST4">Permanent Disconnection & Security Refund</option>
           <option value="LIST5">Load Reduction/Load Augmentation</option>
          <option value="LIST6">Name & Address Correction</option>
          <option value="LIST7">Not in Use Declaration</option>
           <option value="LIST8">Shifting of Service / Meter</option>
        </select>
         </div>
        
        {openNewCon ? (<div class="col-md-12">
		<div className="list1" >
        <div className="personal_details">
         <img src="assets/images/newConn_Domestic.jpg" alt=""/>
         </div>
        </div>
        </div>):null}
        
        {openNameMutation ? (<div class="list2">
        <div className="personal_details">
        <img src="assets/images/Name-Mutation.jpg" alt=""/>
        </div>
         </div>): null}
        
        {openCategoryChange ? (
            <div class="list3">
        <div className="personal_details">
        <img src="assets/images/Category-Change.jpg" alt=""/>		
        </div>
       </div>
        ): null}

        {openPermanent ? (<div class="list4">
        <div class="personal_details">
         <img src="assets/images/Permanent-Disconnection-and-Security-Refund.jpg" alt=""/>		
          </div>
         </div>): null}

         {openLoadReduction ? (<div class="list5">
         <div class="personal_details">
        <img src="assets/images/Load-reduction.jpg" alt=""/>		
         </div>
         </div>):null}

         {openName ? (<div class="list6">
       <div class="personal_details">
       <img src="assets/images/Name-and-address-correction.jpg" alt=""/>		
        </div>
       </div>):null}

       {openNotInUse ? ( <div class="list7">
        <div class="personal_details">
        <img src="assets/images/NotInUseDeclaration.jpg" alt=""/>		
        </div> 
       </div>): null}

       {openShifting ? ( <div class="list8">
      <div class="personal_details">
      <img src="assets/images/Shifting_Of_Services.jpg" alt=""/>		
       </div>
       </div>): null}

         </div>
        </div>
        </div>
        </div>):null}
        {openNonDomestic ? (<div className="" id="first">
        <ul className="nav nav-pills" role="tablist">
        <li className="nav-item">
         <Link className="nav-link" data-toggle="pill" to="/new-service-connection">Apply</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-calculate">Calculate Your Load</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link active" data-toggle="pill" to="/new-service-connection-document">Document Checklist</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-sample">Sample Documents</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/new-service-connection-online">Online Application</Link>
       </li>
        </ul>
        <div class="tab-content">
        <div id="checklist" class="container"><br/>
        <h4 class="mt-2">Document Checklist(Non-Domestic) </h4>
        <div class="row">
         <div class="col-md-4">
         <label for="country">Select Checklist <span>*</span></label>
	     <select name="country" id="country" class="slt_check" onClick={(e)=>selectedValue(e)}>
          <option disabled selected="selected">Select</option>
          <option value="LIST1">New Connection</option>
          <option value="LIST2">Name Mutation</option>
          <option value="LIST3">Categogy Change</option>
          <option value="LIST4">Permanent Disconnection & Security Refund</option>
           <option value="LIST5">Load Reduction/Load Augmentation</option>
          <option value="LIST6">Name & Address Correction</option>
          <option value="LIST7">Not in Use Declaration</option>
           <option value="LIST8">Shifting of Service / Meter</option>
        </select>
         </div>
        
        {openNewCon ? (<div class="col-md-12">
		<div className="list1" >
        <div className="personal_details">
         <img src="assets/images/newConn_Domestic.jpg" alt=""/>
         </div>
        </div>
        </div>):null}
        
        {openNameMutation ? (<div class="list2">
        <div className="personal_details">
        <img src="assets/images/Name-Mutation.jpg" alt=""/>
        </div>
         </div>): null}
        
        {openCategoryChange ? (
            <div class="list3">
        <div className="personal_details">
        <img src="assets/images/Category-Change.jpg" alt=""/>		
        </div>
       </div>
        ): null}

        {openPermanent ? (<div class="list4">
        <div class="personal_details">
         <img src="assets/images/Permanent-Disconnection-and-Security-Refund.jpg" alt=""/>		
          </div>
         </div>): null}

         {openLoadReduction ? (<div class="list5">
         <div class="personal_details">
        <img src="assets/images/Load-reduction.jpg" alt=""/>		
         </div>
         </div>):null}

         {openName ? (<div class="list6">
       <div class="personal_details">
       <img src="assets/images/Name-and-address-correction.jpg" alt=""/>		
        </div>
       </div>):null}

       {openNotInUse ? ( <div class="list7">
        <div class="personal_details">
        <img src="assets/images/NotInUseDeclaration.jpg" alt=""/>		
        </div> 
       </div>): null}

       {openShifting ? ( <div class="list8">
      <div class="personal_details">
      <img src="assets/images/Shifting_Of_Services.jpg" alt=""/>		
       </div>
       </div>): null}

         </div>
        </div>
        </div>
        </div>):null}
        


        </div>
        </section>
        </div>
    </>
  )
}

export default NewServiceConnectionDocument