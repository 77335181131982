import React from "react";
import Announcement from "../components/Announcement";
import Slider from "react-slick";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";

const ForgetPassword = () => {
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/loginpage.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/login" className="active">
                      Login
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...settings2}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="our_other_links">
                        <h4>Other Links</h4>
                        <ul>
                          <li>
                            <Link to="#">
                              <span></span> Calculate Energy Consumption{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres" target="_blank">
                              <span></span>Payment Options{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online-vendor-registration"
                              target="_blank"
                            >
                              <span></span>Vendor Registration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Regulation &amp; Statutory Bodies{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div wrapper-pd-top-login">
                      <div className="bs-example bs-example-1">
                        <ul className="nav nav-tabs nav_tbds">
                          <li className="nav-item">
                            <Link
                              to="/login"
                              className="nav-link "
                              data-toggle="tab"
                            >
                              Login
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/needhelpinsignin"
                              className="nav-link"
                              data-toggle="tab"
                            >
                              Need Help in Sign In{" "}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/new-user"
                              className="nav-link"
                              data-toggle="tab"
                            >
                              New User
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/forget-password"
                              className="nav-link active"
                              data-toggle="tab"
                            >
                              Forgot Password
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/login-info"
                              className="nav-link"
                              data-toggle="tab"
                            >
                              Login Info
                            </Link>
                          </li>
                        </ul>
                        <div className="tab-content tab_main">
                          <div className="main_wrappersd loginpgui forgot-passwords">
                            <h2>Forgot Password</h2>

                            <form className="qbfsection">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="form-group form_gp_des">
                                    <label>
                                      Email<span>*</span>
                                    </label>
                                    <input
                                      name="username"
                                      type="text"
                                      className="form-control"
                                      placeholder="Your Email Address"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="form-group form_gp_des">
                                    <label>
                                      Consumer Number<span>*</span>
                                    </label>
                                    <input
                                      name="Number"
                                      type="text"
                                      className="form-control"
                                      placeholder="Consumer Number"
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="form-group form_gp_des">
                                    <button className="btn form-control btn-primary">
                                      Send My Password
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ForgetPassword;
