import React from "react";

const ChequeGuidelines = () => {
  return (
    <>
      <div className="container">
        <img
          className="imgfullwidth"
          src="assets/images/guideline_Cheque.gif"
          alt=""
        />
      </div>
    </>
  );
};

export default ChequeGuidelines;
