import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const KnowYourPrepaidMeter = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Know Your Prepaid Meter
                    </a>
                  </li>
                </ol>
              </div>
            </div>

            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="tariff_standard">
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            marginBottom: "20px",
                          }}
                        >
                          <img src="assets/img/pdf.png" alt="" />
                          <a
                            href="https://iwebapps.noidapower.com:8032/download/PRE_PAID_MANUAL.pdf"
                            target="_blank"
                          >
                            Know Your Prepaid Meter
                          </a>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            marginBottom: "20px",
                          }}
                        >
                          <img src="assets/img/pdf.png" alt="" />
                          <a
                            href="https://iwebapps.noidapower.com:8032/download/Know_your_prepaid_meter_Radius_(User_Handbook).pdf"
                            target="_blank"
                          >
                            Know Your Prepaid Meter Radius(User Handbook)
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default KnowYourPrepaidMeter;
