import React,{useState} from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import Header from "../components/Header";
import Slider from "react-slick";
import FixMenu from "../components/FixMenu";

const NukkadNatak = () => {
  const [show,setShow] = useState(false);
  const [show1,setShow1] = useState(false);
  const [show2,setShow2] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed:1900,
  };
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" +
                "assets/images/nukkadbanner.jpg" +
                ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="NukkadNatak" className="active">
                     NukkadNatak
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                 
                  <legend style={{textAlign:"center"}}>Nukkad Natak</legend>
                  <div className="col-sm-12">
                    <div className="row justify-content-center">
                      <div className="col-sm-10">
                        <div className="filterdesign slider main_div_dessd">
                          <Slider
                            ref={(slider) => (slider = slider)}
                            {...settings}
                          >
                            <div className="csrslider" >
                              <img src="assets/images/nukkad-natak-01.jpg" style={{objectFit:"cover"}}/>
                            
                            
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/nukkad-natak-02.jpg" style={{objectFit:"cover"}}/>
                            </div>
                            <div className="csrslider">
                              <img src="assets/images/nukkad-natak-03.jpg" style={{objectFit:"cover"}}/>
                                                          </div>
                            <div className="csrslider">
                              <img src="assets/images/nukkad-natak-04.jpg" style={{objectFit:"cover"}}/>
                                                         </div>
                            <div className="csrslider">
                              <img src="assets/images/nukkad-natak-05.jpg" style={{objectFit:"cover"}}/>
                                                     </div>
                           
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row" style={{ marginTop: "120px" }}>
                    <div className="col-md-12">
                      <p>
                        In continuation to our CSR Initiative; NPCL constructed
                        washrooms in the Advocates Chamber at Collectrate
                        Office, Greater Noida, District Gautam Buddha Nagar,
                        Uttar Pradesh These newly constructed washrooms were
                        inaugurated by Mr. B. N. Singh, District Magistrate
                        Gautam Buddha Nagar on 9th Jan’20.
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NukkadNatak;
