import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const EnergyAudit = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>

        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/EnergyAudit" className="active">
                           Energy audit & accounting
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    {/* <ul className="nav nav-tabs nav_tbds">
                      <li className="nav-item">
                        <Link
                          to="/cost-data-book"
                          className="nav-link "
                          data-toggle="tab"
                        >
                          Cost Data Book
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/tarrif-schedule"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          Tariff Schedule
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/up-electricity-supply-code"
                          className="nav-link"
                          data-toggle="tab"
                        >
                          UP Electricity Supply Code - 2005
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/up-electricity-supply-code"
                          className="nav-link active"
                          data-toggle="tab"
                        >
                          Energy audit and accounting
                        </Link>
                      </li>
                    </ul> */}
                    <div className="col-sm-12">

                        <legend> Energy audit & accounting  </legend>
                        <div className="row">
                          <div className="col-md-12">

                            <ul className="service_standard">
                              <li>
                                <div className="cost_pdf">
                                  <img src="assets/img/pdf.png" alt="" />
                                  <a
                                  href="https://iwebapps.noidapower.com:8032/download/Energy_Audit/Annual_Energy_Audit_Report_FY-2021-22.pdf"
                                  target="_blank" rel="noreferrer"    
                                  >
                                    Annual Energy Audit Report FY-2021-22
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="cost_pdf">
                                  <img src="assets/img/pdf.png" alt="" />
                                  <a
                                    href="https://iwebapps.noidapower.com:8032/download/Energy_Audit/Annual_Energy_Audit_Report_FY_2020-21.pdf"
                                target="_blank" rel="noreferrer"
                                  >
                                  Annual Energy Audit Report FY 2020-21
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="cost_pdf">
                                  <img src="assets/img/pdf.png" alt="" />
                                  <a
                                  href="https://iwebapps.noidapower.com:8032/download/Energy_Audit/23/Energy_Accounting_Report_for_the_Period_of_Q1_FY_23.pdf"
                                  target="_blank" rel="noreferrer"    
                                  >
                                    Energy Accounting Report for the Period of Q1 FY 23
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="cost_pdf">
                                  <img src="assets/img/pdf.png" alt="" />
                                  <a
                                    href="https://iwebapps.noidapower.com:8032/download/Energy_Audit/23/Energy_Accounting_Report_for_the_Period_of_Q2_FY_23.pdf"
                                      target="_blank" rel="noreferrer"
                                  >
                                  Energy Accounting Report for the Period of Q2 FY 23
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="cost_pdf">
                                  <img src="assets/img/pdf.png" alt="" />
                                  <a
                                  href="https://iwebapps.noidapower.com:8032/download/Energy_Audit/22/Energy_Accounting_Report_for_the_Period_of_Q2_FY_22.pdf"
                                  target="_blank" rel="noreferrer"    
                                  >
                                    Energy Accounting Report for the Period of Q2 FY 22
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="cost_pdf">
                                  <img src="assets/img/pdf.png" alt="" />
                                  <a
                                    href="https://iwebapps.noidapower.com:8032/download/Energy_Audit/22/Energy_Accounting_Report_for_the_Period_of_Q3_FY_22.pdf"
                                target="_blank" rel="noreferrer"
                                  >
                                  Energy Accounting Report for the Period of Q3 FY 22
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="cost_pdf">
                                  <img src="assets/img/pdf.png" alt="" />
                                  <a
                                  href="https://iwebapps.noidapower.com:8032/download/Energy_Audit/22/Energy_Accounting_Report_for_the_Period_of_Q4_FY_22.pdf"
                                  target="_blank" rel="noreferrer"    
                                  >
                                  Energy Accounting Report for the Period of Q4 FY 22
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EnergyAudit;
