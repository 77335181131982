import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const ComplaintManagementProcess = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="consumer_inner_bg">
          <img src="assets/images/supply-related-banner.jpg" />
          <div className="container-fluid">
            <div className="apply_title">
              <h1>Supply Related Complaint</h1>
            </div>
          </div>
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/consumer-portal">Consumer Portal</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/register-complain">Register a Complaint</Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/supply-related-comaplaint" className="active">
                      Supply Related Complaint
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 bg_cols">
                    <div className="form_div wrapper-pd-top-login">
                      <div className="bs-example bs-example-1">
                        <div
                          className="tab-pane fade show active"
                          id="newconnection"
                        >
                          <div className="innerhdnds">
                            <h2>Supply Related Complaint</h2>
                          </div>
                          <ul className="nav nav-tabs nav_tbds" style={{overflow:"hidden"}}>
                            <li className="nav-item">
                              <Link
                                to="/supply-related-comaplaint"
                                className="nav-link "
                                data-toggle="tab"
                              >
                                Planned Outages
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/register"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Register
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/status"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Status
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/feedbackform"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Feedback
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/complaintmanagementprocess"
                                className="nav-link active"
                                data-toggle="tab"
                              >
                                Complaint Management Process (Guide)
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/faqs"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                FAQs
                              </Link>
                            </li>
                          </ul>
                        </div>{" "}
                      </div>
                      <div className="tab-content tab_main" style={{marginLeft:'20px'}}>
                        <h4 className="mt-2" style={{marginBottom:"20px"}}>
                          Complaint Management Process (Guide)
                        </h4>

                        <p>
                          <b>
                            TIER – I: NPCL Complaint escalation & Redressal
                            structure
                          </b>
                        </p>
                        <p>
                          In case of any concern/ request you may visit our
                          Customer Care Office and meet the following official
                          with complete detail:
                        </p>
                        <ul className="col-xs-12">
                          <li style={{listStyle:"none"}}>
                            <b>Level 1:</b> Customer Relation Officer
                          </li>
                          <li style={{listStyle:"none"}}>
                            <b>Level 2:</b> Customer Relation Managers
                          </li>
                          <li style={{listStyle:"none"}}>
                            <b>Level 3:</b>Concern Departmental Heads with prior
                            appointment{" "}
                          </li>
                        </ul>
                        <p>
                          <b>TIER-II&nbsp; </b>
                        </p>
                        <p>
                          Consumer Grievance Redressal Forum (CGRF): A forum is
                          constituted under the Chairmanship of a retired Judge
                          and two other members wherein the Consumers of
                          electricity can file their complaints for the
                          redressal of their grievances related to deficiency/
                          error in bill or services or any non- compliance of
                          standards & directions issued by the Uttar Pradesh
                          Electricity Regulatory Commission in this regard.
                        </p>
                        <p>
                          Address of the forum: Consumer Grievance Redressal
                          Forum (CGRF), 33/11KV Electric Sub Station, Block-D,
                          Opposite Ryan International School, Sector Alpha-1,
                          Greater Noida, Uttar Pradesh- 201310.
                        </p>
                        <p>
                          <strong>
                            Phone No. of the Secretary of the Forum – 9911411373{" "}
                          </strong>
                        </p>
                        <p>
                          Public Hearing by the Forum: A monthly camp is
                          organized on the 17th of every month (in case, 17th
                          being Saturday/holiday, after holiday) at CGRF, where
                          the employees of NPCL are also present.
                        </p>
                        <p>
                          <b>TIER – III:</b>
                        </p>
                        <p>
                          Electricity Ombudsman: An Appeal can be filled before
                          the Electricity Ombudsman of the State on not being
                          satisfied by the order of the CGRF.
                        </p>
                        <p>
                          Address of the Office of Electricity Ombudsman:
                          Niyamak Bhawan, Vibhuti Khand, Gomti Nagar, Lucknow,
                          Uttar Pradesh – 226010 Phone No. – 0522- 2720856, Fax-
                          0522-2720857,
                        </p>
                        <p>Email: eo-up@uperc.org</p>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ComplaintManagementProcess;
