import React from "react";
import Slider from "react-slick";

const ReactCarousel2 = () => {
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  return (
    <>
      <section className="powering">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 p-0">
              <div className="poweringslider slider">
                <Slider {...settings2}>
                  <div className="sliderpower">
                    <img src="assets/img/slider11.jpg" />
                  </div>
                  <div className="sliderpower">
                    <img src="assets/img/slider12.jpg" />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReactCarousel2;
