import React from "react";
import Header2 from "../components/Header2";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import { useState } from "react";

const PermanentSample = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods consumersec">
          <div className="upperdesignds">
            <div className="row">
              <div className="col-md-1">
                <img
                  className="bgstlsd"
                  src="assets/img/small/payment-options.jpg"
                  alt=""
                />
              </div>
              <div className="col-md-11 textdesing">
                <h2 className="headtit">
                  Permanent Disconnection & Securty Refund
                </h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div class="row justify-content-center padtops">
              <div
                class="col-sm-12 form_div"
                style={{ marginTop: "20px" }}
              ></div>
            </div>
            <div className="" id="first">
              <ul className="nav nav-pills" role="tablist">
                <li className="nav-item">
                  <Link className="nav-link" data-toggle="pill" to="/permanent">
                    Document Checklist
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    data-toggle="pill"
                    to="/permanentSample"
                  >
                    Sample Documents
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    data-toggle="pill"
                    to="/permanentFaqs"
                  >
                    FAQ'S
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    data-toggle="pill"
                    to="/permanentApply"
                  >
                    Apply
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    data-toggle="pill"
                    to="/permanentFeedback"
                  >
                    Feedback
                  </Link>
                </li>
              </ul>
              <div id="sampledoc" class="container tab-pane">
                <br />
                <div class="col-md-12">
                  <h4 class="mt-2">Sample Document</h4>
                  <ul class="service_standard">
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/SAFETY_CHALLAN_FORMAT.pdf"
                          target="_blank"
                        >
                          Safety Challan Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/GST_CERTIFICATE_FORMAT.pdf"
                          target="_blank"
                        >
                          GST Certificate Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/Co-owner_NOC_Format.pdf"
                          target="_blank"
                        >
                          Co-Owner NOC Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/DADA_LAI_PROPERTY_FORMAT.pdf"
                          target="_blank"
                        >
                          Dada Lai Property Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/COMPLETION_CERTIFICATE_FORMAT.pdf"
                          target="_blank"
                        >
                          Completion Certificate Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/MSME_CERTIFICATE_FORMAT.pdf"
                          target="_blank"
                        >
                          MSME Certificate Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/B&L_FORMAT.pdf"
                          target="_blank"
                        >
                          B & L Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/PERSONAL_BOND.pdf"
                          target="_blank"
                        >
                          Personal Bond
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/DECLARATION_REGARDING_NON_REGISTRATION_OF_GST.pdf"
                          target="_blank"
                        >
                          Declaration REGARDING Non Registration on GST
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/COMPLETION%20_CERTIFICATE_APPLIED_FOR_RECEIPT_FORMAT.pdf"
                          target="_blank"
                        >
                          Completion Certificate Applied For Receipt Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/OWNER_CONSENT_LETTER_FORMAT.pdf"
                          target="_blank"
                        >
                          Owner Consent LETTER Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/INDEMNITY_BOND_FORMAT.pdf"
                          target="_blank"
                        >
                          Indemnity Bond Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/KHASRA_KHATONI_FORMAT.pdf"
                          target="_blank"
                        >
                          Khasra Khatoni Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/POLLUTION%20_CERTIFICATE_FORMAT.pdf"
                          target="_blank"
                        >
                          Pollution Certificate Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/POSSESSION_CERTIFICATE_FORMAT.pdf"
                          target="_blank"
                        >
                          Possession Certificate Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/RULAR_SALE_DEED_FORMAT.pdf"
                          target="_blank"
                        >
                          Rular Sale Deed Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/UPSIDC_REGISTRY_FORMAT.pdf"
                          target="_blank"
                        >
                          UPSIDC Registry Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/UPSIDC_TRANSFER_LETTER_FORMAT.pdf"
                          target="_blank"
                        >
                          UPSIDC transfer Letter Format
                        </a>
                      </div>
                    </li>
                    <li>
                      <div class="cost_pdf">
                        <img src="assets/img/pdf.png" alt="" />
                        <a
                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/TM_LETTER_GNIDA_FORMAT.pdf"
                          target="_blank"
                        >
                          TM Letter GNIDA Format
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PermanentSample;
