import React from "react";
import Announcement from "../components/Announcement";
import Slider from "react-slick";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";

const SafetyPrecautions = () => {
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/login" className="active">
                      SAFETY TIPS
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...settings2}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="our_other_links">
                        <h4>Other Links</h4>
                        <ul>
                          <li>
                            <Link to="#">
                              <span></span> Calculate Energy Consumption{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres" target="_blank">
                              <span></span>Payment Options{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online-vendor-registration"
                              target="_blank"
                            >
                              <span></span>Vendor Registration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Regulation &amp; Statutory Bodies{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                  <div className="form_div">
                  <div className="main_wrappersd">
                  <div className="profile_cont">
                  <h2>SAFETY TIPS
                   </h2>
                   <p><b>Why we are at risk
                  </b></p>
                 <p>Electricity travels by the path of least resistance to ground. Hence, elements, which are good conductors of electricity, are more at risk when in contact with a source of electricity. For instance Metals, water and human bodies are good conductors of electricity.
                   </p>
                  <p>The sheer water content of human bodies (approximately 70%) makes human beings particularly susceptible to passage of electricity. When electricity passes through our body, it puts enormous stress on the nervous and cardiovascular systems.
                     </p>
                   <p>Burns caused by electric 'shocks' may result in serious injury or death. Hence, electrical safety is something that should never be taken lightly. Therefore, to ensure your safety, this section brings you some safety tips.
                     </p>
                     <ul className="nav nav-tabs nav_tbds">
                     <li className="nav-item"><Link to="/safety" className="nav-link" data-toggle="tab">Outdoors </Link></li>
                  <li className="nav-item"><Link to="/safetyIndoors" className="nav-link" data-toggle="tab">Indoors </Link></li>
                   <li className="nav-item"><Link to="/safetyPrecautions" className="nav-link active" data-toggle="tab">Precautions for Kids  </Link></li>
                   </ul>
                   <div className="tab-content tab_main">
                   <div className="tab-pane show active" id="Precautions">
                   <ol>
        		<li>Keep electrical equipment out of reach of children.
                 </li>
        		<li>Educate your children about electrical safety. Teach them never to put fingers or objects into outlets or appliances, and use outlet covers wherever necessary.
                  </li>
        		<li>Don't let your children climb trees near power lines. Let them play with kites and balloons only in open areas away from power lines. Even when your kids are flying kites far away from power lines, static electricity can build up enough on the kite and string to be dangerous. If kite string gets caught in power lines, ask them to leave it alone and not try to remove the string from the lines.
                   </li>
        		<li>Help them recognize <b>"Danger-High Voltage"</b> signs and identify electric utility equipment. Teach them to call for help in the event of an electrical emergency.
                  </li>
        	</ol>
                   </div>
                   </div>
                  </div>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SafetyPrecautions;
