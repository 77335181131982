import React, { useState } from "react";
import Header2 from "../components/Header2";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import Table from "../components/Table";

const LoadReductionAugmentationSample = () => {
  const [loadReduction, setLoadReduction] = useState(true);
  const [augmentation, setAugmentation] = useState(false);

  const openLoad = () => {
    setLoadReduction(true);
    setAugmentation(false);
  };
  const openAug = () => {
    setLoadReduction(false);
    setAugmentation(true);
  };
  return (
    <>
      <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods consumersec">
          <div className="upperdesignds">
            <div className="row">
              <div className="col-md-1">
                <img
                  className="bgstlsd"
                  src="assets/img/small/payment-options.jpg"
                  alt=""
                />
              </div>
              <div className="col-md-11 textdesing">
                <h2 className="headtit">
                  {loadReduction ? "Load Reduction" : "load Augmentation"}
                </h2>
              </div>
            </div>
          </div>
          <div className="container">
            <section className="main_sectiods">
              <div className="container">
                <div className="row justify-content-center padtops">
                  <div className="col-sm-12">
                    <div className="col-lg-12 brdcum_designs">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="#">Home</Link>
                          <span className="icondsds">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/consumer-portal" target="_blank">
                            Consumer Portal
                          </Link>
                          <span className="icondsds">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#" className="active">
                            Apply Online
                          </a>
                        </li>
                      </ol>
                    </div>
                    <div className="bs-example bs-example-1">
                      <div className="tab-pane show active" id="newconnection">
                        <ul className="nav">
                          <li className="nav-item" onClick={openLoad}>
                            <Link
                              className={`nav-link ${
                                loadReduction ? "active" : ""
                              }`}
                              data-toggle="tab"
                              style={{ color: loadReduction ? "blue" : "red" }}
                            >
                              Load Reduction
                            </Link>
                          </li>
                          <li className="nav-item" onClick={openAug}>
                            <Link
                              className={`nav-link ${
                                augmentation ? "active" : ""
                              }`}
                              data-toggle="tab"
                              style={{ color: augmentation ? "blue" : "red" }}
                            >
                              Load Augmentation
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {loadReduction ? (
                      <div className="col-sm-12 bg_cols">
                        <div className="form_div">
                          <div className="tab-content tab_main">
                            <div
                              className="tab-pane fade show active"
                              id="newconnection"
                            >
                              <div className="bs-example bs-example-1">
                                <div
                                  className="tab-pane fade show active"
                                  id="newconnection"
                                >
                                  <ul
                                    className="nav nav-tabs nav_tbds"
                                    style={{ overflow: "hidden" }}
                                  >
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Calculate Your Load
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-doc"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Document Checklist
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-sample"
                                        className="nav-link active"
                                        data-toggle="tab"
                                      >
                                        Sample Documents
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-faq"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        FAQs
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-apply"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Apply
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-status"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Application Status
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-feedback"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Feedback
                                      </Link>
                                    </li>
                                  </ul>
                                </div>{" "}
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <h4
                                    className="mt-2"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    Sample Document
                                  </h4>
                                  <ul className="service_standard">
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/SAFETY_CHALLAN_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Safety Challan Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/GST_CERTIFICATE_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          GST Certificate Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/Co-owner_NOC_Format.pdf"
                                          target="_blank"
                                        >
                                          Co-Owner NOC Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/DADA_LAI_PROPERTY_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Dada Lai Property Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/COMPLETION_CERTIFICATE_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Completion Certificate Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/MSME_CERTIFICATE_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          MSME Certificate Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/B&L_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          B & L Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/PERSONAL_BOND.pdf"
                                          target="_blank"
                                        >
                                          Personal Bond
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/DECLARATION_REGARDING_NON_REGISTRATION_OF_GST.pdf"
                                          target="_blank"
                                        >
                                          Declaration REGARDING Non Registration
                                          on GST
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/COMPLETION%20_CERTIFICATE_APPLIED_FOR_RECEIPT_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Completion Certificate Applied For
                                          Receipt Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/OWNER_CONSENT_LETTER_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Owner Consent LETTER Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/INDEMNITY_BOND_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Indemnity Bond Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/KHASRA_KHATONI_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Khasra Khatoni Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/POLLUTION%20_CERTIFICATE_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Pollution Certificate Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/POSSESSION_CERTIFICATE_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Possession Certificate Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/RULAR_SALE_DEED_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Rular Sale Deed Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/UPSIDC_REGISTRY_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          UPSIDC Registry Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/UPSIDC_TRANSFER_LETTER_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          UPSIDC transfer Letter Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/TM_LETTER_GNIDA_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          TM Letter GNIDA Format
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {augmentation ? (
                      <div className="col-sm-12 bg_cols">
                        <div className="form_div">
                          <div className="tab-content tab_main">
                            <div
                              className="tab-pane fade show active"
                              id="newconnection"
                            >
                              <div className="bs-example bs-example-1">
                                <div
                                  className="tab-pane fade show active"
                                  id="newconnection"
                                >
                                  <ul
                                    className="nav nav-tabs nav_tbds"
                                    style={{ overflow: "hidden" }}
                                  >
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Calculate Your Load
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-doc"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Document Checklist
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-sample"
                                        className="nav-link active"
                                        data-toggle="tab"
                                      >
                                        Sample Documents
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-faq"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        FAQs
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-apply"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Apply
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-status"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Application Status
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-feedback"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Feedback
                                      </Link>
                                    </li>
                                  </ul>
                                </div>{" "}
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <h4
                                    className="mt-2"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    Sample Document
                                  </h4>
                                  <ul className="service_standard">
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/SAFETY_CHALLAN_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Safety Challan Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/GST_CERTIFICATE_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          GST Certificate Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/Co-owner_NOC_Format.pdf"
                                          target="_blank"
                                        >
                                          Co-Owner NOC Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/DADA_LAI_PROPERTY_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Dada Lai Property Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/COMPLETION_CERTIFICATE_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Completion Certificate Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/MSME_CERTIFICATE_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          MSME Certificate Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/B&L_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          B & L Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/PERSONAL_BOND.pdf"
                                          target="_blank"
                                        >
                                          Personal Bond
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/DECLARATION_REGARDING_NON_REGISTRATION_OF_GST.pdf"
                                          target="_blank"
                                        >
                                          Declaration REGARDING Non Registration
                                          on GST
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/COMPLETION%20_CERTIFICATE_APPLIED_FOR_RECEIPT_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Completion Certificate Applied For
                                          Receipt Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/OWNER_CONSENT_LETTER_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Owner Consent LETTER Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/INDEMNITY_BOND_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Indemnity Bond Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/KHASRA_KHATONI_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Khasra Khatoni Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/POLLUTION%20_CERTIFICATE_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Pollution Certificate Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/POSSESSION_CERTIFICATE_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Possession Certificate Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/RULAR_SALE_DEED_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          Rular Sale Deed Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/UPSIDC_REGISTRY_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          UPSIDC Registry Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/UPSIDC_TRANSFER_LETTER_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          UPSIDC transfer Letter Format
                                        </a>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cost_pdf">
                                        <img src="assets/img/pdf.png" alt="" />
                                        <a
                                          href="https://iwebapps.noidapower.com:8032/download/CHKDOC/TM_LETTER_GNIDA_FORMAT.pdf"
                                          target="_blank"
                                        >
                                          TM Letter GNIDA Format
                                        </a>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default LoadReductionAugmentationSample;
