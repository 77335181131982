import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Chart from "react-apexcharts";

const OurCustomer = () => {
  const series2 = [858, 3324, 95855, 520, 1249, 3554, 8, 342, 246, 751];
  const options2 = {
    chart: {
      width: "100%",
      type: "bar",
      color: "#ff0000",
    },
    labels: [
      "Large & Heavy Industry",
      "Small & Medium Industry",
      "Domestic Light, Fan & Power Supply",
      "Institutions",
      "Private Tubewell Connections",
      "Commercial Establishments",
      "State Tube well Connections",
      "Public Lighting",
      "Public Water Works",
      "Temporary Supplies",
    ],
    theme: {
      monochrome: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },
    title: {
      text: "",
    },

    legend: {
      show: true,
    },
    chart: {
      foreColor: "#373d3f",
    },
  };

  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/about-banner.png" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <div className="innerpagebanner"></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row">
              <div className="col-lg-12 brdcum_designs">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home</a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/" className="active">
                      Our Customer
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12 bg_cols">
                <div className="form_div">
                  <ul className="nav nav-tabs nav_tbds">
                    <li className="nav-item">
                      <Link
                        to="/company-profile"
                        className="nav-link "
                        data-toggle="tab"
                      >
                        Profile
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/energy-services"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Energy Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/initiativeundertaken"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Initiative Undertaken
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/our-customer"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        Our Customer
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/challangeahead"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Challenges Ahead
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://iwebapps.noidapower.com:8032/awards_recognition.aspx"
                        target="_blank"
                        className="nav-link"
                        //data-toggle="tab"
                      >
                        Award & Recognition
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/credit-rating"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        Credit Rating
                      </Link>
                    </li>
                  </ul>
                  <legend>Customer Profile</legend>
                  <p>
                    NPCL has a dynamic customer profile with domestic customers
                    constituting the largest share by volume, followed by
                    industrial and commercial customers. Institutional category
                    forms the next major segment while tubewells, temporary
                    supply and public services constitute the remaining bucket
                    of customers. Detailed bifurcation of overall consumer base
                    among various customer segments is shown over here.
                  </p>
                  <div className="row com_profile">
                    <div className="wrapper-customers-names-style">
                      <table className="table table-responsive table-inverse">
                        <tr>
                          <th>Category of Consumers</th>
                          <th>No. of Connections as on (31.03.2023)</th>
                        </tr>

                        <tr>
                          <td>Large & Heavy Industry </td>
                          <td>972</td>
                        </tr>
                        <tr>
                          <td>Small & Medium Industry </td>
                          <td>3601</td>
                        </tr>
                        <tr>
                          <td>Domestic Light, Fan & Power Supply </td>
                          <td>122861</td>
                        </tr>
                        <tr>
                          <td>Institutions </td>
                          <td>527</td>
                        </tr>
                        <tr>
                          <td>Private Tubewell Connections </td>
                          <td>1025</td>
                        </tr>
                        <tr>
                          <td>Commercial Establishments </td>
                          <td>4497</td>
                        </tr>
                        {/* <tr>
                          <td>State Tube well Connections </td>
                          <td>8</td>
                        </tr> */}
                        <tr>
                          <td>Public Lighting </td>
                          <td>421</td>
                        </tr>
                        <tr>
                          <td>Public Water Works </td>
                          <td>302</td>
                        </tr>
                        <tr>
                          <td>Other </td>
                          <td>1008</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>135214</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OurCustomer;
