import React from "react";
import { Link } from "react-router-dom";
import {useState} from 'react'
import Table from "../components/Table";
import Select from "react-select";
import { Component } from "react";
const TableData = [
  {
    Price1: 100,
    Price2: 230,
    Price3: 500,
    Price4: 780,
    Price5: 101,
    Price6: 567,
    Price7: 4356,
    Price8: 1234,
    Price9: 8790,
    Price10: 3242
  }
];
const options = [
  { value: "Lamps", label: "Lamps" },
  { value: "tubeLights", label: "tubeLights" },
  { value: "electricIron", label: "electricIron" },
  { value: "immersionHeater", label: "immersionHeater" },
  { value: "waterHeater", label: "waterHeater" },
  { value: "toaster", label: "toaster" },
  { value: "roomHeater", label: "roomHeater" },
  { value: "refrigerator", label: "refrigerator" },
  { value: "airConditioner", label: "airConditioner" },
  { value: "tableFan", label: "tableFan" },
  { value: "exhaustFan", label: "exhaustFan" },
  { value: "washingMachine", label: "washingMachine" },
  { value: "radio", label: "radio" },
  { value: "television", label: "television" },
  { value: "mixerGrinder", label: "mixerGrinder" },
  { value: "computer", label: "computer" },
  { value: "pumpMotor", label: "pumpMotor" }
];

export default class App extends Component {
  // Initialize the state variable with the a currency (USD in this case)
  state = {
    currency: options[0]
  }

  getConvertedPrice = (price) => {
    // assuming your prices TableData are in USD
    // I just pulled conversions from google for USD to currency ABC
    const { value } = this.state.currency;
    if (value === "Lamps") {
      return price.toFixed(2);
    }
    if (value === "tubeLights") {
      return (price * 2).toFixed(2);
    }
    if (value === "electricIron") {
      return (price * 2).toFixed(2);
    }
     if (value === "immersionHeater") {
      return (price * 2).toFixed(2);
    }
     if (value === "waterHeater") {
      return (price * 2).toFixed(2);
    }
     if (value === "toaster") {
      return (price * 2).toFixed(2);
    }
     if (value === "roomHeater") {
      return (price * 2).toFixed(2);
    }
     if (value === "refrigerator") {
      return (price * 2).toFixed(2);
    }
     if (value === "airConditioner") {
      return (price * 2).toFixed(2);
    }

     if (value === "tableFan") {
      return (price * 2).toFixed(2);
    }
     if (value === "exhaustFan") {
      return (price * 2).toFixed(2);
    }
    if (value === "washingMachine") {
      return (price * 2).toFixed(2);
    }
    if (value === "radio") {
      return (price * 2).toFixed(2);
    }
     if (value === "television") {
      return (price * 2).toFixed(2);
    }
     if (value === "mixerGrinder") {
      return (price * 2).toFixed(2);
    }
     if (value === "computer") {
      return (price * 2).toFixed(2);
    }
     if (value === "pumpMotor") {
      return (price * 2).toFixed(2);
    }
  };

  handleCurrencyChange = (changedCurrency) => {
    this.setState({currency: changedCurrency})
  }

  render() {

    return (
     <tr className="calculate_cont">
    <td className="name">
    <select name="country" id="countrySelect" size="1" onChange="makeSubmenu(this.value)">
<option disabled="" defaultValue="selected">Select</option>
 <option value="Lamps">Lamps </option>
<option value="tubeLights">Tube Lights </option>
<option value="electricIron">Electric Iron </option>
<option value="immersionHeater">Immersion Heater </option>
<option value="waterHeater">Water Heater/ Geyser </option>
<option value="toaster">Toaster</option>
<option value="roomHeater">Room Heater </option>
<option value="refrigerator">Refrigerator </option>
<option value="airConditioner">Window air- conditioner</option>
<option value="tableFan">Table fan/ Ceiling fan</option>
<option value="exhaustFan">Exhaust Fan </option>
<option value="washingMachine">Washing Machine </option>
<option value="radio">Radio </option>
<option value="television">Television </option>
<option value="mixerGrinder">Mixer-cum-Grinder </option>
<option value="computer">Computer </option>
<option value="pumpMotor">Pump Motor </option>
          </select>
    </td>
    <td className="name">
    <select id="citySelect" size="1" onChange="calculateDemo();">
  <option value="" disabled>Choose Option</option>
   <option></option>
   </select>
      <input name="LoadWatts1" type="hidden" id="lW" className="form-control t_sm-field" value="" />

    </td>
    <td className="city">
<input name="Equipments1" id="Equit" type="text" className="form-control t_sm-field" onChange="calculateDemo()" />

    </td>
    <td className='state'>
 <input readOnly="readOnly" name="Load0" id="d1" type="text" className="form-control t_sm-field" />
    </td>
    <td className='state'>
    <select name="LoadPerDay1" id="LoadPD" onChange="calculateDemo()" >
<option value="1">01</option>
<option value="2">02</option>
<option value="3">03</option>
<option value="4">04</option>
<option value="5">05</option>
<option value="6">06</option>
<option value="7">07</option>
<option value="8">08</option>
<option value="9">09</option>
<option value="10">10</option>
<option value="11">11</option>
<option value="12">12</option>
<option value="13">13</option>
<option value="14">14</option>
<option value="15">15</option>
<option value="16">16</option>
<option value="17">17</option>
<option value="18">18</option>
<option value="19">19</option>
<option value="20">20</option>
<option value="21">21</option>
<option value="22">22</option>
<option value="23">23</option>
<option value="24">24</option>
</select>
    </td>
    <td className='state'>
   <input name="unitPM1" type="text" id="uPM0" className="form-control t_sm-field" readOnly={true} />
    </td>
  </tr>
  )
}

}


