import React from "react";
import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import FixMenu from "../components/FixMenu";
import Header from "../components/Header";

const ConsumerOffer = () => {
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />

        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              backgroundImage:
                "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          ></div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container main_bdy">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.noidapower.com/">Home </a>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="active">
                      Consumer Offer
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="row bg_cols">
                <div className="col-sm-12 bg_cols">
                  <div className="form_div">
                    <h2 className="online_heading">Consumer Offer</h2>
                    <div className="rediobuttonstyle">
                      <form className="form">
                        <div className="plan">
                          <ul className="box_online">
                            <li>
                              <Link to="/rebate">
                                <img src="assets/images/rebate.jpg" alt="" />
                                Rebates
                              </Link>
                            </li>
                            <li>
                              <Link to="/consumerOfferjuggi">
                                <img src="assets/images/Other.jpg" alt="" />
                                Other Offers
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ConsumerOffer;
