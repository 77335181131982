import React from "react";
import Header2 from "../components/Header2";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import {useState} from 'react'

const CategoryChangeFeedback = () => {
   
  return (
    <>
         <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods consumersec">
        <div className="upperdesignds">
			<div className="row">
				<div className="col-md-1">
				<img className="bgstlsd" src="assets/img/small/payment-options.jpg" alt=""/>
			</div>
			<div className="col-md-11 textdesing">
							<h2 className="headtit">Category Change</h2>
			</div>
			</div>
		</div>
        <div className="container">
        <div class="row justify-content-center padtops">
        <div class="col-sm-12 form_div" style={{marginTop:"20px"}}>
          
        </div>
        </div>
       <div className="" id="first">
        
        <ul className="nav nav-pills" role="tablist">
        <li className="nav-item">
         <Link className="nav-link" data-toggle="pill" to="/categoryChange">Document Checklist</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/categoryChangeSample">Sample Documents</Link>
       </li>
        <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/categoryChangeFaqs">FAQ'S</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link" data-toggle="pill" to="/categoryChangeApply">Apply</Link>
       </li>
       <li className="nav-item">
        <Link className="nav-link active" data-toggle="pill" to="/categoryChangeFeedback">Feedback</Link>
       </li>
        </ul> 
       <div style={{marginTop:"20px"}}>
        <span style={{fontWeight:"bold",fontSize:'20px',marginLeft:'17px'}}>Feedback Fetching Soon?</span>
       </div>
        </div>
        </div>
        </section>
        </div>
    </>
  )
}

export default CategoryChangeFeedback