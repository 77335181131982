import React from 'react'
import Announcement from "../components/Announcement";
import Slider from "react-slick";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";

const Dosndnts = () => {
    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
      }
  return (
    <>
        <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner">
          <div
            className="innerpagebanner"
            style={{
              background: "url(" + "assets/img/inner/cost-data-book.jpg" + ")",
              backgroundSize: "100% auto",
            }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-sm-10">
                  <h1>Quick Pay</h1>
                </div>
              </div>
            </div>
          </div>
          <Announcement />
        </section>
        <section className="main_sectiods">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="#">Home </Link>
                    <span className="icondsds">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/login" className="active">
                    DO'S & DON'TS
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row justify-content-center padtops">
                <div className="row">
                  <div className="col-sm-3 imphdmdev">
                    <div className="inner_boxs">
                      <div className="mainwrapper">
                        <h4>Awards</h4>
                        <div className="slideraward slider">
                          <Slider {...settings2}>
                            <div className="awslider">
                              <img src="assets/img/awards-1.jpg" />
                            </div>

                            <div className="awslider">
                              <img src="assets/img/awards-3.jpg" />
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="our_other_links">
                        <h4>Other Links</h4>
                        <ul>
                          <li>
                            <Link to="#">
                              <span></span> Calculate Energy Consumption{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/cash-collection-centres" target="_blank">
                              <span></span>Payment Options{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/online-vendor-registration"
                              target="_blank"
                            >
                              <span></span>Vendor Registration{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <span></span>Regulation &amp; Statutory Bodies{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9 bg_cols">
                    <div className="form_div">
                    <div class="main_wrappersd">
								<div class="profile_cont">
									<h2>DO'S & DON'TS</h2>
									<ol>
										<li>Do not replace the FUSE, if you do not know the process.
										</li>
										<li>Do not fix the fuse in dark.
										</li>
										<li>Do not allow children to touch switches and plug points.
										</li>
										<li>Do not repair the broken covers of switches, sockets and plug points, better change the covers.
										</li>
										<li>Do not try to stop the flow of electricity by holding wires, stop the supply in proper manner by switching it off.
										</li>
										<li>Carefully, switch off the flow of electricity before repairs of switches or other electric points. If necessary, stop the supply from the cut-outs or the main switch.
										</li>
										<li>Do not touch SWITCH and PLUG POINTS with wet hands.
										</li>
										<li>Do not use electrical appliances, without adequate earthing.
										</li>
										<li>Do not repair any electric appliance without switching it off.
										</li>
										<li>Do not come in contact with any live-wire appliance, switch it off before rushing to rescue a person. In case it is not possible, then the affected person may be separated from the electrical contact, by applying one of the following techniques.
										</li>
										<li>Remove the affected person from the electrical contact with the help of a dry piece of wood or any other bad conductor of electric current.
										</li>
										<li>If the clothes of the affected person are dry, then he may be pulled away by holding the clothes.
										</li>
										<li>Stand on a dry wooden plank or stool to pull the affected person away from contact with electricity.
										</li>
									</ol>
								</div>
							</div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section>
        </div>
    </>
  )
}

export default Dosndnts